import { CRUDApiService } from "./CRUDApiService";
import { TestCabecalhoDataType } from "app/types";

export interface DashboardApiService {
  getTestCabelhoDashAndUser(userId: number): Promise<TestCabecalhoDataType[]>;
}

export class DashboardApiServiceImpl extends CRUDApiService
  implements DashboardApiService {
  constructor() {
    super("dashboard");
  }

  async getTestCabelhoDashAndUser(
    userId: number
  ): Promise<TestCabecalhoDataType[]> {
    const response = await this.makeHttpRequest({
      url: `/testcabecalhodashanduser?UserId=${userId}`
    });
    return response?.result as Array<TestCabecalhoDataType>;
  }
}

import React from "react";
import { useSelector } from "react-redux";

import "./Styles.scss";
import Br from './br';

import { useTranslation } from "_metronic/i18n/language";

export default function PrivacyPolicyPage() {
  const translate = useTranslation();

  window.setPageTitle(translate("defaultMessages.privacyPolicy"));
  return <Br />;
}

import { SHOW_SPINNER, HIDE_SPINNER } from "../actions/spinner.actions";

const initialState = {
  show: false,
};

export default function spinner(state = initialState, action) {
  switch (action.type) {
    case SHOW_SPINNER:
    case HIDE_SPINNER:
      return {
        ...state,
        show: action.payload.show,
      };
    default:
      return state;
  }
}

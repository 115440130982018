import { HTMLAttributes, PropsWithChildren } from "react";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

type WrapperProps = PropsWithChildren &
  HTMLAttributes<HTMLDivElement> & { headerTitle?: string };

export const Wrapper = ({ children, headerTitle, ...rest }: WrapperProps) => {
  const { isDarkMode } = useThemeApp();
  const theme = isDarkMode ? "dark" : "light";
  const classByTheme = rest.className?.split(" ");
  classByTheme?.push(`form-${theme}`);
  rest.className = classByTheme?.join(" ");

  return (
    <div {...rest}>
      {headerTitle && (
        <div className={`card-header header-form header-form-${theme}`}>
          {headerTitle}
        </div>
      )}
      {children}
    </div>
  );
};

import { DebouncedFunc } from "lodash";
import {
  ActionMeta,
  CSSObjectWithLabel,
  GroupBase,
  InputActionMeta,
  Options,
  OptionsOrGroups,
  SingleValue
} from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Accessors } from "react-select/dist/declarations/src/useCreatable";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

type AsyncCreatableSelectComponentProps = {
  value: string;
  isDisabled?: boolean;
  loadOptions: DebouncedFunc<(inputValue: any, callback: any) => void>;
  placeholder: string;
  className: string;
  createOptionPosition: "first" | "last" | undefined;
  isValidNewOption:
    | ((
        inputValue: string,
        value: Options<string>,
        options: OptionsOrGroups<string, GroupBase<string>>,
        accessors: Accessors<string>
      ) => boolean)
    | undefined;
  noOptionsMessage: () => string;
  loadingMessage: () => string;
  onChange:
    | ((newValue: SingleValue<string>, actionMeta: ActionMeta<string>) => void)
    | undefined;
  onInputChange:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  formatCreateLabel: ((inputValue: string) => React.ReactNode) | undefined;
};

export const AsyncCreatableSelectComponent = ({
  value,
  isDisabled,
  loadOptions,
  placeholder,
  className,
  createOptionPosition,
  isValidNewOption,
  noOptionsMessage,
  loadingMessage,
  onChange,
  onInputChange,
  formatCreateLabel
}: AsyncCreatableSelectComponentProps) => {
  const { isDarkMode } = useThemeApp();

  return (
    <AsyncCreatableSelect
  value={value}
  cacheOptions
  defaultOptions
  isDisabled={isDisabled}
  loadOptions={loadOptions}
  placeholder={placeholder}
  noOptionsMessage={noOptionsMessage}
  loadingMessage={loadingMessage}
  className={className}
  onChange={onChange}
  onInputChange={onInputChange}
  isValidNewOption={isValidNewOption}
  createOptionPosition={createOptionPosition}
  formatCreateLabel={formatCreateLabel}
  styles={{
    control: (base: any, props) => ({
      ...base,
      borderColor: isDarkMode ? "#262626" : "#ebedf2",
      borderWidth: 2,
      backgroundColor: "inherit",
      marginBottom: "12px",
    }),
    option: (base: any, state) => ({
      ...base,
      backgroundColor: isDarkMode
        ? state.isFocused
          ? "#0d0d0d"
          : "#262626"
        : state.isFocused
        ? "lightgray"
        : "inherit",
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: isDarkMode ? "#262626" : "#fff",
    }),
    input: (base: any) => ({
      ...base,
      color: "inherit",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: isDarkMode ? "#fff" : "inherit",
    }),
  }}
/>

  );
};

import Logo from "assets/images/logo.png";
import { useRTL } from "themes/RTLProvider/RTLProvider";

const NoarLogo = () => {
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  return (
    <div>
      <img
        className="folhaLogo"
        src={Logo}
        alt="NoarLogo"
        style={{ transform: valueTransformIfRtl }}
      />
    </div>
  );
};

export default NoarLogo;

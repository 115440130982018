import {
  Block,
  Delete,
  SendSharp,
  RemoveCircleOutline
} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Dialog,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import ConfirmChangeStatusDialog from "app/partials/datatable/ConfirmChangeStatusDialog";
import MenuComponent from "components/Menu";
import store from "app/store/store";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import { useTranslation } from "_metronic/i18n/language";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}));

interface MenuInterface {
  icon: React.ReactElement;
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined | any;
}

interface IAlert {
  show: boolean;
  title: string;
  message: string;
  onConfirm: any;
}

interface Props {
  title: string & React.ReactNode;
  subheader: string & React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined | any;
  menus?: MenuInterface[];
  avatar?: boolean;
  dataLength?: number;
  handleRemove?: () => void | null;
  handleDisassociate?: () => void | null;
  handleStatus?: () => void;
}

const CardList: React.FC<Props> = ({
  subheader,
  title,
  onClick,
  menus,
  avatar,
  dataLength,
  handleRemove,
  handleDisassociate,
  handleStatus
}) => {
  const translate = useTranslation();
  const { auth } = store.getState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [alert, setAlert] = React.useState<IAlert>({} as IAlert);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Card sx={{ marginY: 1 }}>
      <ConfirmDialog
        open={alert.show}
        closeDialog={() => setAlert({ show: false } as IAlert)}
        confirm={alert.onConfirm}
        message={alert.message}
        title={alert.title}
      />
      <CardHeader
        avatar={
          avatar && (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar alt={title} src="/static/images/avatar/1.jpg" />
            </StyledBadge>
          )
        }
        action={
          handleRemove || handleStatus || handleDisassociate ? (
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          ) : null
        }
        title={title}
        subheader={subheader}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {handleRemove && (
          <MenuItem
            onClick={() => {
              handleClose();
              setAlert({
                show: true,
                onConfirm: handleRemove,
                title: translate("deleteRegister.title"),
                message: translate("deleteRegister.message")
              });
            }}
          >
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {translate("deleteRegister.delete")}
            </ListItemText>
          </MenuItem>
        )}
        {handleDisassociate && (
          <MenuItem
            onClick={() => {
              handleClose();
              setAlert({
                show: true,
                onConfirm: handleDisassociate,
                title: translate("disassociateRegister.reallyDisassociate"),
                message: translate("disassociateRegister.confirmDisassociate")
              });
            }}
          >
            <ListItemIcon>
              <RemoveCircleOutline fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {translate("disassociateRegister.disassociate")}
            </ListItemText>
          </MenuItem>
        )}
        {handleStatus && (
          <MenuItem
            onClick={() => {
              handleClose();
              if (dataLength === auth.user.usersLicense) {
                setAlert({
                  show: true,
                  onConfirm: null,
                  title: translate("labels.attention"),
                  message: translate("message_limitUser")
                });
                return;
              }
              setAlert({
                show: true,
                onConfirm: handleStatus,
                title: translate("changeStatus.confirmTitle"),
                message: translate("changeStatus.message")
              });
            }}
          >
            <ListItemIcon>
              <Block fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {translate("labels_activeInactive")}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Card>
  );
};

export default CardList;

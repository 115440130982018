import { format, Locale } from "date-fns";
import { ptBR, enUS, es, de, fr, it } from "date-fns/locale";

const languageLocales: { [key: string]: Locale } = {
  en: enUS,
  pt: ptBR,
  es: es,
  fr: fr,
  it: it,
  de: de
};

export function formatedDate(
  date: Date | string = new Date(),
  type = false,
  hours = false,
  locale = "pt"
): string {
  const newvalue = new Date(date);

  const options = {
    locale: languageLocales[locale]
  };

  if (type && hours) {
    return format(newvalue, "Pp", options);
  }

  if (type) {
    return format(newvalue, "P", options);
  }
  if (locale && hours) {
    return format(newvalue, "PPp", options);
  }

  return format(newvalue, "PPP", options);
}

export function formatedHours(date: Date = new Date()) {
  const newvalue = new Date(date);
  return newvalue
    .toTimeString()
    .toString()
    .slice(0, 8);
}

function leftZero(value: number) {
  return value.toString().padStart(2, "0");
}

export const maskDate = (value: any) => {
  let birthDate = value;

  if (value?.length === 8) {
    // TODO: Consider validating the input
  }
  birthDate = birthDate.replace(/\D/g, "");
  birthDate = birthDate.replace(/(\d{2})/, "$1/");
  birthDate = birthDate.replace(/(\d{2})(\d{4})$/, "$1/$2");

  return birthDate;
};

export const formatDateTime = () => {
  const newDate = new Date();
  return (
    leftZero(newDate.getDate()) +
    "/" +
    leftZero(newDate.getMonth() + 1) +
    "/" +
    newDate.getFullYear() +
    " " +
    newDate
      .toTimeString()
      .toString()
      .slice(0, 5)
  );
};

export const formatDate = (date: any) => {
  const newDate = new Date(date);
  return newDate
    .toUTCString()
    .toString()
    .slice(5, 16);
};

import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { useRTL } from "themes/RTLProvider/RTLProvider";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

interface Props {
  title?: string;
  route?: string;
}

export default function BackWithTitle({ title, route }: Props) {
  const isRtl = useRTL();
  const arrowIcon = isRtl ? <ArrowForward /> : <ArrowBack />;
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      justifyContent="flex-start"
    >
      <IconButton aria-label="voltar">
        <Link to={route ?? "/admin/account"} className="back-button">
          {arrowIcon}
        </Link>
      </IconButton>
      <h3>{title}</h3>
    </Stack>
  );
}

import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import React, { useCallback, useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Card,
  CardContent,
  Grid,
  Box,
  styled,
  Paper,
  Tooltip,
  Button,
  ButtonGroup
} from "@mui/material";
import Datatable from "app/partials/datatable/Datatable";
import CrudActions from "app/partials/datatable/CrudActions";
import store from "app/store/store";
import TableData from "app/partials/datatable/TableData";
import {
  ChatBubble,
  ChatBubbleOutline,
  Edit,
  InsertComment,
  Message,
  ModeComment,
  OpenInBrowser,
  RateReview
} from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, useParams } from "react-router-dom";
import DataTable from "./components/DataTable";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ModalResult from "./components/ModalResult";
import jsPDF from "jspdf";
import { resultSheet } from "./components/renderPDF";
import { IAccount, UseSelectorI } from "interfaces";
import { format, addHours } from "date-fns";
import { formatedDate } from "utils";
import BackWithTitle from "./components/BackWithTitle";
import { titlePage } from "utils/titlepage";
import Loading from "app/pages/home/components/Loading";
import DetailResultForm from "./DetailResultForm";
import { useTranslation } from "_metronic/i18n/language";

interface ParamsI {
  id: number | undefined;
  name: string;
}

interface ResultsI {
  testResultId: any;
  quizAnswer: Array<any>;
  anamneseResultId?: any;
}

const data = [
  {
    testResultId: "jdjfjj-kkdsj-s99f94n-jmk33n3n3",
    quizAnswer: [{ rightAnswer: "menta" }],
    anamneseResultId: "458223-kkdsj-s99f94n-jmk33n3n3"
  },
  {
    testResultId: "jdjfjj-noar-s99f94n-jmk33n3n3",
    quizAnswer: [{ rightAnswer: "menta" }],
    anamneseResultId: null
  },
  {
    testResultId: "458223-kkdsj-s99f94n-jmk33n3n3",
    quizAnswer: [{ rightAnswer: "N/A" }],
    anamneseResultId: null
  }
];
const ResultListing = () => {
  const translate = useTranslation();
  const api = new AnanseApiService();
  const params: any = useParams();
  const account: ParamsI | undefined = params;
  const endPoint = `/quiz/accountid/${account?.id}`;
  const [loading, setLoading] = useState(false);
  const [isOpenDetailResult, setOpenDetailResult] = useState(false);
  const [results, setResults] = useState<Array<ResultsI>>([]);
  const [anamneses, setAnamneses] = useState<Array<ResultsI>>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [itemData, setItemData] = React.useState(null);
  const [itIsAnamnese, setIsItAnamnese] = React.useState(false);
  const [accountSelected, setAccountSelected] = React.useState({} as IAccount);
  const [resultDetails, setResultDetails] = React.useState<any[]>([]);

  const loadRecords = async () => {
    setLoading(true);
    try {
      getAccount(account?.id);
      const result = await api.makeHttpRequest({ url: endPoint });

      const sortedResult = result.sort(
        (a: any, b: any) =>
          new Date(b.quizAnswer[0].initDate).getTime() -
          new Date(a.quizAnswer[0].initDate).getTime()
      );

      const filterAnamnese = sortedResult.filter((ai: ResultsI) =>
        ai.quizAnswer.some((v: any) => v.rightAnswer === "N/A")
      );

      const filterResults = sortedResult.filter((ai: ResultsI) =>
        ai.quizAnswer.some((v: any) => v.rightAnswer !== "N/A")
      );

      setAnamneses(filterAnamnese);
      setResults(filterResults);
    } catch (error) {
      Swal.fire(
        translate("defaultMessages.error"),
        translate("defaultMessages.loadData"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRecords();
  }, []);

  const getAccount = async (
    accountId: number | undefined
  ): Promise<IAccount | any> => {
    try {
      const response: IAccount = await api.makeHttpRequest({
        url: `/account/${accountId}`
      });
      setAccountSelected(response);
    } catch (error) {
      console.error("error getAccount: ", error);
    }
  };

  const headColumns = [
    { id: "none", label: translate("screens.dashboard.action") },
    { id: "createdAt", label: translate("screenApp.results.examDate") },
    {
      id: "testResultId",
      label: translate("screenApp.results.numberTest")
    },
    { id: "doctor", label: translate("screenApp.results.title") },
    { id: "email", label: translate("screens.login.email") },
    { id: "macAddress", label: translate("screens.device.title") }
  ];

  const gerarResultado = (value: any) => {
    setItemData(value);
    setIsItAnamnese(false);
    setOpenModal(true);
  };

  const gerarAnamnese = (item: any) => {
    const filterAnamnese: any = anamneses?.find(
      value => value?.testResultId === item?.quizAnswer[0]?.anamneseResultId
    );
    setItemData(filterAnamnese);
    setIsItAnamnese(true);
    setOpenModal(true);
  };

  const onOpenDetailResult = (item: any) => {
    setItemData(item);
    setOpenDetailResult(true);
  };

  const getResultDetails = useCallback(async () => {
    const { total } = await api.makeHttpRequest({
      method: "GET",
      url: `/quizanswerdetails`
    });
    const { data } = await api.makeHttpRequest({
      method: "GET",
      url: `/quizanswerdetails?PerPage=${total}`
    });
    setResultDetails(data);
    return data.length;
  }, [isOpenDetailResult]);

  useEffect(() => {
    getResultDetails();
  }, [getResultDetails]);

  const formatRows = (row: any, labelId: any) => {
    const filterRD = resultDetails.find(
      r => r.testResultId === row.testResultId
    );

    return (
      <TableRow
        hover
        tabIndex={-1}
        key={row.testResultId}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <ButtonGroup size="small" aria-label="small button group">
            <Tooltip
              title={translate("screenApp.anamnese.list.commentResults")}
            >
              <IconButton size="small" onClick={() => onOpenDetailResult(row)}>
                {filterRD ? (
                  <Message fontSize="small" />
                ) : (
                  <ChatBubbleOutline
                    fontSize="small"
                    //  sx={{ color: "#7e7c7c" }}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={translate("screenApp.anamnese.list.viewResults")}
            >
              <Button
                size="small"
                variant="contained"
                onClick={() => gerarResultado(row)}
              >
                {translate("screenApp.results.screenName")}
              </Button>
            </Tooltip>
            {row?.anamneseResultId && !!row?.anamneseResultId ? (
              <Tooltip
                title={translate("screenApp.anamnese.list.viewAnamnese")}
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => gerarAnamnese(row)}
                >
                  {translate("screenApp.anamnese.list.anamneseTitle")}
                </Button>
              </Tooltip>
            ) : null}
          </ButtonGroup>
        </TableCell>
        <TableCell align="inherit">
          {row.quizAnswer[0]?.createdAt
            ? formatedDate(row.quizAnswer[0]?.createdAt, true, true)
            : null}
        </TableCell>
        <TableCell id={labelId} align="inherit">
          {row.testResultId}
        </TableCell>
        <TableCell id={labelId} align="inherit">
          {row.quizAnswer[0]?.doctor}
        </TableCell>
        <TableCell id={labelId} align="inherit">
          {row.quizAnswer[0]?.email}
        </TableCell>
        <TableCell align="inherit">{row.quizAnswer[0]?.macAddress}</TableCell>
      </TableRow>
    );
  };

  React.useEffect(() => {
    titlePage(`${nameTitle} - ${translate("labels_admin")}`);
  }, [accountSelected?.name]);

  const nameTitle =
    translate("screenApp.anamnese.list.resultsOf") + accountSelected?.name;

  return (
    <div>
      <DetailResultForm
        open={isOpenDetailResult}
        setOpen={setOpenDetailResult}
        data={{ account: accountSelected }}
        result={isOpenDetailResult ? itemData : null}
        setResult={setItemData}
      />
      <ModalResult
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={itemData}
        setData={setItemData}
        accountId={account?.id}
        itIsAnamnesis={itIsAnamnese}
      />
      <Loading isLoading={loading} />
      <DataTable
        titleTable={<BackWithTitle title={nameTitle} />}
        headColumns={headColumns}
        rows={results}
        formatRows={formatRows}
        placeholderSearch={
          translate("screenApp.anamnese.list.search") +
          " " +
          translate("screenApp.results.numberTest")
        }
        loading={loading}
        onRefresh={loadRecords}
      />
    </div>
  );
};

export default ResultListing;

import { useState } from "react";

export const useTestNamesFilter = () => {
  const [selectedTestNames, setSelectedTestNames] = useState([] as string[]);

  const handleTestNamesChange = (testNames: Array<string>): void => {
    setSelectedTestNames(testNames);
  };

  return { selectedTestNames, handleTestNamesChange };
};

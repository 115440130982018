import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  TableHead,
  TableSortLabel,
  Paper
} from "@mui/material";
// import translate from "@noar-health/commons";
import { Portlet, PortletBody } from "../content/Portlet";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

type HeadRow = {
  column?: string;
  label?: string;
  align?: "right" | "left" | "inherit" | "center" | "justify";
  disablePadding?: boolean;
  size?: "small" | "medium";
  width?: string | number;
};

type EnhancedTableProps = {
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  headRows: HeadRow[];
};

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
  headRows
}) => {
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            key={row.column || index}
            align={row.align || "left"}
            padding={row.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === row.column ? order : false}
            size={row.size || "medium"}
            style={{ width: row.width || "auto" }}
          >
            {row.column ? (
              <TableSortLabel
                active={orderBy === row.column}
                direction={order}
                onClick={createSortHandler(row.column)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              row.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type Row = {
  [key: string]: any;
};

type DatatableStandaloneProps = {
  rows: Row[];
  headRows: HeadRow[];
  formatRow: (row: Row) => React.ReactNode;
  className?: string;
  widthTable?: number;
  orderBy?: string;
  endpoint?: string;
};

type Order = "asc" | "desc";

type Comparator<T> = (a: T, b: T) => number;

function stableSortTS<T>(
  array: any[],
  comparator: Comparator<T>,
  order: Order = "asc"
): T[] {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const orderResult = comparator(a[0], b[0]);

    if (orderResult !== 0) {
      return order === "asc" ? orderResult : -orderResult;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function getComparator<T>(order: Order, orderBy: keyof T): Comparator<T> {
  return order === "desc"
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  return ("" + b[orderBy]).localeCompare("" + a[orderBy]);
}

const DatatableStandalone: React.FC<DatatableStandaloneProps> = ({
  rows,
  headRows,
  formatRow,
  className,
  widthTable,
  orderBy: propOrderBy,
  endpoint
}) => {
  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>(
    propOrderBy || headRows[0].column || "id"
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedRows, setSortedRows] = useState(rows);

  useEffect(() => {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    if (!!endpoint && paginationState[endpoint]) {
      setOrder(paginationState[endpoint].order || "asc");
      setRowsPerPage(paginationState[endpoint].perPage || 10);
    }
  }, [endpoint]);

  useEffect(() => {
    setSortedRows(stableSortTS(rows, getComparator(order, orderBy)));
  }, [rows, order, orderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = sortedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className={`col-xl-12 ${className}`} style={{ marginTop: "10px" }}>
      <Portlet fluidHeight={true}>
        <PortletBody fit={true}>
          <Paper sx={{ width: "100%", mb: 0, overflowX: "auto" }}>
            <Table sx={{ minWidth: widthTable || 400 }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headRows={headRows}
              />
              <TableBody>
                {paginatedRows.length > 0 ? (
                  paginatedRows.map(row => formatRow(row))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={headRows.length}
                      className="text-center"
                    >
                      {translate("datatable.nothingData")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${translate("datatable.of")} ${count}`
              }
              labelRowsPerPage={translate("datatable.labelRowsPerPage")}
              backIconButtonProps={{
                "aria-label": translate("datatable.backButton"),
                style: { transform: valueTransformIfRtl }
              }}
              nextIconButtonProps={{
                "aria-label": translate("datatable.nextButton"),
                style: { transform: valueTransformIfRtl }
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </PortletBody>
      </Portlet>
    </div>
  );
};

export default DatatableStandalone;

import React from "react";
import Button from "@mui/material/Button/";
import Dialog from "@mui/material/Dialog/";
import DialogActions from "@mui/material/DialogActions/";
import DialogContent from "@mui/material/DialogContent/";
import DialogContentText from "@mui/material/DialogContentText/";
import DialogTitle from "@mui/material/DialogTitle/";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  open?: any;
  closeDialog?: any;
  confirm?: any;
  title?: any;
  message?: any;
}
export default function ConfirmDeleteDialog({
  open,
  closeDialog,
  confirm,
  title,
  message
}: Props) {
  const translate = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title && title !== ""
            ? title
            : translate("deleteRegister.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && message !== ""
              ? message
              : translate("deleteRegister.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={closeDialog}>
            {translate("buttons.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={confirm}
            color="success"
            autoFocus
          >
            {translate("buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

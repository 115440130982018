import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { generateColorPalette } from "app/utils/generateColorPalette";

interface RadarChartProps {
  labels: string[];
  data?: number[][];
  series?: {
    name: string;
    data: number[];
  }[];
}

const RadarChart: React.FC<RadarChartProps> = ({ labels, data, series }) => {
  const seriesData =
    series ||
    data?.map((item, index) => ({
      name: `${index + 1}`,
      data: item
    })) ||
    [];
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: labels
    },
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        borderRadius: 2
      }
    },
    colors: generateColorPalette(seriesData.length)
  };

  return (
    <Chart options={options} series={seriesData} type="radar" height={500} />
  );
};

export default RadarChart;

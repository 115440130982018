import styled from "styled-components";
import { TextField } from "@mui/material";

export const CentralizedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .subtitle {
    text-align: center;
    margin-bottom: 30px;
  }

  margin: ${props => props.margin || "auto"};
  outline: none;
`;

export const Requisitos = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;

  margin-top: 40px;

  span {
    font-size: 14px;
  }
`;

const maxWidth = 450;
export const StyledInput = styled(TextField)`
  display: block;
  margin: 10px 0 !important;

  max-width: ${maxWidth + "px"};

  input {
    font-size: 18px;
    padding: 7px 5px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  width: ${props => props.width || "auto"};
`;

export const Button = styled.button`
  outline: none;
  border: none;

  max-width: ${maxWidth + "px"};

  padding: 10px 25px;
  font-size: 16px;
  border-radius: 5px;

  color: white;
  background: #5867dd;

  text-align: center;
`;

export const ModalContainer = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  outline: none;
  text-align: center;

  @media (max-width: 600px) {
    margin: 0 20px;
  }

  button {
    margin-right: 10px;
  }

  div {
    @media (min-width: 810px) {
      &.success {
        width: 40%;
      }
    }
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  span {
    display: block;
    margin: 0;

    &.title {
      color: ${props => props.color || "black"};
      font-weight: bold;
      font-size: 24px;
      text-transform: uppercase;
    }

    &.subtitle {
      font-size: 14px;
      margin: 0;
    }
  }
`;

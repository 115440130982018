import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Chart from "react-apexcharts";
import { ISelectSearchQuiz } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { SelectPage } from "components";
import Loading from "app/pages/home/components/Loading";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

const DashBoard: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const { auth } = store.getState();
  const { isDarkMode } = useThemeApp();

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [quizSelected, setQuizSelected] = React.useState(
    {} as ISelectSearchQuiz
  );
  const [quizChartSelected, setQuizChartSelected] = React.useState({} as any);

  const api = new AnanseApiService();

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getCharts = async (quizId: number) => {
    setLoading(true);
    try {
      if (quizId) {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `dashboard/report?QuizId=${quizId}&UserId=${auth.user.id}`
        });
        setQuizChartSelected(response);
      }
    } catch (error) {
      console.error("Erro ao carregar gráficos: ${error}");
    } finally {
      setLoading(false);
    }
  };

  const calculatePercentage = React.useCallback(() => {
    const percentages: Array<any> = [];
    const questions = quizSelected?.obj?.questions?.length ?? 20;
    const reportGraf = quizChartSelected?.reportGraf ?? [];
    for (let i = 0; i < questions; i++) {
      const report = reportGraf.find((item: any) => item.acertos === i + 1);
      const qtdTestes = report ? report.qtdTestes : 0;
      const porcent = (
        (qtdTestes / quizChartSelected?.totalTestesRealizados) *
        100
      ).toFixed(2);
      percentages.push(parseFloat(porcent));
    }

    return percentages;
  }, [quizSelected, quizChartSelected]);

  const quizChartData = React.useCallback(() => {
    const result = calculatePercentage();
    const questions = quizSelected?.obj?.questions?.length ?? 20;
    const series: Array<any> = result ?? [];
    const seriesAcertos: Array<any> = [];
    if (questions) {
      for (let i = 1; i <= questions; i++) {
        seriesAcertos.push(i);
      }
    }
    return {
      series,
      seriesAcertos
    };
  }, [quizSelected, quizChartSelected]);

  const defaultOptions = {
    tooltip: {
      followCursor: true,
      style: {
        fontSize: "14px"
      }
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.1
        }
      }
    }
  };

  const options: any = {
    ...defaultOptions,
    chart: {
      id: "chart2",
      animations: {
        enabled: true,
        speed: 800,
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "95%",
        dataLabels: {
          position: "center"
        }
      }
    },
    series: [
      {
        name: translate("screenApp.results.details.percentageHits"),
        type: "column",
        data: quizChartData()?.series
      }
    ],
    xaxis: {
      categories: quizChartData()?.seriesAcertos
    },
    dataLabels: {
      enabled: true,
      formatter: function(val: any) {
        return val + "%";
      },
      offsetY: 0,
      style: {
        fontSize: "10px",
        colors: ["#ffffff"]
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: function(val: any) {
          return val + "%";
        }
      }
    },
    title: {
      text: quizSelected.label,
      floating: true,
      offsetY: 0,
      align: "center",
      style: {
        color: isDarkMode ? "#FFF" : "#000",
        marginBottom: 12
      }
    }
  };

  const replaceDecimal = (value: any, decimal = 2) => {
    const text = value?.toFixed(decimal);
    return parseFloat(text) ?? "0";
  };

  const data = [
    {
      title: translate("screenApp.results.details.average"),
      value: quizChartSelected?.media
    },
    {
      title: translate("screenApp.results.details.standardDeviation"),
      value: replaceDecimal(quizChartSelected?.desvioPadrao, 5)
    },
    {
      title: translate("screenApp.results.details.median"),
      value: quizChartSelected?.mediana
    },
    {
      title: translate("screenApp.results.details.minimum"),
      value: quizChartSelected?.minAcertos
    },
    {
      title: translate("screenApp.results.details.maximum"),
      value: quizChartSelected?.maxAcertos
    },
    {
      title: translate("screenApp.results.details.percentile") + " 10%",
      value: quizChartSelected?.percentil10
    },
    {
      title: translate("screenApp.results.details.percentile") + " 5%",
      value: quizChartSelected?.percentil5
    },
    {
      title: translate("test.numbers"),
      value: quizChartSelected?.totalTestesRealizados
    }
  ];

  const ChartBar = () => {
    return (
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" component="h2">
            {translate("dashboard_label_one")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectPage
            label={translate("screens.quiz.graphic")}
            endPoint={`/quiz?Type=1&UserId=${auth.user.id}&PerPage=1000`}
            value={quizSelected.id}
            // showTotal
            handleChange={(e: any) => {
              const value = e;
              setQuizSelected(value);
              getCharts(value.id);
            }}
            convertObject={(obj: any) => ({
              id: obj.quizId,
              value: obj.quizId,
              label: `${obj.name}`,
              obj
            })}
          />
          {quizSelected.id ? (
            <Grid container spacing={2} marginTop={2}>
              <Grid xs={3}>
                <Table size="small">
                  <TableHead>
                    <TableCell>
                      {translate("screenApp.results.details.totalHits")}
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {data.map(item => (
                      <TableRow
                        key={item.value}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                      >
                        <TableCell align="left">{item.title}</TableCell>
                        <TableCell align="left">{item.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid xs={9} sx={{ transform: valueTransformIfRtl }}>
                <Chart
                  options={options}
                  type="bar"
                  series={options.series}
                  height={500}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography
              variant="h5"
              component="h4"
              align="center"
              marginTop={2}
            >
              {translate("screens.quiz.graphic2")}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 5 }}>
      <Loading isLoading={loading} />
      <ChartBar />
    </Box>
  );
};

export default DashBoard;

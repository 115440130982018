import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import { TableRow, TableCell, Checkbox } from "@mui/material";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import Swal from "sweetalert2";
import FormatDocument from "../../../../utils/FormatDocument";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";

export class SendEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      isLoading: false,
      user: "",
      filter: {},
      selectAll: false,
      checked: [],
      reload: false,
      count: 0,
      sucess: false,
      error: "",
      html: "",
      assunto: "",
      resetEditor: false
    };

    this.api = new AnanseApiService();
    this.setUsers = this.setUsers.bind(this);
  }

  setUsers(users) {
    this.setState({ users });
  }

  setChecked(r) {
    let listChecked = this.state.checked;
    if (listChecked.indexOf(r.id) === -1) {
      listChecked.push(r.id);
    } else {
      listChecked.splice(listChecked.indexOf(r.id), 1);
    }
    this.setState({ selectAll: false, checked: listChecked });
  }

  setSelectAll() {
    this.setState({ selectAll: !this.state.selectAll });
  }

  isChecked(r) {
    let listChecked = this.state.checked;
    if (listChecked.indexOf(r.id) === -1) {
      return false;
    } else {
      return true;
    }
  }

  showMensage(sucess, error) {
    if (error) {
      Swal.fire(
        "Erro!",
        error
          ? error
          : "Erro ao envias os e-mails, tente novamente mais tarde.",
        "error"
      );
    } else if (sucess) {
      Swal.fire({
        title: "Sucesso!",
        text: "Emails disparados com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    }
  }

  formataModificacoes(user) {
    let data;
    let dataModificacao = null;
    let dataExclusao = null;
    let dataCriacao = null;
    if (user.dataModificação) {
      dataModificacao = Date.parse(user.dataModificação);
    }
    if (user.dataExclusao) {
      dataExclusao = Date.parse(user.dataExclusao);
    }
    if (user.dataCriacao) {
      dataCriacao = Date.parse(user.dataCriacao);
    }

    if (dataModificacao && dataModificacao > dataExclusao) {
      data = dataModificacao;
    } else if (dataExclusao && dataExclusao > dataModificacao) {
      data = dataExclusao;
    } else {
      data = dataCriacao;
    }

    return Moment(data).format("DD/MM/YYYY");
  }

  sendEmails = async () => {
    const reqBase = { method: "POST", url: "/emailparametrizavel" };
    try {
      this.setState({ isLoading: true });
      const response = await this.api.makeHttpRequest({
        ...reqBase,
        data: {
          filter: this.state.filter,
          listID: !this.state.selectAll ? this.state.checked : null,
          body: this.state.html,
          assunto: this.state.assunto
        }
      });
      if (response) {
        this.setState({ isLoading: false });
        this.setState({
          resetEditor: !this.state.resetEditor,
          reload: !this.state.reload,
          selectAll: false,
          checked: []
        });
        this.showMensage(true, "");
      }
    } catch (error) {
      this.setState({ isLoading: false });
      if (error.response && error.response.data) {
        this.showMensage(false, error.response.data);
      } else {
        this.showMensage(false, "Falha ao disparar os e-mails");
      }
    }
  };

  render() {
    window.setPageTitle("Enviar E-mails Personalizados - Admin");

    const headRows = [
      {
        label: (
          <div className="row ml-1 mt-1">
            <Checkbox
              color="secondary"
              name="selectAll"
              onChange={() => this.setSelectAll()}
              checked={this.state.selectAll}
            />
          </div>
        ),
        align: "center"
      },
      { column: "Nome", label: "Nome" },
      { column: "Email", label: "Email" },
      { column: "CpfCnpj", label: "CPF" },
      { column: "Organizacao.Nome", label: "Organização" },
      { column: "Perfil.Nome", label: "Perfil" },
      { column: "Ativo", label: "Status" },
      { column: "DataCriacao", label: "Data de criação" }
    ];

    const formatRow = r => (
      <TableRow hover tabIndex={-1} key={r.id}>
        <TableCell width="40px" align="center" scope="row">
          <Checkbox
            color="secondary"
            name={r.nome}
            onChange={() => {
              this.setChecked(r);
            }}
            checked={this.isChecked(r) || this.state.selectAll}
          />
        </TableCell>
        <TableCell scope="row">{r.nome}</TableCell>
        <TableCell scope="row">{r.email}</TableCell>
        <TableCell scope="row">
          {r.cpfCnpj ? FormatDocument(r.cpfCnpj) : r.cpfCnpj}
        </TableCell>
        <TableCell scope="row">{r.organizacao && r.organizacao.nome}</TableCell>
        <TableCell scope="row">{r.perfil && r.perfil.nome}</TableCell>
        <TableCell scope="row">{r.ativo ? "ativo" : "inativo"}</TableCell>
        <TableCell scope="row">
          {Moment(r.dataCriacao).format("DD/MM/YYYY")}
        </TableCell>
      </TableRow>
    );

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />

        <Datatable
          title="Enviar E-mails Personalizados"
          endpoint="/usuario"
          reload={this.state.reload}
          resetEditor={this.state.resetEditor}
          editorEmail={true}
          setEmail={(html, assunto) => {
            this.setState({ html, assunto });
          }}
          headRows={headRows}
          formatRow={formatRow}
          setRows={this.setUsers}
          rows={this.state.users}
          setCountAndFilter={(count, filter) =>
            this.setState({ count, filter })
          }
          placeholderSearch="Buscar por Nome, Email, Documento ou Organização"
          width={this.props.width}
          mobile={AdminDropdown}
          buttons={
            (!this.state.selectAll && this.state.checked.length < 1) ||
            !this.state.assunto ||
            !this.state.html
              ? []
              : [
                  {
                    label: "Disparar e-mails",
                    icone: this.props.width >= 768 ? "send" : "paper-plane",
                    onClick: () => this.sendEmails()
                  }
                ]
          }
        />
      </div>
    );
  }
}

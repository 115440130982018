import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { PrintRounded } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import Loading from "app/pages/home/components/Loading";
import { useSelector } from "react-redux";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

const PrintResult = ({ children, nameFile, ChildrenB }) => {
  const translate = useTranslation();
  const componentRef = React.useRef(null);
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);
    setText("Loading new text...");

    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: () => {
      const contentToPrint = componentRef.current.cloneNode(true);
      contentToPrint.style.transform = valueTransformIfRtl;
      return contentToPrint;
    },
    documentTitle: nameFile,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  return (
    <div>
      <Loading isLoading={loading} />
      <Stack spacing={1} style={{ position: "absolute", right: 8, top: 2 }}>
        <Stack>{ChildrenB}</Stack>
        <Stack>
          <Button
            variant="text"
            color="success"
            loading={loading}
            disabled={loading}
            onClick={handlePrint}
            startIcon={<PrintRounded />}
          >
            <span style={{ marginLeft: isRtl ? 15 : 0 }}>
              {translate("screens.campaigns.list.print")}
            </span>
          </Button>
        </Stack>
      </Stack>
      <div ref={componentRef} className="containerPrint">
        {children}
      </div>
    </div>
  );
};

export default PrintResult;

export const toolbar={
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "remove",
    "history"
  ],
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      "rgb(56,76,30)",
      "rgb(289,289,289)",
      "rgb(40,49,65)",
      "rgb(17,289,135)",
      "rgb(85,120,289)",
      "rgb(289,280,34)",
      "rgb(289,57,129)",
      "rgb(100,113,161)",
      "rgb(289,289,105)",
      "rgb(255,200,92)",
      "rgb(153,72,52)",
      "rgb(91,126,179)",
      "rgb(106,153,44)",
      "rgb(289,289,0)",
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)"
    ]
  },
  fontFamily: {
    options: [
      "Arial",
      "Georgia",
      "Impact",
      "Tahoma",
      "Times New Roman",
      "Verdana",
      "Helvetica Neue LT Std 47 Light Condensed",
      "Helvetica Neue LT Std 57 Condensed",
      "Helvetica Neue LT Std 77 Bold Condensed",
      "Helvetica",
      "sans-serif"
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  image: { previewImage: true },
  inputAccept:
    "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel"
}

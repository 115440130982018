import axios from "axios";

type PluralRulesType = {
  length: number;
  equation: string;
  forms: string[];
};

type TranslationProgressType = {
  translated: number;
  untranslated: number;
  flagged: number;
  words: number;
};

export type LocaleType = {
  code: string;
  name: string;
  source: boolean;
  native: boolean;
  plurals: PluralRulesType;
  progress: TranslationProgressType;
};

const API_KEY = "msZYtfXEGxDnjQETe8nqysxlmu3Hc7LU";
const BASE_URL = "https://noar-localise-api.azurewebsites.net/api/localise";

export const getLocales = async (): Promise<LocaleType[] | undefined> => {
  try {
    const response = await axios.get(`${BASE_URL}/languages?key=${API_KEY}`);
    return response.data;
  } catch (error) {
    console.error("Erro get locales", error);
  }
};

export const fetchAllLocales = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/locales?key=${API_KEY}`);
    return response.data;
  } catch (error) {
    console.error("Erro fetch all locales", error);
  }
};

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "_metronic/i18n/language";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { colors, months, testResultInit } from "components/Dash/chartConfig";
import { initialMonthsData } from "../dsa.data";
import { DataDashType, DataTest, UserDashboardDataType } from "../dsa.type";
import { Resultado } from "app/pages/admin/types/dashboard.type";
import { TestCabecalhoDataType } from "app/types/dashboard";
import { DashboardApiService } from "app/services";

export default function useManageDashboardData(
  api: AnanseApiService,
  dashboardApi: DashboardApiService
) {
  const { auth } = store.getState();
  const translate = useTranslation();

  const [testList, setTestList] = useState<TestCabecalhoDataType[]>([]);
  const [numeroTeste, setNumeroTeste] = useState(0);
  const [distinctMedicos, setDistinctMedicos] = useState<number>(0);
  const [dispositivos, setDispositivos] = useState<number>(0);
  const [assinatura, setAssinatura] = useState<number>(0);
  const [pacientes, setPacientes] = useState<number>(0);
  const [device, setDevice] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("hasReloaded");
    }
  }, [translate]);

  useEffect(() => {
    loadingData();
  }, []);

  const loadingData = async (): Promise<void> => {
    setLoading(true);
    try {
      const [
        usersQuatity,
        testCabecalhoData,
        quantityDevices
      ] = await Promise.all([
        await getUserDashAndUser(auth.user.id),
        await dashboardApi.getTestCabelhoDashAndUser(auth.user.id),
        await getQuantityDevices()
      ]);

      setTestList(testCabecalhoData);
      setAssinatura(usersQuatity.length);
      setDevice(quantityDevices);

      const lista = [...testCabecalhoData];
      const medicosSet = new Set<string>();
      const dispositovosSet = new Set<string>();
      const pacientesSet = new Set<string>();

      lista.forEach(item => {
        processTestItem(item, medicosSet, dispositovosSet, pacientesSet);
      });

      setNumeroTeste(lista.length);
      setDistinctMedicos(medicosSet.size);
      setDispositivos(dispositovosSet.size);
      setPacientes(pacientesSet.size);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const getUserDashAndUser = async (
    userId: number
  ): Promise<UserDashboardDataType[]> => {
    const userDashboardData = await api.makeHttpRequest({
      url: `/dashboard/userdashanduser?UserId=${userId}`
    });
    return userDashboardData?.result || [];
  };

  const processTestItem = (
    item: TestCabecalhoDataType,
    medicosSet: Set<string>,
    dispositovosSet: Set<string>,
    pacientesSet: Set<string>
  ): void => {
    if (item.Medico) {
      medicosSet.add(item.Medico);
    }
    if (item.MacAddress) {
      dispositovosSet.add(item.MacAddress);
    }
    if (item.Paciente) {
      pacientesSet.add(item.Paciente);
    }
  };

  const getQuantityDevices = async (): Promise<number> => {
    const response = await api.makeHttpRequest({
      url: `/dashboard/devicedashanduser?UserId=${auth.user.id}`
    });

    return response?.result?.length;
  };

  const getTestDataByYear = (): DataTest | undefined => {
    if (!testList || testList.length === 0) {
      return;
    }
    const prepareData = {} as DataTest;

    testList.forEach(item => {
      const { mes, ano } = extractDateInfo(item.Inicio_Teste);

      if (!prepareData[ano]) {
        prepareData[ano] = { ...initialMonthsData };
      }
      prepareData[ano][months[mes]] += 1;
    });
    return prepareData;
  };

  const extractDateInfo = (
    dateString: string
  ): { mes: number; ano: number } => {
    const date = new Date(dateString);
    return {
      mes: date.getMonth(),
      ano: date.getFullYear()
    };
  };

  const getUniqueTestNames = (): string[] => {
    if (!testList || testList.length === 0) {
      return [];
    }

    const uniqueTestNames = new Set<string>(testList.map(item => item.Teste));
    return Array.from(uniqueTestNames);
  };

  const getValuesByTestNamesAndYear = (
    testNames: Array<string>,
    year: number
  ): Array<DataDashType> => {
    if (!testList || testList.length === 0) {
      return [];
    }

    const prepareData = Array.from({ length: 12 }, () => ({
      value: 0
    }));

    testList.forEach(item => {
      const { mes, ano } = extractDateInfo(item.Inicio_Teste);
      if (testNames.includes(item.Teste) && ano === year) {
        prepareData[mes] = {
          value: prepareData[mes].value + 1
        };
      }
    });
    return prepareData;
  };

  const getGenreByTestNamesAndYear = (
    testNames: Array<string>,
    year: number
  ): { name: string; value: number; fill: string }[] => {
    const quantityByGenre = { M: 0, F: 0 };

    testList.forEach(item => {
      const { ano } = extractDateInfo(item.Inicio_Teste);
      const hasTestAndYear = testNames.includes(item.Teste) && ano === year;
      if (hasTestAndYear && (item.genre === "M" || item.genre === "F")) {
        quantityByGenre[item.genre] += 1;
      }
    });

    return [
      {
        name: translate("screens_user_feminine"),
        value: quantityByGenre.F,
        fill: colors.pink
      },
      {
        name: translate("screens_user_male"),
        value: quantityByGenre.M,
        fill: colors.blue
      }
    ];
  };

  const getResults = (testNames: Array<string>, year: number): Resultado[] => {
    if (!testList || testList.length === 0) {
      return [];
    }

    const totalResult = testResultInit({
      anosmia: translate("labels_dashboard_anosmiaFunctional"),
      normosmia: translate("screenApp_results_details_normosmia"),
      hiposmia: translate("screenApp_results_details_hyposmia")
    });

    testList.forEach(item => {
      const { ano } = extractDateInfo(item.Inicio_Teste);
      const hasTestAndYear = testNames.includes(item.Teste) && ano === year;
      if (!hasTestAndYear) {
        return;
      }
      if (item.Acertos <= 10) {
        totalResult[
          totalResult.findIndex(
            resultado =>
              resultado.name === translate("labels_dashboard_anosmiaFunctional")
          )
        ].value += 1;
      } else if (item.Acertos >= 15) {
        totalResult[
          totalResult.findIndex(
            resultado =>
              resultado.name ===
              translate("screenApp_results_details_normosmia")
          )
        ].value += 1;
      } else {
        totalResult[
          totalResult.findIndex(
            resultado =>
              resultado.name === translate("screenApp_results_details_hyposmia")
          )
        ].value += 1;
      }
    });
    return totalResult;
  };

  const getAgeGroups = useCallback(
    (testNames: Array<string>, year: number): Array<Resultado> => {
      const ageGroup = [
        {
          name: "00 - 10",
          value: 0,
          fill: colors.brightGreen
        },
        {
          name: "11 - 20",
          value: 0,
          fill: colors.salmonPink
        },
        {
          name: "21 - 30",
          value: 0,
          fill: colors.peach
        },
        {
          name: "31 - 40",
          value: 0,
          fill: colors.blue
        },
        {
          name: "41 - 50",
          value: 0,
          fill: colors.green
        },
        {
          name: "51 - 60",
          value: 0,
          fill: colors.pink
        },
        {
          name: "61 - 70",
          value: 0,
          fill: colors.aquamarine
        },
        {
          name: "71 - 80",
          value: 0,
          fill: colors.lemon
        }
      ];
      testList.forEach(item => {
        const { ano } = extractDateInfo(item.Inicio_Teste);
        const hasTestAndYear = testNames.includes(item.Teste) && ano === year;
        if (!hasTestAndYear) {
          return;
        }
        const rangeIndex = ageGroup.findIndex(
          faixa => faixa.name === item.FaixaEtaria
        );
        if (rangeIndex !== -1) {
          ageGroup[rangeIndex].value += 1;
        }
      });

      return ageGroup;
    },
    [testList]
  );

  return {
    loading,
    translate,
    device,
    distinctMedicos,
    numeroTeste,
    dispositivos,
    pacientes,
    getTestDataByYear,
    getUniqueTestNames,
    getValuesByTestNamesAndYear,
    getGenreByTestNamesAndYear,
    getResults,
    getAgeGroups,
    assinatura: assinatura.toString()
  };
}

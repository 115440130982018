import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TransitionProps } from "@mui/material/transitions";
import QRCode from "qrcode.react";
import React, { useState } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FragranceData {
  fragranceId: number;
  slot: number;
  name: string;
  selected: boolean;
  customerCode: string;
  dueDate: string;
  serialNumber: string;
}

interface QRCodesGridProps {
  name: string;
  data: Array<FragranceData>;
  open: boolean;
  onClose: () => void;
}

const formatDataToString = (data: FragranceData): string => {
  const dateObject = new Date(data.dueDate);
  const day = ("0" + dateObject.getUTCDate()).slice(-2);
  const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
  const year = dateObject
    .getUTCFullYear()
    .toString()
    .slice(-2);

  const formattedId = data.fragranceId.toString().padStart(4, "0");

  return `${data.customerCode}${formattedId}${data.serialNumber}${day}${month}${year}`;
};

const QRCodesGrid: React.FC<QRCodesGridProps> = ({
  name,
  data,
  open,
  onClose
}) => {
  const [selectedQRCodeIndex, setSelectedQRCodeIndex] = useState<number | null>(
    null
  );

  const handleQRCodeClick = (index: number) => {
    setSelectedQRCodeIndex(index);
  };

  const handleCloseQRCodeDialog = () => {
    setSelectedQRCodeIndex(null);
  };

  const handlePrev = () => {
    if (selectedQRCodeIndex !== null && selectedQRCodeIndex > 0) {
      setSelectedQRCodeIndex(selectedQRCodeIndex - 1);
    }
  };

  const handleNext = () => {
    if (selectedQRCodeIndex !== null && selectedQRCodeIndex < data.length - 1) {
      setSelectedQRCodeIndex(selectedQRCodeIndex + 1);
    }
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle textAlign={"center"} sx={{ cursor: "none" }}>
          QR Codes para configuração: {name}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ flexGrow: 1, margin: 2 }}
          >
            {data.map((item, index) => (
              <Grid key={index} xs={2} sm={4} md={3}>
                <Card key={index}>
                  <Stack padding={0.5}>
                    <Tooltip title="ABRIR" arrow>
                      <IconButton onClick={() => handleQRCodeClick(index)}>
                        <QRCode value={formatDataToString(item)} />
                      </IconButton>
                    </Tooltip>
                    <Typography align="center" sx={{ cursor: "none" }}>
                      {`Slot: ${item.slot} - ${item.name}`}
                    </Typography>
                    <Typography align="center" sx={{ cursor: "none" }}>
                      {formatDataToString(item)}
                    </Typography>
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={() => handleQRCodeClick(index)}
                    >
                      Abrir
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            color="error"
            onClick={onClose}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        TransitionComponent={Transition}
        open={selectedQRCodeIndex !== null}
        onClose={handleCloseQRCodeDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ cursor: "none" }}>
          QR Code:{" "}
          {selectedQRCodeIndex !== null &&
            formatDataToString(data[selectedQRCodeIndex])}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <IconButton
            onClick={handlePrev}
            title="Anterior"
            disabled={selectedQRCodeIndex === 0}
          >
            <ArrowBackIos />
          </IconButton>
          {selectedQRCodeIndex !== null && (
            <Box>
              <QRCode
                value={formatDataToString(data[selectedQRCodeIndex])}
                size={256}
              />
              <Typography align="center" sx={{ cursor: "none", marginTop: 2 }}>
                {`Slot: ${data[selectedQRCodeIndex].slot} - ${data[selectedQRCodeIndex].name}`}
              </Typography>
            </Box>
          )}
          <IconButton
            onClick={handleNext}
            title="Próxima"
            disabled={selectedQRCodeIndex === data.length - 1}
          >
            <ArrowForwardIos />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            color="error"
            onClick={handleCloseQRCodeDialog}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QRCodesGrid;

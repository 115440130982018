/* eslint-disable no-control-regex */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Apple,
  ArrowBack,
  ArrowForward,
  Email,
  Google,
  Password,
  Person,
  Phone,
  Shop2,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import { checkEmailExists, registerNewUser } from "app/crud/auth.crud";
import Loading from "app/pages/home/components/Loading";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic";
import Logo from "../../../../_metronic/_assets/media/logos/login-logo.png";
import Swal from "sweetalert2";
import ButtonsStore from "../Components/ButtonsStore";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface FormData {
  name: string;
  mobilePhone: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Register: React.FC = () => {
  const { goBack } = useHistory();
  const translate = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const isRtl = useRTL();
  const arrowIcon = isRtl ? <ArrowForward /> : <ArrowBack />;

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const schema = yup.object().shape({
    name: yup.string().required(translate("screens_register_required_name")),
    mobilePhone: yup
      .string()
      .required(translate("screens_register_required_phone")),
    email: yup
      .string()
      .email(translate("screens_register_errors_emailIvalid"))
      .required(translate("screens_register_required_email"))
      .test(
        "email-exists",
        translate("screens_register_email_exists"),
        async function(value) {
          const emailExists = await checkEmailExists(value);
          return emailExists;
        }
      ),
    password: yup
      .string()
      .required(translate("screens_register_required_password"))
      .min(8, translate("screens_register_password_minRequired"))
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?!.*[^\x00-\x7F]).*$/,
        translate("screens_register_password_matches")
      )
      .test(
        "password-complexity",
        translate("screens_register_password_complexity"),
        function(value, { parent }) {
          const { name, email } = parent;
          const lowerCaseValue = value.toLowerCase();
          const lowerCaseName = name.toLowerCase();
          const lowerCaseEmail = email.toLowerCase();

          if (
            lowerCaseValue.includes(lowerCaseName) ||
            lowerCaseValue.includes(lowerCaseEmail)
          ) {
            return false;
          }

          return true;
        }
      )
      .test(
        "password-sequences",
        translate("screens_register_password_sequences"),
        function(value) {
          // Verificar se a senha contém uma sequência previsível como "123" ou "abc", etc.
          const sequentialRegex = /012|123|234|345|456|567|678|789|890|qwerty|asdf|zxcv|qaz|wsx|edc|rfv|tgb|yhn|ujm|ik,|cvb|nm/;
          return !sequentialRegex.test(value.toLowerCase());
        }
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password")],
        translate("screens_register_errors_passwordConfirm")
      )
      .required(translate("screens_register_required_passwordConfirm"))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormData> = async formData => {
    setLoading(true);
    try {
      const userData = {
        nome: formData.name,
        email: formData.email,
        mobilePhone: formData.mobilePhone || "",
        senha: formData.password,
        ativo: true,
        grouper: true,
        grouperId: 0,
        usersLicense: 1,
        devicesLicense: 1,
        consumptionControl: "perCapsule",
        userType: "official"
      };
      await registerNewUser(userData);
      Swal.fire(
        translate("defaultMessages_success"),
        translate("screens_register_success"),
        "success"
      );
      goBack();
    } catch (e) {
      console.error("Error:", e);
      Swal.fire(
        translate("defaultMessages_error"),
        translate("screens_register_error"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const containerStyle = {
    padding: "20px",
    width: "100%",
    maxWidth: 500,
    margin: "5px"
  };

  return (
    <>
      <Loading isLoading={loading} />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, marginLeft: "10px" }}
            onClick={goBack}
          >
            {arrowIcon}
          </IconButton>
          <Typography
            align="center"
            variant="h6"
            color="inherit"
            component="div"
          >
            {translate("labels_createAccount")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card elevation={5} style={containerStyle}>
              <Stack
                spacing={3}
                sx={{ marginBottom: "20px" }}
                justifyItems="center"
                alignItems="center"
              >
                <img alt="apple" src={Logo} style={{ width: 200 }} />
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={translate("screens_register_name")}
                    variant="outlined"
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={translate("screenApp_listPacient_account_phone")}
                    variant="outlined"
                    {...register("mobilePhone")}
                    error={!!errors.mobilePhone}
                    helperText={errors.mobilePhone?.message}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label={translate("screens_register_email")}
                    variant="outlined"
                    {...register("email", {
                      required: translate("screens_register_required_email"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: translate(
                          "screens_register_errors_emailIvalid"
                        )
                      },
                      validate: async value => {
                        console.log("validate");
                        const emailExists = await checkEmailExists(value);
                        return emailExists
                          ? translate("screens_register_email_exists")
                          : false;
                      }
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={translate("screens_register_password")}
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    {...register("password")}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Password />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={translate("screens_register_confirm")}
                    type={showPasswordConfirm ? "text" : "password"}
                    variant="outlined"
                    {...register("confirmPassword")}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Password />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Stack
                sx={{ marginTop: "10px" }}
                justifyContent="center"
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
              >
                <Button
                  style={{ marginTop: "20px" }}
                  type="submit"
                  color="success"
                  variant="contained"
                  fullWidth
                >
                  {translate("screens_register_register")}
                </Button>
              </Stack>

              <ButtonsStore />
            </Card>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default Register;

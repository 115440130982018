import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  Radio,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  Add,
  ArrowBack,
  ArrowForward,
  BalanceOutlined,
  Refresh
} from "@mui/icons-material";

import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { UserType, UseSelectorI } from "interfaces";
import store from "app/store/store";
import CardList from "../CardList";
import Loading from "app/pages/home/components/Loading";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface DataItem {
  id: number;
  label: string;
  subTitle: string;
  isActive: boolean;
  obj: any;
  // Add more properties as needed
}

interface DataTableProps {
  data: DataItem[];
  total: number;
  totalPages: number;
}

interface Props {
  convertObject?: any;
  url: any;
  filterId?: any;
  avatar?: boolean;
  onCreate?: () => void;
  handleData?: any;
  handleRemove: (id: number) => Promise<any>;
  handleStatus: (value: any) => Promise<any>;
  childrenToolbar: any;
  userSelected: UserType;
}

const CardData: React.FC<Props> = ({
  avatar,
  url,
  convertObject,
  filterId,
  onCreate,
  handleData,
  handleRemove,
  handleStatus,
  childrenToolbar,
  userSelected
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dataResponse, setDataResponse] = useState<DataTableProps>(
    {} as DataTableProps
  );

  const [dataLength, setDataLength] = useState(1);

  const [data, setData] = useState<DataItem[]>([]);

  const translate = useTranslation();
  const { auth } = store.getState();
  const api = new AnanseApiService();

  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [perPage, setPerPage] = useState("5");
  const [selectedValue, setSelectedValue] = React.useState("ativo");

  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;

  function CustomPreviousIcon () {
    return <ArrowBack style={{ transform: valueTransformIfRtl }} />
  }

  function CustomNextIcon() {
    return <ArrowForward style={{ transform: valueTransformIfRtl }} />;
  }

  const loadingData = React.useCallback(async () => {
    setLoading(true);
    const newList: any[] = [];
    try {
      const { id } = userSelected;
      if (id) {
        const getData = await api.makeHttpRequest({
          method: "GET",
          url: `/${url}?IsActive=${isActive}`
        });
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/${url}?PerPage=${getData.total}&IsActive=${isActive}`
        });
        const responseAtive = await api.makeHttpRequest({
          method: "GET",
          url: `/${url}?PerPage=${getData.total}&IsActive=${true}`
        });
        setDataLength(
          responseAtive.data.filter(
            (valeu: any) => valeu.grouperId === filterId
          ).length
        );
        response.data.forEach((item: any) => {
          const converted = convertObject(item);
          if (newList.indexOf(converted) === -1) {
            newList.push(converted);
          }
        });
        const filterByGrouperId = !filterId
          ? newList
          : newList
              .filter((valeu: any) => valeu.filterId === filterId)
              .sort((a, b) => a.label - b.label);
        // console.log("newList", filterByGrouperId);
        if (filterByGrouperId.length) {
          handleData(filterByGrouperId);
        }
        setDataResponse(response);
        setData(filterByGrouperId);
      }
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages.error"),
        "Erro ao trazer dados: 139",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }, [isActive, dataLength]);

  React.useEffect(() => {
    loadingData();
  }, [loadingData]);

  const filteredData =
    data?.filter(item =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    ) ?? [];

  const totalPages: number = Math.ceil(filteredData?.length / Number(perPage));

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const startIndex: number = (currentPage - 1) * Number(perPage);
  const visibleData: DataItem[] = filteredData.slice(
    startIndex,
    startIndex + Number(perPage)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = event.target.value;
    setSelectedValue(radioValue);
    setIsActive(radioValue === "ativo" ? true : false);
    setCurrentPage(1);
  };

  const handleConfirmRemove = (id: number) => {
    handleRemove(id);
  };
  const handleConfirmStatus = (value: any) => {
    handleStatus(value);
  };

  const handleChangePerPage = (event: SelectChangeEvent) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent={isActive ? "space-between" : "flex-end"}
        alignItems="center"
      >
        {isActive && (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="text.secondary">
              {visibleData.length} / {userSelected.usersLicense}{" "}
              {translate("labels_createdUsers")}
            </Typography>
            <Stack direction="row">
              {onCreate && (
                <IconButton
                  aria-label="settings"
                  disabled={visibleData.length === userSelected.usersLicense}
                  onClick={onCreate}
                >
                  <Add />
                </IconButton>
              )}
              {childrenToolbar && childrenToolbar}
            </Stack>
          </Stack>
        )}
        <IconButton aria-label="settings" onClick={() => loadingData()}>
          <Refresh />
        </IconButton>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Radio
            color="success"
            checked={selectedValue === "ativo"}
            onChange={handleChange}
            value="ativo"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
          />
          <Typography>{translate("datatable.active")}</Typography>
          <Radio
            color="success"
            checked={selectedValue === "inativo"}
            onChange={handleChange}
            value="inativo"
            name="radio-buttons"
            inputProps={{ "aria-label": "B" }}
          />
          <Typography>{translate("datatable.inactive")}</Typography>
        </Stack>

        <TextField
          label={translate("labels_searchByName")}
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Stack>

      {visibleData.map(value => {
        console.log("obj", value);
        return (
          <CardList
            avatar={avatar}
            dataLength={isActive ? 1000 : dataLength}
            key={value.id}
            title={value.label}
            subheader={value.subTitle}
            handleRemove={
              value.obj.deletedAt
                ? () => {
                    handleConfirmRemove(value.id);
                    setCurrentPage(1);
                  }
                : undefined
            }
            handleStatus={() => {
              handleConfirmStatus(value);
              loadingData();
              setCurrentPage(1);
            }}
          />
        );
      })}
      {visibleData.length < 1 && (
        <Typography>{translate("datatable.nothingData")}</Typography>
      )}
      <Divider sx={{ marginBottom: 2 }} />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="flex-end"
        alignItems="center"
      >
        <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            {translate("datatable.labelRowsPerPage")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={perPage}
            onChange={handleChangePerPage}
            autoWidth
            label="PerPage"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          renderItem={item => (
            <PaginationItem
              slots={{ previous: CustomPreviousIcon, next: CustomNextIcon }}
              {...item}
            />
          )}
        />
      </Stack>
      {/* <Loading isLoading={loading} /> */}
    </div>
  );
};

export default CardData;

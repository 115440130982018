import { useTranslation } from "_metronic/i18n/language";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from "recharts";

interface ShotsAverage {
  RightAnswer: string;
  averageShots: number;
}

interface BarChartAverageShortsProps {
  data: ShotsAverage[];
}

const BarChartShorts: React.FC<BarChartAverageShortsProps> = ({ data }) => {
  const translate = useTranslation();
  const sortedData = data.sort((a, b) => b.averageShots - a.averageShots);
  const renderLabel = (value: number): string => {
    const numeriValue = parseFloat(value.toString());
    return numeriValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    });
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        data={sortedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" style={{ fontWeight: "bold" }} />
        <YAxis
          type="category"
          dataKey="RightAnswer"
          style={{ fontWeight: "bold", whiteSpace: "nowrap", }}
          interval={0}
          width={120}
        />
        <Tooltip formatter={renderLabel} />
        <Legend />
        <Bar
          dataKey="averageShots"
          fill="#1e90ff"
          name={translate("labels_number_average_shots")}
        >
          <LabelList
            dataKey="averageShots"
            position="inside"
            fill="#fff"
            formatter={renderLabel}
            style={{ fontWeight: "bold" }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartShorts;

import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Stack, Divider } from "@mui/material";
// import { Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { TranslationContext } from "_metronic/i18n/language";

class FormActions extends React.Component {

  static contextType = TranslationContext;

  render() {
    const translate = this.context;
    const { formIsValid, onCleanForm, isEdit, module } = this.props;

    return (
      <Stack
        sx={{ marginTop: "30px" }}
        justifyContent="flex-end"
        alignItems="flex-end"
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
      >
        <Link to={`/admin/${module}`}>
          <Button
            variant="contained"
            color="error"
            type="button"
            className="mr-3"
            style={{ color: "#fff" }}
          >
            {translate("buttons.cancel")}
          </Button>
        </Link>

        <Button
          variant="contained"
          color="warning"
          onClick={onCleanForm}
          className="mr-3"
          style={{ color: "#fff" }}
        >
          {!isEdit ? translate("buttons.clean") : translate("buttons.revert")}
        </Button>

        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={!formIsValid}
          style={{ color: formIsValid && "#fff" }}
          title={formIsValid ? "Submeter formulário" : "Verifique os campos"}
        >
          {translate("buttons.save")}
        </Button>
      </Stack>
    );
  }
}

const mapStateToProps = store => ({
  i18n: store.i18n
});

export default connect(mapStateToProps)(FormActions);

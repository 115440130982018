import CloseIcon from "@mui/icons-material/Close";
import { Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ButtonCode from "app/pages/admin/components/EditorConvertToHTML/Components/ButtonCode";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { UseSelectorI } from "interfaces";
import * as React from "react";
import { ContentState, Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Loading from "app/pages/home/components/Loading";
import RichText from "components/RichText";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  const isRtl = useRTL();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <p style={{ textAlign: isRtl ? "end" : "start" }}>{children}</p>
    </DialogTitle>
  );
}

interface ResultsI {
  testResultId: any;
  quizAnswer: Array<any>;
  anamneseResultId?: any;
}
interface IData {
  // result: any;
  account: any;
}

interface IResultDetail {
  quizAnswerDescriptionId?: number;
  observation: string;
  testResultId: string;
  quizAnswerId: number;
  accountId: number;
  userId: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: IData;
  result: any;
  setResult: any;
}

export default function DetailResultForm({
  open,
  setOpen,
  data,
  result,
  setResult
}: Props) {
  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const [loading, setLoading] = React.useState(false);
  const [code, setCode] = React.useState(false);
  const [resultDetail, setResultDetail] = React.useState({} as IResultDetail);
  const [html, setHtml] = React.useState("");
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const api = new AnanseApiService();

  const handleClose = () => {
    setHtml("");
    setResultDetail({} as IResultDetail);
    setResult(null);
    setOpen(false);
  };

  const loadData = React.useCallback(async () => {
    setLoading(true);
    try {
      if (result?.quizAnswer?.length) {
        const payload = {
          accountId: result?.quizAnswer[0].accountId,
          testResultId: result?.testResultId,
          userId: result?.quizAnswer[0].userId
        };
        const reqBase = {
          method: "GET",
          url: `/quizanswerdetails/quizanswerdetails/${payload.testResultId}/${payload.userId}/${payload.accountId}`
        };
        const response = await api.makeHttpRequest({
          ...reqBase
        });
        setResultDetail(response[0]);
        setHtml(response[0].observation ?? "");
      }
    } catch (error) {
      // console.error("error loadData Detail Result: ", error);
    } finally {
      setLoading(false);
    }
  }, [data, editorState]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const onSubmit = async () => {
    setLoading(true);
    const payload: IResultDetail = {
      accountId: result.quizAnswer[0].accountId,
      observation: html,
      testResultId: result.testResultId,
      userId: result.quizAnswer[0].userId,
      quizAnswerId: result.quizAnswer[0].id
    };
    try {
      let reqBase: any;
      if (resultDetail?.quizAnswerDescriptionId) {
        reqBase = {
          method: "PUT",
          url: `/quizanswerdetails/${resultDetail?.quizAnswerDescriptionId}`
        };
      } else {
        reqBase = { method: "POST", url: "/quizanswerdetails" };
      }

      await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(value => {
        if (value.isConfirmed) {
          // history.push("/admin/account");
          setResultDetail({} as IResultDetail);
          setHtml("");
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const MAX_CHARACTERS = 3000;

  return (
    <div>
      <Loading isLoading={loading} />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {translate("screenApp.results.comments")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack sx={{ marginBottom: 2, transform: valueTransformIfRtl }}>
            <Typography>
              {translate("screenApp.results.patient")}: {data?.account?.name}
            </Typography>
            <Typography>
              {translate("screenApp.results.numberTest")}:{" "}
              {result?.testResultId}
            </Typography>
          </Stack>
          <RichText content={html} setContent={setHtml} />
        </DialogContent>
        <DialogActions sx={{ transform: valueTransformIfRtl }}>
          <Button
            variant="contained"
            color="error"
            autoFocus
            onClick={handleClose}
            style={{ marginRight: isRtl ? 5 : 0}}
          >
            {translate("buttons.cancel")}
          </Button>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={onSubmit}
          >
            {translate("buttons.save")}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

import { CheckCircle, Close } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography
} from "@mui/material";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { TesteResultType } from "../../types";
import { fetchAddress } from "../../utils/geolocation";
import { useEffect, useState } from "react";
import { useTranslation } from "_metronic/i18n/language";

interface RenderResultsProps {
  testResult: TesteResultType | undefined;
  validation: boolean | undefined;
}

export function RenderResults({ testResult, validation }: RenderResultsProps) {
  const translate = useTranslation();
  const location = testResult?.quizAnswer[0].location;

  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [address, setAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getAddress = async () => {
      try {
        const address = await fetchAddress(testResult?.quizAnswer[0].location);
        console.log(address);
        setAddress(address);
      } catch (error) {
        // setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getAddress();
  }, [location]);

  return (
    <Card
      style={{
        // padding: "2px",
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <CardHeader
        title={testResult?.testResultId}
        subheader={`${translate("labels_numberResponses")}: ${
          testResult?.quizAnswer.length
        } `}
      />
      <CardContent>
        {/* <Typography>{address}</Typography> */}
        <Typography>{testResult?.quizAnswer[0].location}</Typography>
      </CardContent>
      <Stack sx={{ margin: "10px" }}>
        {validation ? <CheckCircle /> : <Close />}
      </Stack>
    </Card>
  );
}

import { BluetoothDisabled, PermDeviceInformation } from "@mui/icons-material";
import { Button, IconButton, Modal, TableCell, TableRow } from "@mui/material";
import { getTokenByDevice } from "@noar-health/commons/lib/hash";
import { useTranslation } from "_metronic/i18n/language";
import Loading from "app/pages/home/components/Loading";
import ConfirmChangeStatusDialog from "app/partials/datatable/ConfirmChangeStatusDialog";
import Datatable from "app/partials/datatable/Datatable";
import { DevicesApiService } from "app/services/Ananse/DevicesApiService";
import store from "app/store/store";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { titlePage } from "utils/titlepage";

interface Device {
  deviceId: string;
  macAddress: string;
  campaign?: {
    name: string;
  };
  user?: {
    nome: string;
  };
  updatedAt: string;
  owner?: {
    nome: string;
  };
  createdAt: string;
  appVersion: string;
  remainingTests: string;
}

interface DevicesListingProps {
  i18n: {
    lang: string;
  };
}

const DevicesListing: React.FC<DevicesListingProps> = () => {
  const api = new DevicesApiService();
  const { auth } = store.getState();
  const translate = useTranslation();

  const [devices, setDevices] = React.useState<Device[]>([]);
  const [reload, setReload] = React.useState<boolean>(false);

  const [toggleConfirmDialog, setToggleConfirmDialog] = React.useState<boolean>(
    false
  );
  const [showResetModal, setShowResetModal] = React.useState<boolean>(false);
  const [resetFirmewareCode, setResetFirmewareCode] = React.useState<
    string | null
  >(null);
  const [remainingSecondsCode, setRemainingSecondsCode] = React.useState<
    number | undefined
  >(0);
  const [selectedDevice, setSelectedDevice] = React.useState<string | null>(
    null
  );
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  const disconnectDevice = async () => {
    try {
      setShowLoading(true);
      await api.removeUser(selectedDevice!);

      setShowLoading(false);
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("screens.device.messages.success"),
        icon: "success",
        cancelButtonText: translate("buttons.exit")
      }).then(value => {
        if (value.isDismissed) {
          setReload(true);
        }
      });
    } catch (e) {
      console.log(e);
      setShowLoading(false);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.device.errors.disconnect"),
        "error"
      );
    }
  };

  const showTokenModal = async (device: Device) => {
    const result = getTokenByDevice(device.macAddress);
    setShowResetModal(true);
    setResetFirmewareCode(result.token);

    for (let i = result.remainingSeconds; i > 0; i--) {
      if (!showResetModal && i !== result.remainingSeconds) {
        break;
      }
      setRemainingSecondsCode(i);
      await new Promise(r => setTimeout(r, 1000));
    }

    if (showResetModal) {
      setResetFirmewareCode("GETTING NEW");
      setRemainingSecondsCode(0);
      showTokenModal(device);
    }
  };

  React.useEffect(() => {
    titlePage(`${translate("screens.device.title") || "Device"} - Admin`);
  }, [translate]);

  const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: 100%;
    margin: auto;
    outline: none;
  `;

  const DivResetFirmeware = styled.div`
    border-radius: 3px;
  `;

  const headRowsInitial = [
    {
      key: 2,
      column: "MacAddress",
      label: translate("screens.device.labels.macAddress")
    },
    {
      key: 3,
      column: "Campaign.Name",
      label: translate("screens.quiz.list.campaign")
    },
    {
      key: 4,
      column: "User.Nome",
      label: translate("screens.device.labels.user")
    },
    {
      key: 5,
      column: "UpdatedAt",
      label: translate("screens.quiz.list.updated")
    },
    {
      key: 6,
      column: "Owner.Nome",
      label: translate("screens.device.labels.owner")
    },
    {
      key: 7,
      column: "CreatedAt",
      label: translate("screens.quiz.list.created")
    },
    {
      key: 8,
      column: "AppVersion",
      label: translate("screens.device.labels.versionApp")
    }
  ];
  const [headRows, setHeadRows] = useState(headRowsInitial);
  const isAdmin = auth.user.profile.nome === "ADMIN";

  useEffect(() => {
    const isUser = isAdmin;

    const moreHeadRows = [
      {
        key: 1,
        column: "",
        label: translate("screens.quiz.list.actions")
      },
      {
        key: 9,
        column: "remainingTests",
        label: translate("screens.device.labels.remainingTests")
      }
    ];
    if (isUser) {
      setHeadRows(v => [...v, ...moreHeadRows]);
    } else {
      setHeadRows(headRowsInitial);
    }
  }, [auth, translate]);

  const formatRow = (r: Device) => (
    <TableRow hover tabIndex={-1} key={r.deviceId}>
      {isAdmin && (
        <TableCell>
          <div key={"actions-" + r.deviceId}>
            <IconButton
              size="small"
              style={{
                margin: 0,
                color: "#0D2551"
              }}
              title={translate("screens.device.labels.modal")}
              onClick={() => showTokenModal(r)}
            >
              <PermDeviceInformation fontSize="small" />
            </IconButton>

            {r.user && r.user.nome && (
              <IconButton
                size="small"
                style={{
                  margin: 0,
                  color: "#0D2551"
                }}
                title={translate("screens.device.labels.disconnect")}
                onClick={() => {
                  setToggleConfirmDialog(true);
                  setSelectedDevice(r.deviceId);
                }}
              >
                <BluetoothDisabled fontSize="small" />
              </IconButton>
            )}
          </div>
        </TableCell>
      )}
      <TableCell scope="row">{r.macAddress}</TableCell>
      <TableCell scope="row">{r.campaign?.name}</TableCell>
      <TableCell scope="row">{r.user?.nome}</TableCell>
      <TableCell scope="row">
        {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
      <TableCell scope="row">{r.owner?.nome}</TableCell>
      <TableCell scope="row">
        {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
      <TableCell scope="row">{r.appVersion}</TableCell>
      {isAdmin && <TableCell scope="row">{r.remainingTests}</TableCell>}
    </TableRow>
  );

  return (
    <>
      <Loading isLoading={showLoading} />
      <ConfirmChangeStatusDialog
        open={toggleConfirmDialog}
        closeDialog={() => {
          setToggleConfirmDialog(false);
          setSelectedDevice(null);
        }}
        confirm={disconnectDevice}
        message={translate("screens.device.messages.disconnect")}
        title={translate("changeStatus.confirmTitle")}
      />
      {showResetModal && (
        <Modal
          open={showResetModal}
          onClose={() => {
            setShowResetModal(false);
            setSelectedDevice(null);
            setResetFirmewareCode(null);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalContainer>
            <>
              <div className="container-modal">
                <span className="title p-1 m-3 text-justify">
                  {translate("screens.device.messages.modal")}
                </span>
                <CountdownCircleTimer
                  isPlaying={true}
                  duration={60}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  colorsTime={[7, 5, 2]}
                  initialRemainingTime={remainingSecondsCode}
                >
                  {({ remainingTime }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <div style={{ fontSize: "3rem" }}>{remainingTime}s</div>
                      <DivResetFirmeware className="title shadow bg-white p-2 ">
                        {resetFirmewareCode}
                      </DivResetFirmeware>
                    </div>
                  )}
                </CountdownCircleTimer>
                <div className="d-flex justify-content-between pt-5">
                  <Button
                    onClick={() => {
                      setShowResetModal(false);
                      setSelectedDevice(null);
                    }}
                    color="success"
                    variant="contained"
                  >
                    {translate("buttons.close")}
                  </Button>
                </div>
              </div>
            </>
          </ModalContainer>
        </Modal>
      )}

      <Datatable
        title={translate("screens.device.title")}
        endpoint={`/devices?FilterInt=${auth.user.id}`}
        headRows={headRows.sort((a, b) => a.key - b.key)}
        formatRow={formatRow}
        rows={devices}
        setRows={setDevices}
        reload={reload}
        disableStatus={true}
        placeholderSearch={translate("screens.device.placeholders.filter")}
      />
    </>
  );
};

const mapStoreToProps = (store: any) => ({
  i18n: store.i18n
});

export default connect(mapStoreToProps, null)(DevicesListing);

import { Tab, Tabs } from "@material-ui/core";
import Loading from "app/pages/home/components/Loading";
import {
  BarAndLineChartTestComponent,
  BarChartAverageTime,
  BarChartFaixaEtaria,
  BarChartGender,
  BarChartPorcentagem,
  BarChartShort
} from "components";
import { useDashDsaResultModel } from "./hooks/useDashDsaResultModel";
import { TabChartContents } from "./components/TabChartContents";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTestNamesFilter } from "./hooks/useTestNamesFilter";
import "./dashdsaresult.scss";
import { LabelsContants } from "app/helpers/constants";

type DashDsaResultViewProps = ReturnType<typeof useDashDsaResultModel> & {
  getUniqueTestNames: () => string[];
};

export const DashDsaResultView = ({
  isLoading,
  selectedTab,
  cardTitles,
  handleTabChange,
  windowHeight,
  getUniqueTestNames,
  getUniqueDetailsTestNames,
  getResults,
  getGenderResults,
  getAverageTimeResults,
  getAverageShotsResults,
  getHitsByTests,
  getHitsByAgeGroup
}: DashDsaResultViewProps) => {
  const [testNameOptions, setTestNameOptions] = useState<string[]>([]);
  const { handleTestNamesChange, selectedTestNames } = useTestNamesFilter();

  useEffect(() => {
    let tests = getUniqueTestNames();
    if (selectedTab <= 3) {
      tests = getUniqueDetailsTestNames();
    }
    handleTestNamesChange(tests);
    setTestNameOptions(tests);
  }, [getUniqueDetailsTestNames, getResults]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedTestNames>
  ): void => {
    const {
      target: { value }
    } = event;
    handleTestNamesChange(typeof value === "string" ? value.split(",") : value);
  };

  const chartContentComponent = {
    0: <BarChartPorcentagem data={getResults(selectedTestNames)} />,
    1: <BarChartGender data={getGenderResults(selectedTestNames)} />,
    2: <BarChartAverageTime data={getAverageTimeResults(selectedTestNames)} />,
    3: <BarChartShort data={getAverageShotsResults(selectedTestNames)} />,
    4: (
      <BarAndLineChartTestComponent data={getHitsByTests(selectedTestNames)} />
    ),
    5: <BarChartFaixaEtaria data={getHitsByAgeGroup(selectedTestNames)} />
  };

  return (
    <div className="dashboard-dsa-result">
      <Loading isLoading={isLoading} />
      <div className="test-filter-container">
        <FormControl className="multiple-test-filter" sx={{ m: 1 }}>
          <Select
            id="multiple-checkbox"
            className="multiple-checkbox-select"
            multiple
            value={selectedTestNames}
            onChange={handleChange}
            input={<OutlinedInput label="Test" />}
            renderValue={selected => selected.join(", ")}
            style={{ height: 46 }}
          >
            <MenuItem key={"key-0"} value={LabelsContants.ALL}>
              <Checkbox
                checked={testNameOptions.length === selectedTestNames.length}
                className="test-name-checkox"
                color="info"
                name="all"
                onChange={({ target }) => {
                  if (target.checked) {
                    handleTestNamesChange(testNameOptions);
                  }
                  if (!target.checked) {
                    handleTestNamesChange([]);
                  }
                }}
              />
              <ListItemText primary={LabelsContants.ALL} />
            </MenuItem>
            {testNameOptions.map(name => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={selectedTestNames.includes(name)}
                  className="test-name-checkox"
                  color="info"
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        centered
      >
        {cardTitles.map((title, index) => (
          <Tab key={index} label={title} />
        ))}
      </Tabs>
      <TabChartContents windowHeight={windowHeight}>
        {chartContentComponent[selectedTab]}
      </TabChartContents>
    </div>
  );
};

import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { injectIntl } from "react-intl";

export default function withFormValidation(WrappedComponent, initialState) {
  const NewComponent = class extends React.Component {
    constructor(props) {
      super(props);

      this.validator = new SimpleReactValidator({
        className: "kt-error-label text-left",
        locale: props.intl.locale
      });

      this.validateForm = this.validateForm.bind(this);
    }

    handleChange(e) {
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

      this.setState({
        [e.target.name]: value
      });
    }

    validateForm() {
      if (this.validator.allValid()) {
        return true;
      } else {
        this.validator.showMessages();
        this.forceUpdate();
        return false;
      }
    }

    onCleanForm() {
      this.setState(initialState);
    }

    render() {
      this.validator.purgeFields();

      return (
        <WrappedComponent
          validator={this.validator}
          handleChange={this.handleChange}
          validateForm={this.validateForm}
          onCleanForm={this.onCleanForm}
          {...this.props}
        />
      );
    }
  };

  return injectIntl(NewComponent);
}

import { Edit, Info } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "_metronic/i18n/language"; // Ajuste da importação

import Datatable from "app/partials/datatable/Datatable";
import store from "app/store/store";
import { titlePage } from "utils/titlepage";

interface Capsule {
  capsuleId: number;
  deviceId: number;
  // ... outras propriedades
}

const CapsulesList: React.FC = () => {
  const [capsules, setCapsules] = useState<Capsule[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const { auth } = store.getState();
  const isAdmin = auth.user.profile.nome === "ADMIN";
  const translate = useTranslation(); // Uso da função de tradução

  const isTestUser = auth.user.profile.nome === "USER" && auth.user.consumptionControl === "byTest";
  const remainingLabel = isTestUser ?
  translate("screens_capsule_labels_remaining") : translate("screenApp_quiz_messageAvailable")

  useEffect(() => {
    titlePage(`${translate('screens.capsule.title') || "Capsule"} - Admin`);
  }, [translate]);

  const headRowsInitial = [
    {
      key: 2,
      column: "SerialNumber",
      label: translate("screens.capsule.labels.serial")
    },
    {
      key: 3,
      column: "Fragrance.Name",
      label: translate("screens.fragrance.list.title")
    },
    {
      key: 4,
      column: "RemainingShots",
      label: remainingLabel
    },
    {
      key: 5,
      column: "Device.MacAddress",
      label: translate("screens.device.labels.macAddress")
    },
    {
      key: 6,
      column: "DueDate",
      label: translate("screens.capsule.labels.dueDate")
    },
    {
      key: 7,
      column: "CreatedAt",
      label: translate("screens.quiz.list.created")
    },
    {
      key: 8,
      column: "UpdatedAt",
      label: translate("screens.quiz.list.updated")
    }
  ];
  const [headRows, setHeadRows] = useState(headRowsInitial);

  useEffect(() => {
    if (isAdmin) {
      setHeadRows((prevHeadRows) => [
        ...prevHeadRows,
        {
          key: 1,
          column: "",
          label: translate("screens.quiz.list.actions")
        }
      ]);
    } else {
      setHeadRows(headRowsInitial);
    }
  }, [isAdmin, translate]);

  const formatRow = (r: any) => {
    const crudActions = [];
    if (r.deletedAt === null) {
      crudActions.push("edit");
    }

    return (
      <TableRow hover tabIndex={-1} key={r.deviceId}>
        {isAdmin && (
          <TableCell scope="row">
            <>
              <Link
                to={{
                  pathname: `/admin/capsules/update/${r.serialNumber}-${r.fragranceId}`,
                  state: { data: r }
                }}
              >
                <IconButton
                  aria-label="edit capsule"
                  component="label"
                  size="small"
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </Link>
              <Link
                to={{
                  pathname: `/admin/capsules/detailing/${r.serialNumber}-${r.fragranceId}-${r.fragrance?.name}`,
                  state: { data: r }
                }}
              >
                <IconButton
                  aria-label="detail capsule"
                  component="label"
                  size="small"
                  title="Detalhamento"
                >
                  <Info fontSize="inherit" />
                </IconButton>
              </Link>
            </>
          </TableCell>
        )}
        <TableCell scope="row">{r.serialNumber}</TableCell>
        <TableCell scope="row">{r.fragrance?.name}</TableCell>
        {isTestUser ? (
          <TableCell scope="row">{r.device?.remainingTests}</TableCell>
        ) : (
          <TableCell scope="row">{r.remainingShots}</TableCell>
        )}
        <TableCell scope="row">{r.device?.macAddress}</TableCell>
        <TableCell scope="row">
          {moment(r.dueDate).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell scope="row">
          {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Datatable
        title={translate("screens.capsule.title")}
        endpoint={`/capsules?FilterInt=${auth.user.id}`}
        headRows={headRows.sort((a, b) => a.key - b.key)}
        formatRow={formatRow}
        rows={capsules}
        setRows={setCapsules}
        reload={reload}
        placeholderSearch={translate("screens.capsule.placeholders.filter")}
        buttons={
          isAdmin
            ? [
              {
                label: translate("datatable.add"),
                onClick: null,
                icon: "add",
                path: "/admin/capsules/create"
              }
            ]
            : []
        }
      />
    </>
  );
};

export default CapsulesList;

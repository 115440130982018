export const selectTypeQuiz = [
  { key: 1, name: "Indentificação" },
  // { key: 2, name: "Anamnese" }, // não descomentar, pois é usado no formulario de Anamnese
  { key: 3, name: "Discriminação" }
];

export const selectInput = [
  {
    key: 1,
    type: "slider",
    name: "Slider"
  },
  {
    key: 2,
    type: "checkbox",
    name: "CheckBox"
  },
  {
    key: 3,
    type: "select",
    name: "Select"
  },
  {
    key: 4,
    type: "radio",
    name: "Radio"
  },
  {
    key: 5,
    type: "text",
    name: "Text"
  }
];

export function formatedTypeInput(key: string) {
  const keyInt: number = parseInt(key, 10);
  console.log(key);
  console.log(keyInt);
  const find = selectInput.find(v => v.key === keyInt || v.type === key);
  return find?.name;
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import {
  AverageData,
  NumeroTeste,
  RespostaDetalhada,
  SelectTabType,
  ShotsAverage,
  TestResultDetailType,
  TimeAverage
} from "../dsaresult.type";
import {
  calcularMediaPorFaixaEtaria,
  calculateAverageShotsByAnswer,
  calculateAverageTimeByAnswer,
  processData
} from "../utils/calculateAge";
import { useTranslation } from "_metronic/i18n/language";
import { colors } from "../dsaresult.data";
import { DashboardApiService } from "app/services";
import { Resultado } from "../../../types/dashboard.type";
import { TestCabecalhoDataType } from "app/types";

export const useDashDsaResultModel = (
  api: AnanseApiService,
  dashboardApi: DashboardApiService
) => {
  const translate = useTranslation();
  const { auth } = store.getState();

  const [testDetalhesList, setTestDetalhesList] = useState<
    TestResultDetailType[]
  >([]);
  const [testCabecalhoList, setTestCabecalhoList] = useState<
    TestCabecalhoDataType[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [selectedTab, setSelectedTab] = useState<SelectTabType>(0);

  const cardTitles = [
    translate("labels_hits_fragrance"),
    translate("labels_hits_gender"),
    translate("labels_average_response_time"),
    translate("labels_shot_average"),
    translate("labels_hits_tests"),
    translate("labels_hits_ageGroup")
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await api.makeHttpRequest({
          url: `/dashboard/testdetalhesdashanduser?UserId=${auth.user.id}`
        });
        if (response.result && Array.isArray(response.result)) {
          const data: TestResultDetailType[] = response.result;
          setTestDetalhesList(data);
        } else {
          console.error("Unexpected response format:", response.result);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [auth.user.id]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await dashboardApi.getTestCabelhoDashAndUser(
          auth.user.id
        );
        setTestCabecalhoList(result);
      } catch (error) {
        console.error("API call failed:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: SelectTabType
  ) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUniqueDetailsTestNames = (): string[] => {
    if (!testDetalhesList || testDetalhesList.length === 0) {
      return [];
    }

    const uniqueTestNames = new Set<string>(
      testDetalhesList.map(item => item.Teste)
    );
    return Array.from(uniqueTestNames);
  };

  const getResults = useCallback(
    (testNames: Array<string>): Array<RespostaDetalhada> => {
      const filteredResults = filterTestsByNames(
        testDetalhesList,
        testNames,
        processData
      );
      return filteredResults;
    },
    [testDetalhesList, processData]
  );

  const getGenderResults = (testNames: Array<string>): Array<Resultado> => {
    if (!testDetalhesList || testDetalhesList.length === 0) {
      return [];
    }
    const sumByGenre: { [key: string]: number } = { M: 0, F: 0 };
    const HomemSet = new Set<string>();
    const MulherSet = new Set<string>();

    testDetalhesList.forEach(item => {
      if (!testNames.includes(item.Teste)) {
        return;
      }
      const value = item.Acerto === 1 ? 1 : 0;
      sumByGenre[item.genre] += value;
      if (item.genre === "M") {
        HomemSet.add(item.Paciente);
      } else {
        MulherSet.add(item.Paciente);
      }
    });

    const media: Resultado[] = Object.keys(sumByGenre).map(genre => ({
      name: genre,
      value:
        sumByGenre[genre] / (genre === "M" ? HomemSet.size : MulherSet.size),
      fill: colors[genre] || "#888888"
    }));

    return media;
  };

  const getAverageTimeResults = (
    testNames: Array<string>
  ): Array<TimeAverage> => {
    const time = filterTestsByNames(
      testDetalhesList,
      testNames,
      calculateAverageTimeByAnswer
    );
    return time;
  };

  const getAverageShotsResults = (
    testNames: Array<string>
  ): Array<ShotsAverage> => {
    const shots = filterTestsByNames(
      testDetalhesList,
      testNames,
      calculateAverageShotsByAnswer
    );
    return shots;
  };

  const filterTestsByNames = useCallback(
    <T,>(
      testList: Array<TestResultDetailType | TestCabecalhoDataType>,
      testNames: string[],
      next: (
        filteredTests: Array<TestResultDetailType | TestCabecalhoDataType>
      ) => T
    ): never[] | T => {
      if (!testList || testList.length === 0) {
        return [];
      }
      const filteredTests = testList.filter(item =>
        testNames.includes(item.Teste)
      );
      return next(filteredTests);
    },
    []
  );

  const getHitsByTests = (testNames: Array<string>): NumeroTeste => {
    const tests: NumeroTeste = {};
    testCabecalhoList.forEach(item => {
      if (!testNames.includes(item.Teste)) {
        return;
      }
      const test = item.Acertos;
      if (tests[test]) {
        tests[test] += 1;
      } else {
        tests[test] = 1;
      }
    });
    return tests;
  };

  const getHitsByAgeGroup = (testNames: Array<string>): Array<AverageData> => {
    const listaFaixa = filterTestsByNames(
      testCabecalhoList,
      testNames,
      calcularMediaPorFaixaEtaria
    );
    return listaFaixa;
  };

  return {
    isLoading,
    selectedTab,
    cardTitles,
    handleTabChange,
    windowHeight,
    getUniqueDetailsTestNames,
    getResults,
    getGenderResults,
    getAverageTimeResults,
    getAverageShotsResults,
    getHitsByTests,
    getHitsByAgeGroup
  };
};

export const formatCEP = cep => {
    cep = cep.replace(/\D/g, '');
    if (cep.length === 8) {
        cep = cep.replace(/(\d{5})(\d)/, "$1-$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    }

    return cep;
}

export default function verifyCEP(str) {
    let error = '';
    let isValid = true;
    let cep = formatCEP(str);

    if (/\d{5}-\d{3}/.test(cep)) {
        isValid = true;
        error = '';
    }
    else {
        isValid = false;
        error = 'CEP inválido'
    }

    return {
        error: error,
        isValid: isValid,
        format: cep
    }
}
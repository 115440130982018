import { ChangeEvent } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

type InputCheckboxProps = {
  checked: boolean;
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  color?: "default";
  label: string;
};

export const InputCheckbox = ({
  checked,
  onChange,
  color = "default",
  label
}: InputCheckboxProps) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} color={color} />}
      label={label}
    />
  );
};

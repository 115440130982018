function verify(str) 
{
    let nasc = str.split('-').map(Number);
    let test = new Date(nasc[0] + 12, nasc[1] - 1, nasc[2]);


    if (new Date() >= test) 
    {
        return true;
    }

    return false;
}

export default function verifyBirthday(str) {
    let isValid

    if (str === '') {
        isValid = true;
    } else if (verify(str)) {
        isValid = true;
    } else {
        isValid = false;
    }

    return {
      isValid: isValid
    }
}
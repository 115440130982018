import { useState } from "react";
import { Answer, QuestionType } from "./preViewQuiz";
import { useTranslation } from "_metronic/i18n/language";

type PreviewDiscriminationProps = {
  scaleFactor: number;
  answers: Answer[];
  selectedAnswerId: number | null;
  currentQuestionIndex: number;
  questionsLength: number;
  currentQuestion: QuestionType;
  handleSelectAnswer: (answerId: number) => void;
  handleNextQuestion: () => void;
};

const PreviewDiscrimination = ({
  scaleFactor,
  answers,
  selectedAnswerId,
  currentQuestionIndex,
  questionsLength,
  currentQuestion,
  handleSelectAnswer,
  handleNextQuestion
}: PreviewDiscriminationProps) => {
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [isDisabledNextButton, setIsDisabledNextButton] = useState(true);
  const [checkedIndex, setCheckedIndex] = useState<number | null>(null);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);
  const translate = useTranslation();
  const [enabledButtons, setEnabledButtons] = useState([
    true,
    ...Array(answers.length - 1).fill(false)
  ]);

  const labelSmell = [
    {
      key: 1,
      name: translate("screenApp_quiz_smell")
    },
    {
      key: 2,
      name: translate("screenApp_quiz_nowSmell")
    },
    {
      key: 3,
      name: translate("screenApp_quiz_finallySmell")
    }
  ];

  const handleAnswerClick = (answerId: number, index: number) => {
    handleSelectAnswer(answerId);
    setCurrentLabelIndex(prevIndex => (prevIndex + 1) % labelSmell.length);
    setEnabledButtons(prevState =>
      prevState.map((_, idx) => idx === index + 1)
    );
    if (index === answers.length - 1) {
      setShowCheckboxes(true);
    }
      };

  const handleCheckboxChange = (index: number) => {
    setCheckedIndex(index);
    setIsDisabledNextButton(false);
  };

  const handleNextClick = () => {
    if (currentQuestionIndex + 1 >= questionsLength) {
      return;
    }
    handleNextQuestion();
    setShowCheckboxes(false);
    setIsDisabledNextButton(true);
    setCheckedIndex(null);
    setEnabledButtons([true, ...Array(answers.length - 1).fill(false)]);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "360px",
        width: "100%",
        textAlign: "center"
      }}
    >
      <p
        style={{
          marginTop: "40px",
          marginBottom: "10px",
          fontSize: `${20 * scaleFactor}px`,
          color: "#000000"
        }}
      >
        {showCheckboxes ? (
          checkedIndex !== null ? (
            translate("screenApp_quiz_goNextQuestion")
          ) : (
            <span>{currentQuestion.question.quest}</span>
          )
        ) : (
          <span>
            {labelSmell[currentLabelIndex].name.replace("{{letter}}", "")}
          </span>
        )}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
      >
        {answers.map((answer, index) => (
          <div
            key={answer.quizQuestionAnswerId}
            style={{
              marginBottom: "10px",
              alignItems: "center",
              width: "80%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <button
              onClick={() => handleAnswerClick(answer.answerId, index)}
              disabled={!enabledButtons[index]}
              style={{
                width: "100%",
                padding: "7px",
                borderRadius: "6px",
                border: "none",
                backgroundColor: enabledButtons[index]
                  ? selectedAnswerId === answer.answerId
                    ? "#F0F0FF"
                    : "#ff5c33"
                  : "#d9d9d9",
                fontSize: `${18 * scaleFactor}px`,
                cursor: enabledButtons[index] && "pointer",
                display: "flex",
                justifyContent: "center",
                color: enabledButtons[index] ? "#fff" : "#808080"
              }}
            >
              {String.fromCharCode(65 + index)}
            </button>
            {showCheckboxes && (
              <input
                type="checkbox"
                checked={checkedIndex === index}
                onChange={() => handleCheckboxChange(index)}
                style={{
                  marginLeft: "10px",
                  width: "24px",
                  height: "24px",
                  transform: `scale(${scaleFactor})`,
                  cursor: "pointer"
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex"
        }}
      >
        <button
          onClick={handleNextClick}
          disabled={isDisabledNextButton}
          style={{
            flex: 1,
            paddingBlock: "4px",
            borderRadius: "4px",
            border: "none",
            backgroundColor: isDisabledNextButton ? "#d9d9d9" : "#794dff",
            color: isDisabledNextButton ? "#808080" : "#fff",
            fontSize: `${18 * scaleFactor}px`
          }}
        >
          {translate("screenApp_validation_next").toUpperCase()} &gt;&gt;
        </button>
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: `${14 * scaleFactor}px`,
          fontSize: `${16 * scaleFactor}px`,
          color: "#ffffff",
          backgroundColor: "#a1a1a9",
          borderRadius: "5px",
          padding: "1px"
        }}
      >
        {translate("screens_quiz_labels_question")} {currentQuestionIndex + 1} /{" "}
        {questionsLength}
      </div>
    </div>
  );
};
export default PreviewDiscrimination;

export const initialMonthsData = {
  Jan: 0,
  Fev: 0,
  Mar: 0,
  Abr: 0,
  Mai: 0,
  Jun: 0,
  Jul: 0,
  Ago: 0,
  Set: 0,
  Out: 0,
  Nov: 0,
  Dez: 0
};

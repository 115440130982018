import React, { useState } from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card
} from "@mui/material";
import { AccountType } from "../../types";
import { useTranslation } from "_metronic/i18n/language";

interface RenderAccountProps {
  account: AccountType | undefined;
  button: JSX.Element;
}

export function RenderAccount({ account, button }: RenderAccountProps) {
  const translate = useTranslation();
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2px",
        marginBottom: "10px"
      }}
    >
      <TableContainer component={Paper} style={{ marginBottom: "0px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate("screenApp_listPacient_account_name")}
              </TableCell>
              <TableCell>
                {translate("screenApp_listPacient_account_email")}
              </TableCell>
              <TableCell>
                {translate("screenApp_listPacient_account_cpf")}
              </TableCell>
              <TableCell>{translate("screens_user_list_genre")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{account?.name}</TableCell>
              <TableCell>{account?.email}</TableCell>
              <TableCell>{account?.cpf}</TableCell>
              <TableCell>{account?.genre}</TableCell>
              <TableCell align="right">{button}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

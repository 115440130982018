import { useTranslation } from "_metronic/i18n/language";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from "recharts";

interface RespostaDetalhada {
  RightAnswer: string;
  Acertos: number;
  Erros: number;
  AcertosPercent: string;
  ErrosPercent: string;
}

interface BarChartPorcentagemProps {
  data: RespostaDetalhada[];
}

const BarChartPorcentagem: React.FC<BarChartPorcentagemProps> = ({ data }) => {
  const translate = useTranslation();
  // Ordenar dados pela porcentagem de erros de forma ascendente (menor para maior)
  const sortedData = data.sort(
    (a, b) => parseFloat(a.ErrosPercent) - parseFloat(b.ErrosPercent)
  );
  const renderLabel = (value: number): string => {
    const numericValue = parseFloat(value.toString());
    return (
      numericValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      }) + "%"
    );
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical" // Gráfico com barras horizontais
        data={sortedData}
        margin={{
          top: 33,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" style={{ fontWeight: "bold" }} />
        <YAxis
          type="category"
          dataKey="RightAnswer"
          style={{ fontWeight: "bold", whiteSpace: "nowrap", }}
          interval={0}
          width={120}
        />
        <Tooltip formatter={renderLabel} />
        <Legend />
        <Bar
          dataKey="AcertosPercent"
          fill="#1e90ff"
          name={translate("labels_percentage_hits")}
          stackId="a"
        >
          <LabelList
            dataKey="AcertosPercent"
            position="inside"
            fill="#fff"
            style={{ fontWeight: "bold" }}
            formatter={renderLabel}
          />
        </Bar>
        <Bar
          dataKey="ErrosPercent"
          fill="#376bae"
          name={translate("labels_percentage_errors")}
          stackId="a"
        >
          <LabelList
            dataKey="ErrosPercent"
            position="inside"
            fill="#fff"
            style={{ fontWeight: "bold" }}
            formatter={renderLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartPorcentagem;

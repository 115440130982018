import axios from "axios";
import { AnanseApiService } from "../services/Ananse/AnanseApiService";

export const REGISTER_URL = "api/usuario";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// const instanciaAno = parseInt(process.env.REACT_APP_ENEF_YEAR || new Date().getFullYear(), 10)
const api = new AnanseApiService();

export async function login(email, password) {
  return api.makeHttpRequest({
    url: "/login",
    method: "POST",
    data: {
      // InstanciaAno: instanciaAno,
      Login: email,
      Senha: password
    }
  });
}

export async function register(
  login,
  email,
  nome,
  cpfCnpj,
  senha,
  organizacao,
  perfil,
  loginFacebook,
  loginGoogle
) {
  const response = await api.makeHttpRequest({
    url: "/usuario",
    method: "POST",
    data: {
      login,
      email,
      nome,
      cpfCnpj,
      senha,
      organizacao,
      perfil,
      loginFacebook,
      loginGoogle
    }
  });
  return response;
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return api.makeHttpRequest({
    url: "/usuario/me"
  });
}

export async function loginSocial(email) {
  return api.makeHttpRequest({
    url: `/api/login/login-with-social?login=${email}`,
    method: "GET"
  });
}

export async function registerActive(data) {
  const response = await api.makeHttpRequest({
    url: "/usuario/useractive",
    method: "POST",
    data
  });
  return response;
}

export async function registerNewUser(data) {
  const response = await api.makeHttpRequest({
    url: "/usuario",
    method: "POST",
    data
  });
  return response;
}

export const checkEmailExists = async value => {
  try {
    const response = await api.makeHttpRequest({
      url: `/usuario/verificar-email-login?email=${value}`,
      method: "GET"
    });
    return !response;
  } catch (error) {
    console.error("Error checking email:", error);
    return false;
  }
};

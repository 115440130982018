import { Stack, Skeleton } from "@mui/material";

const MySkeleton = () => (
  <Skeleton variant="rectangular" width={210} height={40} />
);

const Variants = () => {
  const renderSkeleton = () => {
    const output: Array<JSX.Element> = [];
    for (let i = 0; i < 3; i++) {
      output.push(<MySkeleton key={i} />);
    }
    return output;
  };

  return <Stack spacing={1}>{renderSkeleton()}</Stack>;
};

export default Variants;

import { ArrowBack, ArrowForward, Close, DarkMode, LightMode, Menu, MenuOpen } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Toolbar,
  useMediaQuery
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import LanguageSelector from "app/partials/layout/LanguageSelector";
import UserProfile from "app/partials/layout/UserProfile";
import React from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { translatePageName, determineActionName } from "./utils";
import { useTranslation } from "_metronic/i18n/language";
import Brand from "../brand/Brand";
import { useRTL } from "themes/RTLProvider/RTLProvider";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const drawerWidth = 300;
const drawerHeight = 64;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: drawerHeight,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Topbar: React.FC<{
  open?: boolean;
  isMobile?: boolean;
  onOpenMenu?: any;
}> = ({ open, isMobile, onOpenMenu }) => {
  const translate = useTranslation();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { toggleTheme, isDarkMode } = useThemeApp();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const isRtl = useRTL();
  const arrowIcon = isRtl ? <ArrowForward /> : <ArrowBack />;

  const splitPathname = pathname.split("/").filter(Boolean);

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* {isMobileView && <Brand />} */}
          {!open && <Brand />}
          <Button color="inherit" onClick={goBack}>
            {arrowIcon}
          </Button>
        </Box>
        {!isMobileView && (
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ flexGrow: 1, textAlign: isMobileView ? "center" : "left" }}
          >
            {splitPathname.map((path, index) => (
              <Link
                key={index}
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={"/" + splitPathname.slice(0, index + 1).join("/")}
              >
                {index === splitPathname.length - 1
                  ? determineActionName(
                      "/" + splitPathname.slice(0, index + 1).join("/"),
                      translate
                    ) ||
                    translatePageName(
                      "/" + splitPathname.slice(0, index + 1).join("/"),
                      translate
                    )
                  : translatePageName(
                      "/" + splitPathname.slice(0, index + 1).join("/"),
                      translate
                    )}
              </Link>
            ))}
          </Breadcrumbs>
        )}

        <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleTheme}
              sx={{ marginRight: 1 }}
            >
              {isDarkMode ? <LightMode /> : <DarkMode />}
            </IconButton>
          {isMobileView && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onOpenMenu}
              sx={{ marginRight: 1 }}
            >
              {open ? <MenuOpen /> : <Menu />}
            </IconButton>
          )}
          <LanguageSelector iconType="" />
          <UserProfile showBadge={true} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;

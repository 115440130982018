import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState
} from "react";

type DashDsaProps = PropsWithChildren;

type DashDsaContextType = {
  selectedYear: number;
  selectedTestNames: string[];
  handleChangeYear: (year: number) => void;
  handleTestNamesChange: (testNames: Array<string>) => void;
};

const DashDsaContext = createContext<DashDsaContextType>(
  {} as DashDsaContextType
);

const DashDsaProvider: FC<DashDsaProps> = ({ children }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedTestNames, setSelectedTestNames] = useState<Array<string>>([]);

  const handleChangeYear = (year: number): void => {
    setSelectedYear(year);
  };

  const handleTestNamesChange = (testNames: Array<string>): void => {
    setSelectedTestNames(testNames);
  };

  return (
    <DashDsaContext.Provider
      value={{
        selectedYear,
        selectedTestNames,
        handleChangeYear,
        handleTestNamesChange
      }}
    >
      {children}
    </DashDsaContext.Provider>
  );
};

export function useDashDsa(): DashDsaContextType {
  return useContext(DashDsaContext);
}

export default DashDsaProvider;

import React from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

interface Props {
  children: React.ReactNode;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  width?: any
  minWidth?: any
}
const ModalComponent = ({ children, openModal, setOpenModal,width,minWidth }: Props) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "99%",
    maxHeight: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    overflow: "auto",
    width,
    minWidth
  };
  const handleClose = () => setOpenModal(false);

  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {children}
          <IconButton
            style={{ position: "absolute", right: 8, top: 5 }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalComponent;

import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import {
  BaseDataEntry,
  LabelRenderFunction
} from "react-minimal-pie-chart/types/commonTypes";

type Data = {
  title?: string;
  value: number;
  color: string;
  label: number;
};

type CollectionType = {
  slot: number;
  shotSeconds: number;
  expirationDays: number;
  fragrance?: { name: string };
  fragranceDuplicated?: boolean;
};

type PieChartComponentProps = {
  selectedIndex: number;
  collection: Array<CollectionType>;
  lineWidth?: number;
  paddingAngle?: number;
  segmentsStyle?:
    | React.CSSProperties
    | ((dataIndex: number) => React.CSSProperties | undefined);
  label?: LabelRenderFunction<BaseDataEntry>;
  labelPosition?: number;
  center?: [number, number];
  viewBoxSize?: [number, number];
  onClick?: (
    event: React.MouseEvent<Element, MouseEvent>,
    dataIndex: number
  ) => void;
};

const colors = {
  configured: "#5578EB",
  notConfigured: "#5A6268",
  selected: "#CE8300"
};

const buildSlots = (
  selectedIndex: number,
  collection: Array<CollectionType>
) => {
  const slots = [];
  for (let i = 0; i < collection.length; i++) {
    const c = collection[i];

    const body = {
      title: "",
      value: 1,
      color: colors.notConfigured,
      label: c.slot
    };

    if (c.fragrance && !c.fragranceDuplicated) {
      body.title = c.fragrance.name;
      body.color = colors.configured;
    }

    if (selectedIndex === i) {
      body.color = colors.selected;
    }

    slots.push(body);
  }

  return slots;
};

export const PieChartComponent = ({
  selectedIndex,
  collection,
  lineWidth = 15,
  paddingAngle = 5,
  segmentsStyle = { cursor: "pointer" },
  label,
  labelPosition = 110,
  center,
  viewBoxSize,
  onClick
}: PieChartComponentProps) => {
  const [data, setData] = useState<Data[]>([]);
  const { isDarkMode } = useThemeApp();

  useEffect(() => {
    const slotsData = buildSlots(selectedIndex, collection);
    setData(slotsData);
  }, [selectedIndex, collection]);

  const fill = isDarkMode ? "#fff" : "inherit";

  return (
    <PieChart
      data={data}
      lineWidth={lineWidth}
      paddingAngle={paddingAngle}
      segmentsStyle={segmentsStyle}
      label={label}
      labelStyle={() => ({
        fontSize: "5px",
        fill
      })}
      labelPosition={labelPosition}
      center={center}
      viewBoxSize={viewBoxSize}
      onClick={onClick}
    />
  );
};

import React, { ReactNode } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

type ModalContainerProps = {
  isDarkMode: boolean;
};

const CustomModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  position: relative;
  margin: auto;
  outline: none;

  background-color: ${props => (props.isDarkMode ? "#333333" : "#fff")};
  color: ${props => (props.isDarkMode && "#fff" )};
  border-radius: 10px;
  padding: 20px;
`;

type ModalComponentProps = {
  open: boolean;
  children: ReactNode;
  container?: boolean;
};

const ModalComponent: React.FC<ModalComponentProps> = ({
  open,
  children,
  container
}) => {
  const { isDarkMode } = useThemeApp();

  return (
    <CustomModal open={open}>
      {container ? (
        <ModalContainer isDarkMode={isDarkMode}>{children}</ModalContainer>
      ) : (
        <>{children}</>
      )}
    </CustomModal>
  );
};

export default ModalComponent;

type BackgroundDescriptionProps = {
  description: string;
  question: string;
};

const BackgroundTitle = ({
  description,
  question
}: BackgroundDescriptionProps) => {
  return (
    <div className="title-question-container">
      <h1 className="title-question-text">{description}</h1>
      <h2 className="title-question-text">{question}</h2>
    </div>
  );
};
export default BackgroundTitle;

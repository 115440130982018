import { Typography } from "@material-ui/core";
import { Card, CardContent } from "@mui/material";
import React from "react";

interface CounterCardProps {
  title: string;
  count: string;
}

const CounterCard: React.FC<CounterCardProps> = ({ title, count }) => {
  return (
    <Card>
      <CardContent style={{ alignItems: "center", justifyContent: "center" }}>
        <Typography variant="h6" component="h2" style={{ textAlign: "center" }}>
          {title}
        </Typography>
        <Typography variant="h4" component="p" style={{ textAlign: "center" }}>
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CounterCard;

import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { AnanseApiService } from "../../../../../services/Ananse/AnanseApiService";
import { useEffect } from "react";
import { Form } from "react-bootstrap";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    }
  }
};

const api = new AnanseApiService();

const MultipleSelectCheckmarks = ({
  url,
  handleMultiSelect,
  value: getvalue,
  placeholder
}) => {
  const [data, setData] = React.useState([]);
  const [personName, setPersonName] = React.useState([]);

  const getListFromValue = async inputValue => {
    let searchedList = [];
    // if (inputValue && inputValue.length >= 3) {
    try {
      const { total } = await api.makeHttpRequest({
        url: "/usuario"
      });
      console.log("total: ", total);
      const searchedData = await api.makeHttpRequest({
        url: `/usuario?PerPage=${total}`
      });
      if (searchedData.data && searchedData.data.length > 0) {
        searchedData.data.forEach(item => {
          searchedList.push(item);
        });
      }
    } catch (error) {
      console.error(`Erro ao fazer a busca: ${error.message}`);
    }
    setData(searchedList);
    return searchedList;
  };

  useEffect(() => {
    let newValue =
      typeof getvalue === "string" ? getvalue.split(";") : getvalue;
    if (typeof getvalue === "string") {
      newValue = getvalue.split(";").map(x => Number(x.trim()));
    }
    getListFromValue();
    if (getvalue && personName.length < 1) {
      setPersonName(newValue);
    }
  }, [getvalue, personName]);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(";") : value
    );
    handleMultiSelect(
      typeof value === "string" ? value.split(";") : value.join("; ")
    );
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        {/* <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel> */}
        <Form.Label>{placeholder}</Form.Label>
        <Select
          multiple
          renderValue={selected => selected.join("; ")}
          MenuProps={MenuProps}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Médicos"
          style={{ height: 38.4 }}
          value={personName}
          onChange={handleChange}
        >
          {data.map(item => (
            <MenuItem key={item} value={item.id}>
              <Checkbox
                variant="contained"
                color="success"
                checked={personName.indexOf(item.id) > -1}
              />
              <ListItemText primary={item.id} secondary={item.nome} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectCheckmarks;

import styled from "styled-components";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  max-width: 600px;
  margin: 0 auto;
  @media (max-width: 600px) {
    margin: 0 20px;
  }

  background: ${props => props.bg || "transparent"};
  border-radius: 15px;
  padding: 20px;

  span {
    display: block;
  }

  .forgot-title {
    font-size: 24px;
    font-weight: bold;
  }

  .forgot-subtitle {
    font-size: 16px;
    margin: 15px 0 30px;
  }
`;

export const SuccessMessage = styled.div`
  outline: none;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;

  border-radius: 15px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  border-bottom: 5px solid green;

  background: white;

  span {
    font-size: 18px;
  }
`;

export const defaultInnerWidth = 90;

export const StyledInput = styled(TextField)`
  width: ${defaultInnerWidth + "%"};
  margin: 20px 0;

  input {
    font-size: 18px;
    padding: 7px 5px;
  }
`;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  width: ${defaultInnerWidth + "%"};
`;

export const PatternButton = `
  outline: none;
  border: none;
  margin: none;
  padding: 15px 25px;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: all 0.2s linear;
`;

export const CancelButton = styled.button`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props.theme.disabledButton : "#555"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;

  &:hover {
  background: ${props =>
    props.disabled ? props.theme.disabledButton : "#474747"};
    color: white;
  }
`;
// cor antiga #0D2551
export const SubmitButton = styled.button`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props.theme.disabledButton : "#007bff"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;
`;

export const LinkButton = styled(Link)`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props.theme.disabledButton : "#007bff"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;
`;

import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

type ConfiguredFragranceTypes = {
  slot: number;
  fragrance: {
    image?: {
      tempUri: string;
    };
    name: string;
  };
  shotSeconds: number;
  expirationDays: number;
  fragranceDuplicated?: boolean;
};
type TableFragrancesProps = {
  slot: string;
  fragrance: string;
  shotSeconds: string;
  expirationDays: string;
  dataDelete: string;
  nothingConfig: string;
  configuredFragrances: ConfiguredFragranceTypes[];
  clearSlot: (slot: number) => void;
};

const TableFragrances = ({
  slot,
  fragrance,
  shotSeconds,
  expirationDays,
  dataDelete,
  nothingConfig,
  configuredFragrances,
  clearSlot
}: TableFragrancesProps) => {
  const { isDarkMode } = useThemeApp();
  const theme = isDarkMode ? "dark" : "light";

  return (
    <table
      className={`table-fragrances table-${theme}`}
    >
      <thead>
        <tr className="text-uppercase">
          <th className="font-weight-bold" style={{ width: "10%" }}>
            {slot}
          </th>
          <th className="font-weight-bold" style={{ width: "40%" }}>
            {fragrance}
          </th>
          <th className="font-weight-bold" style={{ width: "20%" }}>
            {shotSeconds}
          </th>
          <th className="font-weight-bold" style={{ width: "20%" }}>
            {expirationDays}
          </th>
          <th className="font-weight-bold" style={{ width: "10%" }}></th>
        </tr>
      </thead>
      <tbody>
        {configuredFragrances.length ? (
          configuredFragrances.map((c: ConfiguredFragranceTypes) => (
            <tr
              key={c.slot}
              className={c.fragranceDuplicated ? "text-danger" : ""}
            >
              <td>{c.slot}</td>
              <td>
                {c.fragrance.image && (
                  <img
                    className="fragrance-preview"
                    src={c.fragrance.image.tempUri}
                    alt={c.fragrance.name}
                  />
                )}

                {c.fragrance.name}
              </td>
              <td>{c.shotSeconds}</td>
              <td>{c.expirationDays}</td>
              <td>
                <IconButton
                  size="small"
                  title={dataDelete}
                  onClick={() => clearSlot(c.slot)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </td>
            </tr>
          ))
        ) : (
          <tr className="text-center">
            <td colSpan={4}>{nothingConfig}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableFragrances;

import clsx from "clsx";
import PreviewButton from "./PreviewButton";
import { Answer } from "../preViewQuiz";

type ChildIdentificationAnswersProps = {
  imageBackground: string;
  answers: Answer[];
  enabledButtons: boolean;
  selectedAnswerId: number | null;
  handleAnswerClick: (answerId: number) => void;
};

const ChildIdentificationAnswers = ({
  imageBackground,
  answers,
  enabledButtons,
  selectedAnswerId,
  handleAnswerClick
}: ChildIdentificationAnswersProps) => {
  return (
    <div
      className={clsx("container-answers", {
        "container-answers-with-image": imageBackground
      })}
    >
      {answers.map(answer => {
        const backgroundImageButton = answer.answer.files?.[0]?.tempUri
          ? `url(${answer.answer.files[0].tempUri})`
          : "none";

        return (
          <div key={answer.quizQuestionAnswerId} className="answer-item">
            <PreviewButton
              onClick={() => handleAnswerClick(answer.answerId)}
              disabled={!enabledButtons}
              className={clsx("btn-answers", {
                "btn-answer-selected": selectedAnswerId === answer.answerId
              })}
              style={{
                backgroundImage: backgroundImageButton
              }}
              label={answer.answer.label}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChildIdentificationAnswers;

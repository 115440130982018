export const generateColorPalette = (numColors: number): string[] => {
  const colors: string[] = [];
  const predefinedColors = [
    "hsla(206, 100%, 49%, 0.5)",
    "hsla(160, 100%, 45%, 0.5)",
    "hsla(40, 99%, 55%, 0.5)",
    "hsla(351, 100%, 64%, 0.5)",
    "hsla(254, 55%, 59%, 0.5)"
  ];

  // Adicionar cores pré-definidas (evitando repetições)
  predefinedColors.forEach(color => {
    if (!colors.includes(color)) {
      colors.push(color);
    }
  });

  // Se ainda restarem mais cores para gerar
  if (numColors > colors.length) {
    const remainingColors = numColors - colors.length;
    const hueStep = 360 / remainingColors;

    // Gerar cores adicionais sem repetições
    for (let i = 0; i < remainingColors; i++) {
      const hue = hueStep * i;
      const newColor = `hsla(${hue}, 70%, 50%, 0.5)`;

      // Verificar se a nova cor já existe na lista
      if (!colors.includes(newColor)) {
        colors.push(newColor);
      }
    }
  }

  return colors.slice(0, numColors); // Retornar apenas o número de cores solicitado
};


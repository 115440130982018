import { CRUDApiService } from "./CRUDApiService";

export class QuestionApiService extends CRUDApiService {
  constructor() {
    super('question');
  }

  getFileByFileOwnerId(id) {
    if (!id) {
      throw new Error('id should be provided');
    }

    return this.makeHttpRequest({
      method: 'GET',
      url: `/by-fileownerid?fileOwnerId=${id}`
    });
  }
}

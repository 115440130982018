import { useTranslation } from "_metronic/i18n/language";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import { CampaignType } from "app/crud";
import {
  CAMPAIGN_ID_FIELD,
  CampaignIdType
} from "app/pages/admin/pages/Devices/DeviceUpdate";
import { SelectHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";

type SelectCampaignsProps = SelectHTMLAttributes<HTMLSelectElement> & {
  data: Array<CampaignType>;
  register: UseFormRegister<CampaignIdType>;
};

export const SelectCampaigns = ({
  data,
  register,
  ...rest
}: SelectCampaignsProps) => {
  const translate = useTranslation();
  const { isDarkMode } = useThemeApp();
  const theme = isDarkMode ? "dark" : "light";

  return (
    <div className="col-lg-4 col-12">
      <label className="form-label">
        {translate("screens_quiz_list_campaign")}
      </label>
      <select
        {...rest}
        {...register(CAMPAIGN_ID_FIELD)}
        className={`form-control custom-select select-border-${theme}`}
      >
        {data.map(campaign => (
          <option key={campaign.id} value={campaign.id}>
            {campaign.name}
          </option>
        ))}
      </select>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { connect } from "react-redux";
import translate from "@noar-health/commons";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Icon, Modal } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import ModalAlterPassword from "./components/ModalAlterPassword/index";
import "./style.scss";
import DrawerLeft from "./DrawerLeft";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      statusModal: false,
      viewModal: false,
      sucess: false,
      disabledClickOut: false
    };
  }
  showMensage(sucess, error) {
    if (error) {
      Swal.fire(
        translate(this.props.i18n.lang).defaultMessages.error,
        error
          ? error
          : translate(this.props.i18n.lang).defaultMessages.changeStatus,
        "error"
      );
    } else if (sucess) {
      Swal.fire({
        title: translate(this.props.i18n.lang).defaultMessages.success,
        text: translate(this.props.i18n.lang).defaultMessages
          .alterPasswordSuccess,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: translate(this.props.i18n.lang).defaultMessages.ok,
        cancelButtonText: translate(this.props.i18n.lang).buttons.exit
      });
    }
  }

  render() {
    const { user, showHi, showBadge } = this.props;
    const ModalContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;

      width: fit-content;
      height: 100%;
      margin: auto;
      outline: none;
      margin-top: 64;
    `;
    return (
      <>
        <Modal
          open={this.state.statusModal}
          onClose={() => this.setState({ statusModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalContainer>
            <ModalAlterPassword
              closeModal={() => this.setState({ statusModal: false })}
              bg="white"
              result={(sucess, error) => {
                this.showMensage(sucess, error);
                this.setState({ statusModal: false });
              }}
            />
          </ModalContainer>
        </Modal>
        <DrawerLeft
          user={user}
          onPassword={() => this.setState({ statusModal: true })}
        />
      </>
    );
  }
}

const mapStateToProps = store => ({
  user: store.auth.user,
  i18n: store.i18n
});

export default connect(mapStateToProps)(UserProfile);

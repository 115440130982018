import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Paper } from '@mui/material';

interface SortableItemProps {
    id: number;
    children: React.ReactNode;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '16px',
        marginBottom: '8px',
        // backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        cursor: 'move',
    };

    return (
        <Paper ref={setNodeRef} style={style} {...attributes} {...listeners} elevation={2}>
            {children}
        </Paper>
    );
};

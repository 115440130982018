import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formatToCPF, formatToPhone, isCPF, isPhone } from "brazilian-values";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
  Button,
  Icon,
  Stack,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { useEffect, useState } from "react";
import Loading from "app/pages/home/components/Loading";
import Swal from "sweetalert2";
import { Link, useParams, useHistory } from "react-router-dom";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import store from "app/store/store";
import FormActions from "components/FormActions";
import { useTranslation } from "_metronic/i18n/language";

const UserForm = (props: any) => {
  const params = useParams();
  const history = useHistory();
  const translate = useTranslation();
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [docType, setDocType] = useState("cpf");

  const schema = Yup.object().shape({
    cpf: Yup.string()
      // .test("valid-cpf", "CPF inválido!", (value: any) => {
      //   return isCPF(value);
      // })
      // .required(translate(locale).screens.login.validations.required)
      // .min(11, "O número do CPF deve conter 11 dígito!")
      .nullable(),
    // docType: Yup.string(),
    nome: Yup.string().required(
      translate("screens.login.validations.required")
    ),
    genre: Yup.string().required(
      translate("screens.login.validations.required")
    ),
    email: Yup.string()
      // .required(translate(locale).screens.login.validations.required)
      .email(translate("screens.login.validations.invalid")),
    mobilePhone: Yup.string()
      // .required(translate(locale).screens.login.validations.required)
      .test(
        "valid-phone",
        "O número do celular deve conter 11 dígitos!",
        (value: any) => {
          return value.length == 0
            ? true
            : value.length > 0 && value.length <= 11
            ? false
            : true;
        }
      )
      // .min(11, "O número do celular deve conter 11 dígito!")
      .nullable(),
    birthday: Yup.date().required(
      translate("screens.login.validations.required")
    ),
    settingsPassword: Yup.string()
      .required(translate("screenApp.modalAuth.messageError.pin"))
      .nullable()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userId: "",
      cpf: "",
      nome: "",
      genre: "",
      email: "",
      mobilePhone: "",
      birthday: "",
      deletedAt: "",
      document: "",
      settingsPassword: ""
    }
  });

  useEffect(() => {
    loadingData();
  }, []);

  const onSubmit = async (data: any) => {
    // delete data.address;
    const payload = {
      ...data,
      mobilePhone: `+${data.mobilePhone.replace(/\D/g, "")}`,
      tin: data.cpf.replace(/\D/g, ""),
      birthday: new Date(data.birthday).toISOString(),
      profile: data.profile.nome,
      ativo: true,

    };

    setLoading(true);
    try {
      const { id }: any = params;
      let reqBase: any;
      if (id) {
        console.log("payload put: ", payload);
        reqBase = { method: "PUT", url: `/usuario/${id}` };
      } else {
        console.log("payload post: ", payload);
        reqBase = { method: "POST", url: "/usuario" };
      }

      await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/account");
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const loadingData = async () => {
    // console.log(data);
    setLoading(true);
    try {
      const { id }: any = params;
      console.log("params: ", auth.user);
      const user = auth.user;
      if (id) {
        // const response = await api.makeHttpRequest({
        //   method: "GET",
        //   url: `/usuario/${id}`
        // });
        reset({
          ...user,
          cpf: formatToCPF(user?.cpf.replace(/\D/g, "")),
          settingsPassword: ""
        });
        console.log("loadingData: ", user);
      }
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <div>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.name")} *
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder={translate("screens.user.placeholders.name")}
                  {...register("nome")}
                />

                {errors.nome && (
                  <span className="invalid-input">{errors.nome.message}</span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="2" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.selectGender.title")} *
                </Form.Label>
                <Controller
                  name="genre"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      style={{ height: 38.4, width: "100%" }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(value: any) => {
                        setDocType(value.target.value);
                        onChange(value.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>
                          {translate("screens.user.placeholders.genre")}
                        </em>
                      </MenuItem>
                      <MenuItem value={"F"}>
                        {
                          translate("screenApp.listPacient.account.selectGender.female")
                        }
                      </MenuItem>
                      <MenuItem value={"M"}>
                        {
                          translate("screenApp.listPacient.account.selectGender.male")
                        }
                      </MenuItem>
                    </Select>
                  )}
                />
                {errors.genre && (
                  <span className="invalid-input">{errors.genre.message}</span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="2" xs="12">
                <Form.Label>
                  {translate("screens.user.labels.document")}
                </Form.Label>
                <Controller
                  name="document"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      style={{ height: 38.4, width: "100%" }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(value: any) => {
                        setDocType(value.target.value);
                        onChange(value.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"cpf"}>
                        {translate("screenApp.listPacient.account.cpf")}
                      </MenuItem>
                      <MenuItem value={"rg"}>
                        {translate("screenApp.listPacient.account.rg")}
                      </MenuItem>
                      <MenuItem value={"cnh"}>
                        {translate("screenApp.listPacient.account.cnh")}
                      </MenuItem>
                      <MenuItem value={"passport"}>
                        {
                          translate("screenApp.listPacient.account.passport")
                        }
                      </MenuItem>
                    </Select>
                  )}
                />
                {errors.document && (
                  <span className="invalid-input">
                    {errors.document?.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.docNumber")}
                </Form.Label>

                <Controller
                  name="cpf"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    console.log("doc: ", value);
                    if (docType === "cpf") {
                      return (
                        <Form.Control
                          type="text"
                          as={IMaskInput}
                          mask={"000.000.000-00"}
                          placeholder={
                            translate("screens.user.placeholders.document")
                          }
                          value={value}
                          onBlur={onBlur}
                          onChange={(value: any) =>
                            onChange(value.target.value)
                          }
                        />
                      );
                    }
                    return (
                      <Form.Control
                        type="text"
                        placeholder={
                          translate("screens.user.placeholders.document")
                        }
                        value={value}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value.target.value)}
                      />
                    );
                  }}
                />

                {errors.cpf && (
                  <span className="invalid-input">{errors.cpf.message}</span>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.email")}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={
                    translate("screens.user.placeholders.email")
                  }
                  {...register("email")}
                />
                {errors.email && (
                  <span className="invalid-input">{errors.email.message}</span>
                )}
              </Form.Group>

              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.phone")}
                </Form.Label>

                <Controller
                  name="mobilePhone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Form.Control
                      type="text"
                      as={IMaskInput}
                      mask="+00 (00) 0 0000-0000"
                      placeholder={
                        translate("screens.user.placeholders.document")
                      }
                      value={value}
                      onChange={(value: any) => {
                        onChange(value.target.value);
                      }}
                    />
                  )}
                />
                {errors.mobilePhone && (
                  <span className="invalid-input">
                    {errors.mobilePhone.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp.listPacient.account.birthdate")} *
                </Form.Label>

                <Controller
                  name="birthday"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Form.Control
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      value={value ? value.split("T")[0] : value}
                      onChange={(value: any) => onChange(value.target.value)}
                    />
                  )}
                />
                {errors.birthday && (
                  <span className="invalid-input">
                    {errors.birthday.message}
                  </span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp.modalAuth.pin")} *
                </Form.Label>

                <Form.Control
                  type="number"
                  placeholder={translate("screenApp.modalAuth.pin")}
                  max={9999}
                  {...register("settingsPassword")}
                />

                {errors.settingsPassword && (
                  <span className="invalid-input">
                    {errors.settingsPassword.message}
                  </span>
                )}
              </Form.Group>
            </Row>
          </Card.Body>
          <FormActions module="account" onReset={handleReset} />
        </Form>
      </Card>
      <Loading isLoading={loading} />
    </div>
  );
};

export default UserForm;

import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider
} from "@mui/material";
import React from "react";

interface CardComponentProps extends CardProps {
  title?: string;
  children: React.ReactNode;
  styleContent?: React.CSSProperties;
}

const CardComponent: React.FC<CardComponentProps> = ({
  children,
  title,
  styleContent,
  ...rest
}) => {
  return (
    <Card {...rest}>
      {title && (
        <div>
          <CardHeader title={title} />
          <Divider variant="middle" />
        </div>
      )}

      <CardContent style={styleContent}>{children}</CardContent>
    </Card>
  );
};

export default CardComponent;

import { Box } from "@material-ui/core";
import { CardComponent } from "components";
import { FC, PropsWithChildren } from "react";

type TabChartContentsProps = PropsWithChildren & {
  windowHeight: number;
};

export const TabChartContents: FC<TabChartContentsProps> = ({
  children,
  windowHeight
}) => {
  return (
    <Box mt={2}>
      <CardComponent
        style={{ marginBottom: "30px" }}
        styleContent={{ height: `${windowHeight - 200}px` }}
      >
        {children}
      </CardComponent>
    </Box>
  );
};

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
    Listar: "[Calendario.Listar] Action",
    DateInitial: "[Calendario.DateInital] Action"
};

const INITIAL_STATE = {
    list: [],
    dateInitial: null,
    filter: {
        perPage:20,
        page:1,
        orderBy:'Id',
        orderByDirection: 'ASC'
    }
}

export const calendarioReducer = persistReducer(
    { storage, key: "calendario", whitelist: ["list", "dateInitial", "filter"] },
    (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case actionTypes.Listar: {
          return {...state, list: action.payload};
        }
        case actionTypes.DateInitial: {
          return {...state, dateInitial: action.payload};
        }
        default:
          return state;
      }
    }
);

export const calendarioActions = {
    listar: lista => ({ type: actionTypes.Listar, payload: lista }),
    dateInitial: dateInitial => ({ type: actionTypes.DateInitial, payload: dateInitial }),
};

import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Logout, Password, Person, ManageAccounts } from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

import packageJson from "../../../../package.json";
import { Typography } from "@mui/material";
import { VersionApp } from "components";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

export default function DrawerLeft({ user, onPassword }) {
  const translate = useTranslation();
  const isRtl = useRTL();
  const flexDirection = isRtl ? "row-reverse" : "row";
  const iconLogout = isRtl ? (
    <Logout sx={{ transform: "scaleX(-1)" }} />
  ) : (
    <Logout />
  );
  const version = packageJson.version;
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function abreviarNome(nome) {
    let nomeAbreviado = "";

    if (typeof nome === "string" && nome.length > 0) {
      const partesDoNome = nome.trim().split(" ");

      const numeroDePartesDoNome = partesDoNome.length;
      nomeAbreviado = partesDoNome[0].charAt(0).toUpperCase();

      if (numeroDePartesDoNome > 1) {
        nomeAbreviado += partesDoNome[partesDoNome.length - 1]
          .charAt(0)
          .toUpperCase();
      }
    }
    return nomeAbreviado;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: abreviarNome(name)
    };
  }

  function editProfile() {
    history.push(`/admin/users/${user.id}/update`);
  }

  const list = anchor => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        marginTop: '64px'
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem sx={{ flexDirection: flexDirection }}>
          <ListItemIcon sx={{ flexDirection: flexDirection }}>
            {" "}
            <Avatar {...stringAvatar(user.nome)} />
          </ListItemIcon>
          <ListItemText primary={user.nome} secondary={user.profile.nome} />
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            onClick={onPassword}
            sx={{ flexDirection: flexDirection }}
          >
            <ListItemIcon sx={{ flexDirection: flexDirection }}>
              <Password />
            </ListItemIcon>
            <ListItemText
              primary={translate("defaultMessages_myPassword")}
              secondary={translate("defaultMessages_alterPassword")}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={editProfile}
            sx={{ flexDirection: flexDirection }}
          >
            <ListItemIcon sx={{ flexDirection: flexDirection }}>
              <ManageAccounts />
            </ListItemIcon>
            <ListItemText
              primary={translate("labels_my_profile")}
              secondary={translate("labels_edit_myData")}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <div style={{ width: "100%" }}>
            <Link to="/logout">
              <ListItemButton sx={{ flexDirection: flexDirection }}>
                <ListItemIcon sx={{ flexDirection: flexDirection }}>
                  {iconLogout}
                </ListItemIcon>
                <ListItemText
                  primary={translate("buttons_logout")}
                  // secondary={"Alterar a senha de acesso"}
                />
              </ListItemButton>
            </Link>
          </div>
        </ListItem>
      </List>
      <List style={{ position: "absolute", bottom: 20, width: 300 }}>
        <VersionApp />
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map(anchor => (
        <React.Fragment key={anchor}>
          <IconButton
            onClick={toggleDrawer(anchor, true)}
            sx={{ p: 0, margin: 2, height: 5 }}
          >
            <Avatar {...stringAvatar(user.nome)} />
            {/* {user.nome} */}
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

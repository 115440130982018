import { CSSProperties } from "react";

type PreviewButtonProps = {
  label: string;
  style?: CSSProperties;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
};

const PreviewButton = ({
  style,
  label,
  onClick,
  disabled = false,
  className = ""
}: PreviewButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={className}
      style={style}
    >
      {label}
    </button>
  );
};
export default PreviewButton;

import React, { useState, useEffect, memo } from "react";
import Swal from "sweetalert2";
import ApexCharts from "react-apexcharts";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import Loading from "../../../home/components/Loading";
import RefreshButton from "../../components/RefreshButton";
import HeaderButton from "../../components/headerButton";
import DashboardDropdown from "../../../../partials/content/CustomDropdowns/DashboardDropdown";

import IdentityDownload from "./Components/IdentityDownload";
import { CMSService } from "../../../../services";
import { useTranslation } from "_metronic/i18n/language";

const api = new AnanseApiService();

function Dashboard({ width }) {
  const translate = useTranslation();
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [CmsLoading, setCmsLoading] = useState(true);

  const setCmsLoaded = () => {
    setCmsLoading(false);
  };

  useEffect(() => {
    setupChartOptions();
  }, []);

  const exportFile = async url => {
    setLoadingExport(true);
    try {
      await api.download(
        { url },
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      setLoadingExport(false);
    } catch (error) {
      setLoadingExport(false);
      Swal.error("Erro", "Ocorreu algum problema ao exportar arquivo", "error");
    }
  };

  const getChartWidth = () => {
    if (width >= 1900) {
      //1920px
      return 650;
    } else if (width < 1920 && width >= 1360) {
      //1366px
      return 600;
    } else if (width < 1360 && width >= 1270) {
      //1280px
      return 550;
    } else if (width < 1270 && width >= 1010) {
      //1024px
      return 500;
    } else if (width < 1010 && width >= 760) {
      //768px
      return 500;
    } else if (width <= 375 && width > 360) {
      //Iphone X & Iphone 6/7/8
      return 330;
    } else if (width > 375 && width < 760) {
      //Mobiles
      return 400;
    } else if (width <= 360) {
      //Galaxy S5 & Moto G4
      return 300;
    } else {
      return 350;
    }
  };

  const getIniciativaColor = {
    Aprovada: "#6A992C",
    "Em Análise": "#5B7EB3",
    "Necessita Alteração": "#FFC85C",
    Reprovada: "#994834",
    Inativo: "#AAA"
  };

  const getIniciativaStatusName = {
    Aprovada: "Ativas",
    "Necessita Alteração": "Necessitam alteração"
  };

  const setupChartOptions = async () => {
    if (!loading) {
      setLoading(true);
    }

    setLabels([]);
    setColors([]);
    setSeries([]);

    try {
      const iniciativas = await api.makeHttpRequest({
        url: "/dashboard/chart-iniciativa"
      });

      if (iniciativas && Array.isArray(iniciativas)) {
        await iniciativas.forEach(iniciativa => {
          if (
            iniciativa.totalIniciativas &&
            iniciativa.totalIniciativas !== 0
          ) {
            setLabels(_labels => [
              ..._labels,
              getIniciativaStatusName[iniciativa.status] || iniciativa.status
            ]);
            setSeries(_series => [..._series, iniciativa.totalIniciativas]);
            setColors(_colors => [
              ..._colors,
              getIniciativaColor[iniciativa.status] || "#EEE"
            ]);
          }
        });
      }
    } catch (error) {
      Swal.error(
        "Erro",
        "Ocorreu algum problema ao carregar os dados do dashboard.",
        "error"
      );
    }

    setLoading(false);
  };

  const options = {
    chart: {
      id: "listagem-iniciativas-chart",
      animations: {
        enabled: true,
        speed: 800,
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    tooltip: {
      followCursor: true,
      style: {
        fontSize: "14px"
      }
    },
    colors: loading ? [] : colors,
    labels: loading ? [] : labels,
    series: loading ? [] : series,
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.1
        }
      }
    }
  };

  const downloadKit = async () => {
    setLoading(true);

    try {
      const resp = await CMSService.getContentFromYear(
        "admin-identidade-enef",
        "metadata"
      );
      const link = document.createElement("a");
      link.href = resp.metadata.zip.url;
      link.download = "identidade-visual-enef.zip";
      link.click();
    } catch (e) {
      Swal.fire(
        "Erro!",
        "Ocorreu um problema ao baixar o kit de identidade visual, tente novamente mais tarde",
        "error"
      );
    }

    setLoading(false);
  };

  window.setPageTitle(
    `${translate("dashboard")} - ${translate("labels_admin")}`
  );

  return (
    <>
      <Loading isLoading={loading || CmsLoading || loadingExport} />

      <div className="row">
        <div className="col-lg-8 mt-3">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title="Resumo das iniciativas"
              toolbar={
                width >= 760 ? (
                  <PortletHeaderToolbar>
                    <HeaderButton
                      label="Lista Geral das iniciativas"
                      onClick={() => exportFile("/iniciativa/export")}
                      icone="archive"
                    />
                    <HeaderButton
                      label="Resultados das iniciativas"
                      onClick={() => exportFile("/resultado/export")}
                      icone="archive"
                    />
                    <RefreshButton
                      refreshAction={() => {
                        setupChartOptions();
                      }}
                    />
                  </PortletHeaderToolbar>
                ) : (
                  <DashboardDropdown
                    onClickRefresh={setupChartOptions}
                    onClickExportIniciativa={() =>
                      exportFile("/iniciativa/export")
                    }
                    onClickExportResultado={() =>
                      exportFile("/resultado/export")
                    }
                  />
                )
              }
            />

            {!loading && (
              <PortletBody id="pie-chart">
                <ApexCharts
                  options={options}
                  series={options.series}
                  type="pie"
                  width={getChartWidth()}
                />
              </PortletBody>
            )}
          </Portlet>
        </div>

        <div className="col-lg-4 mt-3">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title="Identidade ENEF"
              toolbar={
                <PortletHeaderToolbar>
                  <HeaderButton
                    label="Fazer download da identidade ENEF"
                    onClick={downloadKit}
                    icone="archive"
                  />
                </PortletHeaderToolbar>
              }
            />

            <PortletBody id="identidades">
              <IdentityDownload onLoad={setCmsLoaded} />
            </PortletBody>
          </Portlet>
        </div>
      </div>
    </>
  );
}

export default memo(Dashboard);

import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";

type ToastType = 'success' | 'error' | 'info' | 'warning';

interface ToastProps {
  open: boolean;
  message: string;
  type: ToastType;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ open, message, type, onClose }) => {
  const iconMap = {
    success: <CheckCircle />,
    error: <Error />,
    info: <Info />,
    warning: <Warning />,
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  // Ajuste aqui
    >
      <Alert
        onClose={onClose}
        severity={type}
        icon={iconMap[type]}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

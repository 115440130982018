import { CRUDApiService } from "./CRUDApiService";

export class CampaignApiService extends CRUDApiService {
  constructor() {
    super('campaigns');
  }

  setDefault(campaignId) {
    return this.makeHttpRequest({
      url: '/default',
      method: 'PUT',
      data: {
        campaignId
      }
    });
  }
}

import axios from "axios";
import { QuestionApiService } from "./Ananse/QuestionApiService";
import { QuestionType } from "app/types";

const questionService = new QuestionApiService();
export const uploadFileToAzureBlob = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      "https://noar-localise-api.azurewebsites.net/api/azure-blob/upload?directory=answer",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return response.data.filePath; // Ajuste conforme a estrutura da resposta da API
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed");
  }
};

export const addUpdate = async (data: QuestionType) => {
  try {
    const formData = new FormData();

    formData.append("quizId", data.quizId.toString());
    formData.append("quizQuestionId", (data.quizQuestionId || 0).toString());
    formData.append("questionId", (data.questionId || 0).toString());
    formData.append("quest", data.quest || "");
    formData.append("fragranceId", data.fragranceId || "");
    formData.append("rightAnswer", data.rightAnswer || "");
    formData.append("type", data.type || "");
    formData.append(
      "arrangeQuestionSequence",
      (data.arrangeQuestionSequence || 0).toString()
    );

    // Adiciona o arquivo somente se estiver presente
    console.log("addUpdate.file: ", data.file);
    if (data.file?.file) {
      formData.append("file.file", data.file.file);
      formData.append("file.name", data.file.name ? data.file.name : "");
    }

    formData.append("description", data.description || "");

    // Mapeia as respostas
    data.answers.forEach((answer, index) => {
      formData.append(
        `answers[${index}][quizQuestionAnswerId]`,
        (answer.quizQuestionAnswerId || 0).toString()
      );
      formData.append(
        `answers[${index}][answerId]`,
        (answer.answerId || 0).toString()
      );
      formData.append(`answers[${index}][desc]`, answer.desc || "");
      formData.append(
        `answers[${index}][description]`,
        answer.description || ""
      );
      formData.append(
        `answers[${index}][nextQuizQuestionId]`,
        (answer.nextQuizQuestionId || 0).toString()
      );
    });

    const reqBase = { method: "POST", url: "add-update" };
    const response = await questionService.makeHttpRequest({
      ...reqBase,
      data: formData, // Enviando o FormData em vez do JSON
      headers: {
        "Content-Type": "multipart/form-data" // Necessário para envio de arquivos
      }
    });

    return response;
  } catch (error) {
    console.error("Error during the addUpdate request:", JSON.stringify(error));
    throw new Error("Request failed");
  }
};

// export const addUpdate = async (data: QuestionType) => {
//   try {
//     const body = {
//       quizId: data.quizId,
//       quizQuestionId: data.quizQuestionId || 0,
//       questionId: data.questionId || 0,
//       quest: data.quest || "",
//       fragranceId: data.fragranceId || "",
//       rightAnswer: data.rightAnswer || "",
//       type: data.type || "",
//       arrangeQuestionSequence: data.arrangeQuestionSequence,
//       file: data.file || undefined,
//       description: data.description || '',
//       answers: data.answers.map(answer => ({
//         quizQuestionAnswerId: answer.quizQuestionAnswerId || 0,
//         answerId: answer.answerId || 0,
//         desc: answer.desc || "",
//         description: answer.description || "",
//         nextQuizQuestionId: answer.nextQuizQuestionId || 0
//       }))
//     };

//     const reqBase = { method: "POST", url: "add-update" };
//     const response = await questionService.makeHttpRequest({
//       ...reqBase,
//       data: body
//     });

//     return response;
//   } catch (error) {
//     console.error("Error during the addUpdate request:", error);
//     throw new Error("Request failed");
//   }
// };

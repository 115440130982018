import React from "react";
import { Button, Icon } from "@mui/material";
import { RTLContext } from "themes/RTLProvider/RTLProvider";

class RefreshButton extends React.Component {
  render() {
    const isRtl = RTLContext._currentValue
    const { refreshAction } = this.props;
    return (
      <Button
        variant="outlined"
        className="kt-button-refresh"
        onClick={refreshAction}
        style={{marginRight: isRtl ? 5 : 0}}
      >
        <Icon>refresh</Icon>
      </Button>
    );
  }
}

export default RefreshButton;

import { ChangeEvent, ReactNode } from "react";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";

type FormInputProps = {
  label: string;
  placeholder?: string;
  value?: any;
  name: string;
  errorMessage?: string;
  isInvalid?: boolean;
  className: string;
  type?: "text" | "email" | "password" | "date" | "select" | "number";
  maxLength?: number;
  minLength?: number;
  disable?: boolean;
  required?: boolean;
  Options?: ReactNode;
  key?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  register?: any;
  control?: any;
  rules?: any;
  mask?: string;
  min?: string;
  max?: string;
};

export const FormInput = ({
  label,
  placeholder,
  value,
  name,
  isInvalid,
  errorMessage,
  className,
  type = "text",
  maxLength = 100,
  minLength,
  disable = false,
  required = false,
  Options,
  key,
  onChange,
  register,
  control,
  rules,
  mask,
  min,
  max
}: FormInputProps) => {
  const { isDarkMode } = useThemeApp();
  const theme = isDarkMode ? "dark" : "light";

  return (
    <div className={`${className} col-12`} key={key}>
      <label className="form-label">{`${label} ${required ? "*" : ""}`}</label>
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <>
              {type === "select" ? (
                <select
                  className={`form-control custom-select select-border-${theme}`}
                  name={name}
                  value={value}
                  disabled={disable}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                >
                  {Options}
                </select>
              ) : (
                <>
                  {mask ? (
                    <IMaskInput
                      className={`form-control input-form input-form-${theme}`}
                      type={type}
                      autoComplete="off"
                      name={name}
                      placeholder={placeholder}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      required={required}
                      disabled={disable}
                      style={isInvalid ? { outlineColor: "red" } : {}}
                      ref={ref}
                      mask={mask}
                    />
                  ) : (
                    <input
                      className={`form-control input-form input-form-${theme}`}
                      type={type}
                      autoComplete="off"
                      name={name}
                      placeholder={placeholder}
                      maxLength={maxLength}
                      minLength={minLength}
                      min={type === "date" ? min : undefined}
                      max={type === "date" ? max : undefined}
                      value={type === "date" && value ? value.split("T")[0] : value}
                      onChange={onChange}
                      onBlur={onBlur}
                      required={required}
                      disabled={disable}
                      style={isInvalid ? { outlineColor: "red" } : {}}
                      ref={ref}
                    />
                  )}
                  {isInvalid && <span className="span-input">{errorMessage}</span>}
                </>
              )}
            </>
          )}
        />
      ) : (
        <>
          {type === "select" ? (
            <select
              className={`form-control custom-select select-border-${theme}`}
              name={name}
              value={value}
              disabled={disable}
              onChange={onChange}
              {...(register ? register(name) : {})}
            >
              {Options}
            </select>
          ) : (
            <>
              <input
                className={`form-control input-form input-form-${theme}`}
                type={type}
                autoComplete="off"
                name={name}
                placeholder={placeholder}
                maxLength={maxLength}
                minLength={minLength}
                min={type === "date" ? min : undefined}
                max={type === "date" ? max : undefined}
                value={type === "date" && value ? value.split("T")[0] : value}
                onChange={onChange}
                required={required}
                disabled={disable}
                style={isInvalid ? { outlineColor: "red" } : {}}
                {...(register ? register(name) : {})}
              />
              {isInvalid && <span className="span-input">{errorMessage}</span>}
            </>
          )}
        </>
      )}
    </div>
  );
};

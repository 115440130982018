import React, { useState, useEffect } from "react";
import queryString from "querystring";
import { Button, Modal } from "@mui/material";
import { Redirect, Link } from "react-router-dom";
import Loading from "../../home/components/Loading";
import { IsValidPassword } from "../../../utils/validators/IsValidPassword";
import { AnanseApiService } from "../../../services/Ananse/AnanseApiService";
import {
  CentralizedContainer,
  StyledInput,
  Requisitos,
  ModalContainer,
  ButtonGroup
} from "./style";
import { CancelButton, LinkButton } from "../ForgotPassword/style";
import Actions from "./enum/Actions";
import LanguageSelector from "../../../partials/layout/LanguageSelector";
import { useTranslation } from "_metronic/i18n/language";

const Password = ({ action, ...props }) => {
  const translate = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [configurationModal, setConfigurationModal] = useState({
    title: "",
    subtitle: "",
    color: "black",
    success: false
  });
  const hasToken = queryString.parse(window.location.search.replace("?", "")).t;
  const token = window.location.search.replace("?t=", "");
  const notCriarConta = action !== Actions.CriarConta;
  const [isLoading, setIsLoading] = useState(false);
  const handleNewPasswordChange = e => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
  };

  //Actions.RecuperarSenha
  let messages = {
    title: translate("screens_recoveryPassword_messages_recovery_title"),
    subtitle: translate("screens_recoveryPassword_messages_recovery_subtitle"),
    button: translate("screens_recoveryPassword_messages_recovery_button")
  };
  if (action === Actions.CadastrarSenha) {
    messages = {
      title: translate("screens_recoveryPassword_messages_register_title"),
      subtitle: translate("screens_recoveryPassword_messages_register_subtitle"),
      button: translate("screens_recoveryPassword_messages_register_button")
    };
  } else if (action === Actions.CriarConta) {
    messages = {
      title: translate("screens_recoveryPassword_messages_create_title"),
      subtitle: translate("screens_recoveryPassword_messages_create_subtitle"),
      button: translate("screens_recoveryPassword_messages_create_button")
    };
  }

  const passwordValidation = () => {
    const validation = IsValidPassword(newPassword, translate);
    if (newPassword === "") {
      setError(translate("screens.recoveryPassword.errors.empty"));
      return false;
    }

    if (validation !== true) {
      setError(translate("screens.recoveryPassword.errors.requirement"));
      return false;
    }

    if (newPassword !== confirmPassword) {
      setError(translate("screens.recoveryPassword.errors.different"));
      return false;
    }
    return true;
  };

  const doAction = async () => {
    let data = {
      token
    };
    if (notCriarConta) {
      data = {
        ...data,
        password: newPassword
      };
    }

    let configModal;
    const api = new AnanseApiService();
    setIsLoading(true);
    await api
      .makeHttpRequest({
        url: "/requisicaotemporaria",
        method: "POST",
        data
      })
      .then(() => {
        setIsLoading(false);
        configModal = {
          title: translate("screens.recoveryPassword.success.title"),
          subtitle: translate("screens.recoveryPassword.success.subTitle"),
          color: "#6e5221",
          success: true
        };
      })
      .catch(() => {
        setIsLoading(false);
        configModal = {
          title: translate("screens.recoveryPassword.errors.title"),
          subtitle: translate("screens.recoveryPassword.errors.subTitle"),
          color: "#e74c3c",
          success: false
        };

        if (action === Actions.CriarConta) {
          setError([
            true,
            "",
            translate("screens.recoveryPassword.errors.msg")
          ]);
        }
      });

    setConfigurationModal(configModal);
    setSuccessModal(true);
  };

  const handleSubmit = () => {
    if (notCriarConta) {
      if (passwordValidation() === false) return;
    }

    doAction();
    setError("");
  };

  useEffect(() => {
    if (action === Actions.CriarConta) {
      doAction();
    }
  }, []);

  const closeSuccessModal = () => setSuccessModal(false);

  switch (action) {
    case Actions.CriarConta:
      window.setPageTitle(
        `${translate("screens.recoveryPassword.pageTitle.active")}`
      );
      break;
    case Actions.CadastrarSenha:
      window.setPageTitle(
        `${translate("screens.recoveryPassword.pageTitle.new")}`
      );
      break;
    default:
      window.setPageTitle(
        `${translate("screens.recoveryPassword.pageTitle.recovery")}`
      );
  }

  return (
    <div>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-header__topbar__login d-flex flex-row-reverse">
            <LanguageSelector iconType="" />
          </div>
        </div>
      </div>
      <CentralizedContainer>
        <Loading isLoading={isLoading} />

        {!hasToken && <Redirect to="/auth" />}

        <h2 className="title">
          {error[0] === true ? error[1] : messages.title}
        </h2>
        <h2 className="subtitle">
          {error[0] === true ? error[2] : messages.subtitle}
        </h2>

        {notCriarConta && (
          <>
            <StyledInput
              placeholder={
                translate("screens.recoveryPassword.placeholders.inputPassword")
              }
              value={newPassword}
              onChange={handleNewPasswordChange}
              autoFocus={true}
              error={error !== "" ? true : false}
              helperText={error !== "" && error}
              type="password"
            />
            <StyledInput
              placeholder={
                translate("screens.recoveryPassword.placeholders.confirmPassword")
              }
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoFocus={true}
              type="password"
            />

            <Requisitos>
              <h4>{translate("screens.recoveryPassword.requireTitle")}</h4>
              <span>
                {translate("screens.recoveryPassword.requireChar")}
              </span>
              <span>
                {translate("screens.recoveryPassword.requireNumber")}
              </span>
              <span>
                {translate("screens.recoveryPassword.requireUpperLower")}
              </span>
              <span>
                {translate("screens.recoveryPassword.requireEspecialChar")}
              </span>
            </Requisitos>

            <Modal
              open={successModal}
              onClose={() => setSuccessModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <CentralizedContainer margin="0 15%">
                <ModalContainer color={configurationModal.color}>
                  <span className="title">
                    {configurationModal.title || ""}
                  </span>
                  <span className="subtitle">
                    {configurationModal.subtitle || ""}
                  </span>

                  <div className={configurationModal.success ? "success" : ""}>
                    <CancelButton onClick={closeSuccessModal}>
                      {translate("buttons.cancel")}
                    </CancelButton>
                    {configurationModal.success && (
                      <LinkButton to="/auth">
                        {translate("buttons.enter")}
                      </LinkButton>
                    )}
                  </div>
                </ModalContainer>
              </CentralizedContainer>
            </Modal>
          </>
        )}

        <ButtonGroup width={notCriarConta && "300px"}>
          {/* <button
              className="btn btn-elevate btn-primary"
              onClick={handleSubmit}
            >
              {messages.button}
            </button> */}
          {notCriarConta && (
            <Button variant="contained" color="success" onClick={handleSubmit}>
              {messages.button}
            </Button>
          )}

          <Link to="/auth">
            <Button variant="contained" color="error">
              {translate("buttons.back")}
            </Button>
            {/* <button className="btn btn-outline btn-secondary pl-2 pr-2">
              {translate(locale).buttons.back}
            </button> */}
          </Link>
        </ButtonGroup>
      </CentralizedContainer>
    </div>
  );
};

export default Password;

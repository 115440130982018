import React from "react";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";

import CrudActions from "app/partials/datatable/CrudActions";
import DatatableStandalone from "app/partials/datatable/DatatableStandalone";
import { formatedTypeInput } from "../mock";
import { formatFragrances } from "../utils";
import { useTranslation } from "_metronic/i18n/language";

type Props = {
  collection: Array<any>;
  formType?: "1" | "3";
  onConfirmDelete: any;
  onEdit: any;
  questions: any;
};

const TableQuestions: React.FC<Props> = ({
  collection,
  formType,
  onConfirmDelete,
  onEdit,
  questions
}) => {
  const translate = useTranslation();
  function formatAnswers(answers: any[]) {
    let result: JSX.Element[] = [];

    if (answers) {
      result = answers.map((e, i) => (
        <p key={i}>
          {e.answer?.label}
          {formType === "3" &&
            ` / ${formatFragrances(
              parseInt(e.answer?.description),
              collection
            )}`}
        </p>
      ));
    }

    return result;
  }

  const defaultHeadRows = [
    { label: translate("screens.quiz.list.actions") },
    {
      column: "Question",
      label: translate("screens.quiz.labels.question")
    },
    {
      column: "Answers",
      label: `${translate("screens.quiz.labels.answer")}  ${
        formType === "3"
          ? `/ ${translate("screens.quiz.labels.fragranceResponse")}`
          : ""
      }`
    },
    {
      column: "RightAnswers",
      label: translate("screenApp.results.rightResponse")
    },
    {
      column: "Type",
      label: translate("screenApp.quiz.questionType")
    },
    {
      column: "FragranceId",
      label: translate("screens.fragrance.referenceFragrance")
    },
    {
      column: "CreatedAt",
      label: translate("screens.quiz.list.created")
    },
    {
      column: "UpdatedAt",
      label: translate("screens.quiz.list.updated")
    }
  ];

  let headRows = defaultHeadRows;
  if (formType === "3") {
    headRows = headRows.filter(row => row.column !== "fragranceId");
  }

  const formatRow = (row: any) => (
    <TableRow hover tabIndex={-1} key={row.quizQuestionId}>
      <TableCell>
        <CrudActions
          actions={["edit", "delete"]}
          module="question"
          onConfirmDelete={onConfirmDelete}
          onEdit={onEdit}
          id={row.quizQuestionId}
          obj={row}
        />
      </TableCell>
      <TableCell scope="row">{row.question?.quest}</TableCell>
      <TableCell scope="row">{formatAnswers(row.answers)}</TableCell>
      <TableCell scope="row">{row.rightAnswer}</TableCell>
      <TableCell scope="row">{formatedTypeInput(row.type)}</TableCell>
      {formType === "1" && (
        <TableCell scope="row">
          {formatFragrances(row?.fragranceId, collection)}
        </TableCell>
      )}
      <TableCell scope="row">
        {moment(row.createdAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
      <TableCell scope="row">
        {moment(row.updatedAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
    </TableRow>
  );

  return (
    <DatatableStandalone
      headRows={headRows}
      formatRow={formatRow}
      rows={questions}
      orderBy="createdAt"
    />
  );
};

export default TableQuestions;

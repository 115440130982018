import { AnanseApiService } from "app/services/Ananse/AnanseApiService";

const api = new AnanseApiService();

type UserType = {
  id: number;
  name: string;
};

export async function getUser(id: number): Promise<UserType> {
  const data = await api.makeHttpRequest({
    method: "GET",
    url: `/usuario/${id}`
  });
  return { id: data.id, name: data.nome };
}

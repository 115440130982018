import { TableCell, TableRow } from "@mui/material";
import CrudActions from "app/partials/datatable/CrudActions";
import React from "react";
import { formatedTypeInput } from "../mock";
import { formatFragrances } from "../utils";
import moment from "moment";
import DatatableStandalone from "app/partials/datatable/DatatableStandalone";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  collection: any;
  formType?: "1" | "3";
  onConfirmDelete: any;
  onEdit: any;
  questions: any;
}

const TableQuestions: React.FC<Props> = ({
  collection,
  formType,
  onConfirmDelete,
  onEdit,
  questions,
}) => {
  const translate = useTranslation();
  function formatAnswers(answers: any[]) {
    let result: any[] = [];

    if (answers) {
      result = answers.map((e, i) => (
        <p key={i}>
          {e.answer?.label}
          {formType === "3" &&
            ` / ${formatFragrances(
              parseInt(e.answer?.description),
              collection
            )}`}
        </p>
      ));
    }

    return result;
  }

  // Define the default order of headRows
  const defaultHeadRows = [
    { label: translate("screens.quiz.list.actions") },
    {
      column: "Question",
      label: translate("screens.quiz.labels.question")
    },
    {
      column: "Answers",
      label: `${translate("screens.quiz.labels.answer")}  ${
        formType === "3"
          ? `/ ${translate("screens.quiz.labels.fragranceResponse")}`
          : ""
      }`
    },
    {
      column: "RightAnswers",
      label: translate("screenApp.results.rightResponse")
    },
    {
      column: "Type",
      label: translate("screenApp.quiz.questionType")
    },
    {
      column: "FragranceId",
      label: translate("screens.fragrance.referenceFragrance")
    },
    {
      column: "CreatedAt",
      label: translate("screens.quiz.list.created")
    },
    {
      column: "UpdatedAt",
      label: translate("screens.quiz.list.updated")
    }
  ];

  // Determine the order of headRows based on the formType
  let headRows = defaultHeadRows;
  if (formType === "3") {
    // If formType is "3", remove the FragranceId column
    headRows = headRows.filter(row => row.column !== "FragranceId");
  }

  const formatRow = (r: any) => (
    <TableRow hover tabIndex={-1} key={r.quizQuestionId}>
      <TableCell>
        <CrudActions
          actions={["edit", "delete"]}
          module="question"
          //onConfirmChangeStatus={onConfirmChangeStatus}
          onConfirmDelete={onConfirmDelete}
          onEdit={onEdit}
          id={r.quizQuestionId}
          obj={r}
        />
      </TableCell>
      <TableCell scope="row">{r.question?.quest}</TableCell>
      <TableCell scope="row">{formatAnswers(r.answers)}</TableCell>
      <TableCell scope="row">{r.rightAnswer}</TableCell>
      <TableCell scope="row">{formatedTypeInput(r.type)}</TableCell>
      {formType === "1" && (
        <TableCell scope="row">
          {formatFragrances(r?.fragranceId, collection)}
        </TableCell>
      )}
      <TableCell scope="row">
        {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
      <TableCell scope="row">
        {moment(r.updatedAt).format("DD/MM/YYYY HH:mm")}
      </TableCell>
    </TableRow>
  );

  return (
    <DatatableStandalone
      headRows={headRows}
      formatRow={formatRow}
      rows={questions}
    />
  );
};

export default TableQuestions;

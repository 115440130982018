import { EditNote } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Chip,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TableFooter,
  TableSortLabel,
  TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "_metronic/i18n/language";
import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { SelectPage } from "components";
import { format } from "date-fns";
import { IQuiz, ISelectSearchQuiz, UserType } from "interfaces";
import * as React from "react";
import Swal from "sweetalert2";
import FilterOptions from "./components/FilterOptions";
import { userTypesData } from "./mock";
import { arrayToString, mergeArrays, stringToArray } from "./utils";
import { useRTL } from "themes/RTLProvider/RTLProvider";

const api = new AnanseApiService();

const getUsers = async (
  page: number,
  perpage: number,
  filterString: string,
  isActive: boolean,
  OrderByDirection: "asc" | "desc" = "asc",
  orderBy = "Nome",
  filterType = "Nome"
) => {
  const baseUrl = "/usuario";

  const params = new URLSearchParams();

  params.append("Page", page.toString());
  params.append("PerPage", perpage.toString());
  params.append("OrderByDirection", OrderByDirection.trim());
  params.append("OrderBy", orderBy.trim());
  params.append("IsActive", isActive.toString());

  console.log(filterString + " ", filterType);
  if (filterString !== "") {
    params.append("FilterString", filterString);
    params.append("FilterType", filterType);
  }

  const url = `${baseUrl}?${params.toString()}`;

  const response = await api.makeHttpRequest({
    method: "GET",
    url
  });

  return response;
};

// Função EnhancedTable
export default function EnhancedTable() {
  const useStyles = makeStyles(theme => {
    return {
      table: {
        minWidth: 400
      },
      tableWrapper: {
        overflowX: "auto"
      },
      search: {
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      },
      radio: {
        flexWrap: "nowrap",
        padding: "1rem"
      }
    };
  });

  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const classes = useStyles();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<keyof UserType | string>("Nome");
  const [selected, setSelected] = React.useState<number[]>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [searchInput, setSearchInput] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [active, setActive] = React.useState(true);
  const [open, setOpen] = React.useState(0);
  const [quizSelected, setQuizSelected] = React.useState(
    {} as ISelectSearchQuiz
  );
  const [loadUserQuiz, setLoadUserQuiz] = React.useState(false);
  const [userQuizSelected, setUserQuizSelected] = React.useState(
    {} as { data: IQuiz[]; total: number; totalPages: number }
  );
  const [filter, setFilter] = React.useState(
    {} as { filterType: string; filterString: string }
  );

  React.useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, active, orderBy, order, filter]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getUsers(
        page + 1,
        rowsPerPage,
        filter.filterString ? filter.filterString : "",
        active,
        order,
        orderBy
      );
      setUsers(response.data);
      setTotalUsers(response.total);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const getQuizByUserId = async (userId: number) => {
    setLoadUserQuiz(true);
    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/quiz?Type=1&UserId=${userId}&PerPage=100`
    });
    setUserQuizSelected(response);
    setLoadUserQuiz(false);
  };

  const updateQuiz = async (userIds: string) => {
    try {
      if (quizSelected.id) {
        const response = await api.makeHttpRequest({
          method: "PUT",
          url: `/quiz/${quizSelected.id}`,
          data: { ...quizSelected.obj, userId: userIds }
        });
        console.log(response);
        Swal.fire(
          translate("defaultMessages_success"),
          translate("registration_updated_successfully"),
          "success"
        );
        setQuizSelected({} as ISelectSearchQuiz);
        setOpen(0);
        setSelected([]);
        fetchData();
      }
    } catch (error) {
      console.error("Error updating quiz:", error);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("defaultMessages.errorChangeStatus"),
        "error"
      );
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    // Verificar se a entrada tem mais de 3 caracteres antes de pesquisar
    if (inputValue.length > 2 || inputValue.length === 0) {
      setFilter({ ...filter, filterString: inputValue });
    }
  };

  const onSearchButton = () => {
    setFilter({ ...filter, filterString: searchInput, filterType: "Nome" });
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive((event.target as any).value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      const newSelecteds = users.map(user => user.id);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
    setSelectAll(checked);
  };

  const handleIndividualCheckboxChange = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectAll(newSelected.length === users.length);
  };

  const createSortHandler = (property: any) => (event: any) => {
    if (!property) {
      return;
    }
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onEdit = (value: any, merge = true) => {
    setQuizSelected(value);
    const userIds = stringToArray(value.obj.userId);
    const newUserIds = mergeArrays(selected, userIds);
    setSelected(merge ? newUserIds : userIds);
  };

  const numSelected = selected.length;

  const getTypeQuizName = (type: string | number) => {
    switch (type) {
      case 1:
        return translate("screens.quiz.labels.identification");
      case 3:
        return translate("screens.quiz.labels.discrimination");
      case 5:
        return translate("labels_ratings");
      default:
        return ''; // Retorne um valor padrão ou uma mensagem de erro se necessário
    }
  };

  const getLabelUserType = (type: string): string => {
    const findlabel = userTypesData.find(userType => userType.value === type);
    return findlabel ? translate(findlabel.translationKey) : type;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Loading isLoading={loading} />
      <Paper sx={{ padding: 1, mb: 2 }}>
        <Stack spacing={2}>
          <SelectPage
            label={translate("screens.quiz.graphic")}
            endPoint={`/quiz?Type=1`}
            value={quizSelected.id}
            pagination
            showTotal
            handleChange={(e: any) => {
              const value = e;
              onEdit(value);
            }}
            convertObject={(obj: any) => ({
              id: obj.quizId,
              value: obj.quizId,
              label: `${obj.name}`,
              obj
            })}
          />
          <Typography variant="subtitle2" component="div">
            {numSelected} {translate("labels_user_selected")}
          </Typography>
          <Button
            color="success"
            variant="contained"
            disabled={!numSelected}
            onClick={() => {
              const userids = arrayToString(selected);
              console.log(userids);
              updateQuiz(userids);
            }}
          >
            {translate("labels_save_membership")}
          </Button>
        </Stack>
      </Paper>
      {/* <UserFormModal  /> */}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            marginRight: 10
          }}
        >
          <FormControl style={{ marginLeft: 10 }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={active}
              onChange={handleChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="success" />}
                label={translate("datatable.active")}
              />
              <FormControlLabel
                value={false}
                control={<Radio color="success" />}
                label={translate("datatable.inactive")}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            sx={{ ml: 1, flex: 1 }}
            label={translate("labels.search")}
            variant="standard"
            value={searchInput}
            onChange={handleSearchInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={onSearchButton}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={translate(
                      "screens_capsule_placeholders_clearSearch"
                    )}
                  >
                    <IconButton
                      onClick={() => {
                        setSearchInput("");
                        setFilter({} as any);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <FilterOptions
            filterOptions={userTypesData}
            filters={filter}
            setFilters={setFilter}
          />
          <Tooltip title={translate("datatable.refresh")}>
            <IconButton aria-label="directions" onClick={fetchData}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="secondary"
                    checked={selectAll}
                    onChange={handleCheckboxChange}
                  />
                </TableCell>
                <TableCell padding="checkbox">
                  {translate("screens.dashboard.action")}
                </TableCell>
                <TableCell sortDirection={order}>
                  <TableSortLabel
                    active={orderBy === "Nome"}
                    direction={order}
                    onClick={createSortHandler("Nome")}
                  >
                    {translate("screens.user.list.name")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" sortDirection={order}>
                  <TableSortLabel
                    active={orderBy === "Email"}
                    direction={order}
                    onClick={createSortHandler("Email")}
                  >
                    {translate("screens.user.list.email")}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  {translate("screens.fragrance.labels.type")}
                </TableCell>
                <TableCell>
                  {translate("screenApp.createAccount.profile.nextPaymentDate")}
                </TableCell>
                <TableCell>{translate("screens.user.list.status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(row => {
                const isItemSelected = isSelected(row.id);

                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="secondary"
                          checked={isItemSelected}
                          onChange={() =>
                            handleIndividualCheckboxChange(row.id)
                          }
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Stack direction="row">
                          <Tooltip title={translate("labels_show_membership")}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                setOpen(row.id === open ? 0 : row.id);
                                getQuizByUserId(row.id);
                              }}
                            >
                              {open === row.id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {row.nome}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {getLabelUserType(row.userType)}
                      </TableCell>
                      <TableCell>
                        {row.nextPaymentDate
                          ? format(new Date(row.nextPaymentDate), "dd/MM/yyyy")
                          : null}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={
                            row.deletedAt
                              ? translate("datatable.inactive")
                              : translate("datatable.active")
                          }
                          sx={{
                            backgroundColor: row.deletedAt
                              ? "#FF0000"
                              : "#008000",
                            color: "white"
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={7}
                      >
                        <Collapse
                          in={open === row.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          {loadUserQuiz ? (
                            <Typography
                              variant="h5"
                              style={{ textAlign: "center", margin: 1 }}
                            >
                              {translate("screens.loading.title")}
                            </Typography>
                          ) : userQuizSelected?.data?.length > 0 ? (
                            <Box sx={{ margin: 1 }}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#d8d8d8"
                                  // color: "#fff"
                                }}
                              >
                                {translate("labels_test_membership")}
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      {translate(
                                        "screens.campaigns.list.actions"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {translate("screens.campaigns.list.name")}
                                    </TableCell>
                                    <TableCell>
                                      {translate(
                                        "screens.fragrance.labels.type"
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {translate("screens.quiz.list.campaign")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {userQuizSelected.data.map(userQuiz => (
                                    <TableRow key={userQuiz.quizId}>
                                      <TableCell>
                                        <Tooltip
                                          title={translate(
                                            "labels_edit_membership"
                                          )}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              const value = {
                                                id: userQuiz.quizId,
                                                value: userQuiz.quizId,
                                                label: userQuiz.name,
                                                obj: userQuiz
                                              };
                                              onEdit(value, false);
                                            }}
                                          >
                                            <EditNote />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {userQuiz.name}
                                      </TableCell>
                                      <TableCell>
                                        {getTypeQuizName(userQuiz.type)}
                                      </TableCell>
                                      <TableCell align="left">
                                        {userQuiz.campaign.name}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          ) : (
                            <Typography
                              variant="h5"
                              style={{ textAlign: "center", margin: 1 }}
                            >
                              {translate("labels_notfound_membership")}
                            </Typography>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalUsers}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${translate("datatable.of")} ${count}`
                  }
                  labelRowsPerPage={translate("datatable.labelRowsPerPage")}
                  backIconButtonProps={{
                    "aria-label": translate("datatable.backButton"),
                    style: { transform: valueTransformIfRtl }
                  }}
                  nextIconButtonProps={{
                    "aria-label": translate("datatable.nextButton"),
                    style: { transform: valueTransformIfRtl }
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

import React, { useState } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import Header from "./header/Header";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import { menuConfig } from "./core";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "./header/Topbar";

const htmlClassService = new HTMLClassService();

const drawerWidth = 240;
const drawerWidthLeave = 70;

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig
}) {
  const theme = useTheme();
  const [asideOpen, setAsideOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  htmlClassService.setConfig(layoutConfig);

  // Scroll to top after location changes
  window.scrollTo(0, 0);

  const handleAsideToggle = isOpen => {
    setAsideOpen(isOpen);
  };

  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={menuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      <CssBaseline />
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {/* Header */}
        <Topbar
          open={asideOpen}
          isMobile={isMobile}
          onOpenMenu={() => handleAsideToggle(!asideOpen)}
        />
        {asideDisplay && (
          <AsideLeft
            open={asideOpen}
            isMobileActive={asideOpen}
            onToggle={handleAsideToggle}
          />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingTop: 8,
            overflow: "hidden",
            marginLeft: isMobile
              ? 0
              : asideOpen
              ? `${drawerWidth}px`
              : `${drawerWidthLeave}px`
          }}
        >
          {/* Content */}
          <KtContent>{children}</KtContent>
        </Box>
        {/* Footer */}
        <Footer open={asideOpen} isMobile={isMobile} />
      </Box>
      <QuickPanel />
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // Blank Layout
    <div>
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ i18n: { lang }, builder: { layoutConfig } }) => ({
  layoutConfig,
  lang,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display")
});

export default withRouter(connect(mapStateToProps)(Layout));

import React from "react";

import "./Styles.scss";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

export default function Br() {
  const translate = useTranslation();
  const isRtl = useRTL();
  const textAlignIfRtl = isRtl ? "right" : "left"
  return (
    <div className="privacy-page">
      <h2 style={{textAlign: textAlignIfRtl}}>
        <strong>{translate("privacyPolicy_title_key")}</strong>
      </h2>
      <br />
      <p>
        {translate("privacyPolicy_introduction_paragraph1_key")}
        <br />
        <br />
        {translate("privacyPolicy_introduction_paragraph2_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic1_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic1_paragraph1_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic1_paragraph2_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic1_paragraph3_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic1_paragraph4_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic1_paragraph5_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic2_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic2_paragraph1_key")}
        <br />
        <br />
        <ul>
          <li>{translate("privacyPolicy_topic2_paragraph2_key")}</li>
          <br />
          <li>{translate("privacyPolicy_topic2_paragraph3_key")}</li>
          <br />
          <li>{translate("privacyPolicy_topic2_paragraph4_key")}</li>
        </ul>
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic3_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic3_paragraph1_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic3_paragraph2_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic4_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic4_paragraph1_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic5_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic5_paragraph1_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic5_paragraph2_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic5_paragraph3_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic6_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic6_paragraph1_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic7_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph1_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph2_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph3_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph4_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph5_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph6_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph7_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph8_key")}
        <br />
        <br />
        {translate("privacyPolicy_topic7_paragraph9_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic7_importantNotices_title_key")}</strong>
        <br />
        <br />
        <strong>
          {translate("privacyPolicy_topic7_importantNotices_paragraph1_key")}
        </strong>
        <br />
        <br />
        <strong>
          {translate("privacyPolicy_topic7_importantNotices_paragraph2_key")}
        </strong>
        <br />
        <br />
        <strong>
          {translate("privacyPolicy_topic7_importantNotices_paragraph3_key")}
        </strong>
        <br />
        <br />
        {translate("privacyPolicy_topic7_importantNotices_paragraph4_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic8_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic8_paragraph1_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic9_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic9_paragraph1_key")}
        <br />
        <br />
        <br />
        <strong>{translate("privacyPolicy_topic10_title_key")}</strong>
        <br />
        <br />
        {translate("privacyPolicy_topic10_paragraph1_key")}
      </p>
    </div>
  );
}

import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import { FragranceApiService } from "../../../../services/Ananse/FragranceApiService";
import { TranslationContext } from "_metronic/i18n/language";

class FragrancesListing extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.api = new FragranceApiService();
    this.state = {
      translate: null,
      fragrances: [],
      reload: false,
      isActive: "true"
    };

    this.setFragrances = this.setFragrances.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
    this.initializeTranslation();
  }

  setFragrances(fragrances) {
    this.setState({ fragrances });
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  async onConfirmChangeStatus(fragrance) {
    if (fragrance.deletedAt) {
      await this.api.activate(fragrance.fragranceId);
    } else {
      await this.api.delete(fragrance.fragranceId);
    }

    this.setState({ reload: !this.state.reload });
  }

  render() {
    const translate = this.context;
    window.setPageTitle(`${translate("screens.fragrance.list.title")} - ${translate("labels_admin")}`);

    const headRows = [
      { label: translate("screens.fragrance.list.actions") },
      { column: "FragranceId", label: translate("defaultMessages.id") },
      {
        column: "Name",
        label: translate("screens.fragrance.list.name")
      },
      {
        column: "Category.Name",
        label: translate("screens.fragrance.list.category")
      },
      {
        column: "CreatedAt",
        label: translate("screens.fragrance.list.created")
      }
    ];

    const formatRow = r => {
      const actions = [];
      if (!r.deletedAt) {
        actions.push("edit");
      }

      if (r.deletedAt || !r.collections || !r.collections.length) {
        actions.push("toggle");
      }

      return (
        <TableRow hover tabIndex={-1} key={r.fragranceId}>
          <TableCell>
            <CrudActions
              actions={actions}
              module="fragrances"
              onConfirmChangeStatus={this.onConfirmChangeStatus}
              id={r.fragranceId}
              obj={r}
            />
          </TableCell>
          <TableCell scope="row">
            {r.fragranceId.toString().padStart(4, "0")}
          </TableCell>
          <TableCell scope="row">{r.name}</TableCell>
          <TableCell scope="row">{r.category ? r.category.name : ""}</TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Datatable
        title={translate("screens.fragrance.list.title")}
        endpoint="/fragrances"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.fragrances}
        setRows={this.setFragrances}
        reload={this.state.reload}
        placeholderSearch={translate("screens.fragrance.placeholders.search")}
        isActive={this.state.isActive}
        buttons={[
          {
            label: translate("datatable.add"),
            onClick: null,
            icone: "add",
            path: "/admin/fragrances/create"
          }
        ]}
      />
    );
  }
}

export default FragrancesListing;

import { DeviceHub, Person } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import AvatarComponent from "components/Avatar/AvatarComponent";
import CardData from "components/Cards/CardData";
import CardList from "components/Cards/CardList";
import SendEmailModal from "components/Modais/SendEmailModal";
import { UserType, UseSelectorI } from "interfaces";
import { formatedDate } from "utils";
import { useTranslation } from "_metronic/i18n/language";
import UserFormModal from "../Users/UserFormModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

interface User {
  id: any;
  value: any;
  label: any;
  obj: any;
}

interface Props {
  user: UserType;
}

const ManageGroup: React.FC<Props> = ({ user }) => {
  const [value, setValue] = React.useState(0);
  const [modalUser, setModalUser] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState<UserType>({} as UserType);
  const [deviceSelected, setDeviceSelected] = React.useState<User>({} as User);
  const [modalDevice, setModalDevice] = React.useState(false);
  const [licensedUsers, setLicensedUsers] = useState<UserType[]>([]);
  const [licensedDevices, setLicensedDevices] = useState<any[]>([]);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  const [usersLicensed, setUsersLicensed] = useState(1);
  const usersLicense = 2;
  const deviceLicense = 2;

  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();
  const { auth } = store.getState();
  const isAdmin = auth && auth.user && auth.user.profile.nome === "ADMIN";
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);

  const loadingData = React.useCallback(async () => {
    setLoading(true);

    try {
      const { id } = user;

      if (id) {
        const { devices } = await api.makeHttpRequest({
          method: "GET",
          url: `/devices/${id}`
        });
        setLicensedDevices(devices);
      }
    } catch (error) {
      console.error("Erro ao carregar dispositivos:", error);

      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    loadingData();
  }, [loadingData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const addUserToLicensedUsers = () => {
    const alreadyLicensed = licensedUsers.some(
      user => user.id === userSelected.id
    );

    if (alreadyLicensed) {
      alert(
        `O usuário "${userSelected.nome}" já foi licenciado. \nSelecione outro usuário.`
      );
      return;
    }
    if (Object.keys(userSelected).length > 0) {
      setLicensedUsers(prevLicensedUsers => [
        ...prevLicensedUsers,
        userSelected as UserType
      ]);
      setModalUser(false);
      setUserSelected({} as UserType);
    }
  };

  const removeUserFromLicensedUsers = async (id: number) => {
    try {
      await api.makeHttpRequest({
        method: "DELETE",
        url: `/usuario/${id}`
      });
      loadingData();
    } catch (error) {
      console.error("erro dfdsdf", error);
    }
  };

  const desassociarDispositivo = async (deviceId: number) => {
    try {
      await api.makeHttpRequest({
        method: "PUT",
        url: `/devices/remove-user/${deviceId}`
      });
      loadingData();
    } catch (error) {
      console.error("Erro ao desassociar o dispositivo:", error);
    }
  };

  const handleConfirmChangeStatus = async (obj: any) => {
    try {
      const value = obj.obj;
      const data: any = {
        ativo: value.deletedAt ? true : false,
        grouperId: value.grouperId
      };

      await api.makeHttpRequest({
        method: "PUT",
        url: `/usuario/${obj.id}`,
        data
      });

      loadingData();
    } catch (e) {
      Swal.fire(
        translate("defaultMessages.error"),
        translate("defaultMessages.errorChangeStatus"),
        "error"
      );
    }
  };

  const addDeviceToLicensedDevices = () => {
    const alreadyLicensed = licensedDevices.some(
      user => user.id === deviceSelected.id
    );

    if (alreadyLicensed) {
      alert(
        `O Multiscent "${deviceSelected.label}" já foi licenciado. \nSelecione outro Multiscent.`
      );
      return;
    }
    if (Object.keys(deviceSelected).length > 0) {
      setLicensedDevices(prevLicensedDevices => [
        ...prevLicensedDevices,
        deviceSelected as User
      ]);

      setModalDevice(false);
      setDeviceSelected({} as User);
    }
  };

  const removeDeviceFromLicensedDevices = (id: number) => {
    const updatedLicensedDevices = licensedDevices.filter(
      value => value.deviceId !== id
    );
    setLicensedDevices(updatedLicensedDevices);
  };

  const date = new Date();
  const grouper = {
    name: `${translate("labels_group")} ${user.nome}`,
    createdAt: formatedDate(date, false, true, locale),
    image: ""
  };

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <AvatarComponent
              title={grouper.name}
              restBadge={{ variant: "standard" }}
              rest={{
                alt: grouper.name,
                src: grouper.image,
                variant: "rounded"
              }}
            />
          }
          title={grouper.name}
          subheader={grouper.createdAt}
        />
        <Divider />
        <CardContent>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider"
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="primary"
              indicatorColor="secondary"
            >
              <Tab
                icon={<Person />}
                iconPosition="start"
                label={translate("screens.user.list.title")}
                {...a11yProps(0)}
              />
              <Tab
                icon={<DeviceHub />}
                iconPosition="start"
                label={translate("screens.device.title")}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CardData
              userSelected={user}
              avatar
              url={"usuario"}
              filterId={user.id}
              convertObject={(obj: UserType) => ({
                id: obj.id,
                value: obj.id,
                label: `${obj.nome}`,
                subTitle: obj.email,
                filterId: obj.grouperId,
                obj
              })}
              onCreate={() => setModalUser(true)}
              handleData={setLicensedUsers}
              handleRemove={removeUserFromLicensedUsers}
              handleStatus={handleConfirmChangeStatus}
              childrenToolbar={
                <>
                  {licensedUsers.length === user.usersLicense && (
                    <IconButton
                      aria-label="settings"
                      onClick={() => setModalSendEmail(true)}
                    >
                      {translate("labels_requestMoreLicenses")}
                    </IconButton>
                  )}
                </>
              }
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="body2" color="text.secondary">
                {licensedDevices.length} {translate("labels_devices")}
              </Typography>
            </Stack>
            {licensedDevices.map(value => (
              <CardList
                key={value.deviceId}
                title={value.macAdress}
                subheader={value.userName}
                handleDisassociate={isAdmin ? () => {
                  desassociarDispositivo(value.deviceId);
                } : undefined}
              />
            ))}
          </CustomTabPanel>
        </CardContent>
      </Card>
      <UserFormModal
        userSelected={user}
        open={modalUser}
        onClose={() => setModalUser(false)}
        onCadastro={setUserSelected}
        onFinish={addUserToLicensedUsers}
      />
      <SendEmailModal
        openModal={modalSendEmail}
        setOpenModal={setModalSendEmail}
        // emailCc="carlos.duarte@inv.net.br"
      />
    </>
  );
};

export default ManageGroup;

import React, { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toolbar } from "./toolbar";
import ButtonCode from "app/pages/admin/components/EditorConvertToHTML/Components/ButtonCode";
import { Stack, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";

const handleTranslate: { [key: string]: any } = {
  pt: {
    typeEmail: "Digite o e-mail*",
    typeSubject: "Digite o assunto do e-mail*",
    typeBody: "Digite o corpo do e-mail*",
    preview: "Pré-visualização"
  },
  en: {
    typeEmail: "Type email*",
    typeSubject: "Type email subject*",
    typeBody: "Type email body*",
    preview: "Preview"
  },
  es: {
    typeEmail: "Escriba el correo electrónico*",
    typeSubject: "Escriba el asunto del correo electrónico*",
    typeBody: "Escriba el cuerpo del correo electrónico*",
    preview: "Vista previa"
  },
  fr: {
    typeEmail: "Type email*",
    typeSubject: "Type email subject*",
    typeBody: "Type email body*",
    preview: "Aperçu"
  },
  it: {
    typeEmail: "Digita email*",
    typeSubject: "Digita oggetto email*",
    typeBody: "Digita corpo email*",
    preview: "Anteprima"
  },
  de: {
    typeEmail: "Typ E-Mail*",
    typeSubject: "Typ E-Mail-Betreff*",
    typeBody: "Typ E-Mail-Text*",
    preview: "Vorschau"
  }
};
interface Props {
  resetEditor: boolean;
  htmlValue: any;
  assuntoValue: any;
  email: any;
  onEmail: React.Dispatch<React.SetStateAction<string>>;
  onHtml: React.Dispatch<React.SetStateAction<string>>;
  onAssunto: React.Dispatch<React.SetStateAction<string>>;
}
const EditorConvertToHtml = ({
  resetEditor,
  assuntoValue,
  htmlValue,
  onAssunto,
  onHtml,
  email,
  onEmail
}: Props) => {
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const [code, setCode] = useState(false);
  const [reset, setReset] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getContent = () => {
    if (!code) {
      return htmlValue;
    } else {
      const contentBlock = htmlToDraft(htmlValue);
      return contentBlock.contentBlocks;
    }
  };

  return (
    <div className="col-12 pl-3 pr-3 pb-2">
      <div className="kt-input-icon kt-input-icon--left pb-2">
        <Stack
          direction={{ xs: "column", sm: "column" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <TextField
            type="text"
            fullWidth
            label={handleTranslate[locale].typeEmail}
            onChange={e => {
              const value = e.target.value;
              onEmail(value);
            }}
            disabled
            value={email}
          />
          <TextField
            type="text"
            // className="form-control"
            fullWidth
            label={handleTranslate[locale].typeSubject}
            onChange={e => {
              const value = e.target.value;
              onAssunto(value);
            }}
            value={assuntoValue}
          />
        </Stack>
      </div>
      <Editor
        placeholder={handleTranslate[locale].typeBody}
        editorState={editorState}
        toolbarCustomButtons={[
          <ButtonCode
            key={1}
            content={getContent()}
            toggleCode={() => setCode(!code)}
          />
        ]}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editor"
        toolbar={toolbar}
        onEditorStateChange={(e: any) => setEditorState(e)}
        onChange={() => {
          if (code) {
            onHtml(editorState.getCurrentContent().getPlainText());
          } else {
            onHtml(
              editorState.getCurrentContent().getPlainText()
                ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
                : ""
            );
          }
        }}
      />
      {code ? (
        <div className="pt-2">
          <strong>{handleTranslate[locale].preview}</strong>
          <div
            className="preview-container"
            dangerouslySetInnerHTML={{ __html: htmlValue }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default EditorConvertToHtml;

import React from "react";
import { Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import FormActions from "../../components/FormActions";
import { FileUpload } from "../../../../partials/form";
import withFormValidation from "../../../../hoc/withFormValidation";
import { FragranceApiService, CategoryApiService } from "../../../../services";
import SelectSearchComponent from "../../../auth/Components/Select2";
import FormHelper from "../../../../helpers/FormHelper";
import Loading from "../../../home/components/Loading";
import { TranslationContext } from "_metronic/i18n/language";

import Swal from "sweetalert2";
import { Wrapper } from "../Users/components/Wrapper";
import { isNameSufficient } from "app/utils/validators/IsValidName";
import { FormInput } from "../Users/components/FormInput";
import { languageFields } from "./languageFileds";

const initialState = {
  name: "",
  description: "",
  categoryId: "",
  categoryName: "",
  image: "",
  pt: "",
  en: "",
  es: "",
  fr: "",
  de: "",
  it: "",
  secret: "",
  fragranceNameId: "",
  currentUploadedImage: null,
  currentUploadedSecret: null,
  shouldCreateNewCategory: false,
  translate: null
};

class FragranceForm extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new FragranceApiService();
    this.categoryApiService = new CategoryApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.props.onCleanForm.bind(this);
    this.formHelper = new FormHelper(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  componentDidMount() {
    this.formHelper.loadFromService();
  }

  mapModelToState(model) {
    return {
      name: model.name,
      description: model.description,
      categoryId: model.categoryId,
      categoryName: model.category.name,
      currentUploadedImage: model.image,
      currentUploadedSecret: model.secret,
      fragranceNameId: model.fragranceNameId,
      pt: model.fragranceNames.pt,
      en: model.fragranceNames.en,
      es: model.fragranceNames.es,
      fr: model.fragranceNames.fr,
      de: model.fragranceNames.de,
      it: model.fragranceNames.it
    };
  }

  mapStateToModel() {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("categoryId", this.state.categoryId);
    if (this.state.fragranceNameId) {
      formData.append("fragranceNameId", this.state.fragranceNameId);
      formData.append(
        "fragranceNames.fragranceNameId",
        this.state.fragranceNameId
      );
    }
    // como ficarão estes states
    formData.append("fragranceNames.pt", this.state.pt);
    formData.append("fragranceNames.en", this.state.en);
    formData.append("fragranceNames.es", this.state.es);
    formData.append("fragranceNames.fr", this.state.fr);
    formData.append("fragranceNames.de", this.state.de);
    formData.append("fragranceNames.it", this.state.it);

    if (this.state.image) {
      formData.append("image.file", this.state.image);
    }

    if (this.state.secret) {
      formData.append("secret.file", this.state.secret);
    }

    return formData;
  }

  async handleChangeCategory(e) {
    const translate = this.context;
    this.setState({ categoryName: "", categoryId: "" });
    if (e.target) {
      return this.handleChange(e);
    }

    this.props.showSpinner();
    try {
      const category = await this.categoryApiService.create({ name: e.value });
      this.setState({
        categoryId: category.categoryId,
        categoryName: category.name
      });
    } catch (e) {
      Swal.fire(
        translate("defaultMessages.error"),
        e.response && e.response.status === 400
          ? e.response.data
          : translate("screens.fragrance.categories.createError"),
        "error"
      );
    }

    this.props.hideSpinner();
  }

  render() {
    const translate = this.context;
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${translate("screens.fragrance.pageTitle.edit")} - ${translate(
          "labels_admin"
        )}`
      );
    } else {
      window.setPageTitle(
        `${translate("screens.fragrance.pageTitle.create")} - ${translate(
          "labels_admin"
        )}`
      );
    }

    const {
      name,
      description,
      categoryId,
      currentUploadedImage,
      image
    } = this.state;

    return (
      <Wrapper
        className="card-form"
        headerTitle={translate("screens.fragrance.mainData")}
      >
        <Loading isLoading={this.state.isLoading} />
        <div className="card-body">
          <form onSubmit={this.formHelper.submit}>
            <Row>
              <div className="col-lg-12 col-12">
                <div className="form-label">
                  {translate("screens.fragrance.labels.category")} *
                </div>
                <SelectSearchComponent
                  handleChange={this.handleChangeCategory}
                  name="categoryId"
                  className="kt-width-full"
                  label={translate(
                    "screens.fragrance.placeholders.selectCategory"
                  )}
                  resetInput={
                    !this.state.categoryId && !this.state.categoryName
                  }
                  url={`/categories/quick-search?str=`}
                  value={
                    this.state.categoryName
                      ? { label: this.state.categoryName }
                      : undefined
                  }
                  convertObject={obj => ({
                    id: obj.categoryId,
                    value: obj.categoryId,
                    label: obj.name,
                    target: {
                      value: obj.categoryId,
                      name: "categoryId"
                    }
                  })}
                  newOption={this.state.shouldCreateNewCategory}
                  setNewOption={shouldCreateNewCategory =>
                    this.setState({ shouldCreateNewCategory })
                  }
                  createLabelText={translate("screens.fragrance.labels.create")}
                  translate={translate}
                />

                {this.props.validator.message(
                  "categoryId",
                  categoryId,
                  "required"
                )}
              </div>
            </Row>
            <Row>
              <FormInput
                label={translate("screens.fragrance.labels.name")}
                name="name"
                placeholder={translate("screens.fragrance.placeholders.name")}
                value={name}
                onChange={this.handleChange}
                required
                errorMessage={translate("screens.login.validations.invalid")}
                isInvalid={name && !isNameSufficient(name)}
                className="col-lg-4"
              />
              {languageFields.map(({ key, language }) => (
                <FormInput
                  label={`${translate(
                    "screens.fragrance.labels.name"
                  )} ${translate(
                    `screenApp.menu.languages.${language}`
                  ).toLowerCase()}`}
                  name={key}
                  value={this.state[key]}
                  onChange={this.handleChange}
                  placeholder={translate("screens.user.placeholders.name")}
                  className="col-lg-4"
                  key={key}
                />
              ))}
            </Row>
            <Row>
              <FormInput
                label={translate("screens.fragrance.labels.description")}
                name="description"
                value={description}
                onChange={this.handleChange}
                placeholder={translate(
                  "screens.fragrance.placeholders.description"
                )}
                errorMessage={translate("screens.login.validations.invalid")}
                isInvalid={description && !isNameSufficient(description)}
                className="col-lg-12"
              />
            </Row>

            <Row>
              <div>
                <FileUpload
                  label={`${translate("screens.fragrance.labels.image")} *`}
                  placeholder={translate(
                    "screens.fragrance.placeholders.selectImage"
                  )}
                  name="image"
                  file={image}
                  currentUploadedFile={currentUploadedImage}
                  handleChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required"}
                  accept={"image/*"}
                />
              </div>
            </Row>

            <FormActions
              module="fragrances"
              formIsValid={true}
              onCleanForm={this.onCleanForm}
            />
          </form>
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(withFormValidation(FragranceForm, initialState));

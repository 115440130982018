import React from "react";
import { Row, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import FormActions from "../../../components/FormActions";
import { withFormValidation } from "../../../../../hoc";
import { CategoryApiService } from "../../../../../services/Ananse/CategoryApiService";
import FormHelper from "../../../../../helpers/FormHelper";
import { TranslationContext } from "_metronic/i18n/language";
import { Wrapper } from "../../Users/components/Wrapper";
import { FormInput } from "../../Users/components/FormInput";
import { isNameSufficient } from "app/utils/validators/IsValidName";

const initialState = {
  name: "",
  translate: null
};

class CategoryForm extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.state = {
      ...initialState
    };

    this.api = new CategoryApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.props.onCleanForm.bind(this);
    this.formHelper = new FormHelper(this);
    this.initializeTranslation();
  }

  async initializeTranslation(translateContext) {
    if (translateContext) {
      this.setState({ translate: translateContext });
      return;
    }
    const translate = await this.context;
    this.setState({ translate });
  }
  componentDidMount() {
    this.formHelper.loadFromService();
  }

  mapModelToState(model) {
    return {
      name: model.name
    };
  }

  mapStateToModel() {
    return {
      name: this.state.name
    };
  }

  translateMessage(key) {
    const translate = this.context;
    return translate(key)
  }

  formIsValid = () => {
    return isNameSufficient(this.state.name);
  };
  render() {
    const translate = this.context;
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${translate("screens.category.pageTitle.edit")} - ${translate(
          "labels_admin"
        )}`
      );
    } else {
      window.setPageTitle(
        `${translate("screens.category.pageTitle.create")}- ${translate(
          "labels_admin"
        )}`
      );
    }

    const { name } = this.state;

    return (
      <Wrapper
        className="card-form"
        headerTitle={translate("screens.category.mainData")}
      >
        <div className="card-body">
          <Form onSubmit={this.formHelper.submit}>
            <Row>
              <FormInput
                label={translate("screens.category.labels.name")}
                name="name"
                value={name}
                onChange={this.handleChange}
                required
                placeholder={translate("screens.category.placeholders.name")}
                className="col-lg-12"
                errorMessage={translate("screens.login.validations.invalid")}
                isInvalid={name && !isNameSufficient(name)}
              />
            </Row>
            <FormActions
              module="categories"
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
            />
          </Form>
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(withFormValidation(CategoryForm, initialState));

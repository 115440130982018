import { months } from "components/Dash/chartConfig";
import { DataTest } from "../dsa.type";

export const transformData = (
  data: DataTest | Array<{ value: number }>,
  translatedMonths: { [key: string]: string },
  year = ""
): Array<{ name: string; value: number; monthIndex: number }> => {
  if (Array.isArray(data)) {
    return months.map((month, index) => ({
      name: translatedMonths[month],
      value: data[index].value || 0,
      monthIndex: index + 1
    }));
  }

  const yearData = (data as DataTest)[year];
  if (!yearData) {
    return months.map((month, index) => ({
      name: translatedMonths[month],
      value: 0,
      monthIndex: index + 1
    }));
  }
  return months.map((month, index) => ({
    name: translatedMonths[month],
    value: yearData[month] || 0,
    monthIndex: index + 1
  }));
};

export const getTranslatedMonths = (
  translate: (key: string) => string
): { [key: string]: string } => {
  return {
    Jan: translate("labels_months_jan"),
    Fev: translate("labels_months_feb"),
    Mar: translate("labels_months_mar"),
    Abr: translate("labels_months_apr"),
    Mai: translate("labels_months_may"),
    Jun: translate("labels_months_jun"),
    Jul: translate("labels_months_jul"),
    Ago: translate("labels_months_aug"),
    Set: translate("labels_months_sep"),
    Out: translate("labels_months_oct"),
    Nov: translate("labels_months_nov"),
    Dez: translate("labels_months_dec")
  };
};

import { AnanseApiService } from "app/services/Ananse/AnanseApiService";

const api = new AnanseApiService();

export type CampaignType = {
  id: number;
  name: string;
};

export async function getCampaign(id: number): Promise<CampaignType> {
  const data = await api.makeHttpRequest({
    method: "GET",
    url: `/campaigns/${id}`
  });
  return { id: data.campaignId, name: data.name };
}

export async function getAllCampaigns(): Promise<CampaignType[]> {
  const { data } = await api.makeHttpRequest({
    method: "GET",
    url: `/campaigns/`
  });

  return data.map(
    ({ campaignId, name }: { campaignId: number; name: string }) => ({
      id: campaignId,
      name
    })
  );
}

export async function updateCampaignOnDevice(
  deviceId: number,
  campaignId: number
): Promise<boolean> {
  const response = await api.makeHttpRequest({
    method: "PUT",
    url: `/devices/update-campaign/${deviceId}/${campaignId}`
  });
  return typeof response === "object" && response.deviceId;
}

export async function updateDeviceById(
  deviceId: number,
  data: any
): Promise<boolean> {
  const response = await api.makeHttpRequest({
    method: "PUT",
    url: `/devices/${deviceId}`,
    data
  });
  return typeof response === "object" && response.deviceId;
}

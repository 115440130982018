import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import store from "app/store/store";
import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import EditorConvertToHtml from "components/Email/EditorConvertToHtml";
import ModalComponent from "..";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  emailCc?: string;
}
const SendEmailModal: React.FC<Props> = ({
  emailCc,
  openModal,
  setOpenModal
}) => {
  const translate = useTranslation();
  const { auth } = store.getState();

  const [users, setusers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setuser] = useState("");
  const [cc, setCc] = useState(emailCc ? emailCc : "contato@noar.health");
  const [filter, setFilter] = useState({
    perPage: 1,
    page: 1,
    orderBy: "Name",
    orderByDirection: "ASC",
    isActive: true
  });
  const [html, setHtml] = useState("");
  const [assunto, setAssunto] = useState(translate("labels_usersLicense"));
  const [resetEditor, setResetEditor] = useState(false);

  const api = new AnanseApiService();

  const showMensage = (sucess: any, error: any) => {
    if (error) {
      Swal.fire(
        translate("screenApp_resetFirmwareComponents_titleErro"),
        error
          ? error
          : translate("labels_errorEmail"),
        "error"
      );
    } else if (sucess) {
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: translate("labels.emailSuccess"),
        icon: "success",
        showCancelButton: false,
        confirmButtonText: translate("buttons.ok"),
        cancelButtonText: translate("buttons.exit")
      });
    }
  };

  const sendEmails = async () => {
    const reqBase = { method: "POST", url: "/emailparametrizavel" };
    try {
      setIsLoading(true);

      const response = await api.makeHttpRequest({
        ...reqBase,
        data: {
          filter: filter,
          listID: [2],
          body: html,
          assunto: assunto,
          cc
        }
      });
      if (response) {
        setResetEditor(!resetEditor);
        showMensage(true, "");
      }
    } catch (error:any) {
      if (error.response && error.response.data) {
        showMensage(false, error.response.data);
      } else {
        showMensage(false, translate("labels_failEmail"));
      }
    } finally {
      setOpenModal(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalComponent openModal={openModal} setOpenModal={setOpenModal}>
        <Box>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 5, sm: 2, md: 4 }}
            sx={{ marginBottom: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              {translate("labels_emailcc") + " " + cc}
            </Typography>
          </Stack>
          <EditorConvertToHtml
            resetEditor={resetEditor}
            assuntoValue={assunto}
            htmlValue={html}
            onAssunto={setAssunto}
            onHtml={setHtml}
            email={cc}
            onEmail={setCc}
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ marginTop: 2 }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenModal(false)}
            >
              {translate("buttons.cancel")}
            </Button>
            <Button variant="contained" color="success" onClick={sendEmails}>
              {translate("buttons.send")}
            </Button>
          </Stack>
        </Box>
      </ModalComponent>
      <Loading isLoading={isLoading} />
    </>
  );
};

export default SendEmailModal;

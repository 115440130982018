export const determineActionName = (pathname: string, translate: any): string => {
  if (pathname.endsWith("/create")) {
    return translate("buttons.register");
  } else if (pathname.endsWith("/update")) {
    return translate("buttons.edit");
  } else {
    return "";
  }
};

interface Translations {
  [key: string]: string;
}

export const translatePageName = (pathname: string, translate: any): string => {

  const translations: Translations = {
    "/admin": "labels_home", // Casa
    "/admin/users": "screens_user_list_title", // Usuários
    "/admin/userlist": "menu_quiz_title_quiz", // Testes
    "/admin/fragrances": "screens_fragrance_list_title", // Fragrâncias
    "/admin/categories": "labels_categories", // Categorias
    "/admin/campaigns": "screens_campaigns_list_title", // Campanhas
    "/admin/quiz": "menu_quiz_title_quiz", // Testes
    "/admin/anamense": "screenApp_anamnese_list_anamneseTitle", // Anamnese
    "/admin/devices": "labels_devices_title", // Dispositivos
    "/admin/capsules": "screens_capsule_title", // Cápsulas
    "/admin/account": "screenApp_listPacient_account_titleList", // Meus Pacientes
    "/admin/dashboard": "dashboard", // Dashboard
    "/admin/mygroup": "screens_group_title", // Grupos
    "/admin/quiz/create": "labels_new", // Novo
    "/admin/quiz/update": "datatable_refresh", // Atualizar
    "/admin/users/create": "labels_new", // Novo
    "/admin/users/update": "datatable_refresh", // Atualizar
    "/admin/import": "screenApp_backup_import_label" // Importação
  };

  return translate(translations[pathname] || "");
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  max-width: 600px;
  margin: 0 auto;
  @media(max-width: 600px) {
    margin: 0 20px;
  }

  background: ${props => props.bg || 'transparent'};
  border-radius: 15px;
  padding: 20px;
`;

export const defaultInnerWidth = 90;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: ${defaultInnerWidth + '%'};
`;

export const PatternButton = `
  outline: none;
  border: none;
  margin: none;
  padding: 15px 25px;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: all 0.2s linear;
`;

export const DownloadButton = styled.button`
    ${PatternButton}
    background: #0D2551;
    cursor: pointer;
    color: white;
`;

import { useState } from "react";
import clsx from "clsx";
import { QuestionType } from "../preViewQuiz";
import {
  TrunkOpen,
  TrunkClosed,
  ButtonNextTrunk,
  Smell
} from "../../../../../../../../assets/images";
import { useTranslation } from "_metronic/i18n/language";
import "./style.scss";
import PreviewButton from "./PreviewButton";
import ChildIdentificationAnswers from "./ChildIdentificationAnswers";
import BackgroundTitle from "./BackgroundTitle";

type PreviewChildIdentificationProps = {
  scaleFactor: number;
  selectedAnswerId: number | null;
  currentQuestionIndex: number;
  questionsLength: number;
  currentQuestion: QuestionType;
  imageBackground: string;
  handleSelectAnswer: (answerId: number) => void;
  handleNextQuestion: () => void;
};

const PreviewChildIdentification = ({
  scaleFactor,
  currentQuestion,
  selectedAnswerId,
  currentQuestionIndex,
  questionsLength,
  imageBackground,
  handleSelectAnswer,
  handleNextQuestion
}: PreviewChildIdentificationProps) => {
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [enabledButtons, setEnabledButtons] = useState(false);
  const [isClosedTrunk, setIsClosedTrunk] = useState(true);
  const translate = useTranslation();

  const handleButtonSmell = () => {
    setEnabledButtons(true);
  };

  const handleNextClick = () => {
    if (currentQuestionIndex + 1 >= questionsLength) {
      return;
    }
    setIsClosedTrunk(true);
    handleNextQuestion();
    setEnabledButtons(false);
    setIsNextButtonEnabled(false);
  };

  const handleEnableNextButton = () => setIsNextButtonEnabled(true);
  const borderColor = isNextButtonEnabled ? "#b57c00" : "#C4C4C4";

  return (
    <div
      className={clsx("preview-inner-content", {
        "preview-inner-without-image": !imageBackground
      })}
    >
      {imageBackground ? (
        <BackgroundTitle
          description={currentQuestion.question.description}
          question={currentQuestion.question.quest}
        />
      ) : (
        <div>
          <p
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              fontSize: `${20 * scaleFactor}px`,
              color: "#fff"
            }}
          >
            {translate("screenApp_quiz_startMessages_subTitle4")}
          </p>
          <h2
            style={{
              marginTop: "20px",
              fontSize: `${20 * scaleFactor}px`,
              fontWeight: "normal",
              textAlign: "left",
              color: "#fff"
            }}
          >
            {currentQuestion.question.quest}
          </h2>
        </div>
      )}
      {imageBackground && isClosedTrunk ? (
        <div
          style={{
            marginTop: "238px",
            cursor: "pointer"
          }}
        >
          <img
            src={TrunkClosed}
            onClick={() => setIsClosedTrunk(false)}
            style={{
              width: "80px",
              height: "80px",
              objectFit: "contain"
            }}
          />
        </div>
      ) : (
        <div>
          <ChildIdentificationAnswers
            imageBackground={imageBackground}
            answers={currentQuestion.answers}
            enabledButtons={enabledButtons}
            selectedAnswerId={selectedAnswerId}
            handleAnswerClick={answerId => {
              handleSelectAnswer(answerId);
              handleEnableNextButton();
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: "12px",
              marginTop: "10px",
              fontWeight: "bold"
            }}
          >
            <PreviewButton
              onClick={handleButtonSmell}
              className="btn-smell"
              style={{
                backgroundImage: imageBackground ? `url(${Smell})` : "none"
              }}
              label={translate("screenApp_quiz_tryScent")}
            />
            {imageBackground ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flex: 1
                }}
              >
                <img
                  src={TrunkOpen}
                  alt="NoarLogo"
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "contain"
                  }}
                />

                <PreviewButton
                  onClick={handleNextClick}
                  disabled={!isNextButtonEnabled}
                  className={clsx("btn-next", "btn-next-with-image", {
                    "btn-next-enabled": isNextButtonEnabled
                  })}
                  style={{
                    backgroundImage: imageBackground
                      ? `url(${ButtonNextTrunk})`
                      : "none"
                  }}
                  label={translate(
                    "screenApp_validation_nextChest"
                  ).toUpperCase()}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <PreviewButton
                  onClick={handleNextClick}
                  disabled={!isNextButtonEnabled}
                  className={clsx("btn-next", "btn-next-without-image", {
                    "btn-next-enabled": isNextButtonEnabled
                  })}
                  label={translate("screens_quiz_labels_next")}
                />
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderTop: "24px solid transparent",
                    borderBottom: "24px solid transparent",
                    borderLeft: `24px solid ${borderColor}`,
                    cursor: "pointer"
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: "20px",
              marginBottom: `${14 * scaleFactor}px`,
              fontSize: `${14 * scaleFactor}px`,
              color: "#ffffff",
              backgroundColor: "#d37000",
              borderRadius: "5px",
              padding: "1px"
            }}
          >
            {translate("screens_quiz_labels_question")}{" "}
            {currentQuestionIndex + 1} / {questionsLength}
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewChildIdentification;

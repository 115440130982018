import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Apple, Shop2 } from "@mui/icons-material";
import { toAbsoluteUrl } from "_metronic";
import { useTranslation } from "_metronic/i18n/language";

const ButtonsStore: React.FC = () => {
  const translate = useTranslation();

  const isIOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isWindows = /Windows/i.test(navigator.userAgent);

  const stackStyle = {
    backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-10.jpg")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "120px"
  };

  const handleDownload = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Box sx={stackStyle}>
      <Stack
        sx={{ marginTop: "10px" }}
        justifyContent="center"
        alignItems="center"
        direction={{ xs: "column" }}
        spacing={1}
      >
        <Typography align="center" variant="h3" sx={{ marginTop: "20px" }}>
          {translate("screens_register_app")}
        </Typography>
      </Stack>
      <Stack
        sx={{ marginTop: "10px", marginBottom: "10px" }}
        justifyContent="center"
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
      >
        {(isIOS || isWindows) && (
          <Button
            onClick={() =>
              handleDownload(process.env.REACT_APP_LINK_APPLE_STORE || "")
            }
            variant="contained"
            startIcon={<Apple fontSize="large" />}
          >
            {`${translate("getApp_apple")} \nApp Store`}
          </Button>
        )}
        {(isAndroid || isWindows) && (
          <Button
            onClick={() =>
              handleDownload(process.env.REACT_APP_LINK_PLAY_STORE || "")
            }
            variant="contained"
            startIcon={<Shop2 fontSize="large" />}
          >
            {`${translate("getApp_android")} \nPlay Store`}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ButtonsStore;

import { CRUDApiService } from "./CRUDApiService";

export class QuizApiService extends CRUDApiService {
  constructor() {
    super('quiz');
  }

  getById(id) {
    const baseUrl = `details/${id}`;
    return this.makeHttpRequest({method: 'GET', url: baseUrl})
  }
}

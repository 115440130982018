import React, { StrictMode } from "react";
import { Button, Stack, Checkbox, Typography, Divider } from "@mui/material";
import { Card, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import "react-sortable-tree/style.css";

import withFormValidation from "../../../../hoc/withFormValidation";
import { QuestionApiService } from "../../../../services/Ananse/QuestionApiService";
import { QuizApiService } from "../../../../services/Ananse/QuizApiService";
import IsValidName from "../../../../utils/validators/IsValidName";
import SelectSearchComponent from "../../../auth/Components/Select2";
import Loading from "../../../home/components/Loading";
import ConfirmDeleteDialog from "../../../../partials/datatable/ConfirmDeleteDialog";
import SelectLanguage from "../../components/SelectLanguage";
import NewSelectItem from "./component/NewSelectItem";
import { InputSelect, SelectSearch, TransferList } from "components";
import { selectInput } from "./mock";
import ButtonsQuiz from "./component/ButtonsQuiz";
import TableQuestions from "./component/TableQuestions";
import TableAnswers from "./component/TableAnswers";
import { formatFragrances, getStateByModel } from "./utils";
import FormHelper from "../../../../helpers/FormHelper";
import store from "../../../../store/store";
import { TranslationContext } from "_metronic/i18n/language";

const initialQuestion = {
  quizQuestionId: null,
  questionId: null,
  quest: "",
  answers: [],
  fragranceId: null,
  rightAnswer: "",
  type: "slider"
};

const initialAnswer = {
  quizQuestionAnswerId: null,
  answerId: null,
  desc: "",
  description: "",
  isAnswerFinish: false,
  nextQuizQuestionId: null,
  nextQuizQuestion: null,
  fragrances: []
};

const initialState = {
  quizName: "",
  quizId: null,
  validatedAt: null,
  publishedAt: null,
  campaignId: null,
  campaignName: "",
  language: "",
  questions: [],
  fragrancesSelected: [],
  collection: [],
  loading: false,
  question: { ...initialQuestion },
  answer: { ...initialAnswer },
  answerSelectedIndex: null,
  requiredQuestion: false,
  requiredAnswer: false,
  requiredAnswers: false,
  requiredNextQuestion: false,
  requiredFragrance: false,
  maxShots: null,
  userId: null,
  type: "1",
  resetInput: false,
  anamneseId: 0,
  mandatoryAnamnese: false,
  clone: false,
  redirectClone: {
    to: "/",
    redirect: false
  }
};

class QuizUpdate extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    const { auth } = store.getState();

    this.state = {
      translate: null,
      ...initialState,
      confirmDelete: false,
      id: null,
      treeData: [],
      currentUserId: auth.user.id.toString() || null,
      progressCloneQuestions: 0,
      loadingCloneQuestions: false
    };

    this.api = new QuizApiService();
    this.questionApi = new QuestionApiService();
    this.formHelper = new FormHelper(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleQuestion = this.handleQuestion.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.handleSelectFragranceAnswer = this.handleSelectFragranceAnswer.bind(
      this
    );
    this.handleSelectNextQuestion = this.handleSelectNextQuestion.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.clearAnswer = this.clearAnswer.bind(this);
    this.editAnswer = this.editAnswer.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.validateQuiz = this.validateQuiz.bind(this);
    this.publishQuiz = this.publishQuiz.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    // Aguarde até que o contexto seja definido
    const translate = await this.context;
    this.setState({ translate });
  }

  async getQuiz(id) {
    const quiz = await this.api.get(id);
    const { currentUserId } = this.state;
    if (quiz) {
      this.setState({
        loading: false,
        quizId: quiz.quizId,
        validatedAt: quiz.validatedAt,
        publishedAt: quiz.publishedAt,
        campaignId: quiz.campaignId,
        campaignName: quiz.campaign?.name,
        collection: quiz.campaign?.collection,
        questions: quiz.questions,
        language: quiz.language,
        question: {
          quizQuestionId: null,
          questionId: null,
          quest: "",
          answers: [],
          type: "",
          fragranceId: "",
          rightAnswer: ""
        },
        answer: { ...initialAnswer },
        maxShots: quiz.maxShots,
        type: quiz.type,
        userId:
          this.props.match.params.clone === "clone"
            ? currentUserId
            : quiz.userId,
        anamneseId: quiz.anamneseId,
        mandatoryAnamnese: quiz.mandatoryAnamnese
      });
      return quiz;
    } else {
      this.props.history.push("/admin/quiz");
    }
  }

  async formatTreeData(quiz) {
    let { questions } = quiz;

    const typeQuestion = "question";
    const typeAnswer = "answer";
    const typeFragrance = "fragrance";

    if (!questions.length > 0) {
      return [];
    }

    questions = questions.reverse();

    let questionsFormat = questions.map(q => {
      const { question, answers, questionId, quizQuestionId, quizId } = q;

      return {
        title: (
          <>
            <div
              style={{
                backgroundColor: "cyan",
                display: "inline-block",
                borderRadius: 5,
                color: "#FFF",
                padding: "0 5px",
                marginRight: "0.3rem"
              }}
            >
              Question
            </div>
            {question.quest}
          </>
        ),
        answers,
        question,
        questionId,
        quizQuestionId,
        quizId,
        expanded: true,
        nodeType: typeQuestion,
        children: answers.map(ans => {
          const { answer, fragranceOptions, quizQuestionAnswerId } = ans;
          return {
            title: (
              <>
                <div
                  style={{
                    backgroundColor: "#0D2551",
                    display: "inline-block",
                    borderRadius: 5,
                    color: "#FFF",
                    padding: "0 5px",
                    marginRight: "0.3rem"
                  }}
                >
                  Answer
                </div>
                {answer.label}
              </>
            ),
            nextId: ans.nextQuestion?.question?.questionId,
            quizQuestionAnswerId,
            nodeType: typeAnswer,
            expanded: true,
            children:
              fragranceOptions.length > 0
                ? fragranceOptions.map(frag => {
                    const { fragrance, fragranceOptionId } = frag;
                    return {
                      title: (
                        <>
                          <div
                            style={{
                              backgroundColor: "#0abb87",
                              display: "inline-block",
                              borderRadius: 5,
                              color: "#FFF",
                              padding: "0 5px",
                              marginRight: "0.3rem"
                            }}
                          >
                            Fragrance
                          </div>
                          {fragrance.name}
                        </>
                      ),
                      children: [],
                      nodeType: typeFragrance,
                      fragranceOptionId,
                      noDragging: false,
                      noChildren: true
                    };
                  })
                : []
          };
        })
      };
    });
    let response = await this.questionsFormated(questionsFormat);
    return response;
  }

  questionsFormated(questionsFormat) {
    return new Promise(function(resolve, reject) {
      setTimeout(() => {
        questionsFormat.reverse().forEach(qf => {
          const { questionId } = qf;
          questionsFormat.forEach(q => {
            let { children } = q;
            let childrenFound = children.filter(c => c.nextId === questionId);

            if (childrenFound.length > 0) {
              childrenFound.forEach(cf => {
                cf.children = [qf];
              });
            }
          });
        });
        resolve([questionsFormat[questionsFormat.length - 1]]);
      }, 2800);
    });
  }

  onCleanForm() {
    this.setState({
      loading: false,
      question: {
        quizQuestionId: null,
        questionId: null,
        quest: "",
        answers: [],
        fragranceId: "",
        rightAnswer: ""
      },
      answer: {
        quizQuestionAnswerId: null,
        answerId: null,
        desc: "",
        description: "",
        isAnswerFinish: false,
        nextQuizQuestionId: null,
        nextQuizQuestion: null,
        fragrances: []
      }
    });
  }

  async componentDidMount() {
    await this.initializeTranslation();
    const id = this.props.match.params.id;
    if (!id) {
      return;
    }
    this.formHelper.loadFromService();
    if (this.props.match.params.clone === "clone") {
      Swal.fire({
        title: this.state.translate("screens.quiz.clone.title"),
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.updateQuiz();
        }
      });
      this.setState({
        clone: true
      });
    }
    this.setState({ loading: true });

    try {
      this.makeTreeData(id);
    } catch (error) {
      Swal.fire(
        this.state.translate("screens.quiz.errors.title"),
        this.state.translate("screens.quiz.errors.message"),
        "error"
      );
    }
    this.setState({ loading: false });
  }

  makeTreeData = async id => {
    let quiz = await this.getQuiz(id);
    let treeData = await this.formatTreeData(quiz);
    this.setState({ treeData });
  };

  createQuestion = async e => {
    await this.initializeTranslation();
    e.preventDefault();
    try {
      const reqBase = { method: "POST", url: "add-update" };

      const { quizId, question } = this.state;

      let hasError = false;
      if (!question || (!question.questionId && !question.quest)) {
        this.setState({ requiredQuestion: true });
        hasError = true;
      }

      if (question.answers?.length === 0) {
        this.setState({ requiredAnswers: true });
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.setState({ loading: true });

      question.quizId = quizId;
      await this.questionApi.makeHttpRequest({
        ...reqBase,
        data: question
      });

      await this.getQuiz(quizId);
      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("screens.quiz.messages.add"),
        icon: "success",
        cancelButtonText: this.state.translate("buttons.cancel")
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire(
        "ERROR",
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.createQuestion"),
        "error"
      );
    }
  };

  updateQuiz = async () => {
    await this.initializeTranslation();
    const {
      quizId,
      quizName,
      maxShots,
      type,
      language,
      userId,
      campaignId,
      anamneseId,
      mandatoryAnamnese
    } = this.state;
    if (this.state.clone) {
      this.clone();
      return;
    }
    this.setState({ loading: true });
    try {
      const body = {
        quizId: quizId,
        campaignId: campaignId,
        name: quizName,
        language: language,
        type: type,
        maxShots: maxShots,
        userId,
        anamneseId,
        mandatoryAnamnese
      };
      await this.api.update(quizId, body);
      this.setState({ loading: false });
      await Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("defaultMessages.saveData"),
        icon: "success",
        confirmButtonText: "OK"
      });
    } catch (error) {
      this.setState({ loading: false });
      Swal.fire(
        this.state.translate("screens.quiz.errors.title"),
        this.state.translate("screens.quiz.errors.message"),
        "error"
      );
    }
  };

  handleChangeLanguage = e => {
    this.setState({ language: e.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.maxShots]: e.target.maxShots,
      [e.target.type]: e.target.type
    });
  };

  handleChangeSelect = e => {
    this.setState({
      campaignId: e.id,
      campaignName: e.label,
      resetInput: false
    });
  };

  handleChangeSelectMulti = e => {
    const newId = e.map(usuario => usuario.id).join(";");
    this.setState({
      userId: newId
    });
  };

  handleChangeAnswerDesc = e => {
    const { question } = this.state;
    question.rightAnswer = e.target.value;
    this.setState({
      question
    });
  };

  handleRadio = e => {
    const { answer } = this.state;
    answer.isAnswerFinish = e.target.value === "true";
    this.setState({
      answer
    });
  };

  handleQuestion = obj => {
    const translate = this.context;
    const { question } = this.state;
    question.questionId = obj.id;
    question.quest = obj.label.replace(
      this.state.translate("screens.quiz.placeholders.newQuestion"),
      ""
    );
    this.setState({ question: { ...question }, requiredQuestion: false });
  };

  handleQuestionType = obj => {
    const inputType = obj.target.value;
    const { question } = this.state;
    question.type = inputType;
    this.setState({ question: { ...question }, requiredQuestion: false });
  };

  handleAnswer = obj => {
    const translate = this.context;
    const { answer } = this.state;

    answer.answerId = obj.id;
    answer.desc = obj.label.replace(
      this.state.translate("screens.quiz.placeholders.newAnswer"),
      ""
    );

    this.setState({ answer, requiredAnswer: false });
  };

  handleDescription = obj => {
    const { answer } = this.state;

    answer.description = obj.value ?? "";

    this.setState({ answer, requiredAnswer: false });
  };

  handleSelectFragranceAnswer = obj => {
    const { question } = this.state;
    question.fragranceId = obj.value;
    this.setState({ question });
  };

  handleSelectNextQuestion = obj => {
    const { answer } = this.state;
    answer.nextQuizQuestionId = obj.value;
    answer.nextQuizQuestion = obj;
    this.setState({ answer, requiredNextQuestion: false });
  };

  addAnswer = () => {
    const { question, answer, answerSelectedIndex } = this.state;

    let hasError = false;
    if (!answer || (!answer.answerId && !answer.desc)) {
      this.setState({ requiredAnswer: true });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (answerSelectedIndex !== null) {
      question.answers[answerSelectedIndex] = answer;
    } else {
      question.answers.push(answer);
    }

    this.setState({
      ...this.state,
      question: { ...question },
      answer: { ...initialAnswer },
      answerSelectedIndex: null,
      requiredAnswers: false,
      requiredQuestion: false,
      requiredAnswer: false,
      requiredNextQuestion: false,
      requiredFragrance: false
    });
  };

  clearAnswer(answer) {
    const { question } = this.state;

    const newAnswers = question.answers.filter(e => e !== answer);
    const newQuestion = { ...question, answers: newAnswers };
    this.setState({ question: newQuestion });
  }

  editAnswer(answer, index) {
    const edit = { ...answer };
    this.setState({ answer: edit, answerSelectedIndex: index });
  }

  async onConfirmDelete(quizQuestionId) {
    this.setState({ loading: true });
    await this.questionApi.delete(quizQuestionId);
    await this.getQuiz(this.state.quizId);
    this.setState({ confirmDelete: false });
    this.setState({ loading: false });
  }

  onEdit(obj) {
    const editAnswers = obj.answers?.map(item => ({
      quizQuestionAnswerId: item.quizQuestionAnswerId,
      answerId: item.answerId,
      desc: item.answer?.label,
      description: item.answer?.description,
      isAnswerFinish:
        item.nextQuestionId !== null && item.undefined ? true : false,
      nextQuizQuestionId: item.nextQuestionId,
      nextQuizQuestion: item.nextQuestionId && {
        label: item.nextQuestion?.question?.quest,
        value: item.nextQuestionId
      },
      fragrances: item.fragranceOptions?.map(option => ({
        label: option.fragrance?.name,
        value: option.fragranceId
      }))
    }));

    const editQuestion = {
      quizQuestionId: obj.quizQuestionId,
      questionId: obj.questionId,
      quest: obj.question?.quest,
      type: obj.type,
      answers: editAnswers,
      fragranceId: obj.fragranceId,
      rightAnswer: obj.rightAnswer
    };

    this.setState({ question: { ...editQuestion } });
  }

  handleTreeOnChange = treeData => {
    this.setState({ treeData });
  };

  canDragNode = (node, nextParentNode) => {
    let can = true;
    let nextType = nextParentNode ? nextParentNode.nodeType : null;

    if (node.nodeType === "answer") {
      if (nextType !== "question") can = false;
    } else if (node.nodeType === "question") {
      if (nextType && nextType !== "answer") can = false;
    } else if (node.nodeType === "fragrance") {
      if (nextType !== "answer") can = false;
    }

    return can;
  };

  handleMoveNode = async (treeData, node, nextParentNode) => {
    await this.initializeTranslation();
    if (this.canDragNode(node, nextParentNode)) {
      switch (node.nodeType) {
        case "fragrance":
          try {
            const newQuizQuestionAnswerId = nextParentNode.quizQuestionAnswerId;
            const fragranceOptionId = node.fragranceOptionId;
            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                fragranceOptionId,
                newQuizQuestionAnswerId,
                nodeType: "fragrance"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            Swal.fire({
              title: this.state.translate("defaultMessages.error"),
              text: "Error",
              icon: "error",
              confirmButtonText: "OK"
            });
          }

          this.setState({ loading: false });
          break;
        case "question":
          try {
            const quizQuestionAnswerId = nextParentNode.quizQuestionAnswerId;
            const nextQuestionId = node.quizQuestionId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                quizQuestionAnswerId,
                nextQuestionId,
                nodeType: "question"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            Swal.fire({
              title: this.state.translate("defaultMessages.error"),
              text: "Error",
              icon: "error",
              confirmButtonText: "OK"
            });
          }

          this.setState({ loading: false });

          break;
        case "answer":
          try {
            const newQuizQuestionId = nextParentNode.quizQuestionId;
            const quizQuestionAnswerId = node.quizQuestionAnswerId;

            this.setState({ loading: true });

            const reqBase = { method: "PUT", url: "updateTree" };
            await this.api.makeHttpRequest({
              ...reqBase,
              data: {
                newQuizQuestionId,
                quizQuestionAnswerId,
                nodeType: "answer"
              }
            });

            Swal.fire({
              title: this.state.translate("defaultMessages.success"),
              text: "Tree updated successfuly",
              icon: "success",
              confirmButtonText: "OK"
            });
          } catch (error) {
            Swal.fire({
              title: this.state.translate("defaultMessages.error"),
              text: "Error",
              icon: "error",
              confirmButtonText: "OK"
            });
          }

          this.setState({ loading: false });
          break;
        default:
      }

      return true;
    } else {
      Swal.fire({
        title: this.state.translate("defaultMessages.error"),
        text: "Action does not permitted",
        icon: "error"
      });

      setTimeout(() => {
        this.props.history.go();
      }, 2000);
    }

    return false;
  };

  getAnamnese = async () => {
    try {
      this.setState({ loading: true });
      const type = 1;
      const requestBase = {
        method: "GET",
        url: `anamnese`
      };

      const response = await this.api.makeHttpRequest({ ...requestBase });
      const filterAnamnese = response.filter(value => value.type === type);
    } catch (error) {
      console.error("getAnamnse: ", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  async genericPut(endpoint, msg) {
    await this.initializeTranslation();
    try {
      const { quizId } = this.state;
      const reqBase = { method: "PUT", url: endpoint + "/" + quizId };

      this.setState({ loading: true });

      await this.api.makeHttpRequest({
        ...reqBase
      });

      await this.getQuiz(quizId);
      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: msg,
        icon: "success",
        cancelButtonText: this.state.translate("buttons.cancel")
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire(
        "ERROR",
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.createQuestion"),
        "error"
      );
    }
  }

  async validateQuiz() {
    await this.initializeTranslation();
    await this.genericPut(
      "validate",
      this.state.translate("screens.quiz.messages.validated")
    );
  }

  async publishQuiz() {
    await this.initializeTranslation();
    await this.genericPut(
      "publish",
      this.state.translate("screens.quiz.messages.published")
    );
  }

  toggleConfirmDialog(state) {
    this.setState({ confirmDelete: state });
  }

  checkMandatoryAnamnese = event => {
    const { checked } = event.target;
    this.setState({ mandatoryAnamnese: checked });
  };

  mapModelToState(model) {
    return getStateByModel(model, this.state.clone ? " - Cópia" : "");
  }

  clone = async () => {
    await this.initializeTranslation();
    this.setState({ isLoading: true });
    try {
      const reqBase = { method: "POST" };
      const {
        campaignId,
        language,
        initialInformation,
        maxShots,
        userId,
        type,
        fragranceId,
        quizName,
        questions
      } = this.state;
      const body = {
        name: quizName,
        campaignId,
        language,
        maxShots,
        type,
        fragranceId,
        userId,
        initialInformation,
        questions
      };
      const response = await this.api.makeHttpRequest({
        ...reqBase,
        data: body
      });
      const redirectToEditId = await this.saveQuestions(
        questions,
        response?.quizId
      );
      if (response?.quizId && redirectToEditId) {
        const cloneRedirect = {
          redirect: true,
          to: `/admin/quiz/${response?.quizId}/update`
        };
        this.setState({ redirectClone: cloneRedirect });
      }
      await Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("defaultMessages.saveData"),
        icon: "success",
        confirmButtonText: "OK"
      });
    } catch (e) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.quiz.errors.create"),
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  saveQuestions = async (questions, quizId) => {
    this.setState({ loadingCloneQuestions: true });

    const extractNumber = quest => {
      const match = quest?.match(/^(\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    };

    const sortedQuestions = questions?.sort(
      (a, b) =>
        extractNumber(a?.question?.quest) - extractNumber(b?.question?.quest)
    );

    const totalQuestions = sortedQuestions.length;

    for (let i = 0; i < totalQuestions; i++) {
      const value = sortedQuestions[i];
      const body = {
        answers: this.formatAnswers(value?.answers) || [],
        quest: value?.question?.quest || "",
        questionId: value?.questionId || 0,
        quizId: quizId || 0,
        type: value?.type || "",
        fragranceId: value?.fragranceId || null,
        rightAnswer: value?.rightAnswer || null,
        quizQuestionId: null
      };

      await this.questionApi.makeHttpRequest({
        method: "POST",
        url: "/add-update",
        data: body
      });

      this.setState({
        progressCloneQuestions: ((i + 1) / totalQuestions) * 100
      });
    }

    this.setState({ loadingCloneQuestions: false });

    return true;
  };

  formatAnswers = answers => {
    return answers?.map(answer => ({
      answerId: answer?.answerId || 0,
      desc: answer?.desc || "",
      description: answer?.description || 0,
      fragrances: answer?.fragrances || [],
      isAnswerFinish: answer?.isAnswerFinish ?? false,
      nextQuestion: answer?.nextQuestion || null,
      nextQuestionId: answer?.nextQuestionId || null,
      quizQuestionAnswerId: null
    }));
  };

  render() {
    const translate = this.context;
    window.setPageTitle(
      `${translate("screens.quiz.pageTitle.edit")} - ${translate(
        "labels_admin"
      )}`
    );

    const {
      quizName,
      campaignName,
      language,
      questions,
      question,
      answer,
      collection,
      answerSelectedIndex,
      requiredQuestion,
      requiredAnswers,
      requiredAnswer,
      requiredNextQuestion,
      requiredFragrance,
      validatedAt,
      publishedAt,
      maxShots,
      userId,
      type,
      resetInput,
      anamneseId,
      mandatoryAnamnese,
      progressCloneQuestions,
      loadingCloneQuestions
    } = this.state;

    const fragrancesOptions = collection
      .map(e => ({
        label: `${e.slot} - ${e.fragrance?.name}`,
        value: e.fragrance?.fragranceId,
        slot: e.slot
      }))
      .filter(e => {
        let found = false;
        for (var i = 0; i < answer.fragrances.length; i++) {
          if (
            answer.fragrances[i].label === e.label &&
            answer.fragrances[i].value === e.value
          ) {
            found = true;
            break;
          }
        }
        if (!found) return e;
      })
      .sort((a, b) => a.slot - b.slot);

    const enableEditQuiz = false;

    const selectTheTypeQuiz = [
      { key: 1, name: translate("screens.quiz.labels.identification") },
      // { key: 2, name: "Anamnese" }, // não descomentar, pois é usado no formulario de Anamnese
      { key: 3, name: translate("screens.quiz.labels.discrimination") },
      { key: 5, name: translate("labels_ratings") }
    ];
    console.log("Question: ", questions);
    return (
      <>
        <ConfirmDeleteDialog
          open={this.state.confirmDelete}
          closeDialog={() => this.toggleConfirmDialog(false)}
          confirm={() => this.onConfirmDelete(this.state.id)}
        />
        <Form>
          <Loading
            isLoading={this.state.loading || loadingCloneQuestions}
            progress={progressCloneQuestions}
            msg={loadingCloneQuestions && "Duplicando Perguntas..."}
          />
          {/* Inicio Card 1 - Quiz*/}
          <Card className="mt-3">
            <Card.Header>
              {translate("screens.quiz.labels.principal")}
            </Card.Header>
            {/* formulario de Quiz */}
            <Card.Body>
              {/* row 1 */}
              <Row>
                {/* Nome */}
                <Form.Group as={Col} lg="3" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate("screens.quiz.labels.name")} *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="quizName"
                    placeholder={translate("screens.quiz.placeholders.name")}
                    maxLength="100"
                    value={quizName}
                    isInvalid={quizName && !IsValidName(quizName)}
                    onChange={this.handleChange}
                    required
                    disabled={enableEditQuiz}
                  />
                </Form.Group>

                {/* Campanha */}
                <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate("screens.quiz.labels.campaign")} *
                  </Form.Label>
                  <SelectSearch
                    resetInput={resetInput}
                    required={true}
                    handleChange={e => this.handleChangeSelect(e)}
                    className="kt-width-full"
                    label={translate("screens.quiz.placeholders.campaign")}
                    url={`/campaigns/without-quiz?str=`}
                    value={{ label: campaignName }}
                    convertObject={obj => ({
                      id: obj.campaignId,
                      value: obj.campaignId,
                      label: `${obj.name}`,
                      obj
                    })}
                  />
                </Form.Group>

                {/* Nome */}
                <Form.Group as={Col} lg="3" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate("screens.quiz.labels.language")}
                  </Form.Label>
                  {/* <Form.Control>
                  </Form.Control> */}
                  <SelectLanguage
                    handleChangeLanguage={this.handleChangeLanguage}
                    languageValue={language}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} lg="2" xs="12" className="mb-0 pt-2">
                  <Form.Label>
                    {translate("screens.quiz.maxShotLabel")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    autoComplete="off"
                    name="maxShots"
                    placeholder={translate("screens.quiz.maxShotLabel")}
                    maxLength="100"
                    value={maxShots}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>

                <InputSelect
                  label={translate("screens.fragrance.labels.type")}
                  name="type"
                  handleChange={this.handleChange}
                  selectData={selectTheTypeQuiz}
                  value={type}
                  validator={this.props.validator}
                  validations={"required"}
                  as={Col}
                  lg="3"
                  xs="12"
                  className="mb-0 pt-2"
                />
                <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
                  <StrictMode>
                    <TransferList
                      endPoint={`usuario`}
                      value={userId}
                      handleMultiSelect={this.handleChangeSelectMulti}
                    />
                  </StrictMode>
                </Form.Group>
                {/* SelectAnamnese */}
                <Form.Group as={Col} lg="3" xs="12" className="mb-0 pt-2">
                  <NewSelectItem
                    url="quiz/anamnese"
                    placeholder={translate(
                      "screens.quiz.labels.selectAnamnesis"
                    )}
                    value={anamneseId}
                    onSelect={value => {
                      this.setState({ anamneseId: value });
                    }}
                  />
                </Form.Group>
                {anamneseId ? (
                  <Form.Group as={Col} lg="3" xs="12" className="mb-0 pt-4">
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Checkbox
                        color="success"
                        value={mandatoryAnamnese}
                        checked={mandatoryAnamnese}
                        onChange={this.checkMandatoryAnamnese}
                      />
                      <Typography>
                        {translate("screens.quiz.labels.anamnesisMandatory")}
                      </Typography>
                    </Stack>
                  </Form.Group>
                ) : null}
              </Row>
            </Card.Body>
            <Card.Footer
              style={{
                backgroundColor: "#fff"
              }}
              // className="border-0"
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={this.updateQuiz}
                  // className="mt-1"
                  style={{ width: "100%" }}
                >
                  {translate("buttons.edit")}
                </Button>
              </Stack>
            </Card.Footer>
          </Card>
          {/* Fim Card 1 - Quiz*/}
          {/* Inicio Card 2 */}
          <Card className="mt-3">
            <Card.Header>
              {translate("screens.quiz.labels.questionarie")}
            </Card.Header>
            <Card.Body>
              <Form.Group as={Col} lg="12" xs="12" className="mt-3">
                <Row>
                  <Form.Group as={Col} lg="12" xs="12" className="mb-5">
                    {/* 1 */}
                    <Row>
                      {/* Form.Group para o tipo de pergunta */}
                      <InputSelect
                        label={translate("screenApp.quiz.questionType")}
                        name="type"
                        handleChange={this.handleQuestionType}
                        selectData={selectInput}
                        value={question.type}
                        validator={this.props.validator}
                        validations={"required"}
                        as={Col}
                        lg="2"
                        xs="12"
                        className="mb-1"
                      />
                      {/* fragrancia para question */
                      type === "3" ? null : (
                        <Form.Group as={Col} lg="10" xs="12" className="mb-1">
                          <Form.Label>
                            {translate(
                              "screens.fragrance.questionReferenceFragrance"
                            )}
                          </Form.Label>
                          <Select
                            // isMulti
                            placeholder={translate(
                              "screens.quiz.placeholders.fragrances"
                            )}
                            noOptionsMessage={obj =>
                              obj?.inputValue
                                ? obj?.inputValue
                                : translate(
                                    "screens.quiz.labels.noFragranceOption"
                                  )
                            }
                            // value={question.fragranceId}
                            value={
                              question.questionId && {
                                id: question.fragranceId,
                                value: question.fragranceId,
                                label: question.fragranceId
                              }
                            }
                            name="fragrancesSelected"
                            options={fragrancesOptions}
                            // className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleSelectFragranceAnswer}
                          />
                          {answer.isAnswerFinish && requiredFragrance ? (
                            <Form.Label style={{ color: "#FF0000" }}>
                              {translate(
                                "screens.quiz.errors.fragranceRequired"
                              )}
                            </Form.Label>
                          ) : null}
                        </Form.Group>
                      )}
                      <Form.Group
                        as={Col}
                        lg={type === "1" ? "12" : "10"}
                        xs="12"
                        className="mb-1"
                      >
                        <Form.Label>
                          {translate("screens.quiz.labels.question")}
                        </Form.Label>
                        <SelectSearch
                          value={
                            question.questionId && {
                              id: question.questionId,
                              value: question.questionId,
                              label: question.quest
                            }
                          }
                          required={
                            requiredQuestion ? { status: "invalid" } : null
                          }
                          resetInput={question.quest === ""}
                          handleChange={this.handleQuestion}
                          name="question"
                          className="kt-width-full"
                          label={translate(
                            "screens.quiz.placeholders.question"
                          )}
                          url={`question/by-name?language=${language}&str=`}
                          convertObject={obj => ({
                            id: obj.questionId,
                            value: obj.questionId,
                            label: obj.quest
                          })}
                          createLabelText={translate(
                            "screens.quiz.placeholders.newQuestion"
                          )}
                          newOption={true}
                        />
                        {requiredQuestion ? (
                          <Form.Label style={{ color: "#FF0000" }}>
                            {translate("screens.quiz.errors.questionRequired")}
                          </Form.Label>
                        ) : null}
                      </Form.Group>
                    </Row>
                    <Divider
                      variant="fullWidth"
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        backgroundColor: "#ccc"
                      }}
                    />
                    {/* 2 Resposta */}
                    <Row>
                      {/* Form.Group para a resposta */}
                      <Form.Group
                        as={Col}
                        lg={type === "3" ? "6" : "10"}
                        xs="12"
                        className="mb-1"
                      >
                        <Form.Label>
                          {translate("screens.quiz.labels.answer")}
                        </Form.Label>
                        <SelectSearch
                          resetInput={answer.desc === ""}
                          handleChange={this.handleAnswer}
                          name="answer"
                          className="kt-width-full"
                          label={translate("screens.quiz.placeholders.answer")}
                          url={`answer/by-name?language=${language}&str=`}
                          convertObject={obj => ({
                            id: obj.answerId,
                            value: obj.answerId,
                            label: obj.label,
                            description: obj?.description
                          })}
                          newOption={true}
                          createLabelText={translate(
                            "screens.quiz.placeholders.newAnswer"
                          )}
                          setNewOption={v => console.log("setNewOption: ", v)}
                        />
                        {requiredAnswer ? (
                          <Form.Label style={{ color: "#FF0000" }}>
                            {translate("screens.quiz.errors.answerRequired")}
                          </Form.Label>
                        ) : null}
                      </Form.Group>

                      {type === "3" ? (
                        <Form.Group as={Col} lg="4" xs="12" className="mb-1">
                          <Form.Label>
                            {translate(
                              "screens.fragrance.answerReferenceFragrance"
                            )}
                          </Form.Label>
                          <Select
                            // isMulti
                            placeholder={translate(
                              "screens.quiz.placeholders.fragrances"
                            )}
                            noOptionsMessage={obj =>
                              obj?.inputValue
                                ? obj?.inputValue
                                : translate(
                                    "screens.quiz.labels.noFragranceOption"
                                  )
                            }
                            // value={question.fragranceId}
                            value={
                              parseInt(answer.description) && {
                                id: parseInt(answer.description),
                                value: parseInt(answer.description),
                                label: formatFragrances(
                                  parseInt(answer.description),
                                  this.state.collection
                                )
                              }
                            }
                            name="fragrancesSelected"
                            options={fragrancesOptions}
                            // className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleDescription}
                          />
                          {answer.isAnswerFinish && requiredFragrance ? (
                            <Form.Label style={{ color: "#FF0000" }}>
                              {translate(
                                "screens.quiz.errors.fragranceRequired"
                              )}
                            </Form.Label>
                          ) : null}
                        </Form.Group>
                      ) : null}

                      {/* Form.Group para o botão de adicionar resposta */}
                      <Form.Group as={Col} lg="2" xs="12" className="mb-0 pt-2">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={this.addAnswer}
                          className="mt-4"
                        >
                          {answerSelectedIndex !== null
                            ? translate("screens.quiz.labels.updateAnswer")
                            : translate("screens.quiz.labels.addAnswer")}
                        </Button>
                      </Form.Group>
                    </Row>

                    {/* 3 */}
                    <Row>
                      <Form.Group as={Col} lg="6" xs="12" className="mb-1">
                        <Form.Label>
                          {translate("screenApp.results.rightResponse")}
                        </Form.Label>
                        <Form.Control
                          value={question?.rightAnswer || ""}
                          name="rightAnswer"
                          onChange={this.handleChangeAnswerDesc}
                          as="select"
                        >
                          <option value="">
                            {translate("screenApp.quiz.selectOption")}
                          </option>
                          {question?.answers?.map(p => (
                            <option
                              key={p.desc}
                              value={p.desc}
                              selected={p.desc === question?.rightAnswer}
                            >
                              {p.desc}
                              {" / "}
                              {formatFragrances(p.description, collection)}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Row>
                  </Form.Group>
                </Row>
                {/* inicio da Tabela */}

                <TableAnswers
                  answers={question.answers}
                  clearAnswer={a => this.clearAnswer(a)}
                  collection={collection}
                  editAnswer={(a, i) => this.editAnswer(a, i)}
                  requiredAnswers={requiredAnswers}
                  formType={type}
                >
                  <ButtonsQuiz
                    createQuestion={this.createQuestion}
                    onCleanForm={this.onCleanForm}
                    publishQuiz={this.publishQuiz}
                    publishedAt={publishedAt}
                    question={question}
                    questions={questions}
                    validateQuiz={this.validateQuiz}
                    validatedAt={validatedAt}
                  />
                </TableAnswers>
              </Form.Group>

              <TableQuestions
                collection={collection}
                onConfirmDelete={this.onConfirmDelete}
                onEdit={this.onEdit}
                questions={questions}
                formType={type}
              />
            </Card.Body>
          </Card>
        </Form>
        {this.state.redirectClone.redirect && (
          <Redirect to={this.state.redirectClone.to} />
        )}
      </>
    );
  }
}

const mapStoreToProps = store => ({
  i18n: store.i18n
});

const connected = connect(mapStoreToProps, null)(QuizUpdate);

export default withRouter(withFormValidation(connected, initialState));

import * as React from "react";
import { Typography } from "@mui/material";

import packageJson from "../../package.json";
import { useTranslation } from "_metronic/i18n/language";

const VersionApp = () => {
  const version = packageJson.version;
  const translate = useTranslation();

  return <Typography textAlign={"center"}>{translate("screenApp.version")}: {version}</Typography>;
};
export default VersionApp;

import Cosmic from 'cosmicjs'
import { setData, getData } from '../utils/CacheStorage';

const api = Cosmic();
const bucket = api.bucket({
  slug: process.env.REACT_APP_COSMICJS_BUCKET_SLUG,
  read_key: process.env.REACT_APP_COSMICJS_BUCKET_READ_KEY,
})

class CMSService {
  static getContentFromYear(slug, props = 'slug,title,content', cached = true) {
    slug = `${process.env.REACT_APP_ENEF_YEAR}-${slug}`;
    return this.getContent(slug, props, cached);
  }

  static getMediaFromYear(folder, props = 'url') {
    folder = `${process.env.REACT_APP_ENEF_YEAR}-${folder}`;
    return this.getMedia(folder, props);
  }

  static async getContent(slug, props = 'slug,title,content', cached = true) {
    let response = cached
      ? getData(slug)
      : null;

    if (response) {
      return JSON.parse(response);
    }

    response = await bucket.getObject({
      slug,
      props
    });

    if (cached) {
      setData(slug, JSON.stringify(response.object));
    }

    return response.object;
  }

  static async getMedia(folder, props = 'url') {
    const response = await bucket.getMedia({
      folder,
      props
    });

    return response.media && response.media.length === 1
      ? response.media[0]
      : response.media;
  }
}

export default CMSService;

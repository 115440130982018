import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

import AuthPage from '../auth/AuthPage';

export default function AdminPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route exact path='/' component={AuthPage} />                                   {/** Inicio */}
      </Switch>
    </Suspense>
  );
}

import React from "react";
import Chart from "react-apexcharts";
import { Card, CardContent, CardHeader } from "@mui/material";
import { ApexOptions } from "apexcharts";

interface DoughnutChartComponentProps {
  data: { name: string; value: number; fill: string }[];
  title: string;
}

const DoughnutChartComponent: React.FC<DoughnutChartComponentProps> = ({
  data,
  title
}) => {
  const chartOptions: ApexOptions = {
    chart: {
      type: "donut"
    },
    labels: data.map(d => d.name),
    colors: data.map(d => d.fill),
    legend: {
      position: "bottom",
      labels: {
        colors: data.map(d => d.fill)
      }
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${value}`
      }
    }
  };

  const chartSeries = data.map(d => d.value);

  return (
    <Card style={{ height: 390 }}>
      <CardHeader title={title} />
      <CardContent>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
          height="300"
        />
      </CardContent>
    </Card>
  );
};

export default DoughnutChartComponent;

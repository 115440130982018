import React from "react";
import Button from "@mui/material/Button/";
import Dialog from "@mui/material/Dialog/";
import DialogActions from "@mui/material/DialogActions/";
import DialogContent from "@mui/material/DialogContent/";
import DialogContentText from "@mui/material/DialogContentText/";
import DialogTitle from "@mui/material/DialogTitle/";
import { useTranslation } from "_metronic/i18n/language";

export default function ConfirmChangeStatusDialog({
  open,
  closeDialog,
  confirm,
  title,
  message
}) {
  const translate = useTranslation();
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title && title !== "" ? title : translate("changeStatus.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && message !== ""
              ? message
              : translate("changeStatus.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="warning"
            onClick={closeDialog}
            style={{ color: "#fff" }}
          >
            {translate("buttons.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={confirm}
            color="success"
            autoFocus
            style={{ color: "#fff" }}
          >
            {translate("buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

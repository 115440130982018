import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import { useState } from "react";
import { AnanseApiService } from "../../../services/Ananse/AnanseApiService";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import Loading from "../../home/components/Loading";
import ModalComponent from "../Components/ModalComponent";
import { SuccessMessage } from "./style";
import { useRTL } from "themes/RTLProvider/RTLProvider";

const ForgotPassword = () => {
  const translate = useTranslation();
  const isRtl = useRTL();
  const [forgetPassModal, setForgetPassModal] = useState(false);
  const [email, setEmail] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const emailValidation = async () => {
    if (email === "") {
      setError(translate("screens.forgotPassword.error.invalidEmailText"));
      setDisabledButton(false);
      return false;
    }

    if (!IsValidEmail(email)) {
      setError(translate("screens.forgotPassword.error.invalidEmailTitle"));
      setDisabledButton(false);
      return false;
    }

    setError("");
    return true;
  };

  const closeBothModals = () => {
    setSuccess(false);
    onCloseModal();
  };

  const sendEmail = async (email: string) => {
    setIsLoading(true);
    const api = new AnanseApiService();
    return await api.makeHttpRequest({
      url: "/usuario/recuperar-senha",
      method: "POST",
      data: {
        email
      }
    });
  };

  const handleEmailSend = async () => {
    setDisabledButton(true);
    if ((await emailValidation()) === false) return;

    const successOnEmailSend = () => {
      setIsLoading(false);
      setError("");
      setSuccess(true);
    };

    const errorOnEmailSend = () => {
      setIsLoading(false);
      setError(translate("screens.forgotPassword.error.relatedEmail"));
      return false;
    };

    const sender = sendEmail(email);
    sender
      .then(successOnEmailSend)
      .catch(errorOnEmailSend)
      .finally(() => setDisabledButton(false));
  };

  const onCloseModal = () => setForgetPassModal(false);

  return (
    <>
      <Button
        style={{ flex: 1 }}
        type="button"
        // variant="outlined"
        color="success"
        onClick={() => setForgetPassModal(true)}
      >
        {translate("screens.login.forgetPassword")}
      </Button>
      <ModalComponent open={forgetPassModal} container>
        <Loading isLoading={isLoading} />
        <Box>
          <Typography mt={1} align="center" variant="h4">
            {translate("screens.forgotPassword.forgotTitle")}
          </Typography>
          <Typography mt={2}>
            {translate("screens.forgotPassword.forgotSubTitle")}
          </Typography>

          <TextField
            type="email"
            margin="normal"
            label={translate("screens.forgotPassword.emailInput")}
            // placeholder={translate("screens.forgotPassword.emailInput")}
            value={email}
            error={error !== "" ? true : false}
            onChange={handleEmailChange}
            autoFocus={true}
            helperText={error !== "" && error}
            sx={{direction: "ltr"}}
          />
          <Stack
            sx={{ marginTop: "10px", direction: "ltr"}}
            justifyContent="center"
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
          >
            <Button
              style={{ flex: 1, marginRight: isRtl ? 5 : 0 }}
              type="button"
              variant="outlined"
              color="success"
              disabled={disabledButton}
              onClick={onCloseModal}
            >
              {translate("buttons.cancel")}
            </Button>
            <Button
              style={{ flex: 1 }}
              type="button"
              variant="contained"
              color="success"
              disabled={disabledButton}
              onClick={handleEmailSend}
            >
              {translate("buttons.send")}
            </Button>
          </Stack>
        </Box>

        <Modal
          open={success}
          onClose={closeBothModals}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <SuccessMessage>
            <span>{translate("screens.forgotPassword.success.message")}</span>
          </SuccessMessage>
        </Modal>
      </ModalComponent>
    </>
  );
};

export default ForgotPassword;

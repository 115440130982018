import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { DeviceType } from "app/types";

const api = new AnanseApiService();

export async function getDevice(macAddress: string): Promise<DeviceType> {
  const data = await api.makeHttpRequest({
    method: "GET",
    url: `/devices/mac/${macAddress}`
  });
  return {
    deviceId: data.deviceId,
    macAddress: data.macAddress,
    connectedUser: data.user.nome,
    campaignId: data.campaignId,
    ownerId: data.ownerId,
    isDisplay: data.isDisplay
  };
}

export async function getDeviceById(deviceId: number): Promise<DeviceType> {
  const response = await api.makeHttpRequest({
    method: "GET",
    url: `capsules/device/${deviceId}`
  })
  const data = response[0].device;
  return {
    deviceId: data.deviceId,
    macAddress: data.macAddress,
    connectedUser: data.user?.nome || "",
    campaignId: data.campaignId,
    ownerId: data.ownerId || "",
    isDisplay: data.isDisplay || ""
  };

}

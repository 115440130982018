import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "@mui/material";
class headerButton extends React.Component {
  render() {
    const {
      label,
      onClick,
      path,
      icone,
      disabled,
      color,
      isDarkMode
    } = this.props;
    return (
      <div>
        {path ? (
          <Link
            to={path}
            style={{
              marginRight: "5px"
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: isDarkMode ? "#121212" : "#FFF",
                color: isDarkMode ? "#FFF" : "#121212"
              }}
              className="kt-button-adicionar"
              disabled={disabled}
            >
              <Icon className="mr-2 kt-icons">{icone ? icone : "add"}</Icon>{" "}
              {label ? label : "Adicionar"}
            </Button>
          </Link>
        ) : (
          <div style={{ marginRight: "5px" }}>
            <Button
              variant="contained"
              color={color}
              style={{ backgroundColor: isDarkMode ? "#121212" : "#FFF" }}
              className="kt-button-adicionar"
              disabled={disabled}
              onClick={onClick}
            >
              <Icon className="mr-2 kt-icons">{icone ? icone : "add"}</Icon>{" "}
              {label ? label : "Adicionar"}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default headerButton;

import React, { useState, useEffect } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  Stack,
  Checkbox,
  Typography,
  Grid
} from "@mui/material";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { Spinner } from "reactstrap";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface IConvertData {
  id: number;
  value: number;
  label: string;
}

interface Props {
  endPoint: string;
  label: string;
  handleChange?: any;
  convertObject?: any;
  pagination?: boolean;
  value?: any;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  multiple?: boolean;
  disabled?: boolean;
  showTotal?: boolean;
}
const ExampleComponent = ({
  convertObject,
  endPoint,
  handleChange,
  label,
  pagination,
  value,
  setLoading,
  multiple,
  disabled,
  showTotal
}: Props) => {
  const translate = useTranslation();
  const isRtl = useRTL();
  const textAlignIfRtl = isRtl ? "right" : "left";
  const [data, setData] = React.useState<Array<IConvertData>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingData, setLoadingData] = useState(false);

  const api = new AnanseApiService();
  useEffect(() => {
    fetchData();
  }, [currentPage, perPage]);

  const fetchData = async () => {
    setLoadingData(true);
    try {
      const searchedList: Array<IConvertData> = [];
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: endPoint
      });
      const response = await api.makeHttpRequest({
        method: "GET",
        url: !pagination
          ? showTotal
            ? `${endPoint}?PerPage=${total}`
            : endPoint
          : `${endPoint}&Page=${currentPage}&PerPage=${
              showTotal ? total : perPage
            }`
      });
      response.data.forEach((item: any) => {
        const converted = convertObject(item);
        if (searchedList.indexOf(converted) === -1) {
          searchedList.push(converted);
        }
      });
      setData(searchedList.sort((a, b) => a.label.localeCompare(b.label)));
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handlecharge = (event: any) => {
    if (multiple) {
      const {
        target: { value }
      } = event;
      handleChange(value);
      return;
    }
    const filter = data.find(item => item.id === event.target.value);
    handleChange(filter);
  };

  const handlePageChange = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const handlePerPageChange = (event: any) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div>
      <Stack spacing={2}>
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value ?? 0}
          onChange={handlecharge}
          multiple={multiple}
          disabled={disabled}
        >
          <MenuItem value={0} disabled={value}>
            {loadingData ? (
              <Grid
                container
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Spinner size="sm"/>
                </Grid>
                <Grid item>
                  <Typography>
                    {translate("screens.loading.title")}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <span
                style={{
                  textAlign: textAlignIfRtl,
                  width: "100%"
                }}
              >
                {label}
              </span>
            )}
          </MenuItem>
          {data.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.id}
              selected={item.id === value}
              disabled={disabled}
              style={{
                backgroundColor: item.id === value ? "#ccc" : "transparent"
              }}
            >
              {multiple && (
                <Checkbox
                  color="default"
                  checked={value.indexOf(item.id) > -1}
                  disabled={disabled}
                />
              )}
              <span
                style={{
                  textAlign: textAlignIfRtl,
                  width: "100%"
                }}
              >
                {item.label}
              </span>
            </MenuItem>
          ))}
        </Select>
        {pagination && !showTotal && (
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            <Select
              autoWidth
              label="Age"
              value=""
              onChange={handlePerPageChange}
            >
              <MenuItem value={5}>5 {translate("labels_per_page")}</MenuItem>
              <MenuItem value={10}>10 {translate("labels_per_page")}</MenuItem>
              <MenuItem value={20}>20 {translate("labels_per_page")}</MenuItem>
            </Select>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default ExampleComponent;

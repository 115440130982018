import { useTranslation } from "_metronic/i18n/language";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from "recharts";

interface AverageData {
  faixaEtaria: string;
  mediaAcertos: number;
}

interface BarChartFaixaEtariaProps {
  data: AverageData[];
}

const BarChartFaixaEtaria: React.FC<BarChartFaixaEtariaProps> = ({ data }) => {
  const translate = useTranslation();

  const formatValue = (value: number): string => {
    return value.toLocaleString("pt-BR");
  };

  const sortedData = data.sort((a, b) => {
    const rangeA = a.faixaEtaria.split(" - ").map(Number);
    const rangeB = b.faixaEtaria.split(" - ").map(Number);
    return rangeA[0] - rangeB[0];
  });
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={sortedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="faixaEtaria" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="mediaAcertos"
          fill="#1e90ff"
          name={translate("labels_hit_average")}
          barSize={40}
        >
          <LabelList
            dataKey="mediaAcertos"
            position="top"
            style={{ fill: "#000", fontWeight: "bold" }}
            formatter={formatValue}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartFaixaEtaria;

import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import FormatDocument from "../../../../utils/FormatDocument";
import { TableRow, TableCell } from "@mui/material";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import countriesBR from "../../components/Data/countriesBR.json";
import countriesEN from "../../components/Data/countriesEN.json";
import { format, addHours } from "date-fns";
import Loading from "../../../home/components/Loading";
import store from "../../../../store/store";
import { TranslationContext } from "_metronic/i18n/language";

export default class UsersListing extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.api = new AnanseApiService();

    this.state = {
      users: [],
      reload: false,
      loading: false,
      translate: null,
      isActive: "true"
    };

    this.initializeTranslation();
    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.handleSendConfirmationMail = this.handleSendConfirmationMail.bind(
      this
    );
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setUsers = this.setUsers.bind(this);
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  handleConfirmDelete = async function(id) {
    await this.api.makeHttpRequest({
      method: "DELETE",
      url: `/usuario/${id}`
    });

    this.setState({ reload: !this.state.reload });
  };

  handleConfirmChangeStatus = async function(obj) {
    await this.initializeTranslation();
    try {
      const data = {
        grouper: obj.grouper,
        ativo: obj.deletedAt ? true : false,
        grouperId: obj.grouperId
      };
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/usuario/${obj.id}`,
        data
      });
      this.setState({ reload: !this.state.reload });
    } catch (e) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        this.state.translate("defaultMessages.errorChangeStatus"),
        "error"
      );
    }
  };

  handleSendConfirmationMail = async function(obj) {
    await this.initializeTranslation();
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/usuario/resend-mail/${obj.id}`
      });

      Swal.fire({
        title: this.state.translate("defaultMessages.success"),
        text: this.state.translate("defaultMessages.sendMail"),
        icon: "success",
        cancelButtonText: this.state.translate("buttons.exit")
      });
    } catch (e) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        this.state.translate("defaultMessages.errorSendMail"),
        "error"
      );
    }
  };

  setUsers(users) {
    this.setState({ users });
  }

  formatAddress(address) {
    let str = "";

    if (address.street) str += address.street;

    if (address.city) str += ` ${address.city}`;

    if (address.state) str += ` ${address.state}`;

    if (address.zipcode) str += ` ${address.zipcode}`;

    if (address.country) {
      let country = "";
      if (this.props.lang === "pt") {
        country = countriesBR.find(data => data.code === address.country);
      } else {
        country = countriesEN.find(data => data.code === address.country);
      }
      str += ` ${country && country.name ? country?.name : ""}`;
    }

    return str;
  }

  formatRow(r) {
    const { auth } = store.getState();
    const isDiffAdmin =
      auth && auth.user && auth.user.profile.nome.toUpperCase() !== "ADMIN";
    const crudActions = [];
    if (r.confirmedAt && !isDiffAdmin) {
      crudActions.push("toggle", "edit");
    } else if (!r.confirmedAt && !isDiffAdmin) {
      crudActions.push(...["resend", "delete", "edit"]);
    } else {
      crudActions.push(...["resend"]);
      if (r.id === auth.user.id) {
        crudActions.push("edit");
      }
    }

    return (
      <TableRow hover tabIndex={-1} key={r.id}>
        <TableCell>
          <CrudActions
            actions={crudActions}
            module="users"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            onConfirmResendEmail={this.handleSendConfirmationMail}
            onConfirmDelete={this.handleConfirmDelete}
            id={r.id}
            obj={r}
          />
        </TableCell>
        <TableCell scope="row">{r.nome}</TableCell>
        <TableCell scope="row">{r.email}</TableCell>
        <TableCell scope="row">
          {r.cpf ? FormatDocument(r.cpf) : r.document}
        </TableCell>
        <TableCell scope="row">{r.genre}</TableCell>
        {/* <TableCell scope="row">
          {r.birthday
            ? format(addHours(new Date(r.birthday), 5), "dd/MM/yyyy")
            : null}
        </TableCell> */}
        <TableCell scope="row">{r.profile && r.profile.nome}</TableCell>
        {/* <TableCell scope="row">
          {r.address ? this.formatAddress(r.address) : null}
        </TableCell>
        <TableCell scope="row">
          {!r.deletedAt
            ? this.props.translate.defaultMessages.active
            : this.props.translate.defaultMessages.inactive}
        </TableCell> */}
        <TableCell scope="row">
          {format(new Date(r.createdAt), "dd/MM/yyyy")}
        </TableCell>
        <TableCell scope="row">
          {r.nextPaymentDate
            ? format(new Date(r.nextPaymentDate), "dd/MM/yyyy")
            : null}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const translate = this.context;
    window.setPageTitle(`${translate("screens.user.list.title")} - ${translate("labels_admin")}`);

    const headRows = [
      { label: translate("screens.dashboard.action") },
      { column: "Nome", label: translate("screens.user.list.name") },
      { column: "Email", label: translate("screens.user.list.email") },
      { column: "Documento", label: translate("screens.user.list.document") },
      { column: "Genero", label: translate("screens.user.list.genre") },
      // {
      //   column: "Data de nascimento",
      //   label: this.props.translate.screens.user.list.birthday
      // },
      {
        column: "Profile.Nome",
        label: translate("screens.user.list.profile")
      },
      // {
      //   column: "Endereco",
      //   label: this.props.translate.screens.user.list.address
      // },
      // { column: "Ativo", label: this.props.translate.screens.user.list.status },
      {column: "DataCriacao", label: translate('screens.user.list.created')},
      {column: "nextPaymentDate", label: translate('screenApp.createAccount.profile.nextPaymentDate')}
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title={translate('screens.user.list.title')}
          endpoint="/usuario"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.users}
          setRows={this.setUsers}
          reload={this.state.reload}
          placeholderSearch={translate('screens.user.placeholders.search')}
          mobile={AdminDropdown}
          width={this.props.width}
          isActive={this.state.isActive}
          buttons={[
            {
              label: translate('datatable.add'),
              onClick: null,
              icone: "add",
              path: "/admin/users/create"
            },
            {
              label: translate('datatable.export'),
              endpoint: "/export",
              icone: this.props.width >= 768 ? "archive" : "download"
            }
          ]}
        />
      </>
    );
  }
}

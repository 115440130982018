import { useSelector } from "react-redux";
import GraphicTest from "assets/images/graphic.png";
import { useTranslation } from "_metronic/i18n/language";

const TableDescription = ({ refDescription, printA, printB }) => {
  const translate = useTranslation();

  const data = [
    {
      title: translate("screenApp.results.details.average"),
      value: "17,38"
    },
    {
      title: translate("screenApp.results.details.standardDeviation"),
      value: "2,24"
    },
    { title: translate("screenApp.results.details.median"), value: "18" },
    { title: translate("screenApp.results.details.minimum"), value: "5" },
    { title: translate("screenApp.results.details.maximum"), value: "20" },
    {
      title: translate("screenApp.results.details.percentile") + " 10%",
      value: "15"
    },
    {
      title: translate("screenApp.results.details.percentile") + " 5%",
      value: "13"
    },
    { title: translate("screenApp.results.details.n"), value: "1.765" }
  ];

  const dataClassification = [
    {
      title: translate("screenApp.results.details.normosmia"),
      value: ">= 15",
      description: translate("screenApp.results.details.normosmiaDescription")
    },
    {
      title: translate("screenApp.results.details.hyposmia"),
      value: "11 - 14",
      description: translate("screenApp.results.details.hyposmiaDescription")
    },
    {
      title: translate("screenApp.results.details.anosmia"),
      value: "<= 10",
      description: translate("screenApp.results.details.anosmiaDescription")
    }
  ];

  return (
    <>
      <div className="footerDescription" style={{color: '#000', background: '#FFF'}}>
        <div className="meio">
          {printB && (
            <>
              <div className="page-break" />
              <div className="tableClassification">
                <table className="tableDescription">
                  <tr className="tableDescription">
                    <th className="tableDescription">
                      {translate("screenApp.results.details.totalHits")}
                    </th>
                  </tr>
                  {data.map((item, index) => (
                    <tr key={index} className="tableDescription">
                      <td className="tableDescription">{item.title}</td>
                      <td className="tableDescription">{item.value}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </>
          )}
          {printA && (
            <div ref={refDescription} className="tableClassification">
              <table className="tableDescription">
                {dataClassification.map((item, index) => (
                  <tr key={index} className="tableDescription">
                    <th className="tableDescription">
                      {item.value} {item.description}
                    </th>
                  </tr>
                ))}
              </table>
              <table className="tableObs">
                <tr>
                  <td className="tableObs">
                    {translate("screenApp.results.details.text")}
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div>
        {printB && (
          <div className="imgDescription">
            <div>
              <img
                className="graphicDescription"
                src={GraphicTest}
                alt="GraphicTest"
              />
              <p style={{ textAlign: "center" }}>
                {translate("screenApp.results.details.totalHits")}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TableDescription;

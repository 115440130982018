import React from "react";
import { Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { format, addHours } from "date-fns";

import FormActions from "../../components/FormActions";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import verifyCpfCnpj from "../../../../utils/validators/IsValidCpfCnpj";
import verifyCEP from "../../../../utils/validators/IsValidCEP";
import verifyBirthday from "../../../../utils/validators/IsValidBirthday";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import Loading from "../../../home/components/Loading";
import withFormValidation from "../../../../hoc/withFormValidation";
import countriesBR from "../../components/Data/countriesBR.json";
import countriesEN from "../../components/Data/countriesEN.json";
import GroupForm from "components/GroupForm";
import { TranslationContext } from "_metronic/i18n/language";
import { userTypesData } from "./mock";
import { Wrapper } from "./components/Wrapper";
import { FormInput } from "./components/FormInput";
import { InputCheckbox } from "components/InputCheckbox";

const initialState = {
  name: "",
  tin: "",
  document: "",
  profile: "",
  profiles: [],
  email: "",
  mobilePhone: "",
  resetInput: false,
  isLoading: false,
  newOption: false,
  showCities: false,
  showRegions: false,
  deletedAt: null,
  birthday: "",
  genre: "",
  genres: [],
  zipcode: "",
  street: "",
  city: "",
  cityLabel: "",
  regions: [],
  region: "",
  regionLabel: "",
  cities: [],
  regionCode: "",
  country: "",
  countryCode: "",
  countries: [],
  nextPaymentDate: null,
  settingsPassword: "",
  showPassword: false,
  grouper: false,
  grouperId: 0,
  usersLicense: 0,
  devicesLicense: 0,
  consumptionControl: "",
  userTypeOption: userTypesData,
  userType: ""
};

class UserForm extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      translate: null,
      ...initialState
    };

    this.api = new AnanseApiService();
    this.initializeTranslation();

    this.viaCepUrl = "https://viacep.com.br";
    this.geoDbUrl = "http://geodb-free-service.wirefreethought.com/v1/geo";
    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const genres = [
        {
          key: "M",
          name: this.state.translate?.("screens.user.male")
        },
        {
          key: "F",
          name: this.state.translate?.("screens.user.feminine")
        },
        {
          key: "O",
          name: this.state.translate?.("screens.user.other")
        }
      ];

      const cityLabel = this.state.translate?.(
        "screens.user.placeholders.city"
      );
      const regionLabel = this.state.translate?.(
        "screens.user.placeholders.region"
      );

      this.setState({ genres, cityLabel, regionLabel });

      const reqs = [this.loadProfiles()];

      if (this.props.match.params.id) {
        reqs.push(this.loadUser(this.props.match.params.id));
      }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        this.state.translate?.("defaultMessages.error"),
        this.state.translate?.("defaultMessages.loadData"),
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadUser = async id => {
    const user = await this.api.makeHttpRequest({
      url: `/usuario/${id}`
    });

    this.setState(
      {
        name: user.nome,
        tin: user.cpf ? user.cpf : "",
        document: user.document ? user.document : "",
        email: user.email,
        mobilePhone: user.mobilePhone,
        profile: user.profile.nome,
        ativo: user.ativo,
        birthday:
          user.birthday !== null
            ? format(addHours(new Date(user.birthday), 5), "yyyy-MM-dd")
            : "",
        genre: user.genre !== null ? user.genre : "",
        zipcode:
          user.address !== null && user.address.zipcode !== null
            ? user.address.zipcode
            : "",
        street:
          user.address !== null && user.address.street !== null
            ? user.address.street
            : "",
        country:
          user.address !== null && user.address.country !== null
            ? user.address.country
            : "",
        deletedAt: user.deletedAt,
        nextPaymentDate:
          user.nextPaymentDate !== null
            ? format(addHours(new Date(user.nextPaymentDate), 5), "yyyy-MM-dd")
            : "",
        grouper: user.grouper !== null ? user.grouper : false,
        grouperId: user.grouperId !== null ? user.grouperId : 0,
        usersLicense: user.usersLicense ? user.usersLicense : 0,
        devicesLicense: user.devicesLicense ? user.devicesLicense : 0,
        consumptionControl: user.consumptionControl,
        userType: user.userType
      },
      () => {
        if (user.address) {
          var country = countriesBR.find(
            data => data.code === this.state.country
          )
            ? countriesBR.find(data => data.code === this.state.country)
            : countriesEN.find(data => data.code === this.state.country);

          this.setState(
            {
              countryCode: country.code
            },
            () => {
              this.loadRegions();
              this.setState(
                {
                  region: user.address.state ? user.address.state : "",
                  regionCode: user.address.state ? user.address.state : ""
                },
                () => {
                  if (this.state.region !== "") {
                    this.loadCities();
                    this.setState({
                      city: user.address.city ? user.address.city : ""
                    });
                  }
                  return;
                }
              );
              return;
            }
          );
        }
      }
    );
  };

  loadProfiles = async () => {
    const profiles = await this.api.makeHttpRequest({
      url: "/perfil/"
    });

    this.setState({
      profiles: profiles ? profiles.data : []
    });

    if (profiles.data.length === 1) {
      this.setState({
        profile: profiles.data[0].nome,
        disableProfile: true
      });
    }
  };

  loadCities = async () => {
    await this.initializeTranslation();
    this.setState({ cities: [] });

    let limit = 10;
    let offset = 0;
    let resultData = true;
    do {
      var response = await axios.get(
        `${this.geoDbUrl}/countries/${this.state.countryCode}/regions/${this.state.regionCode}/cities?languageCode=${this.props.lang}&limit=${limit}&offset=${offset}&hateoasMode=false`
      );

      const { data } = response.data;

      data.forEach(value => {
        const { id, name } = value;

        let cities = this.state.cities;
        let result = cities.find(city => city.name === name);

        if (!result) {
          cities.push({ id, name });
        }

        this.setState({ cities });
      });

      if (!data.length > 0) resultData = false;
      offset += 10;
    } while (resultData);

    this.setState({
      showCities: true,
      cityLabel: this.state.translate("screens.user.placeholders.city")
    });
  };

  loadRegions = async () => {
    await this.initializeTranslation();
    let limit = 10;
    let offset = 0;
    let resultData = true;
    do {
      var response = await axios.get(
        `${this.geoDbUrl}/countries/${
          this.state.countryCode
        }/regions?limit=${limit}&offset=${offset}&hateoasMode=false${
          this.props.lang === "pt" ? "&languageCode=pt" : ""
        }`
      );
      const { data } = response.data;

      data.forEach(value => {
        const { isoCode, name } = value;

        let regions = this.state.regions;
        regions.push({ isoCode, name });

        this.setState({ regions });
        return;
      });

      if (!data.length > 0) resultData = false;
      offset += 10;
    } while (resultData);

    this.setState({
      showRegions: true,
      regionLabel: this.state.translate("screens.user.placeholders.region")
    });
  };

  submit = async e => {
    await this.initializeTranslation();
    e.preventDefault();
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/usuario/${id}` }
        : { method: "POST", url: "/usuario" };

      const {
        name,
        tin,
        document,
        email,
        mobilePhone,
        profile,
        genre,
        birthday,
        zipcode,
        street,
        city,
        region,
        country,
        deletedAt,
        nextPaymentDate,
        settingsPassword,
        grouper,
        grouperId,
        usersLicense,
        devicesLicense,
        consumptionControl,
        userType
      } = this.state;

      const address =
        country !== ""
          ? { zipcode, street, city, state: region, country }
          : null;
      const data = {
        email,
        mobilePhone,
        nome: name,
        cpf: tin !== "" ? tin : null,
        document: document !== "" ? document : null,
        ativo: !id ? false : deletedAt ? false : true,
        profile,
        birthday,
        genre,
        address,
        nextPaymentDate,
        settingsPassword,
        grouper,
        grouperId: grouper ? 0 : grouperId,
        usersLicense: usersLicense ? usersLicense : 0,
        devicesLicense: devicesLicense ? devicesLicense : 0,
        consumptionControl,
        userType
      };

      console.log("submit: ", data);

      await this.api.makeHttpRequest({
        ...reqBase,
        data
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        e.response && e.response.status === 400
          ? e.response.data
          : this.state.translate("screens.user.errors.register"),
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    await this.initializeTranslation();
    this.setState({
      ...initialState,
      resetInput: true,
      resetInputFilial: true
    });

    const isEditing = !!this.props.match.params.id;

    const { value } = await Swal.fire({
      title: this.state.translate("defaultMessages.success"),
      text: this.state.translate("defaultMessages.saveData"),
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing
        ? "Ok"
        : this.state.translate("buttons.newRegister"),
      cancelButtonText: this.state.translate("buttons.exit")
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/users");
    }
  };

  handleChange = e => {
    if (e.target.name === "tin" || e.target.name === "document") {
      this.setState({
        document: "",
        tin: ""
      });
    }

    if (
      e.target.name === "zipcode" &&
      this.state.country === "BR" &&
      verifyCEP(e.target.value).isValid
    ) {
      this.loadAddressBr(e.target.value);
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  loadAddressBr = async zipcode => {
    await this.initializeTranslation();
    const test = "/[^0-9]/";
    const response = await axios.get(
      `${this.viaCepUrl}/ws/${zipcode.replace(test, "")}/json`
    );

    if (response && response.data?.uf) {
      this.setState(
        {
          region: response.data.uf,
          regionCode: response.data.uf,
          city: response.data.localidade
        },
        () => {
          this.setState({
            cityLabel: this.state.translate("defaultMessages.loading"),
            showCities: false
          });
          this.loadCities();
        }
      );
    }
  };

  formIsValid = () => {
    return (
      IsValidName(this.state.name) &&
      IsValidEmail(this.state.email) &&
      this.state.profile &&
      (this.state.birthday
        ? verifyBirthday(this.state.birthday, this.props.lang).isValid
        : true) &&
      (this.state.tin && this.state.country === "BR"
        ? verifyCpfCnpj(this.state.tin).isValid
        : true) &&
      (this.state.zipcode && this.state.country === "BR"
        ? verifyCEP(this.state.zipcode).isValid
        : true)
    );
  };

  onCleanForm = async () => {
    await this.initializeTranslation();
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });
      this.setState({
        city: "",
        region: "",
        zipcode: "",
        street: "",
        showCities: false,
        showRegions: false
      });

      try {
        await this.loadUser(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          this.state.translate("defaultMessages.error"),
          this.state.translate("defaultMessages.cleanForm"),
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true,
        resetInputFilial: true
      });

      if (this.state.profiles.length === 1) {
        this.setState({
          profile: this.state.profiles[0].none,
          disableProfile: true
        });
      }
    }
  };

  handleSelectCountry = e => {
    this.setState({
      region: "",
      regions: [],
      city: "",
      cities: [],
      regionCode: "",
      countryCode: "",
      street: "",
      zipcode: "",
      document: "",
      tin: "",
      showRegions: false,
      showCities: false
    });

    if (e.target.value === "") {
      this.setState({ country: "" });
      return;
    }

    const result =
      this.props.lang === "pt"
        ? countriesBR.find(data => data.code === e.target.value)
        : countriesEN.find(data => data.code === e.target.value);

    this.setState(
      {
        country: e.target.value,
        countryCode: result?.code,
        regionLabel: this.state.translate("defaultMessages.loading")
      },
      () => {
        this.loadRegions();
      }
    );
  };

  handleSelectRegion = e => {
    this.setState({
      region: "",
      city: "",
      showCities: false
    });

    if (e.target.value === "") {
      this.setState({ region: "" });
      return;
    }

    const result = this.state.regions.find(
      data => data.isoCode === e.target.value
    );
    this.setState(
      {
        region: e.target.value,
        regionCode: result.isoCode,
        showCities: false,
        cityLabel: this.state.translate("defaultMessages.loading")
      },
      () => {
        this.loadCities();
      }
    );
  };

  handleChangeDate = event => {
    this.setState({ nextPaymentDate: event.target.value });
  };

  handleChangeSettingsPassword = event => {
    this.setState({ settingsPassword: event.target.value });
  };

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const translate = this.context;

    if (this.props.match.params.id) {
      window.setPageTitle(
        `${translate("screens.user.pageTitle.edit")} - ${translate(
          "labels_admin"
        )}`
      );
    } else {
      window.setPageTitle(
        `${translate("screens.user.pageTitle.create")} - ${translate(
          "labels_admin"
        )}`
      );
    }

    const consumptionOptions = [
      { key: "byTest", label: translate("screens.user.labels.byTest") },
      { key: "perCapsule", label: translate("screens.user.labels.perCapusle") },
      { key: "unlimited", label: translate("screens.user.labels.unlimited") }
    ];

    const currentCountry =
      country === "BR"
        ? translate("screens.user.labels.cpf")
        : translate("screens.user.labels.document");

    const {
      name,
      tin,
      email,
      mobilePhone,
      profiles,
      profile,
      disableProfile,
      country,
      document,
      nextPaymentDate,
      isLoading,
      settingsPassword,
      showPassword,
      grouper,
      grouperId,
      usersLicense,
      devicesLicense,
      consumptionControl,
      userType,
      userTypeOption
    } = this.state;

    return (
      <Wrapper
        className="card-form"
        headerTitle={translate("screens.user.mainData")}
      >
        <Loading isLoading={isLoading} />
        <div className="card-body">
          <form onSubmit={this.submit}>
            <Row>
              <FormInput
                label={translate("screens.user.labels.name")}
                name="name"
                value={name}
                onChange={this.handleChange}
                required
                placeholder={translate("screens.user.placeholders.name")}
                errorMessage={translate(
                  "screens.category.labels.invalide.name"
                )}
                isInvalid={name && !IsValidName(name)}
                className="col-lg-4"
              />

              <FormInput
                label={translate("screens.user.labels.email")}
                name="email"
                value={email}
                onChange={this.handleChange}
                required
                placeholder={translate("screens.user.placeholders.email")}
                errorMessage={translate(
                  "screens.forgotPassword.error.invalidEmailTitle"
                )}
                isInvalid={email && !IsValidEmail(email)}
                className="col-lg-4"
                maxLength={100}
              />

              <FormInput
                label={translate("screens.user.labels.profile")}
                name="profile"
                value={profile}
                onChange={this.handleChange}
                required
                placeholder={translate("screens.user.placeholders.profile")}
                className="col-lg-4"
                type="select"
                disable={disableProfile}
                Options={
                  <>
                    <option value={profile}>
                      {translate("screens.user.placeholders.profile")}
                    </option>
                    {profiles.map(p => (
                      <option key={p.id} value={p.nome}>
                        {p.nome}
                      </option>
                    ))}
                  </>
                }
              />
            </Row>

            <Row>
              <FormInput
                label={translate("screenApp.listPacient.account.phone")}
                name="mobilePhone"
                value={mobilePhone}
                onChange={this.handleChange}
                placeholder={translate("screenApp.createAccount.phone")}
                className="col-lg-2"
                maxLength={14}
              />

              {country === "BR" ? (
                <FormInput
                  label={currentCountry}
                  name="tin"
                  value={verifyCpfCnpj(tin).cpfCnpjFormat}
                  onChange={this.handleChange}
                  placeholder={translate("screens.user.placeholders.cpf")}
                  errorMessage={verifyCpfCnpj(tin).error}
                  isInvalid={!verifyCpfCnpj(tin).isValid}
                  className="col-lg-2"
                  maxLength={14}
                />
              ) : (
                <FormInput
                  label={currentCountry}
                  name="document"
                  value={document}
                  onChange={this.handleChange}
                  placeholder={translate("screens.user.placeholders.document")}
                  className="col-lg-2"
                  maxLength={14}
                />
              )}

              <FormInput
                label={translate(
                  "screenApp.createAccount.profile.nextPaymentDate"
                )}
                name="nextPaymentDate"
                value={nextPaymentDate}
                onChange={this.handleChangeDate}
                required
                className="col-lg-4"
                type="date"
              />

              <FormInput
                label={translate("screens.user.labels.formConsumption")}
                name="consumptionControl"
                value={consumptionControl}
                onChange={this.handleChange}
                required
                className="col-lg-4"
                type="select"
                Options={
                  <>
                    {consumptionOptions.map(option => (
                      <option key={option.key} value={option.key}>
                        {option.label}
                      </option>
                    ))}
                  </>
                }
              />
            </Row>
            <Row>
              <FormInput
                label={translate("screens.user.labels.pin")}
                name="settingsPassword"
                value={settingsPassword}
                onChange={this.handleChangeSettingsPassword}
                placeholder={translate("screens.user.labels.insert.pin")}
                required
                className="col-lg-4"
                type={!showPassword && "password"}
              />

              <InputCheckbox
                checked={showPassword}
                onChange={this.toggleShowPassword}
                label={translate("screens.user.labels.showPin")}
              />

              <FormInput
                label={translate("labels_user_type")}
                name="userType"
                value={userType}
                onChange={this.handleChange}
                required
                className="col-lg-4"
                type="select"
                Options={
                  <>
                    <option value={""}>
                      {translate(
                        "screens_user_consumptionControl_optionsSelect"
                      )}
                    </option>
                    {userTypeOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {translate(option.translationKey)}
                      </option>
                    ))}
                  </>
                }
              />
            </Row>
            <Row>
              <GroupForm
                isGrouper={grouper}
                setIsGrouper={group => this.setState({ grouper: group })}
                usersLicense={usersLicense}
                setUsersLicense={value =>
                  this.setState({ usersLicense: value })
                }
                devicesLicense={devicesLicense}
                setDevicesLicense={value =>
                  this.setState({ devicesLicense: value })
                }
                grouperId={grouperId}
                setGrouperId={value => this.setState({ grouperId: value })}
              />
            </Row>

            <FormActions
              module="users"
              isEdit={!!this.props.match.params.id}
              formIsValid={this.formIsValid()}
              onCleanForm={this.onCleanForm}
            />
          </form>
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(withFormValidation(UserForm, initialState));

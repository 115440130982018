import React from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.scss";
import ButtonCode from "./Components/ButtonCode";
class EditorConvertToHTML extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      html: "",
      code: false,
      assunto: "",
      reset: false
    };
  }

  componentDidUpdate() {
    if (this.props.resetEditor !== this.state.reset) {
      this.setState({
        editorState: EditorState.createEmpty(),
        html: "",
        code: false,
        assunto: "",
        reset: !this.state.reset
      });
    }
  }

  getContent = () => {
    const { html, code } = this.state;
    if (!code) {
      return html;
    } else {
      const contentBlock = htmlToDraft(html);
      return contentBlock.contentBlocks;
    }
  };

  render() {
    const { editorState, html, code, assunto } = this.state;
    return (
      <div className="col-12 pl-3 pr-3 pb-2">
        <div className="kt-input-icon kt-input-icon--left pb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Digite o assunto do e-mail*"
            onChange={e => {
              this.setState({ assunto: e.target.value });
              this.props.getEmail(html, e.target.value);
            }}
            value={this.state.assunto}
          />
        </div>
        <Editor
          placeholder="Digite o corpo do e-mail*"
          editorState={editorState}
          toolbarCustomButtons={[
            <ButtonCode
              key={1}
              content={this.getContent()}
              toggleCode={() => this.setState({ code: !this.state.code })}
            />
          ]}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editor"
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "embedded",
              "emoji",
              "image",
              "remove",
              "history"
            ],
            colorPicker: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: [
                "rgb(56,76,30)",
                "rgb(289,289,289)",
                "rgb(40,49,65)",
                "rgb(17,289,135)",
                "rgb(85,120,289)",
                "rgb(289,280,34)",
                "rgb(289,57,129)",
                "rgb(100,113,161)",
                "rgb(289,289,105)",
                "rgb(255,200,92)",
                "rgb(153,72,52)",
                "rgb(91,126,179)",
                "rgb(106,153,44)",
                "rgb(289,289,0)",
                "rgb(97,189,109)",
                "rgb(26,188,156)",
                "rgb(84,172,210)",
                "rgb(44,130,201)",
                "rgb(147,101,184)",
                "rgb(71,85,119)",
                "rgb(204,204,204)",
                "rgb(65,168,95)",
                "rgb(0,168,133)",
                "rgb(61,142,185)",
                "rgb(41,105,176)",
                "rgb(85,57,130)",
                "rgb(40,50,78)",
                "rgb(0,0,0)",
                "rgb(247,218,100)",
                "rgb(251,160,38)",
                "rgb(235,107,86)",
                "rgb(226,80,65)",
                "rgb(163,143,132)",
                "rgb(239,239,239)",
                "rgb(255,255,255)",
                "rgb(250,197,28)",
                "rgb(243,121,52)",
                "rgb(209,72,65)",
                "rgb(184,49,47)",
                "rgb(124,112,107)",
                "rgb(209,213,216)"
              ]
            },
            fontFamily: {
              options: [
                "Arial",
                "Georgia",
                "Impact",
                "Tahoma",
                "Times New Roman",
                "Verdana",
                "Helvetica Neue LT Std 47 Light Condensed",
                "Helvetica Neue LT Std 57 Condensed",
                "Helvetica Neue LT Std 77 Bold Condensed",
                "Helvetica",
                "sans-serif"
              ],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined
            },
            image: { previewImage: true },
            inputAccept:
              "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel"
          }}
          onEditorStateChange={e => this.setState({ editorState: e })}
          onChange={() => {
            if (code) {
              this.setState({
                html: editorState.getCurrentContent().getPlainText()
              });
              this.props.getEmail(
                editorState.getCurrentContent().getPlainText(),
                assunto
              );
            } else {
              this.setState({
                html: editorState.getCurrentContent().getPlainText()
                  ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  : ""
              });
              this.props.getEmail(
                editorState.getCurrentContent().getPlainText()
                  ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  : "",
                assunto
              );
            }
          }}
        />
        {code ? (
          <div className="pt-2">
            <strong>Pré-visualização</strong>
            <div
              className="preview-container"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default EditorConvertToHTML;

import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Typography } from "@mui/material";

import { useTranslation } from "_metronic/i18n/language";
import { LocaleType, getLocales } from "app/services/Localise/localiseApi";

type SelectLanguageProps = {
  languageValue: string;
  handleChangeLanguage: (
    e: EventTarget & { value: string; name: string }
  ) => void;
};

const SelectLanguage = ({
  languageValue = "pt-BR",
  handleChangeLanguage
}: SelectLanguageProps) => {
  const translate = useTranslation();
  const [locales, setLocales] = useState<LocaleType[]>([]);

  useEffect(() => {
    async function fetchLocales() {
      try {
        const localesData = await getLocales();
        if (localesData) {
          setLocales(localesData);
        }
      } catch (error) {
        console.error("Erro ao buscar locais:", error);
      }
    }
    fetchLocales();
  }, []);

  const options = locales.map(locale => ({
    value: locale.code,
    label: locale.name,
    iconDisplay: languageValue === locale.code
  }));

  console.log(languageValue);
  return (
    <Select
      value={languageValue || 'pt-BR'}
      onChange={e => {
        handleChangeLanguage(e.target);
      }}
      style={{ height: 38.4, display: "flex" }}
      inputProps={{ "aria-label": "Without label" }}
      placeholder={translate("defaultMessages.selectLanguage")}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Box display="flex">
            {option.iconDisplay && <LanguageIcon />}
            <Typography ml={option.iconDisplay ? 1 : 3}>
              {option.label}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectLanguage;

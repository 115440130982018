import SimpleReactValidator from "simple-react-validator";

SimpleReactValidator.addLocale('pt', {
  accepted             : 'Este campo devem ser aceito.',
  after                : 'Este campo deve ser depois de :date.',
  after_or_equal       : 'Este campo deve ser depois ou igual à :date.',
  alpha                : 'Este campo deve conter apenas letras.',
  alpha_space          : 'Este campo deve conter apenas letras e espaços.',
  alpha_num            : 'Este campo deve conter apenas letras e números.',
  alpha_num_space      : 'Este campo deve conter apenas letras, números e espaços.',
  alpha_num_dash       : 'Este campo deve conter apenas letras, números e hífens.',
  alpha_num_dash_space : 'Este campo deve conter apenas letras, números, hífens e espaços.',
  array                : 'Este campo deve ser uma matriz.',
  before               : 'Este campo deve ser antes de :date.',
  before_or_equal      : 'Este campo deve ser antes ou igual à :date.',
  between              : 'Este campo deve ser entre :min e :max:type.',
  boolean              : 'Este campo deve ser boleano.',
  card_exp             : 'Este campo deve ser uma data de validade válida.',
  card_num             : 'Este campo deve ser um número de cartão válido.',
  currency             : 'Este campo deve ser uma moeda válida.',
  date                 : 'Este campo deve ser uma data.',
  date_equals          : 'Este campo deve ser :date.',
  email                : 'Este campo deve ser um endereço de email válido.',
  in                   : 'Este campo deve ser :values.',
  integer              : 'Este campo deve ser um valor inteiro.',
  max                  : 'Este campo não deve ser maior que :max:type.',
  min                  : 'Este campo deve ser ao menos :min:type.',
  not_in               : 'Este campo não deve ser :values.',
  not_regex            : 'Este campo não é válido.',
  numeric              : 'Este campo deve ser um número.',
  phone                : 'Este campo deve ser um número de telefone válido.',
  regex                : 'Este campo não é válido.',
  required             : 'Este campo é obrigatório.',
  size                 : 'Este campo deve ser :size:type.',
  string               : 'Este campo deve ser um conjunto de caracteres.',
  typeof               : 'Este campo deve ser do tipo :type.',
  url                  : 'Este campo deve ser uma url.',
});

import React, { useState } from "react";
import { Container, ButtonDownloadAndroid, ButtonDownloadApple } from "./style";
import { toAbsoluteUrl } from "../../../../_metronic";
import ModalComponent from "../Components/ModalComponent";
import { Button } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";

export default function RegisterOld() {
  const translate = useTranslation();
  const [register, setRegister] = useState(false);

  const handleDownload = (link: any) => {
    window.open(link);
  };

  const closeModal = () => {
    setRegister(false);
  };

  const openModal = () => {
    setRegister(true);
  };

  const stackStyle = {
    backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-10.jpg")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "120px"
  };

  return (
    <>
      <Button
        style={{ flex: 1 }}
        type="button"
        color="success"
        fullWidth
        onClick={openModal}
      >
        {translate("screens.login.noAccount")}
      </Button>
      <ModalComponent open={register}>
        <Container bgImg={toAbsoluteUrl("/media/bg/bg-10.jpg")}>
          <button
            style={{ position: "relative", top: "-30%", right: "-45%" }}
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <ButtonDownloadApple
            onClick={() =>
              handleDownload(process.env.REACT_APP_LINK_APPLE_STORE)
            }
            bgImage={toAbsoluteUrl("/media/bg/btnApple.svg")}
            className="btn"
          ></ButtonDownloadApple>
          <ButtonDownloadAndroid
            onClick={() =>
              handleDownload(process.env.REACT_APP_LINK_PLAY_STORE)
            }
            bgImage={toAbsoluteUrl("/media/bg/btnAndroid.svg")}
            className="btn"
          ></ButtonDownloadAndroid>
        </Container>
      </ModalComponent>
    </>
  );
}

import { AnanseApiService } from "./AnanseApiService";

export class CRUDApiService extends AnanseApiService {
  constructor(resource) {
    if (!resource) {
      throw new Error('Resource should be provided!');
    }

    super(resource);
  }

  get(id) {
    if (!id) {
      throw new Error('id should be provided');
    }

    return this.makeHttpRequest({
      url: `/${id}`
    });
  }

  create(payload) {
    if (!payload) {
      throw new Error('payload should be provided');
    }

    return this.makeHttpRequest({
      method: 'POST',
      data: payload
    });
  }

  update(id, payload) {
    if (!id) {
      throw new Error('id should be provided');
    }

    if (!payload) {
      throw new Error('payload should be provided');
    }

    return this.makeHttpRequest({
      method: 'PUT',
      url: `/${id}`,
      data: payload
    });
  }

  delete(id, hardDelete = false) {
    if (!id) {
      throw new Error('id should be provided');
    }

    return this.makeHttpRequest({
      method: 'DELETE',
      url: `/${id}`,
      params: {
        hardDelete
      }
    });
  }

  activate(id) {
    if (!id) {
      throw new Error('id should be provided');
    }

    return this.makeHttpRequest({
      method: 'PUT',
      url: `/activate/${id}`
    });
  }
}

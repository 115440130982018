import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import { AnanseApiService } from "../../../../services/Ananse/AnanseApiService";
import { TableRow, TableCell } from "@mui/material";

export default class ProfileListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = new AnanseApiService();
    this.state = {
      profiles: []
    };

    this.setProfiles = this.setProfiles.bind(this);
  }

  setProfiles(profiles) {
    this.setState({ profiles });
  }

  render() {
    window.setPageTitle("Perfis - Admin");

    const headRows = [
      { column: "Nome", label: "Nome" },
      { column: "PerfilMaster.Nome", label: "Perfil master" },
      { column: "TotalUsuarios", label: "Usuários" }
    ];

    const formatRow = r => (
      <TableRow hover tabIndex={-1} key={r.id}>
        <TableCell scope="row">{r.nome}</TableCell>
        <TableCell>{r.perfilMaster && r.perfilMaster.nome}</TableCell>
        <TableCell>{r.totalUsuarios}</TableCell>
      </TableRow>
    );

    return (
      <Datatable
        title="Perfis"
        endpoint="/perfil"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.profiles}
        setRows={this.setProfiles}
        buttons={[]}
      />
    );
  }
}

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal } from "@mui/material";
import FormActions from "components/FormActions";
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";

import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { UserType } from "interfaces";
import { useTranslation } from "_metronic/i18n/language";
import { FormInput } from "./components/FormInput";
import { Wrapper } from "./components/Wrapper";

const validationSchema = yup.object({
  nome: yup.string().required("Campo obrigatório"),
  email: yup
    .string()
    .email("Email inválido")
    .required("Campo obrigatório")
});

interface CadastroUsuarioModalProps {
  open: boolean;
  onClose: () => void;
  onCadastro?: any;
  userSelected?: UserType;
  userId?: number;
  onFinish: () => void;
}

const UserFormModal: React.FC<CadastroUsuarioModalProps> = ({
  open,
  onClose,
  onCadastro,
  userSelected,
  userId,
  onFinish
}) => {
  const translate = useTranslation();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: "",
      email: "",
      mobilePhone: ""
    }
  });

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      profile: "USER",
      grouperId: userSelected?.id,
      usersLicense: 0,
      devicesLicense: 0,
      mobilePhone:
        `${data.mobilePhone.replace(/\s/g, "").replace(/[-()]/g, "")}` ?? null
    };

    setLoading(true);
    try {
      let reqBase: any;
      if (userId) {
        reqBase = { method: "PUT", url: `/usuario/${userId}` };
      } else {
        reqBase = { method: "POST", url: "/usuario" };
      }

      const response = await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      onCadastro(response);
      Swal.fire({
        title: translate("defaultMessages?.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then((result: any) => {
        if (result.isConfirmed) {
          onClose();
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages?.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
      onClose();
      onFinish();
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4
        }}
      >
        <Wrapper className="card-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card-body">
              <Row>
                <FormInput
                  label={translate("screenApp.listPacient.account.name")}
                  name="nome"
                  required
                  placeholder={translate("screens.user.placeholders.name")}
                  errorMessage={translate(
                    "screens.category.labels.invalide.name"
                  )}
                  register={register}
                  className="col-lg-12"
                />
                {errors.nome && (
                  <span className="invalid-input">
                    {translate("screens.category.labels.invalide.name")}
                  </span>
                )}
              </Row>
              <Row>
                <FormInput
                  label={translate("screenApp.listPacient.account.email")}
                  name="email"
                  type="email"
                  required
                  register={register}
                  placeholder={translate("screens.user.placeholders.email")}
                  className="col-lg-12"
                />
                {errors.email && (
                  <span className="invalid-input">
                    {translate(
                      "screens.forgotPassword.error.invalidEmailTitle"
                    )}
                  </span>
                )}

                <FormInput
                  label={translate("screenApp.listPacient.account.phone")}
                  placeholder={translate("screenApp.createAccount.phone")}
                  type="text"
                  className="col-lg-12"
                  required
                  control={control}
                  rules={{ required: true }}
                  name="mobilePhone"
                  value={getValues("mobilePhone")}
                  mask="+00 (00) 0 0000-0000"
                />
              </Row>
            </div>
            <FormActions
              module="usuario"
              onReset={handleReset}
              onCancel={onClose}
            />
          </form>
        </Wrapper>
        <Loading isLoading={loading} />
      </Box>
    </Modal>
  );
};

export default UserFormModal;

import { useState } from "react";
import { Answer, QuestionType } from "./preViewQuiz";
import { useTranslation } from "_metronic/i18n/language";

type PreviewRatingProps = {
  scaleFactor: number;
  answers: Answer[];
  currentQuestionIndex: number;
  questionsLength: number;
  currentQuestion: QuestionType;
  handleNextQuestion: () => void;
};

type SliderValues = {
  [key: number]: number;
};

const PreviewRatings = ({
  scaleFactor,
  answers,
  currentQuestionIndex,
  questionsLength,
  currentQuestion,
  handleNextQuestion
}: PreviewRatingProps) => {
  const [isSliderEnabled, setIsSliderEnabled] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [sliderValues, setSliderValues] = useState<SliderValues>(
    answers.reduce((acc, answer) => {
      acc[answer.quizQuestionAnswerId] = 50;
      return acc;
    }, {} as SliderValues)
  );
  const translate = useTranslation();

  const resetSliders = () => {
    setSliderValues(
      answers.reduce((acc, answer) => {
        acc[answer.quizQuestionAnswerId] = 50;
        return acc;
      }, {} as SliderValues)
    );
  };

  const handleSliderChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    answerId: number
  ) => {
    const value = Number(e.target.value);
    setSliderValues(prevValues => ({
      ...prevValues,
      [answerId]: value
    }));

    e.target.style.setProperty(
      "--val",
      `${((value - Number(e.target.min)) /
        (Number(e.target.max) - Number(e.target.min))) *
        100}%`
    );
  };

  const handleButtonSmell = () => {
    setIsSliderEnabled(true);
    setIsNextButtonEnabled(true);
  };

  const handleNextQuestionClick = () => {
    if (currentQuestionIndex + 1 >= questionsLength) {
      return;
    }
    handleNextQuestion();
    resetSliders();
    setIsSliderEnabled(false);
    setIsNextButtonEnabled(false);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "360px",
        width: "100%",
        textAlign: "center"
      }}
    >
      <p
        style={{
          marginTop: "40px",
          marginBottom: "10px",
          fontSize: `${24 * scaleFactor}px`,
          color: "#000000"
        }}
      >
        {isSliderEnabled
          ? `${currentQuestion.question.quest}`
          : `${translate("screenApp_quiz_smell").replace("{{letter}}", "")}`}
      </p>
      <div
        style={{
          fontSize: `${20 * scaleFactor}px`
        }}
      >
        {answers.map(answer => (
          <div
            key={answer.quizQuestionAnswerId}
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "0.5px solid gray",
              paddingBottom: "4px"
            }}
          >
            <span
              style={{
                width: "20%"
              }}
            >
              {answer.answer.label}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 6,

                width: "80%",
                alignItems: "center"
              }}
            >
              <span>{translate("screenApp_fragranceData_nothing")}</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <span>{sliderValues[answer.quizQuestionAnswerId] || 50}</span>
                <input
                  type="range"
                  id={answer.answer.label}
                  min="0"
                  max="100"
                  disabled={!isSliderEnabled}
                  value={sliderValues[answer.quizQuestionAnswerId]}
                  onChange={e =>
                    handleSliderChange(e, answer.quizQuestionAnswerId)
                  }
                  style={
                    {
                      background: `${
                        sliderValues[answer.quizQuestionAnswerId]
                      }%`
                    } as React.CSSProperties
                  }
                />
              </div>
              <span>{translate("screenApp_fragranceData_very")}</span>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px"
        }}
      >
        <button
          onClick={handleButtonSmell}
          style={{
            flex: 1,
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            backgroundColor: "#FF6B6B",
            color: "white",
            fontSize: `${16 * scaleFactor}px`,
            cursor: "pointer",
            marginRight: "10px"
          }}
        >
          {translate("labels_buttons_smell").toUpperCase()}
        </button>
        <button
          onClick={handleNextQuestionClick}
          disabled={!isNextButtonEnabled}
          style={{
            flex: 1,
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            backgroundColor: isNextButtonEnabled ? "#9da5fa" : "#C4C4C4",
            color: "white",
            fontSize: `${18 * scaleFactor}px`,
            cursor: "pointer"
          }}
        >
          {translate("screenApp_validation_next").toUpperCase()} &gt;&gt;
        </button>
      </div>

      <div
        style={{
          marginTop: "20px",
          marginBottom: `${14 * scaleFactor}px`,
          fontSize: `${16 * scaleFactor}px`,
          color: "#ffffff",
          backgroundColor: "#a1a1a9",
          borderRadius: "5px",
          padding: "1px"
        }}
      >
        {translate("screens_quiz_labels_question")} {currentQuestionIndex + 1} /{" "}
        {questionsLength}
      </div>
    </div>
  );
};

export default PreviewRatings;

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Tooltip, alpha, styled } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Delete } from "@mui/icons-material";
import { OptionsType } from "../mock";
import { useSelector } from "react-redux";
import { UseSelectorI } from "interfaces";
import { useTranslation } from "_metronic/i18n/language";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

const ITEM_HEIGHT = 50;

type FilterOptions = {
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  filterOptions: OptionsType[];
};

export default function FilterOptions({
  filterOptions,
  filters,
  setFilters
}: FilterOptions) {
  const translate = useTranslation();
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: OptionsType) => {
    handleClose();
    const filter = { filterString: event.value, filterType: event.filterType };
    console.log(filter);
    setFilters(filter);
  };

  return (
    <div>
      <Tooltip title={translate("labels_filter")}>
        <IconButton onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button"
        }}
        // MenuListProps={{
        //   "aria-labelledby": "long-button"
        // }}
        anchorEl={filters}
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   style: {
        //     maxHeight: ITEM_HEIGHT * 4.5,
        //     width: "30ch"
        //   }
        // }}
      >
        <MenuItem onClick={() => handleChange({} as any)}>
          <Delete />
          {translate("screens_capsule_placeholders_clearSearch")}
        </MenuItem>
        <Divider variant="fullWidth" sx={{ my: 0.5 }} />
        {filterOptions.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === filters.filterString}
            onClick={() => handleChange(option)}
            disableRipple
          >
            {translate(option.translationKey)}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

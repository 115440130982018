import React, {
  forwardRef,
  useEffect,
  useState,
  ForwardRefRenderFunction
} from "react";
import clsx from "clsx";
import { isFragment } from "react-is";

interface PortletHeaderIconProps {
  className?: string;
}

const PortletHeaderIconComponent: ForwardRefRenderFunction<
  HTMLSpanElement,
  PortletHeaderIconProps
> = ({ className }, ref) => (
  <span ref={ref} className={clsx("kt-portlet__head-icon", className)} />
);
const PortletHeaderIcon = forwardRef(PortletHeaderIconComponent);

interface PortletHeaderTitleProps {
  className?: string;
  children?: React.ReactNode;
}

const PortletHeaderTitleComponent: ForwardRefRenderFunction<
  HTMLHeadingElement,
  PortletHeaderTitleProps
> = ({ className, ...props }, ref) => (
  <h3
    {...props}
    ref={ref}
    className={clsx("kt-portlet__head-title", className)}
  />
);
const PortletHeaderTitle = forwardRef(PortletHeaderTitleComponent);

interface PortletHeaderToolbarProps {
  className?: string;
  children?: React.ReactNode;
}

const PortletHeaderToolbarComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  PortletHeaderToolbarProps
> = ({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx("kt-portlet__head-toolbar", className)}
  />
);
const PortletHeaderToolbar = forwardRef(PortletHeaderToolbarComponent);

type PortletHeaderProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  toolbar?: React.ReactNode;
  className?: string;
  sticky?: boolean;
  labelRef?: React.Ref<any>;
};

const PortletHeaderComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  PortletHeaderProps
> = (
  { icon, title, toolbar, className, sticky = false, labelRef, ...props },
  ref
) => {
  const [top, setTop] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!sticky || windowHeight === 0) {
      return;
    }

    const headerElement: any = document.querySelector(".kt-header");
    const subheaderElement: any = document.querySelector(".kt-subheader");
    const headerMobileElement: any = document.querySelector(".kt-header-mobile");

    let nextMarginTop = 0;

    if (window.getComputedStyle(headerElement)?.height === "0px") {
      nextMarginTop = headerMobileElement?.offsetHeight;
    } else {
      if (document.body.classList.contains("kt-header--minimize-topbar")) {
        nextMarginTop = 60;
      } else {
        if (document.body.classList.contains("kt-header--fixed")) {
          nextMarginTop += headerElement?.offsetHeight;
        }

        if (document.body.classList.contains("kt-subheader--fixed")) {
          nextMarginTop += subheaderElement?.offsetHeight;
        }
      }
    }

    setTop(nextMarginTop);
  }, [sticky, windowHeight]);

  return (
    <div
      ref={ref}
      className={clsx("kt-portlet__head", className)}
      style={
        !sticky
          ? undefined
          : { top, position: "sticky", backgroundColor: "#fff" }
      }
    >
      {title != null && (
        <div
          ref={labelRef}
          className={clsx("kt-portlet__head-label", "p-1", className)}
        >
          {icon}
          {typeof title === "string" || isFragment(title) ? (
            <PortletHeaderTitle>{title}</PortletHeaderTitle>
          ) : (
            title
          )}
        </div>
      )}
      {toolbar}
    </div>
  );
};
const PortletHeader = forwardRef(PortletHeaderComponent);

interface PortletBodyProps {
  fit?: boolean;
  fluid?: boolean;
  className?: string;
  id?: string;
  children?: React.ReactNode;
}

const PortletBodyComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  PortletBodyProps
> = ({ fit, fluid, className, id, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    id={id}
    className={clsx(
      "kt-portlet__body",
      { "kt-portlet__body--fit": fit, "kt-portlet__body--fluid": fluid },
      className
    )}
  />
);
const PortletBody = forwardRef(PortletBodyComponent);

interface PortletFooterProps {
  className?: string;
}

const PortletFooterComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  PortletFooterProps
> = ({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={clsx("kt-portlet__foot", className)} />
);
const PortletFooter = forwardRef(PortletFooterComponent);

interface PortletProps {
  fluidHeight?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const PortletComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  PortletProps
> = ({ fluidHeight, className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={clsx(
      "kt-portlet",
      { "kt-portlet--height-fluid": fluidHeight },
      className
    )}
  />
);
const Portlet = forwardRef(PortletComponent);

// Optionally set display names for debugging purposes
PortletHeaderIcon.displayName = "PortletHeaderIcon";
PortletHeaderTitle.displayName = "PortletHeaderTitle";
PortletHeaderToolbar.displayName = "PortletHeaderToolbar";
PortletHeader.displayName = "PortletHeader";
PortletBody.displayName = "PortletBody";
PortletFooter.displayName = "PortletFooter";
Portlet.displayName = "Portlet";

export {
  PortletHeaderIcon,
  PortletHeaderTitle,
  PortletHeaderToolbar,
  PortletHeader,
  PortletBody,
  PortletFooter,
  Portlet
};

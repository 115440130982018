import { Card, CardContent, CardHeader } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

interface FaixaEtariaData {
  name: string;
  value: number;
  fill: string;
  percentage?: string; // Adicionando uma nova propriedade opcional para armazenar a porcentagem
}

interface BarAndLineChartComponentProps {
  data: FaixaEtariaData[];
  title: string;
}

const BarAndLineChartComponent: React.FC<BarAndLineChartComponentProps> = ({
  data,
  title
}) => {
  const translate = useTranslation();
  // Calculando o total dos valores para base de porcentagem
  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  // Adicionando a porcentagem a cada item do dataset
  const sortedData = data
    .sort((a, b) => {
      const rangeA = a.name.split(" - ").map(Number);
      const rangeB = b.name.split(" - ").map(Number);
      return rangeA[0] - rangeB[0];
    })
    .map(item => ({
      ...item,
      percentage: `${((item.value / total) * 100).toFixed(2)}%` // Calculando a porcentagem e formatando como string
    }));

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader title={title} />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart width={600} height={300} data={sortedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" interval={0} tick={{ fontSize: 9 }} />
            <YAxis />
            <Tooltip
              formatter={(value, name, props) => [
                `${value} (${props.payload.percentage})`,
                name
              ]}
            />
            <Legend />
            <Bar dataKey="value" fill="#8884d8" name={translate("labels_dashboard_testsNumbers")} />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#ff8d12"
              name={translate("labels_dashboard_testLine")}
              >
              <Label value="Porcentagem" position="top" />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default BarAndLineChartComponent;

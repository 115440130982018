import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  Close,
  IndeterminateCheckBox
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect } from "react";
import { JsonResultType, ValidadeResultType } from "../../types";
import { RenderAccount } from "../RenderAccount";
import { RenderResults } from "../RenderResults";

type Props = {
  jsonData: JsonResultType[];
  validationResults: ValidadeResultType;
  validationResultsQuizAnswers: ValidadeResultType;
};

const RenderJsons: React.FC<Props> = ({
  jsonData,
  validationResults,
  validationResultsQuizAnswers
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (!jsonData.length) {
      setExpanded("");
    }
  }, [jsonData]);

  return (
    <div>
      {jsonData.map((item, index) => {
        const check = Object.entries(validationResults).map(
          ([accountId, result]) => {
            return { accountId: Number(accountId), result };
          }
        );
        const find = check.find(c => c.accountId === item.account.accountId);

        const checkAnswers = Object.entries(validationResultsQuizAnswers).map(
          ([testeResult, result]) => {
            return { testeResult, result };
          }
        );
        const findAnswers = checkAnswers.find(c => {
          return c.testeResult === item.testeResult.testResultId.toLowerCase();
        });
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              style={{ alignItems: "center" }}
            >
              {findAnswers?.result && find?.result ? (
                <CheckBox />
              ) : findAnswers?.result ? (
                <IndeterminateCheckBox />
              ) : (
                <CheckBoxOutlineBlank />
              )}

              <Typography style={{ marginLeft: "10px" }}>
                {item.account.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RenderAccount
                account={item.account}
                button={!find?.result ? <Close /> : <CheckCircle />}
              />
              <RenderResults
                testResult={item.testeResult}
                validation={findAnswers?.result}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default RenderJsons;

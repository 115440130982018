import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { InputBase, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Close, Refresh } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface Data {
  acoes: any;
  carbstestResultId: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data | any;
  label: string;
  numeric?: boolean;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headColumns?: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headColumns
  } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headColumns?.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  nameTable: string;
  placeholderSearch?: string;
  search?: any;
  setSearch?: any;
  onRefresh: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const translate = useTranslation();
  const { numSelected } = props;
  const handleCharge = (value: any) => {
    const newValue = value.target.value;
    console.log("handleCharge; ", newValue);
    props.setSearch(newValue);
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected < 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.success.main,
              theme.palette.action.disabledOpacity
            )
        }),
        minHeight: 55
      }}
    >
      <Stack spacing={2} sx={{ width: "100%", margin: 1 }}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-evenly"}
          sx={{ width: "100%" }}
        >
          <Typography
            sx={{ flex: "1 1 50%", marginLeft: 0 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props.nameTable}
          </Typography>
          <IconButton
            aria-label="refresh"
            // disabled
            // color="secondary"
            size="large"
            onClick={props.onRefresh}
          >
            <Refresh />
          </IconButton>
        </Stack>
        <Paper
          component="form"
          sx={{
            flex: "1 1 50%",
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            minWidth: 400,
            marginX: 1
          }}
        >
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            disabled
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={props.placeholderSearch}
            inputProps={{ "aria-label": "search google maps" }}
            onChange={handleCharge}
            value={props.search}
          />
          <Tooltip
            title={translate("screens.capsule.placeholders.clearSearch")}
          >
            <IconButton onClick={() => props.setSearch("")}>
              <Close />
            </IconButton>
          </Tooltip>
        </Paper>
      </Stack>
    </Toolbar>
  );
}

interface Props {
  headColumns?: any;
  rows?: any;
  titleTable: any;
  formatRows: any;
  placeholderSearch?: string;
  loading: boolean;
  onRefresh: any;
}

export default function DataTable({
  headColumns,
  rows,
  titleTable,
  formatRows,
  placeholderSearch,
  loading,
  onRefresh
}: Props) {
  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState("");
  const [resultsSearch, setResultsSearch] = React.useState<any[]>([]);
  const [results, setResults] = React.useState<any[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = resultsSearch.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - resultsSearch.length) : 0;

  const searchInData = React.useCallback(() => {
    const filteredRows = rows.filter((row: any) => {
      for (let i = 0; i < headColumns.length; i++) {
        const column = headColumns[i];
        const columnName = column.id;
        const cellValue = row[columnName];

        if (
          cellValue &&
          cellValue
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setResultsSearch(filteredRows);
    return filteredRows;
  }, [search, rows]);

  React.useEffect(() => {
    searchInData();

    const newRows = stableSort(
      resultsSearch,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setResults(newRows);
  }, [resultsSearch, order, orderBy, page, rowsPerPage, searchInData]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          nameTable={titleTable}
          placeholderSearch={placeholderSearch}
          search={search}
          setSearch={setSearch}
          onRefresh={onRefresh}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={resultsSearch.length}
              headColumns={headColumns}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={headColumns.length}
                    className="text-center"
                  >
                    {translate("defaultMessages.loading")}
                  </TableCell>
                </TableRow>
              ) : results.length > 0 ? (
                results.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.testResultId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return formatRows(row, labelId);
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headColumns.length}
                    className="text-center"
                  >
                    {translate("datatable.nothingData")}
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={
            translate("datatable.labelRowsPerPage")
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${translate("datatable.of")} ${count}`
          }
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={resultsSearch.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": translate("datatable.backButton"),
            style: { transform: valueTransformIfRtl }
          }}
          nextIconButtonProps={{
            "aria-label": translate("datatable.nextButton"),
            style: { transform: valueTransformIfRtl }
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

import React, { createContext, useContext, ReactNode } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "@mwvalen/stylis-plugin-rtl";
import { useSelector } from "react-redux";

interface RootState {
  i18n: {
    lang: string;
  };
}

export const RTLContext = createContext<boolean>(false);

const rtlLocales = ["ar-SA", "fa-IR", "he-IL", "ur-PK", "ps-AF"];

export const RTLProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const locale = useSelector((state: RootState) => state.i18n.lang);

  const isRtl = rtlLocales.includes(locale);

  const cache = createCache({
    key: isRtl ? "muirtl" : "muiltr",
    stylisPlugins: isRtl ? [prefixer, rtlPlugin] : [prefixer]
  });

  return (
    <RTLContext.Provider value={isRtl}>
      <CacheProvider value={cache}>
        <div style={{ direction: isRtl ? "rtl" : "ltr" }}>{children}</div>
      </CacheProvider>
    </RTLContext.Provider>
  );
};

export const useRTL = () => useContext(RTLContext);

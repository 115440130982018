import store from "app/store/store";
import React from "react";
import ShowAllGroups from "./ShowAllGroups";
import ManageGroup from "./ManageGroup";
import { UserType } from "interfaces";

const Group = () => {
  const { auth } = store.getState();
  const user: UserType = auth.user;
  const isGrouper =
    (user.grouper && user.profile.nome === "ADMIN" && user.usersLicense) ||
    user.profile.nome === "ADMIN" ;

  return isGrouper ? <ShowAllGroups /> : <ManageGroup user={user} />;
};

export default Group;

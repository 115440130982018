import styled from 'styled-components';

interface ContainerProps {
  bgImg: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media(max-width: 600px) {
    margin: 0 20px;
  }

  @media(min-width: 992px) {
    height: 800px;
    width: 800px;
  }

  @media(min-width: 768px) {
    height: 600px;
    width: 600px;
  }

  @media (min-width: 576px) {
    height: 400px;
    width: 400px;
  }

  @media (max-width: 576px) {
    height: 300px;
    width: 300px;
  }

  background-image: url(${props => props.bgImg});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 150%;
`;

export const defaultInnerWidth = 90;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: ${defaultInnerWidth + '%'};
`;

export const PatternButton = `
  border: none;
  width: 43%;
  height: 15%;
  left: 21%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100%;
  cursor: pointer;
  position: relative;
`;

interface ButtonDownloadAndroidProps {
  bgImage: string;
}

export const ButtonDownloadAndroid = styled.button<ButtonDownloadAndroidProps>`
    ${PatternButton}
    background-image: url(${props => props.bgImage});
    background-size: cover;  // Ensuring the background covers the button area
    background-position: center; // Center the background image
    position: relative; // Set position relative to allow for offset with 'bottom'
    /* bottom: -15%; */
    border: none; // Remove border if not needed
    cursor: pointer; // Indicate that the element is clickable

    &:hover {
        opacity: 0.8; // Add a hover effect for better user interaction feedback
    }

    &:focus {
        outline: none; // Remove default focus outline
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4); // Add custom focus style
    }

    &:active {
        transform: scale(0.98); // Slight scale down when the button is clicked
    }
`;

export const ButtonDownloadApple = styled.button<ButtonDownloadAndroidProps>`
    ${PatternButton}
    background-image: url(${props => props.bgImage});
    /* bottom: -13%; */
`;

import React from "react";
import { Box, Typography, Link, Container, Grid } from "@mui/material";
import { LinkedIn, Instagram } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";

const noar = {
  contact: {
    email: "",
    phone: ""
  },
  socialMedia: {
    linkedIn: "https://www.linkedin.com/company/noar-digital-scent",
    instagram: "https://www.instagram.com/noarbrasil/"
  }
};

const drawerWidth = 240;
const drawerWidthLeave = 70;

const Footer = ({ open, isMobile }: { open: boolean; isMobile: boolean }) => {
  const translate = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      py={2}
      // bgcolor="white"
      //
      sx={{
        marginLeft: isMobile
          ? 0
          : open
          ? `${drawerWidth}px`
          : `${drawerWidthLeave}px`
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} textAlign={{ xs: "center", sm: "left" }}>
            <Link
              href={`mailto:${noar.contact.email}`}
              color="inherit"
              underline="none"
            >
              <Typography variant="body2">{noar.contact.email}</Typography>
            </Link>
            <Typography variant="body2">{noar.contact.phone}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <Typography variant="body2">
              © {currentYear} {translate(`footerbar_title`)}
            </Typography>
            <Link
              href="/politica-de-privacidade"
              color="inherit"
              underline="hover"
            >
              <Typography variant="body2">
                {translate("defaultMessages.privacyPolicy")}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} textAlign={{ xs: "center", sm: "right" }}>
            <Link
              href={noar.socialMedia.linkedIn}
              target="_blank"
              rel="noopener"
              color="inherit"
              sx={{ mx: 1 }}
            >
              <LinkedIn fontSize="medium" />
            </Link>
            <Link
              href={noar.socialMedia.instagram}
              target="_blank"
              rel="noopener"
              color="inherit"
              sx={{ mx: 1 }}
            >
              <Instagram fontSize="medium" />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

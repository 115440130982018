import { useEffect } from "react";
import Loading from "app/pages/home/components/Loading";
import useManageDashboardData from "./hooks/useManageDashboardData";
import { Grid } from "@mui/material";
import {
  BarAndLineChartComponent,
  CounterCard,
  DoughnutChartComponent
} from "components";
import TestByMonthChart from "./components/TestByMonthChart";
import { useDashDsa } from "./context/DashdsaContext";

type DashDSAViewProps = ReturnType<typeof useManageDashboardData>;

export function DashDSAView(props: DashDSAViewProps): JSX.Element {
  const {
    loading,
    translate,
    device,
    dispositivos,
    numeroTeste,
    distinctMedicos,
    assinatura,
    pacientes,
    getTestDataByYear,
    getUniqueTestNames,
    getValuesByTestNamesAndYear,
    getGenreByTestNamesAndYear,
    getResults,
    getAgeGroups
  } = props;

  const {
    handleTestNamesChange,
    selectedTestNames,
    selectedYear
  } = useDashDsa();

  useEffect(() => {
    const testNames = getUniqueTestNames();
    if (testNames.length > 0) {
      handleTestNamesChange(testNames);
    }
  }, [getUniqueTestNames]);

  return (
    <>
      <Loading isLoading={loading} />
      <Grid container spacing={2} style={{ marginBottom: 30, marginTop: 10 }}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CounterCard
                title={translate("labels_dashboard_bases")}
                count={device.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CounterCard
                title={translate("labels_dashboard_doctors")}
                count={distinctMedicos.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CounterCard
                title={translate("labels_dashboard_allTests")}
                count={numeroTeste.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CounterCard
                title={translate("labels_devices_title")}
                count={dispositivos.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CounterCard
                title={translate("labels_dashboard_subscriptions")}
                count={assinatura}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CounterCard
                title={translate("labels_dashboard_patients")}
                count={pacientes.toString()}
              />
            </Grid>
          </Grid>
        </Grid>
        <TestByMonthChart
          getTestDataByYear={getTestDataByYear}
          testNamesList={getUniqueTestNames()}
          getValuesByTestNames={getValuesByTestNamesAndYear}
        />
        <Grid item xs={12} md={4}>
          <DoughnutChartComponent
            title={translate("labels_dashboard_testsSex")}
            data={getGenreByTestNamesAndYear(selectedTestNames, selectedYear)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DoughnutChartComponent
            title={translate("screenApp_results_screenName")}
            data={getResults(selectedTestNames, selectedYear)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BarAndLineChartComponent
            title={translate("labels_dashboard_testsAgeGroup")}
            data={getAgeGroups(selectedTestNames, selectedYear)}
          />
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Card, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Avatar, Box, Divider, Radio, Stack, Typography } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";

import { CapsuleI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import FormActions from "components/FormActions";
import Loading from "app/pages/home/components/Loading";
import { useTranslation } from "_metronic/i18n/language";
import { Wrapper } from "../Users/components/Wrapper";
import { FormInput } from "../Users/components/FormInput";
import { CollectionType, CapsuleType, CampaignType } from "app/types";

const CapsuleUpdate = () => {
  const params: any = useParams();
  const history = useHistory();
  const api = new AnanseApiService();
  const translate = useTranslation();
  const schema = Yup.object().shape({
    serialNumber: Yup.string()
      .required(translate("screens.login.validations.required"))
      .nullable(),
    dueDate: Yup.date().required(
      translate("screens.login.validations.required")
    )
  });

  const [loading, setLoading] = React.useState(false);
  const [enableButtonSave, setEnableButtonSave] = React.useState(false);
  const [capsuleStatus, setCapsuleStatus] = React.useState<
    "ativada" | "desativada"
  >("desativada");
  const [valueFragrance, setValueFragrance] = React.useState<CollectionType | undefined>(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerCode: "",
      capsuleId: 0,
      deviceId: 0,
      fragranceId: 0,
      dueDate: "",
      updatedAt: "",
      deletedAt: "",
      serialNumber: "",
      remainingShots: 0,
      performedShots: 0,
      macAddress: "",
      campaignName: ""
    }
  });

  React.useEffect(() => {
    loadingData();
  }, []);

  const loadingData = async () => {
    setLoading(true);
    try {
      const [response, result] = await Promise.all([
        api.makeHttpRequest({
          method: "GET",
          url: "settings"
        }),
        getCapsule(params.serialNumber, params?.fragranceId)
      ]);

      const capsules = await getCapsulesByDeviceId(result.deviceId.toString());

      if (!capsules || !capsules.length) {
        throw new Error("No capsules found");
      }

      const device = capsules?.[0].device;

      const campaignResponse = await getCampaignId(
        device.campaignId.toString()
      );

      checkCapsuleExists({
        ...result,
        capsules: capsules || [],
        collection: campaignResponse?.collection || []
      });

      const resultFragrance = campaignResponse?.collection.find(
        item => item.fragranceId === result.fragranceId
      );

      setValueFragrance(resultFragrance);
      setValue("customerCode", response.customerCode);
      setValue("capsuleId", result.capsuleId);
      setValue("deviceId", result.deviceId);
      setValue("fragranceId", result.fragranceId);
      setValue("dueDate", result.dueDate);
      setValue("serialNumber", result.serialNumber);
      setValue("remainingShots", result.remainingShots);
      setCapsuleStatus(result.deletedAt === null ? "ativada" : "desativada");
      if (device) {
        setValue("macAddress", device.macAddress);
      }
      if (campaignResponse) {
        setValue("campaignName", campaignResponse.name);
      }
    } catch (error) {
      console.warn("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCampaignId = async (
    campaignId: string
  ): Promise<CampaignType | undefined> => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `campaigns/${campaignId}`
      });
      return response;
    } catch (error) {
      console.error("Erro ao buscar o dispositivo:", error);
    }
  };

  const getCapsulesByDeviceId = async (
    deviceId: string
  ): Promise<CapsuleType[] | undefined> => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/device/${deviceId}`
      });
      return response;
    } catch (error) {
      console.error("Erro ao buscar o dispositivo:", error);
    }
  };

  const checkCapsuleExists = async ({
    fragranceId,
    serialNumber,
    capsules,
    collection
  }: {
    fragranceId: number;
    serialNumber: string;
    capsules: CapsuleType[];
    collection: CollectionType[];
  }): Promise<boolean> => {
    try {
      const filteredCollections = collection.filter(
        c => c.fragranceId === fragranceId
      );
      if (!filteredCollections.length) {
        Swal.fire({
          icon: "error",
          title: translate("screenApp_capsula_error"),
          text: translate("labels_fragrance_notCollection")
        }).then(() => {
          history.push("/admin/capsules");
        });
        return false;
      }

      const checkCapsulebyDevice = capsules.find(
        c => c.fragranceId === fragranceId
      );

      if (checkCapsulebyDevice) {
        if (checkCapsulebyDevice.serialNumber === serialNumber) {
          setEnableButtonSave(true);
          return true;
        }
        return false;
      }

      setEnableButtonSave(true);
      return true;
    } catch (error) {
      return false;
    }
  };

  const getCapsule = async (serialNumber: string, fragranceId: number) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/serial/${serialNumber}/${fragranceId}`
      });
      return { ...response };
    } catch (error) {
      return { status: fragranceId, serialNumber };
    }
  };

  const isValidDueDate = (dueDate: Date, capsuleStatus: string | null) => {
    const today = new Date().toISOString().split("T")[0];
    const dueDateFormatted = dueDate.toISOString().split("T")[0];

    if (capsuleStatus === "desativada" && dueDateFormatted !== today) {
      Swal.fire({
        icon: "error",
        title: translate("screenApp_capsula_error"),
        text: translate("labels_capsule_expirationToday")
      });
      return false;
    } else if (capsuleStatus === "ativada" && dueDateFormatted <= today) {
      Swal.fire({
        icon: "error",
        title: translate("screenApp_capsula_error"),
        text: translate("labels_capsule_laterToday")
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const reqBase = {
        method: "PUT",
        url: `/capsules/notremainingshots/${data.capsuleId}`
      };

      const dueDate = new Date(data.dueDate);
      if (!isValidDueDate(dueDate, capsuleStatus)) {
        setLoading(false);
        return;
      }

      const body: CapsuleI = {
        customerCode: data.customerCode,
        capsuleId: data.capsuleId,
        deviceId: data.deviceId,
        fragranceId: data.fragranceId,
        dueDate: data.dueDate,
        serialNumber: data.serialNumber,
        remainingShots: data.remainingShots,
        performedShots: data.performedShots || 1,
        deletedAt: capsuleStatus === "ativada" ? null : new Date().toISOString()
      };

      await api.makeHttpRequest({
        ...reqBase,
        data: body
      });

      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/capsules");
        }
      });
    } catch (e) {
      Swal.fire(
        translate(".defaultMessages.error"),
        translate(".screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <div>
      <Wrapper className="card-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row style={{ marginBottom: 10, alignItems: "center" }}>
              <Col>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Avatar
                    src={valueFragrance?.fragrance?.image?.tempUri}
                    alt={valueFragrance?.fragrance?.name}
                    sx={{ width: 56, height: 56 }}
                  />
                  <Typography variant="body1">
                    {translate("screens_collectionDevice_table_slot")}:{" "}
                    {valueFragrance?.slot} - {valueFragrance?.fragrance?.name}
                  </Typography>
                </Box>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("labels_capsule_status")}</Form.Label>
                <Controller
                  name="deletedAt"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Radio
                          color="success"
                          checked={capsuleStatus === "ativada"}
                          onChange={() => {
                            onChange(null);
                            setCapsuleStatus("ativada");
                          }}
                          value="ativada"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "Ativada" }}
                        />
                        <Typography>{translate("labels_activated")}</Typography>
                        <Radio
                          color="success"
                          checked={capsuleStatus === "desativada"}
                          onChange={() => {
                            onChange(new Date().toISOString());
                            setCapsuleStatus("desativada");
                          }}
                          value="desativada"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "Desativada" }}
                        />
                        <Typography>{translate("labels_disabled")}</Typography>
                      </Stack>
                    </Stack>
                  )}
                />
              </Form.Group>
            </Row>
            <Row>
              <FormInput
                label={translate("labels_code")}
                placeholder={translate("labels_code")}
                type="text"
                className="col-lg-4"
                register={register}
                name="customerCode"
                disable
              />
              <FormInput
                label={translate("screens.capsule.labels.serial")}
                placeholder={translate("screens.capsule.labels.serial")}
                type="text"
                className="col-lg-4"
                register={register}
                name="serialNumber"
                disable
              />
              <FormInput
                label={translate("screens_device_title")}
                placeholder={translate("screens_device_title")}
                type="text"
                className="col-lg-4"
                register={register}
                name="macAddress"
                disable
              />
              <FormInput
                label={translate("screens_quiz_list_campaign")}
                placeholder={translate("screens_quiz_list_campaign")}
                type="text"
                className="col-lg-4"
                register={register}
                name="campaignName"
                disable
              />
              <FormInput
                label={translate("screens.capsule.labels.dueDate")}
                placeholder={translate("screens.user.placeholders.document")}
                type="date"
                className="col-lg-4"
                required
                control={control}
                rules={{ required: true }}
                name="dueDate"
                min={new Date().toISOString().split("T")[0]}
                isInvalid={!!errors.dueDate}
                errorMessage={errors.dueDate?.message}
              />
              <FormInput
                label={translate("screens_capsule_labels_remaining")}
                placeholder={translate("screens_capsule_labels_remaining")}
                type="number"
                className="col-lg-4"
                register={register}
                name="remainingShots"
              />
            </Row>
            <Divider />
          </Card.Body>
          <FormActions
            module="capsules"
            onReset={handleReset}
            disableSubmit={!enableButtonSave}
          />
        </Form>
      </Wrapper>
      <Loading isLoading={loading} />
    </div>
  );
};

export default CapsuleUpdate;

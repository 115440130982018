import React from "react";
import { Row } from "react-bootstrap";
import withFormValidation from "../../../../hoc/withFormValidation";
import SelectSearchComponent from "../../../auth/Components/Select2";
import { AppInput } from "../../../../partials/form";
import { Button } from "@mui/material";
import { connect } from "react-redux";
import translate from "@noar-health/commons";
import { FragranceApiService } from "../../../../services/Ananse/FragranceApiService";
import Swal from "sweetalert2";
import "./style.scss";
import { TranslationContext } from "_metronic/i18n/language";
import { PieChartComponent } from "./components/PieChartComponent";
import TableFragrances from "./components/Table/TableFragrances";

class CollectionDevice extends React.Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);

    this.state = {
      translate: null,
      selectedIndex: props.defaultIndex
    };

    this.fragranceApi = new FragranceApiService();
    this.handleChange = this.handleChange.bind(this);
    this.updateConfiguration = this.updateConfiguration.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  handleChange(e) {
    const collection = [...this.props.collection];
    collection[this.state.selectedIndex][e.target.name] = e.target.value;
    this.props.updateCollection(collection);
  }

  updateConfiguration() {
    if (!this.props.validateForm()) {
      return;
    }

    const currentSlot = this.props.collection[this.state.selectedIndex];
    const slotWithSameFragrance = this.props.collection.filter(
      c =>
        c.fragrance &&
        c.fragrance.fragranceId === currentSlot.fragrance.fragranceId
    );

    if (slotWithSameFragrance.length > 1) {
      return this.handleChange({
        target: {
          name: "fragranceDuplicated",
          value: true
        }
      });
    }

    if (currentSlot.fragranceDuplicated) {
      this.handleChange({
        target: {
          name: "fragranceDuplicated",
          value: false
        }
      });
    }

    const nextIndex =
      this.state.selectedIndex === this.props.collection.length - 1
        ? 0
        : this.state.selectedIndex + 1;

    this.setState({ selectedIndex: nextIndex });
  }

  async clearSlot(slot) {
    this.initializeTranslation();
    const collection = [...this.props.collection];
    const index = collection.findIndex(c => c.slot === slot);

    const hasAnswer = await this.checkAnyAnswer(collection[index]);
    if (hasAnswer) {
      Swal.fire(
        this.state.translate("defaultMessages.error"),
        this.state.translate("screens.collectionDevice.validate.hasAnswer"),
        "error"
      );

      return;
    }

    delete collection[index].fragrance;
    this.props.updateCollection(collection);
  }

  async checkAnyAnswer(collection) {
    const { fragranceId, campaignId } = collection;
    const reqBase = {
      method: "GET",
      url: `has-answer/${fragranceId}/${campaignId}`
    };

    const response = await this.fragranceApi.makeHttpRequest({
      ...reqBase
    });

    return response;
  }

  render() {
    const translate = this.context;

    const slotConfig = this.props.collection[this.state.selectedIndex] || {};
    const configuredFragrances = this.props.collection
      .filter(
        c =>
          c.hasOwnProperty("fragrance") &&
          slotConfig &&
          c.slot !== slotConfig.slot
      )
      .sort((a, b) => a.slot - b.slot);

    const validationMessage = slotConfig
      ? this.props.validator.message(
          "fragranceId",
          slotConfig.fragrance,
          "required"
        )
      : null;

    const disableForm = this.state.selectedIndex === null;

    const center = [45, 60];
    const viewBoxSize = [100, 125];
    if (window.innerWidth < 650) {
      center[0] = 50;
      viewBoxSize[1] = 170;
    }

    return (
      <Row className="collection-device">
        <div className="col-lg-5 col-12 device">
          <ul className="legends mt-4">
            <li className="configured">
              <span /> {translate("screens.collectionDevice.legend.configured")}
            </li>
            <li className="not-configured">
              <span />{" "}
              {translate("screens.collectionDevice.legend.notConfigured")}
            </li>
            <li className="selected">
              <span /> {translate("screens.collectionDevice.legend.selected")}
            </li>
          </ul>
          <PieChartComponent
            selectedIndex={this.state.selectedIndex}
            collection={this.props.collection}
            label={({ dataEntry }) => dataEntry.label}
            center={center}
            viewBoxSize={viewBoxSize}
            onClick={(_, index) => {
              this.setState({ selectedIndex: index });
            }}
          />
        </div>

        <div className="col-lg-7 col-12 mt-3">
          <Row>
            <div className="collectionContainer">
              <div className="collectionDevice">
                <label>
                  {translate("screens.collectionDevice.labels.fragrance")}
                </label>
                <SelectSearchComponent
                  handleChange={this.handleChange}
                  name="fragrance"
                  className="kt-width-full"
                  disabled={disableForm}
                  label={translate(
                    "screens.collectionDevice.placeholders.fragrance"
                  )}
                  resetInput={!slotConfig.fragrance}
                  url={`/fragrances/quick-search?str=`}
                  convertObject={obj => ({
                    id: obj.fragranceId,
                    value: obj.fragranceId,
                    label: obj.name,
                    target: {
                      value: obj,
                      name: "fragrance"
                    }
                  })}
                  value={
                    slotConfig.fragrance
                      ? { label: slotConfig.fragrance.name }
                      : undefined
                  }
                  translate={translate}
                />
                {validationMessage}
                {!validationMessage && slotConfig.fragranceDuplicated ? (
                  <div className="kt-error-label text-left">
                    {translate("screens.collectionDevice.validate.duplicated")}
                  </div>
                ) : null}
              </div>
              <div className="collectionDevice">
                <label>
                  {translate("screens.collectionDevice.legend.shotSeconds")}
                </label>
                <AppInput
                  type="number"
                  name="shotSeconds"
                  placeholder={translate(
                    "screens.collectionDevice.placeholders.shotSeconds"
                  )}
                  min="1"
                  disabled={disableForm}
                  value={slotConfig.shotSeconds || ""}
                  onChange={this.handleChange}
                  validator={this.props.validator}
                  validations={"required|numeric"}
                />
              </div>
              <div className="collectionDevice">
                <label>
                  {translate("screens.collectionDevice.legend.expirationDays")}
                </label>
                <AppInput
                  type="number"
                  name="expirationDays"
                  placeholder={translate(
                    "screens.collectionDevice.placeholders.expirationDays"
                  )}
                  disabled={disableForm}
                  value={slotConfig.expirationDays || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className="mt-4"
                  style={{ color: "#fff", backgroundColor: "#677a88" }}
                  disabled={disableForm}
                  onClick={this.updateConfiguration}
                >
                  {translate("defaultMessages.ok")}
                </Button>
              </div>
            </div>
          </Row>
          <Row>
            {this.props.hasDeviceError && (
              <div className="mb-3">
                <div className="kt-error-label text-left">
                  {translate("screens.collectionDevice.validate.deviceError")}
                </div>
              </div>
            )}
            <div className="table-responsive">
              <TableFragrances
                slot={translate("screens.collectionDevice.table.slot")}
                fragrance={translate(
                  "screens.collectionDevice.table.fragrance"
                )}
                shotSeconds={translate(
                  "screens.collectionDevice.table.shotSeconds"
                )}
                expirationDays={translate(
                  "screens.collectionDevice.table.expirationDays"
                )}
                dataDelete={translate("datatable.delete")}
                nothingConfig={translate(
                  "screens.collectionDevice.table.nothingConfig"
                )}
                configuredFragrances={configuredFragrances}
                clearSlot={slot => this.clearSlot(slot)}
              />
            </div>
          </Row>
        </div>
      </Row>
    );
  }
}

function mapStateToProps(store) {
  return {
    translate: translate(store.i18n.lang)
  };
}
export default connect(mapStateToProps)(withFormValidation(CollectionDevice));

import { getCampaign, getDevice, getUser } from "app/crud";

export type MakeGetDeviceType = {
  deviceId: number;
  macAddress: string;
  connectedUser: string;
  campaignId: number;
  campaignName: string;
  ownerId: number;
  ownerName: string;
  isDisplay: boolean;
};

export const makeGetDevice = async (
  macAddress: string
): Promise<MakeGetDeviceType> => {
  const device = await getDevice(macAddress);
  const [campaign, owner] = await Promise.all([
    getCampaign(device.campaignId),
    getUser(device.ownerId)
  ]);

  return {
    ...device,
    campaignName: campaign.name,
    ownerName: owner.name
  };
};

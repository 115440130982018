import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import Loading from "app/pages/home/components/Loading";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import { formatToCPF } from "brazilian-values";
import FormActions from "components/FormActions";
import { UseSelectorI } from "interfaces";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Wrapper } from "../Users/components/Wrapper";
import { FormInput } from "../Users/components/FormInput";

const AccountEdit = (props: any) => {
  const params = useParams();
  const history = useHistory();

  const translate = useTranslation();
  const { auth } = store.getState();
  const api = new AnanseApiService();
  const [loading, setLoading] = useState(false);
  const [docType, setDocType] = useState("cpf");

  const schema = Yup.object().shape({
    cpf: Yup.string()
      // .test("valid-cpf", "CPF inválido!", (value: any) => {
      //   return isCPF(value);
      // })
      // .required(translate(locale).screens.login.validations.required)
      // .min(11, "O número do CPF deve conter 11 dígito!")
      .nullable(),
    // docType: Yup.string(),
    name: Yup.string().required(
      translate("screens.login.validations.required")
    ),
    genre: Yup.string().required(
      translate("screens.login.validations.required")
    ),
    email: Yup.string()
      // .required(translate(locale).screens.login.validations.required)
      .email(translate("screens.login.validations.invalid")),
    phone: Yup.string()
      // .required(translate(locale).screens.login.validations.required)
      .test(
        "valid-phone",
        "O número do celular deve conter 11 dígitos!",
        (value: any) => {
          return value.length == 0
            ? true
            : value.length > 0 && value.length <= 11
            ? false
            : true;
        }
      )
      // .min(11, "O número do celular deve conter 11 dígito!")
      .nullable(),
    birthDate: Yup.date().required(
      translate("screens.login.validations.required")
    )
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userId: "",
      cpf: "",
      name: "",
      genre: "",
      email: "",
      phone: "",
      birthDate: "",
      deletedAt: "",
      docType: ""
    }
  });

  useEffect(() => {
    loadingData();
  }, []);

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      phone: `+${data.phone.replace(/\D/g, "")}`,
      cpf: data.cpf.replace(/\D/g, ""),
      birthDate: new Date(data.birthDate).toISOString(),
      userId: auth.user.id
    };

    setLoading(true);
    try {
      const { id }: any = params;
      let reqBase: any;
      if (id) {
        console.log("payload put: ", payload);
        reqBase = { method: "PUT", url: `/account?id=${id}` };
      } else {
        console.log("payload post: ", payload);
        reqBase = { method: "POST", url: "/account" };
      }

      await api.makeHttpRequest({
        ...reqBase,
        data: payload
      });
      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      }).then(result => {
        if (result.isConfirmed) {
          history.push("/admin/account");
        }
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const loadingData = async () => {
    // console.log(data);
    setLoading(true);
    try {
      const { id }: any = params;
      if (id) {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/account/${id}`
        });
        reset({
          ...response,
          cpf: formatToCPF(response?.cpf.replace(/\D/g, ""))
        });
        console.log("loadingData: ", response);
      }
    } catch (e) {
      console.error(e);
      Swal.fire(
        translate("defaultMessages.error"),
        translate("screens.user.errors.register"),
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({});
  };

  return (
    <div>
      <Wrapper className="card-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <FormInput
                label={translate("screenApp.listPacient.account.name")}
                placeholder={translate("screens.user.placeholders.name")}
                type="text"
                className="col-lg-4"
                required
                register={register}
                name="name"
              />

              <Form.Group as={Col} lg="2" xs="12">
                <Form.Label>
                  {translate(
                    "screenApp.listPacient.account.selectGender.title"
                  )}{" "}
                  *
                </Form.Label>
                <Controller
                  name="genre"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      style={{ height: 38.4, width: "100%" }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(value: any) => {
                        setDocType(value.target.value);
                        onChange(value.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>{translate("screens.user.placeholders.genre")}</em>
                      </MenuItem>
                      <MenuItem value={"F"}>
                        {translate(
                          "screenApp.listPacient.account.selectGender.female"
                        )}
                      </MenuItem>
                      <MenuItem value={"M"}>
                        {translate(
                          "screenApp.listPacient.account.selectGender.male"
                        )}
                      </MenuItem>
                    </Select>
                  )}
                />
                {errors.genre && (
                  <span className="invalid-input">{errors.genre.message}</span>
                )}
              </Form.Group>
              <Form.Group as={Col} lg="2" xs="12">
                <Form.Label>
                  {translate("screens.user.labels.document")}
                </Form.Label>
                <Controller
                  name="docType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      style={{ height: 38.4, width: "100%" }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(value: any) => {
                        setDocType(value.target.value);
                        onChange(value.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"cpf"}>
                        {translate("screenApp.listPacient.account.cpf")}
                      </MenuItem>
                      <MenuItem value={"rg"}>
                        {translate("screenApp.listPacient.account.rg")}
                      </MenuItem>
                      <MenuItem value={"cnh"}>
                        {translate("screenApp.listPacient.account.cnh")}
                      </MenuItem>
                      <MenuItem value={"passport"}>
                        {translate("screenApp.listPacient.account.passport")}
                      </MenuItem>
                    </Select>
                  )}
                />
                {errors.docType && (
                  <span className="invalid-input">
                    {errors.docType?.message}
                  </span>
                )}
              </Form.Group>
              <FormInput
                label={translate("screenApp.listPacient.account.docNumber")}
                placeholder={translate("screens.user.placeholders.document")}
                type="text"
                className="col-lg-4"
                required
                control={control}
                rules={{ required: true }}
                name="cpf"
                value={getValues("cpf")}
                mask="000.000.000-00"
              />
            </Row>
            <Row>
              <FormInput
                label={translate("screenApp.listPacient.account.email")}
                placeholder={translate("screens.user.placeholders.email")}
                type="text"
                className="col-lg-4"
                register={register}
                name="email"
              />

              <FormInput
                label={translate("screenApp.listPacient.account.phone")}
                placeholder={translate("screens.user.placeholders.document")}
                type="text"
                className="col-lg-4"
                required
                control={control}
                rules={{ required: true }}
                name="phone"
                value={getValues("phone")}
                mask="+00 (00) 0 0000-0000"
              />

              <FormInput
                label={translate("screenApp.listPacient.account.birthdate")}
                placeholder={translate("screens.user.placeholders.document")}
                type="date"
                className="col-lg-4"
                required
                control={control}
                rules={{ required: true }}
                name="birthDate"
                value={getValues("birthDate")}
                max={new Date().toISOString().split("T")[0]}
                onChange={(value: any) =>
                  setValue("birthDate", value.target.value)
                }
              />
            </Row>
          </Card.Body>
          <FormActions module="account" onReset={handleReset} />
        </Form>
        <Loading isLoading={loading} />
      </Wrapper>
    </div>
  );
};

export default AccountEdit;

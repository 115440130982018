import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import { AnanseApiService } from "../../../../../services/Ananse/AnanseApiService";

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    }
  }
};

const api = new AnanseApiService();

const MultipleSelectCheckmarks = ({
  handleMultiSelect,
  value: getvalue,
  placeholder
}) => {
  const [data, setData] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [total, setTotal] = useState(99);

  const getListFromValue = async inputValue => {
    let searchedList = [];

    try {
      const searchedData = await api.makeHttpRequest({
        url: `/usuario?PerPage=${total}`
      });
      if (searchedData.data && searchedData.data.length > 0) {
        searchedData.data.forEach(item => {
          searchedList.push(item);
        });
        setTotal(searchedData.total);
      }
    } catch (error) {
      console.error(`Erro ao fazer a busca: ${error.message}`);
    }
    setData(searchedList);
    return searchedList;
  };

  useEffect(() => {
    let newValue =
      typeof getvalue === "string" ? getvalue.split(";") : getvalue;
    if (typeof getvalue === "string") {
      newValue = getvalue.split(";").map(x => Number(x.trim()));
    }
    getListFromValue();
    if (getvalue && personName.length < 1) {
      setPersonName(newValue);
    }
  }, [getvalue, personName]);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setPersonName(typeof value === "string" ? value.split(";") : value);
    handleMultiSelect(
      typeof value === "string" ? value.split(";") : value.join("; ")
    );
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <label className="form-label">{placeholder}</label>
        <Select
          multiple
          value={personName}
          onChange={handleChange}
          renderValue={selected => selected.join("; ")}
          MenuProps={MenuProps}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Médicos"
          style={{ height: 38.4 }}
        >
          {data.map(item => (
            <MenuItem key={item} value={item.id}>
              <Checkbox checked={personName.indexOf(item.id) > -1} />
              <ListItemText primary={item.id} secondary={item.nome} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectCheckmarks;

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Logo from "../../_assets/media/logos/login-logo.png";
import LogoLight from "../../_assets/media/logos/logo-light.png";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

interface BrandProps {
  brandClasses?: string;
}

const Brand: React.FC<BrandProps> = ({ brandClasses }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isDarkMode } = useThemeApp();
  return (
    <Box
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 2,
        marginRight: 8
      }}
    >
      <Link to="/admin">
        <Button>
          <img
            alt="logo"
            src={isDarkMode ? LogoLight : Logo}
            style={{ height: isMobile ? "35px" : "45px" }}
          />
        </Button>
      </Link>
    </Box>
  );
};

const mapStateToProps = (store: any) => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    })
  };
};

export default connect(mapStateToProps)(Brand);

export function formatFragrances(fragrances: any, collection: any[]) {
  if (!fragrances) {
    return "";
  }
  const fragrancesOptions = collection
    .map(e => ({
      label: e.fragrance?.name,
      value: e.fragrance?.fragranceId
    }))
    .filter(e => {
      if (Number(fragrances) === e.value) {
        return e.label;
      }
    });
  let result = "";
  result = fragrancesOptions[0]?.label || "";

  return result;
}
export function formatAnsewerFragrances(fragrances: any, collection: any[]) {
  if (!fragrances) {
    return "";
  }
  const fragrancesOptions = collection
    .map(e => ({
      label: e.fragrance?.name,
      value: e.fragrance?.fragranceId
    }))
    .filter(e => {
      if (Number(fragrances) === e.value) {
        return e.label;
      }
    });
  let result = "";
  result = fragrancesOptions[0].label;

  return result;
}

type ModelType = {
  name: string;
  campaignId: any;
  campaign: { name: any };
  language: any;
  maxShots: any;
  type: any;
  userId: any;
  anamneseId: any;
  mandatoryAnamnese: any;
  questions: any;
};

export function getStateByModel(model: ModelType, nameSuffix = "") {
  return {
    quizName: model.name + nameSuffix,
    name: model.name + nameSuffix,
    campaignId: model.campaignId,
    campaignName: model.campaign.name,
    language: model.language,
    maxShots: model.maxShots,
    type: model.type,
    userId: model.userId,
    anamneseId: model.anamneseId,
    mandatoryAnamnese: model.mandatoryAnamnese,
    questions: model.questions
  };
}

import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton
} from "@mui/material";
import { Preview } from "@mui/icons-material";
import PreViewQuiz from "./preview/preViewQuiz";
import { QuizType } from "app/types";
import { isValidQuizType } from "../utils";

type FrameModalProps = {
  quiz: QuizType;
};

const defaultBackground =
  "https://noarhealth.blob.core.windows.net/prod/images/app/a2802944-8a89-4bd7-87eb-1c6f8ae1c0d4.jpg?sv=2024-05-04&se=2050-08-08T00%3A00%3A00Z&sr=c&sp=r&sig=B79ag0U6rxmBjmOH1pzrV3Yh1DMtCMI4XvYNBvTZux8%3D";

const FrameModal: React.FC<FrameModalProps> = ({ quiz }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [frameType, setFrameType] = useState<string>("iphone-12-pro");
  const imageBackground =
    quiz.questions?.[currentQuestionIndex].files?.[0]?.tempUri as string;

  const handleOpen = () => {
    setCurrentQuestionIndex(0);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentQuestionIndex(0);
    setOpen(false);
  };

  const handleFrameChange = (event: any) => {
    setFrameType(event.target.value as string);
  };

  const handleNextQuestionIndex = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const backgroundStyle = {
    backgroundImage: `url(${imageBackground || defaultBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  };
  const renderFrame = () => {
    const scaleFactor = 0.2;
    const previewQuizProps = {
      quiz,
      currentQuestionIndex,
      handleNextQuestionIndex
    };

    switch (frameType) {
      case "iphone-12-pro": {
        const bottomRadius = `${12}px`;
        return (
          <div
            style={{
              width: `${1170 * scaleFactor}px`,
              height: `${2532 * scaleFactor}px`,
              backgroundColor: "#eeeff0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderRadius: 20,
              overflow: "hidden",
              border: `${4 * scaleFactor}px solid black`,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",

              flexDirection: "column"
            }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                width: `100%`,
                height: `${220 * scaleFactor}px`
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: `100%`,
                height: "90%",
                ...backgroundStyle
              }}
            >
              <PreViewQuiz {...previewQuizProps} />
            </div>
            <div
              style={{
                backgroundColor: "#000",
                width: `${600 * scaleFactor}px`,
                height: `${100 * scaleFactor}px`,
                borderBottomLeftRadius: bottomRadius,
                borderBottomRightRadius: bottomRadius,
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)"
              }}
            ></div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: `100%`,
                height: `${120 * scaleFactor}px`
              }}
            >
              <div
                style={{
                  backgroundColor: "#050505",
                  width: `38.5%`,
                  height: `${16 * scaleFactor}px`,
                  borderRadius: bottomRadius,
                  position: "absolute",
                  bottom: 5,
                  left: "50%",
                  transform: "translateX(-50%)"
                }}
              ></div>
            </div>
          </div>
        );
      }
      case "ipad-10":
        return (
          <div
            style={{
              height: `${2360 * scaleFactor}px`,
              width: `${1640 * scaleFactor}px`,
              backgroundColor: "#f8f8f8",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderRadius: 20,
              overflow: "hidden",
              border: `${4 * scaleFactor}px solid black`,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              ...backgroundStyle
            }}
          >
            <PreViewQuiz {...previewQuizProps} />
          </div>
        );
      case "moto-g8-plus":
        return (
          <div
            style={{
              width: `${1080 * scaleFactor}px`,
              height: `${2280 * scaleFactor}px`,
              backgroundColor: "#f8f8f8",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderRadius: 20,
              overflow: "hidden",
              border: `${4 * scaleFactor}px solid black`,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              ...backgroundStyle
            }}
          >
            <PreViewQuiz {...previewQuizProps} />
            <div
              style={{
                backgroundColor: "#000",
                width: `${90 * scaleFactor}px`,
                height: `${70 * scaleFactor}px`,
                borderBottomLeftRadius: `${8}px`,
                borderBottomRightRadius: `8px`,
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)"
              }}
            ></div>
          </div>
        );
      case "tablet-multilaser-m8-4g": {
        const mv = 30;
        const mh = 10;
        const addscale = scaleFactor + 0.15;
        return (
          <div
            style={{
              width: `${800 * addscale}px`,
              height: `${1280 * addscale}px`,
              backgroundColor: "#f8f8f8",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderRadius: 0,
              overflow: "hidden",
              border: `${4 * scaleFactor}px solid black`,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              marginTop: mv,
              marginBottom: mv,
              marginLeft: mh,
              marginRight: mh,
              ...backgroundStyle
            }}
          >
            <PreViewQuiz {...previewQuizProps} />
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div>
      <IconButton
        disabled={!isValidQuizType(quiz.type)}
        title="Pre-Visualizar Teste"
        onClick={handleOpen}
      >
        <Preview />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography variant="h6" component="h2">
            Selecione o dispositivo
          </Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Select value={frameType} onChange={handleFrameChange} size="small">
              <MenuItem value="iphone-12-pro">iPhone 12 Pro</MenuItem>
              <MenuItem value="ipad-10">iPad 10</MenuItem>
              <MenuItem value="moto-g8-plus">Moto G8 Plus</MenuItem>
              <MenuItem value="tablet-multilaser-m8-4g">
                Tablet Multilaser M8-4G
              </MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #C0C0C0",
              p: 1,
              overflow: "auto",
              borderRadius: 4,
              backgroundColor: "#000000"
            }}
          >
            {renderFrame()}
          </Box>
          <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
            Fechar
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default FrameModal;

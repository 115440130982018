import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles/";
import Icon from "@mui/material/Icon/";
import IconButton from "@mui/material/IconButton/";
import { Delete, ToggleOn, Mail, ToggleOff } from "@mui/icons-material";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import ConfirmChangeStatusDialog from "./ConfirmChangeStatusDialog";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { FaClone } from "react-icons/fa";
import { UseSelectorI } from "interfaces";
import { useTranslation } from "_metronic/i18n/language";

const useStyles = makeStyles((theme: any) => ({
  button: {
    margin: theme.spacing(0)
  },
  div: {
    display: "flex",
    flexDirection: "row"
  },
  buttonActive: {
    margin: theme.spacing(0),
    color: "#0D2551"
  }
}));

interface Props {
  actions?: any;
  onConfirmDelete?: any;
  onConfirmChangeStatus?: any;
  module?: any;
  id?: any;
  obj?: any;
  onViewDetails?: any;
  onConfirmResendEmail?: any;
  onEdit?: any;
}

function CrudActions({
  actions,
  onConfirmDelete,
  onConfirmChangeStatus,
  module,
  id,
  obj,
  onViewDetails,
  onConfirmResendEmail,
  onEdit
}: Props) {
  const classes = useStyles();
  const locale = useSelector(({ i18n }: UseSelectorI) => i18n.lang);
  const translate = useTranslation();
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmChangeStatus, setConfirmChangeStatus] = React.useState(false);

  function toggleConfirmDialog(state: any) {
    setConfirmDelete(state);
  }

  function toggleChangeStatusDialog(state: any) {
    setConfirmChangeStatus(state);
  }

  async function handleConfirmDelete(id: any) {
    try {
      toggleConfirmDialog(false);
      await onConfirmDelete(id);

      Swal.fire({
        title: translate('defaultMessages.success'),
        text: translate('defaultMessages.deletedSuccess'),
        icon: "success",
        cancelButtonText: translate('buttons.exit')
      });
    } catch (e) {
      console.log(e);
      Swal.fire(
        translate('defaultMessages.error'),
        translate('defaultMessages.deletedSuccess'),
        "error"
      );
    }
  }

  async function handleChangeStatus(obj: any) {
    toggleChangeStatusDialog(false);

    try {
      toggleConfirmDialog(false);
      await onConfirmChangeStatus(obj);

      Swal.fire({
        title: translate('defaultMessages.success'),
        text: translate('changeStatus.success'),
        icon: "success",
        cancelButtonText: translate('buttons.cancel')
      });
    } catch (e) {
      Swal.fire(
        translate('defaultMessages.error'),
        translate('changeStatus.error'),
        "error"
      );
    }
  }

  function handleViewDetails(obj: any) {
    onViewDetails(obj);
  }

  function handleResendMail(obj: any) {
    toggleChangeStatusDialog(false);
    onConfirmResendEmail(obj);
  }

  return (
    <div className={classes.div}>
      {actions && actions.includes("delete") && (
        <>
          <ConfirmDeleteDialog
            open={confirmDelete}
            closeDialog={() => toggleConfirmDialog(false)}
            confirm={() => handleConfirmDelete(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title={translate('datatable.delete')}
            onClick={() => toggleConfirmDialog(true)}
          >
            <Delete fontSize="small" />
          </IconButton>
        </>
      )}

      {actions && actions.includes("resend") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmChangeStatus}
            closeDialog={() => setConfirmChangeStatus(false)}
            confirm={() => handleResendMail(obj)}
            message={translate('changeStatus.activeUser')}
            title={translate('changeStatus.confirmTitle')}
          />

          <IconButton
            size="small"
            color="inherit"
            className={classes.button}
            title={translate('changeStatus.resend')}
            onClick={() => toggleChangeStatusDialog(true)}
          >
            <Mail fontSize="small" />
          </IconButton>
        </>
      )}

      {actions && actions.includes("toggle") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmChangeStatus}
            closeDialog={() => setConfirmChangeStatus(false)}
            confirm={() => handleChangeStatus(obj)}
            // message={`Deseja ${(obj && !obj.deletedAt) ? "inativar" : "ativar"} o registro selecionado?`}
            message={
              obj && !obj.deletedAt
                ? translate('changeStatus.inactiveRegister')
                : translate('changeStatus.activeRegister')
            }
            title={translate('changeStatus.confirmTitle')}
          />

          {obj && !obj.deletedAt ? (
            <IconButton
              size="small"
              className={classes.buttonActive}
              title={translate('changeStatus.inactive')}
              onClick={() => toggleChangeStatusDialog(true)}
            >
              <ToggleOn fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              className={classes.button}
              title={translate('changeStatus.active')}
              onClick={() => toggleChangeStatusDialog(true)}
            >
              <ToggleOff fontSize="small" />
            </IconButton>
          )}
        </>
      )}

      {actions && actions.includes("edit") && !onEdit && (
        <Link to={`/admin/${module}/${id}/update`}>
          <IconButton
            size="small"
            className={classes.button}
            title={translate('buttons.editRegister')}
          >
            <Icon fontSize="small">create</Icon>
          </IconButton>
        </Link>
      )}

      {actions && actions.includes("edit") && onEdit && (
        <IconButton
          size="small"
          className={classes.button}
          title={translate('buttons.editRegister')}
          onClick={() => onEdit(obj)}
        >
          <Icon fontSize="small">create</Icon>
        </IconButton>
      )}

      {actions && actions.includes("show") && (
        <IconButton
          // color='primary'
          onClick={() => handleViewDetails(obj)}
          size="small"
          className={classes.button}
          title={translate('buttons.detail')}
        >
          <Icon fontSize="small">visibility</Icon>
        </IconButton>
      )}
      {actions && actions.includes("clone") && (
        <Link to={`/admin/${module}/${id}/clone`}>
          <IconButton
            size="small"
            className={classes.button}
            title={translate('buttons.clone')}
          >
            <FaClone />
          </IconButton>
        </Link>
      )}
    </div>
  );
}

export default CrudActions;

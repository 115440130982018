import { Answer, QuestionType } from "./preViewQuiz";

type PreviewIdentificationProps = {
  currentQuestion: QuestionType;
  selectedAnswerId: number | null;
  scaleFactor: number;
  answers: Answer[];
  currentQuestionIndex: number;
  questionsLength: number;
  handleSelectAnswer: (answerId: number) => void;
  handleNextQuestion: () => void;
};

const PreviewIdentification = ({
  currentQuestion,
  selectedAnswerId,
  questionsLength,
  scaleFactor,
  answers,
  currentQuestionIndex,
  handleNextQuestion,
  handleSelectAnswer
}: PreviewIdentificationProps) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "360px",
        width: "100%",
        textAlign: "center"
      }}
    >
      <p
        style={{
          marginTop: "40px",
          marginBottom: "10px",
          fontSize: `${14 * scaleFactor}px`,
          color: "#000000"
        }}
      >
        Você pode acionar o cheiro: 1 tempo(s).
      </p>
      <h2
        style={{
          marginTop: "20px",
          marginBottom: "15px",
          fontSize: `${20 * scaleFactor}px`,
          fontWeight: "normal",
          textAlign: "left"
        }}
      >
        {currentQuestion.question.quest}
      </h2>
      <div>
        {answers.map(answer => (
          <div
            key={answer.quizQuestionAnswerId}
            style={{ marginBottom: "10px" }}
          >
            <button
              onClick={() => handleSelectAnswer(answer.answerId)}
              style={{
                width: "100%",
                padding: "7px",
                borderRadius: "6px",
                border:
                  selectedAnswerId === answer.answerId
                    ? `${4 * scaleFactor}px solid #9da5fa`
                    : `${4 * scaleFactor}px solid #444349`,
                backgroundColor:
                  selectedAnswerId === answer.answerId ? "#F0F0FF" : "white",
                fontSize: `${18 * scaleFactor}px`,
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              {answer.answer.label}
              {selectedAnswerId === answer.answerId && (
                <span
                  style={{
                    width: `${30 * scaleFactor}px`,
                    height: `${30 * scaleFactor}px`,
                    border: `${6 * scaleFactor}px solid #9da5fa`,
                    borderRadius: "50%",
                    backgroundColor: "#F0F0FF"
                  }}
                ></span>
              )}
            </button>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px"
        }}
      >
        <button
          style={{
            flex: 1,
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            backgroundColor: "#FF6B6B",
            color: "white",
            fontSize: `${16 * scaleFactor}px`,
            cursor: "pointer",
            marginRight: "10px"
          }}
        >
          CHEIRO
        </button>
        <button
          onClick={handleNextQuestion}
          disabled={currentQuestionIndex >= questionsLength - 1}
          style={{
            flex: 1,
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            backgroundColor: selectedAnswerId ? "#9da5fa" : "#C4C4C4",
            color: "white",
            fontSize: `${16 * scaleFactor}px`,
            cursor: "pointer"
          }}
        >
          SEGUINTE &gt;&gt;
        </button>
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: `${14 * scaleFactor}px`,
          fontSize: `${14 * scaleFactor}px`,
          color: "#ffffff",
          backgroundColor: "#a1a1a9",
          borderRadius: "5px",
          padding: "1px"
        }}
      >
        Pergunta {currentQuestionIndex + 1} / {questionsLength}
      </div>
    </div>
  );
};
export default PreviewIdentification;

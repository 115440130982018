import * as React from "react";
import { Close } from "@mui/icons-material";
import { Divider, IconButton, Radio, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

import store from "app/store/store";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import NoarLogo from "assets/components/NoarLogo";
import { calculateTime, formatedDate, getFirstCharacter } from "utils";
import PrintResult from "./PrintResult";
import TableDescription from "./TableDescription.js";
import "./styles.css";
import { IQuiz, IResultDetail } from "interfaces";
import { useTranslation } from "_metronic/i18n/language";
import RadarChart from "app/pages/admin/components/RadarChart";
import RadarChartComponent from "./SliderShowResult";
import { useRTL } from "themes/RTLProvider/RTLProvider";

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  setData: any;
  accountId: any;
  itIsAnamnesis: boolean;
}
const api = new AnanseApiService();

export default function ModalResult({
  openModal,
  setOpenModal,
  data,
  setData,
  accountId,
  itIsAnamnesis
}: Props) {
  const translate = useTranslation();
  const isRtl = useRTL();
  const valueTransformIfRtl = isRtl ? "scaleX(-1)" : undefined;
  const [quiz, setQuiz] = React.useState({} as IQuiz);
  const [questions, setQuestions] = React.useState([]);
  const [account, setAccount] = React.useState<any>({});
  const [resultDetail, setResultDetail] = React.useState({} as IResultDetail);
  const { auth } = store.getState();
  const handleClose = () => {
    setOpenModal(false);
    setData(null);
  };

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [selectedValue, setSelectedValue] = React.useState("all");
  const [results, setResults] = React.useState<Array<any>>([]);

  const printAccountRef = React.useRef(null);

  const frase = translate("screenApp.results.testName");
  const titleTest = !itIsAnamnesis ? frase : frase.substring(0, 9) + "Anamnese";
  const dateNow = new Date();
  const replaceName = account?.name; //.replace(/\s+/g, "-");
  const namefile = `${replaceName}_${
    data?.testResultId
  }_${dateNow.toISOString()}`;

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: windowWidth <= 900 ? "95%" : "95%",
    minWidth: "75%",
    height: "95%",
    bgcolor: "#FFF",
    color: "#000",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    overflow: "auto"
  };

  React.useEffect(() => {
    loadRecords(fillResults);
  }, [data?.quizAnswer]);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadRecords = async (cb?: () => void) => {
    if (cb) {
      cb();
    }
    try {
      await getQuizId(data?.quizAnswer[0]?.quizId);
      const response = await api.makeHttpRequest({ url: "/question" });
      const result = await api.makeHttpRequest({
        url: `/question?PerPage=${response.total}`
      });
      setQuestions(result.data);
      const responseAccount = await api.makeHttpRequest({
        url: `/account/${accountId}`
      });
      setAccount(responseAccount);
    } catch (error) {
      Swal.fire(
        translate("defaultMessages.error"),
        translate("defaultMessages.loadData"),
        "error"
      );
    }
  };
  const getQuizId = async (quizId: number) => {
    try {
      const response: IQuiz = await api.makeHttpRequest({
        url: `/quiz/${quizId}`
      });
      console.log("getQuizId: ", response);
      setQuiz(response);
    } catch (error) {
      console.error("error getQuizId: ", error);
    }
  };

  const selectTheTypeQuiz = [
    { key: 1, name: translate("screenApp.listPacient.nameTest") },
    { key: 2, name: "Anamnese" },
    { key: 3, name: translate("screenApp.results.discriminationTest") }
  ];

  const getTypeQuiz = (type: string) => {
    const findTypequiz = selectTheTypeQuiz.find(f => f.key === Number(type));
    return findTypequiz?.name || "";
  };

  const getResultDetails = React.useCallback(async () => {
    try {
      const payload = {
        accountId: data?.quizAnswer[0].accountId,
        testResultId: data?.testResultId,
        userId: data?.quizAnswer[0].userId
      };
      const reqBase = {
        method: "GET",
        url: `/quizanswerdetails/quizanswerdetails/${payload.testResultId}/${payload.userId}/${payload.accountId}`
      };
      // console.log("loadData reqBase: ", reqBase);
      const response = await api.makeHttpRequest({
        ...reqBase
      });
      setResultDetail(response[0]);
    } catch (error) {
      // console.error("getResultDetails", error);
    }
  }, [data]);

  React.useEffect(() => {
    getResultDetails();
  }, [getResultDetails]);

  const fillResults = () => {
    const dataResults: Array<any> = [];
    data?.quizAnswer.forEach((value: any) => {
      const currentResult = dataResults.find(
        e => e.question === value.question
      );
      if (!currentResult) {
        dataResults.push(value);
      }
    });
    setResults(dataResults);
  };

  const filterQuestion = (value: any) => {
    const quest: any = questions.filter((e: any) => {
      return e.questionId === value.question;
    });
    return quest;
  };

  const acertos = () => {
    if (data?.quizAnswer[0]?.rightAnswer === "N/A") {
      return "N/A";
    }
    const filter = data?.quizAnswer?.filter(
      (x: any) => x.rightAnswer === x.answer
    );

    const text = `${filter?.length}  ${translate(
      "screenApp.results.hits"
    )} ${translate("datatable.of")} ${data?.quizAnswer.length}`;
    return text;
  };

  const renderProfissional = () => {
    if (data?.quizAnswer[0]?.doctor) {
      return (
        <h4 className="labels">
          <b>{translate("screenApp.results.title")}: </b>
          {data?.quizAnswer[0]?.doctor}- <b>CRM: </b>
          {data?.quizAnswer[0]?.professionalId}
        </h4>
      );
    }
    return "";
  };

  const totalTime = () => {
    const lastIndex = data?.quizAnswer.length - 1;
    const time = calculateTime(
      data?.quizAnswer[0]?.initDate,
      data?.quizAnswer[lastIndex]?.endDateTime
    );
    return time;
  };

  const _renderInfo = () => (
    <div className="tableClassification">
      <div>
        <div>
          <div className="headerPdf">
            <NoarLogo />
            <div className="containerTitle">
              <h3 className="title">{titleTest}</h3>
            </div>
          </div>
          <div className="containerForm">
            <table className="top">
              <tr className="top">
                <th className="top">
                  <div className="folha">
                    <h4 className="labels">
                      <b>{translate("screenApp.results.patient")}: </b>
                      {account?.name || ""}
                    </h4>
                    <h4 className="labels">
                      <b>{translate("screenApp.results.record")}: </b>
                      {/* {maskCPFCNPJ(account?.cpf)} */}
                      {account?.cpf}
                    </h4>
                    <h4 className="labels">
                      <b>DN: </b>
                      {formatedDate(account?.birthDate, true)}
                    </h4>
                    <h4 className="labels">
                      <b>{translate("screenApp.results.examLocation")}: </b>
                      {data?.quizAnswer[0]?.location}
                    </h4>
                  </div>
                </th>
                <th className="top">
                  <div className="folha1">
                    {renderProfissional()}
                    <h4 className="labels">
                      <b>{translate("screenApp.results.examDate")}: </b>{" "}
                      {formatedDate(data?.quizAnswer[0]?.createdAt, true, true)}
                    </h4>
                    <h4 className="labels">
                      <b>{translate("screenApp.results.numberTest")}: </b>{" "}
                      {data?.testResultId}
                    </h4>
                    <h4 className="labels">
                      <b>{translate("screens.device.title")}: </b>
                      {data?.quizAnswer[0]?.macAddress}
                    </h4>
                    <h4 className="labels">
                      <b>{translate("screenApp.results.performedBy")}: </b>{" "}
                      {auth.user.nome}
                    </h4>
                  </div>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="fix">
        <h4 className="fix">
          <b>{translate("screenApp.results.technicalManager")}: </b> Dr. Marcio
          Nakanishi CRM-DF 13.081
        </h4>
        <h4 className="fix">
          <b>{translate("screens.register.step3")}: </b> SGAS 610 BL I Sla S-2,
          Brasília - DF, 70200-700
        </h4>
      </div>
      <div>
        <h2 className="titleLeft">
          <b>{translate("screenApp.results.testSubName")}</b>
        </h2>
        {data?.quizAnswer[0].otherAnswers ? null : (
          <>
            <h4 className="titleLeft">
              <b>{translate("screenApp.results.method")}: </b>
              {translate("screenApp.results.testIdentification")}.
            </h4>
            <h4 className="titleLeft">
              <b>{translate("screens.quiz.list.title")}: </b>{" "}
              {/* {"Teste de Idetificação"} */}
              {getTypeQuiz(quiz.type)}
            </h4>
          </>
        )}
      </div>
      <div>
        {data?.quizAnswer[0].otherAnswers ? null : (
          <h2 className="titleLeft">
            <b>{translate("screenApp.results.result")}: </b>
            {acertos()}
          </h2>
        )}
        <h2 className="titleLeft">
          <b>{translate("screenApp.results.time2")}: </b>
          {totalTime()}
        </h2>
      </div>
    </div>
  );

  const _renderResult = () => (
    <div className="container" style={{ color: "#000" }}>
      {_renderInfo()}
      {/* tabela de resultados */}
      {selectedValue !== "a" && (
        <div className="folha">
          <h1>{translate("screenApp.results.detailing")}</h1>
          <table className="result">
            <tr className="result">
              <th className="result">
                {translate("screenApp.results.question")}
              </th>
              <th className="result">
                {translate("screenApp.results.patientResponse")}
              </th>
              {itIsAnamnesis ? null : (
                <th className="result">
                  {translate("screenApp.results.rightResponse")}
                </th>
              )}
              {itIsAnamnesis ? null : (
                <th className="result">
                  {translate("screenApp.results.score")}
                </th>
              )}
              {itIsAnamnesis ? null : (
                <th className="result">
                  {translate("screenApp.results.shots")}
                </th>
              )}
              <th className="result">{translate("screenApp.results.time")}</th>
            </tr>

            {results?.map((item: any, index: any) => {
              const quest = filterQuestion(item);
              if (item.otherAnswers) {
                return (
                  <tr key={index} className="result">
                    <td className="result">
                      {getFirstCharacter(quest[0]?.quest)}
                    </td>
                    <td className="result">{item.otherAnswers}</td>
                    {item.rightAnswer === "N/A" ? (
                      ""
                    ) : (
                      <td className="result">{item.shots || 0}</td>
                    )}
                    <td className="result">
                      {calculateTime(item.initDate, item.endDateTime)}
                    </td>
                  </tr>
                );
              }
              return (
                <tr key={index} className="result">
                  <td className="result">
                    {getFirstCharacter(quest[0]?.quest)}
                  </td>
                  <td className="result">{item.answer}</td>
                  {item.rightAnswer === "N/A" ? (
                    ""
                  ) : itIsAnamnesis ? null : (
                    <td className="result">{item.rightAnswer}</td>
                  )}
                  {item.rightAnswer === "N/A" ? (
                    ""
                  ) : itIsAnamnesis ? null : (
                    <td className="result">
                      <span
                        style={{
                          color:
                            item.rightAnswer === item.answer ? "black" : "red"
                        }}
                      >
                        {item.rightAnswer === item.answer
                          ? translate("screenApp.results.right")
                          : translate("screenApp.results.wrong")}
                      </span>
                    </td>
                  )}
                  {item.rightAnswer === "N/A" ? (
                    ""
                  ) : (
                    <td className="result">{item.shots || 0}</td>
                  )}
                  <td className="result">
                    {calculateTime(item.initDate, item.endDateTime)}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );

  const renderDetails = React.useCallback(() => {
    console.log("resultDetail", resultDetail);
    return (
      <>
        {resultDetail?.observation ? (
          <>
            <div className="page-break" />
            <div className="observation">
              <h1>{translate("screenApp.results.comments")}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: resultDetail?.observation }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }, [resultDetail]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const Buttons = () => {
    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={0} alignItems={"center"}>
            <Typography>
              {translate("screens.campaigns.list.modeView")}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems={"center"}>
            <Radio
              checked={selectedValue === "all"}
              onChange={handleChange}
              value="all"
              name="radio-buttons"
              inputProps={{ "aria-label": "ALL" }}
              color="success"
              sx={{ color: "#000" }}
            />
            <Typography>{translate("screens.campaigns.list.modeM")}</Typography>
          </Stack>
          <Divider
            style={{ backgroundColor: "#000" }}
            orientation="vertical"
            flexItem
          />
          <Stack direction="row" spacing={0} alignItems={"center"}>
            <Radio
              checked={selectedValue === "a"}
              onChange={handleChange}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
              color="success"
              sx={{ color: "#000" }}
            />
            <Typography>{translate("screens.campaigns.list.modeP")}</Typography>
          </Stack>
          <IconButton onClick={handleClose} sx={{ color: "#000" }}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>
    );
  };

  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ transform: valueTransformIfRtl }}
      >
        <Box sx={style}>
          <PrintResult nameFile={namefile} ChildrenB={<Buttons />}>
            {data?.quizAnswer[0].otherAnswers ? (
              <div className="container">
                <RadarChartComponent
                  quizAnswers={data?.quizAnswer}
                  questions={questions}
                >
                  {_renderInfo()}
                </RadarChartComponent>
              </div>
            ) : (
              <div className="print">
                {_renderResult()}
                {itIsAnamnesis ? null : (
                  <TableDescription
                    refDescription={printAccountRef}
                    printA={selectedValue === "a" || selectedValue === "all"}
                    printB={selectedValue === "all"}
                  />
                )}
                {renderDetails()}
              </div>
            )}
          </PrintResult>
        </Box>
      </Modal>
    </div>
  );
}

import React from "react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

type MonthlyTestResultsProps = {
  data: {
    name: string;
    value: number;
    monthIndex: number;
  }[];
  title: string;
  actionFilterComponent?: JSX.Element | null;
};

const truncarText = (str: string, maxLength: number) => {
  return str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;
};

const MonthlyTestResults: React.FC<MonthlyTestResultsProps> = ({
  data,
  title,
  actionFilterComponent: ActionFilterComponent
}) => {
  const translate = useTranslation();

  return (
    <Card>
      <CardHeader title={title} action={ActionFilterComponent} />
      <Divider variant="middle" />
      <CardContent>
        <ResponsiveContainer width="100%" height={217}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tickFormatter={month => truncarText(month, 5)} // Já traduzido no chartData
            />
            <YAxis />
            <Tooltip formatter={(value: any) => [value]} />
            <Legend />
            <Bar
              dataKey="value"
              name={translate("labels_dashboard_testsNumbers")}
              fill="#8884d8"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default MonthlyTestResults;

import React, { useState, useEffect, useCallback } from "react";
import { TableCell, TableRow } from "@mui/material";
import { useParams } from "react-router-dom";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import DataTable from "app/pages/admin/pages/Result/components/DataTable";
import { formatedDate } from "utils";
import BackWithTitle from "app/pages/admin/pages/Result/components/BackWithTitle";
import Loading from "app/pages/home/components/Loading";
import { useTranslation } from "_metronic/i18n/language";

interface ICapsuleHistory {
  id?: number;
  capsuleHistoryId: number;
  capsuleId: number;
  userId: number;
  performedShots: number;
  remainingShots: number;
  nome: string;
  email: string;
  cpf: string;
  genero: string;
  comunicacao: number;
  telefone: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

const CapsuleDetailing = () => {
  const params: any = useParams();
  const [capsuleHistory, setCapsuleHistory] = useState<ICapsuleHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const translate = useTranslation();
  const api = new AnanseApiService();

  const getCapsule = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/serial/${params.serialNumber}/${params.fragranceId}`
      });
      getCapsuleHistory(response.capsuleId);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [params]);

  function sortByCreatedAt(a: any, b: any) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  const getCapsuleHistory = async (capsuleId: number) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsule-history/${capsuleId}`
      });
      const data = response.sort(sortByCreatedAt);
      setCapsuleHistory(data);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    getCapsule();
  }, [getCapsule]);

  const mergedData = capsuleHistory.reduce(
    (accumulator: any, currentValue: any) => {
      const existingItem: any = accumulator.find(
        (item: any) => item.createdAt === currentValue.createdAt
      );

      if (existingItem) {
        existingItem.performedShots += currentValue.performedShots;
        existingItem.remainingShots += currentValue.remainingShots;
      } else {
        accumulator.push(currentValue);
      }

      return accumulator;
    },
    []
  );

  const headRows = [
    {
      id: "createdAt",
      label: translate("screenApp.capsula.triggerDate")
    },
    {
      id: "remainingShots",
      label: translate("screenApp.capsula.remainingShots")
    },
    { id: "nome", label: translate("screenApp.results.patient") },
    { id: "email", label: translate("screens.user.list.email") },
    { id: "cpf", label: translate("screens.user.list.document") },
    { id: "genero", label: translate("screens.user.list.genre") },
    {
      id: "telefone",
      label: translate("screenApp.listPacient.account.phone")
    }
  ];

  const formatRow = (r: ICapsuleHistory) => {
    return (
      <TableRow key={r.id}>
        <TableCell>{formatedDate(r.createdAt, true, true)}</TableCell>
        <TableCell>{r.remainingShots}</TableCell>
        <TableCell>{r.nome}</TableCell>
        <TableCell>{r.email}</TableCell>
        <TableCell>{r.cpf}</TableCell>
        <TableCell>{r.genero}</TableCell>
        <TableCell>{r.telefone}</TableCell>
      </TableRow>
    );
  };
  const nameTitle = translate("screenApp.capsula.usageHistory");
  return (
    <div>
      <div>
        <h5>
          {translate("screens.capsule.labels.serial")}: {params.serialNumber}
        </h5>
        <h5>
          {translate("family.fragrance")}: {params.fragranceName}
        </h5>
      </div>
      <DataTable
        placeholderSearch={translate(
          "screens.capsule.placeholders.typeHereSearch"
        )}
        titleTable={<BackWithTitle title={nameTitle} route="/admin/capsules" />}
        headColumns={headRows}
        formatRows={formatRow}
        rows={mergedData}
        loading={loading}
        onRefresh={getCapsule}
      />
      <Loading isLoading={loading} />
    </div>
  );
};

export default CapsuleDetailing;

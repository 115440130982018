type Location = {
  latitude: number;
  longitude: number;
};

const apiKey = "AIzaSyAV8RD2xKOsFUhTwNKRG4fjoWmlxiuxpoc";

const separa = (location: string): Location | null => {
  try {
    const parsedLocation = JSON.parse(location);
    if (parsedLocation.latitude && parsedLocation.longitude) {
      return {
        latitude: parsedLocation.latitude,
        longitude: parsedLocation.longitude
      };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const fetchAddress = async (
  location: string | undefined
): Promise<any> => {
  const parsedLocation = separa(location!);
  if (!parsedLocation) {
    const address = location == "null" ? "" : location;
    console.log("if", address);
    return address;
  }

  const { latitude, longitude } = parsedLocation;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === "OK") {
      return data.results[0].formatted_address;
    } else {
      // throw new Error(`Geocoding API erro: ${data.status}`);
      return "";
    }
  } catch (error) {
    // throw new Error("Erro ao fazer requisição: " + error);
    return ""
  }
};

import React from "react";
import { Form, Col, Card, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

import FormActions from "../../components/FormActions";
import { AppInput, FileUpload } from "../../../../partials/form";
import withFormValidation from "../../../../hoc/withFormValidation";
import { CampaignApiService } from "../../../../services/Ananse/CampaignApiService";
import FormHelper from "../../../../helpers/FormHelper";
import CollectionDevice from "./CollectionDevice.js";
import SelectLanguage from "../../components/SelectLanguage";
import { TranslationContext } from "_metronic/i18n/language";
import { Wrapper } from "../Users/components/Wrapper";
import { FormInput } from "../Users/components/FormInput";
import IsValidName from "app/utils/validators/IsValidName";

const PIE_VARIANT = 5;
const MAX_SLOTS = 20;
const MIN_SLOTS = 1;

function buildInitialCollection() {
  const initialCollection = [];
  for (let i = 1; i <= MAX_SLOTS; i++) {
    let slot = i + PIE_VARIANT;
    if (slot > MAX_SLOTS) {
      slot -= MAX_SLOTS;
    }

    initialCollection.push({
      slot,
      shotSeconds: 5,
      expirationDays: 90
    });
  }

  return initialCollection;
}

const initialState = {
  name: "",
  cover: null,
  fragranceShots: 200,
  deviceTests: 100,
  collection: buildInitialCollection(),
  currentUploadedCover: null,
  hasDeviceError: false,
  descriptions: {},
  clone: false,
  translate: null
};

class CampaignForm extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      descriptionLanguage: this.convertLanguage()
    };

    this.api = new CampaignApiService();
    this.handleChange = this.props.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.formHelper = new FormHelper(this);
    this.updateCollection = this.updateCollection.bind(this);
    this.submit = this.submit.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }
  async componentDidMount() {
    await this.initializeTranslation();
    if (this.props.match.params.id) {
      this.formHelper.loadFromService();
    }
    if (this.props.match.params.clone) {
      Swal.fire({
        title: this.state.translate("screens.campaigns.clone.title"),
        text: this.state.translate("screens.campaigns.clone.text"),
        icon: "info"
      });
      this.setState({
        clone: true
      });
    }
  }

  handleChangeLanguage = e => {
    this.setState({ descriptionLanguage: e.value });
  };

  mapModelToState(model) {
    let collection = buildInitialCollection();
    if (model.collection) {
      function calculateIndex(c) {
        let index = c.slot - (PIE_VARIANT + 1);
        if (index < 0) {
          index += MAX_SLOTS;
        }

        return index;
      }

      model.collection.forEach(c => (collection[calculateIndex(c)] = c));
    }

    const descriptions = {};
    let descriptionLanguage = null;
    if (model.descriptions && model.descriptions.length) {
      for (const description of model.descriptions) {
        descriptions[description.language] = description.description;

        if (!descriptionLanguage) {
          descriptionLanguage = description.language;
        }
      }
    } else {
      descriptionLanguage = this.state.descriptionLanguage;
    }

    if (this.state.clone) {
      return {
        name: model.name + " - Cópia",
        collection,
        descriptions,
        descriptionLanguage,
        fragranceShots: model.fragranceShots,
        deviceTests: model.deviceTests,
        currentUploadedCover: null
      };
    }

    return {
      name: model.name,
      collection,
      descriptions,
      descriptionLanguage,
      fragranceShots: model.fragranceShots,
      deviceTests: model.deviceTests,
      currentUploadedCover: model.cover
    };
  }

  mapStateToModel() {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("fragranceShots", this.state.fragranceShots);
    formData.append("deviceTests", this.state.deviceTests);
    if (this.state.cover) {
      formData.append("cover.file", this.state.cover);
    }

    this.state.collection
      .filter(c => c.hasOwnProperty("fragrance"))
      .forEach((c, i) => {
        formData.append(
          `collection[${i}][fragranceId]`,
          c.fragrance.fragranceId
        );
        formData.append(`collection[${i}][slot]`, c.slot);
        formData.append(`collection[${i}][shotSeconds]`, c.shotSeconds);
        formData.append(`collection[${i}][expirationDays]`, c.expirationDays);
      });

    Object.keys(this.state.descriptions)
      .filter(lang => !!this.state.descriptions[lang])
      .forEach((lang, i) => {
        formData.append(`descriptions[${i}][language]`, lang);
        formData.append(
          `descriptions[${i}][description]`,
          this.state.descriptions[lang]
        );
      });

    return formData;
  }

  onCleanForm() {
    this.setState({
      ...initialState,
      collection: buildInitialCollection(),
      descriptionLanguage: "en-US"
    });

    document.body.scrollTo(0, 0);
  }

  updateCollection(collection) {
    let hasDeviceError = this.state.hasDeviceError;
    if (hasDeviceError && this.countConfiguredSlots(collection) >= MIN_SLOTS) {
      hasDeviceError = false;
    }

    this.setState({
      ...this.state,
      collection,
      hasDeviceError
    });
  }

  countConfiguredSlots(collection = null) {
    return (collection || this.state.collection).filter(c =>
      c.hasOwnProperty("fragrance")
    ).length;
  }

  submit(e) {
    e.preventDefault();
    const configuredSlots = this.countConfiguredSlots();
    if (configuredSlots < MIN_SLOTS) {
      this.setState({ hasDeviceError: true });
    } else {
      this.setState({ hasDeviceError: false });
    }

    if (this.state.clone) {
      this.formHelper.clone(e);
    } else {
      this.formHelper.submit(e);
    }
  }

  convertLanguage() {
    let { lang } = this.props;

    switch (lang) {
      case "pt":
        return "pt-BR";
      case "en":
        return "en-US";
      case "es":
        return "es-ES";
      default:
        return "en-US";
    }
  }

  render() {
    const translate = this.context;
    const t = key => this.props.intl.formatMessage({ id: key });
    if (this.props.match.params.id) {
      window.setPageTitle(
        `${translate("screens.campaigns.pageTitle.edit")} - ${translate(
          "labels_admin"
        )}`
      );
    } else {
      window.setPageTitle(
        `${translate("screens.campaigns.pageTitle.create")} - ${translate(
          "labels_admin"
        )}`
      );
    }

    const {
      name,
      collection,
      currentUploadedCover,
      hasDeviceError,
      cover,
      fragranceShots,
      deviceTests,
      descriptionLanguage
    } = this.state;

    return (
      <Form onSubmit={this.submit}>
        <Wrapper
          className="card-form"
          headerTitle={translate("screens.campaigns.mainData")}
        >
          <div className="card-body">
            <Row>
              <FormInput
                label={translate("screens.fragrance.labels.name")}
                name="name"
                placeholder={translate("screens.campaigns.placeholders.name")}
                value={name}
                onChange={this.handleChange}
                required
                errorMessage={translate("screens.login.validations.invalid")}
                isInvalid={name && !IsValidName(name)}
                className="col-lg-6"
              />

              <FormInput
                label={translate("screens.campaigns.labels.fragranceShots")}
                type="number"
                name="fragranceShots"
                placeholder={translate("labels_insert_shots")}
                value={fragranceShots}
                onChange={this.handleChange}
                required
                className="col-lg-3"
              />

              <FormInput
                label={translate("screens.campaigns.labels.numberTestsDevice")}
                type="number"
                name="deviceTests"
                placeholder={translate("labels_insert_tests")}
                value={deviceTests}
                onChange={this.handleChange}
                required
                className="col-lg-3"
              />
            </Row>
            <Row>
              <FileUpload
                label={`${translate("screens.campaigns.labels.image")} *`}
                placeholder={translate("screens.campaigns.placeholders.image")}
                name="cover"
                file={cover}
                currentUploadedFile={currentUploadedCover}
                handleChange={this.handleChange}
                validator={this.props.validator}
                validations={"required"}
                accept={"image/*"}
              />

              <div className="col-lg-6 col-12">
                  <div className="col-lg-3">
                    <div className="form-label">
                      {translate("screens.campaigns.labels.language")}
                    </div>
                    <SelectLanguage
                      handleChangeLanguage={this.handleChangeLanguage}
                      languageValue={this.state.descriptionLanguage}
                    />
                  </div>
                <div className="col-lg-6  mt-3">
                  <div className="form-label">
                    {translate("screens.fragrance.labels.description")} *
                  </div>
                  <AppInput
                    as="textarea"
                    name="description"
                    placeholder={translate(
                      "screens.fragrance.placeholders.description"
                    )}
                    maxLength="300"
                    value={this.state.descriptions[descriptionLanguage] || ""}
                    onChange={e =>
                      this.setState({
                        descriptions: {
                          ...this.state.descriptions,
                          [descriptionLanguage]: e.target.value
                        }
                      })
                    }
                    validator={this.props.validator}
                    validations={"required|max:100"}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Wrapper>
        <Wrapper
          className="card-form"
          headerTitle={translate("screens.campaigns.labels.collection")}
        >
          <div className="card-body">
            <CollectionDevice
              t={t}
              collection={collection}
              updateCollection={this.updateCollection}
              hasDeviceError={hasDeviceError}
              defaultIndex={
                this.props.match.params.id
                  ? null
                  : collection.findIndex(c => c.slot === 1)
              }
            />
          </div>
        </Wrapper>

        <FormActions
          module="campaigns"
          formIsValid={true}
          onCleanForm={this.onCleanForm}
        />
      </Form>
    );
  }
}

export default withRouter(withFormValidation(CampaignForm, initialState));

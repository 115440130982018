import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { CapsuleI } from "interfaces";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { Col, Form, Row } from "react-bootstrap";
import FormActions from "components/FormActions";
import Swal from "sweetalert2";
import SelectSearch from "components/Selects/SelectSearch";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Loading from "app/pages/home/components/Loading";
import QRCodesGrid from "./QRCodesGrid";
import { QrCode2 } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";

const CapsuleForm = () => {
  const { isDarkMode } = useThemeApp();
  const isTheme = isDarkMode ? "dark" : "light";
  const translate = useTranslation();
  const api = new AnanseApiService();
  const schema = Yup.object().shape({
    serialNumber: Yup.string()
      .required(translate("screens.login.validations.required"))
      .nullable(),
    dueDate: Yup.date().required(
      translate("screens.login.validations.required")
    )
  });

  const [campaignSelected, setCampaignSelected] = useState(
    {} as { campaignId: number; name: string; obj: { fragranceShots: number } }
  );
  const [loadCampaign, setLoadCampaign] = useState(false);
  const [collections, setCollections] = React.useState<Array<any>>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allowSave, setAllowSave] = React.useState(true);
  const [qrCodes, setQRCodes] = React.useState<Array<any>>([]);
  const [showQRCodes, setShowQRCodes] = React.useState(false);
  const [deviceTestsInCampaigns, setDeviceTestsInCampaigns] = React.useState<
    any
  >("");
  const [remainingTestsChecked, setRemainingTestsChecked] = React.useState(
    false
  );
  const [macAddress, setMacAddress] = React.useState("");
  const [progress, setProgress] = useState(0);
  const [progressSubmit, setProgressSubmit] = useState(0);
  const [valueRemainingShots, setValueRemainingShots] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    control
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerCode: "",
      deviceId: 0,
      fragranceId: 0,
      dueDate: "",
      updatedAt: "",
      deletedAt: "",
      serialNumber: "",
      remainingShots: 0,
      performedShots: 0
    }
  });

  React.useEffect(() => {
    loadingData();
  }, []);

  const loadingData = async () => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: "settings"
      });
      setValue("customerCode", response.customerCode);
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
    }
  };

  const updateDeviceByMacAddress = async () => {
    const response = await api.makeHttpRequest({
      method: "GET",
      url: `devices/mac/${macAddress}`
    });

    const deviceInfo = response;
    const deviceId = deviceInfo.deviceId;

    const updateResponse = await api.makeHttpRequest({
      method: "PUT",
      url: `devices/${deviceId}`,
      data: {
        remainingTests: deviceTestsInCampaigns
      }
    });
  };

  const checkCapsules = async (serialNumber: string, fragranceId: number) => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `capsules/serial/${serialNumber}/${fragranceId}`
      });
      return { status: false, fragranceId, serialNumber };
    } catch (error) {
      return { status: true, fragranceId, serialNumber };
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const totalItems = collections.filter(value => value.selected).length;
      let processedCount = 0;

      const reqBase = { method: "POST", url: "/capsules" };
      for (const value of collections) {
        const body: CapsuleI = {
          customerCode: data.customerCode,
          deviceId: data.deviceId,
          dueDate: data.dueDate,
          fragranceId: parseInt(value.fragranceId),
          remainingShots: valueRemainingShots,
          serialNumber: data.serialNumber,
          performedShots: 0
        };
        if (value.selected) {
          await api.makeHttpRequest({
            ...reqBase,
            data: body
          });
          processedCount++;
          const currentProgress = Math.ceil(
            (processedCount / totalItems) * 100
          ); // Calculating progress as a percentage
          setProgressSubmit(currentProgress);
        }
      }

      if (remainingTestsChecked) {
        await updateDeviceByMacAddress();
      }

      Swal.fire({
        title: translate("defaultMessages.success"),
        text: translate("crudMessages.successText"),
        icon: "success"
      });
    } catch (e) {
      Swal.fire(
        translate(".defaultMessages.error"),
        translate(".screens.user.errors.register"),
        "error"
      );
    } finally {
      setAllowSave(true);
      setLoading(false);
      setProgressSubmit(0); // Reset progress to zero when finished
    }
  };

  const handleReset = () => {
    reset({});
  };
  const handleChangeSeachCampaign = async (campaign: any) => {
    setLoadCampaign(true);
    const url = `/campaigns/${campaign.campaignId}`;
    const reqBase = { method: "GET", url };
    const resposne = await api.makeHttpRequest({
      ...reqBase
    });
    handleChangeSelect(resposne);
    setLoadCampaign(false);
  };
  const handleChangeSelect = (e: any) => {
    const deviceTests = e.deviceTests;
    const newData = e.collection
      .map((item: any) => {
        return {
          fragranceId: item.fragranceId,
          slot: item.slot,
          name: item.fragrance.name,
          selected: false
        };
      })
      .sort((a: any, b: any) => a.slot - b.slot);
    setCampaignSelected({ campaignId: e.campaignId, name: e.name, obj: e });
    setCollections(newData);
    setDeviceTestsInCampaigns(deviceTests);
  };

  const handleSearchDevice = async (e: any) => {
    setValue("deviceId", e.id);
    setMacAddress(e.label);
    handleChangeSeachCampaign(e.obj.campaign);
  };

  const handleCheckboxChange = (itemId: any) => {
    const updatedItems: Array<any> = collections.map((item: any) => {
      if (item.fragranceId === itemId) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setCollections(updatedItems);
  };

  const handleSelectAll = (event: any) => {
    const { checked } = event.target;
    if (checked) {
      const allItems = collections.map((item: any) => {
        return { ...item, selected: true };
      });
      setCollections(allItems);
      setSelectAll(true);
    } else {
      const allItems = collections.map((item: any) => {
        return { ...item, selected: false };
      });
      setCollections(allItems);
      setSelectAll(false);
    }
  };

  const checkItemsInAPI = React.useCallback(async () => {
    setLoading(true);
    const { serialNumber } = getValues();
    const prepareData = collections.filter(
      item => item.selected && serialNumber
    );
    const totalItems = prepareData.length;
    let processedCount = 0;

    for (const [index, item] of prepareData.entries()) {
      try {
        delete item.deselect;
        const response = await checkCapsules(
          serialNumber,
          parseInt(item.fragranceId)
        );
        const itemExists = response.status;
        if (!itemExists) {
          prepareData[index].selected = response.status;
          prepareData[index].deselect = response.status;
        }
      } catch (error) {
        // console.error("Erro ao verificar o item na API", error);
      }
      processedCount++;
      const currentProgress = Math.ceil((processedCount / totalItems) * 100); // Calculating progress as a percentage
      setProgress(currentProgress);
    }

    setCollections(prepareData);
    setAllowSave(false);
    setLoading(false);
    setProgress(0); // Reset progress to zero when finished

    const filterP = prepareData.filter(item => item.deselect === false);
    if (filterP.length > 0) {
      Swal.fire({
        title: translate("defaultMessages.error"),
        text: translate("screens.capsule.messages.validatedCapsules"),
        icon: "info"
      });
      setSelectAll(false);
    }
    if (filterP.length === totalItems) {
      setAllowSave(true);
    }
  }, [collections]);

  React.useEffect(() => {
    const isSelected = collections.filter(
      (item: any) => item.selected === true
    );
    if (isSelected.length === collections.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    if (isSelected.length < 1) {
      setAllowSave(true);
      setSelectAll(false);
    }
  }, [collections, allowSave]);

  const checkCampos = React.useCallback(() => {
    const { deviceId, serialNumber } = getValues();
    const isSelected = collections.filter(
      (item: any) => item.selected === true
    );
    if (serialNumber && deviceId && isSelected.length > 0) {
      return false;
    }
    return true;
  }, [getValues, collections]);

  const handleGenerateQRCodes = (data: any) => {
    const combinedData = collections.map(item => ({
      ...item,
      customerCode: data.customerCode,
      dueDate: data.dueDate,
      serialNumber: data.serialNumber
    }));
    setQRCodes(combinedData);
    setShowQRCodes(true);
  };

  useEffect(() => {
    // if (remainingTestsChecked) {
    //   setValueRemainingShots(campaignSelected?.obj?.fragranceShots * 2);
    // } else {
    setValueRemainingShots(campaignSelected?.obj?.fragranceShots);
    // }
  }, [remainingTestsChecked, campaignSelected]);

  const helperText = (text?: string) => (
    <Form.Text className="text-danger">{text}</Form.Text>
  );

  const today = new Date().toISOString().split("T")[0];

  const inputStyle = {
    backgroundColor: "inherit",
    color: isDarkMode ? "#d1d1d1" : "#707070"
  };

  return (
    <>
      <Card className="mt-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("labels_code")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate("screens.capsule.labels.serial")}
                  disabled
                  {...register("customerCode")}
                  style={inputStyle}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.serial")} *
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={9}
                  minLength={9}
                  placeholder={translate("screens.capsule.labels.serial")}
                  {...register("serialNumber")}
                  style={inputStyle}
                />
                {errors.serialNumber && helperText(errors.serialNumber.message)}
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.capsule.labels.dueDate")}
                </Form.Label>

                <Controller
                  name="dueDate"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      type="date"
                      min={today}
                      value={value ? value : today}
                      onChange={(value: any) => onChange(value.target.value)}
                      style={inputStyle}
                    />
                  )}
                />
                {errors.dueDate && helperText(errors.dueDate.message)}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>{translate("screens.device.title")} *</Form.Label>
                <SelectSearch
                  label={translate("screens.device.labels.select")}
                  required={true}
                  className="kt-width-full"
                  url={`devices?FilterString=`}
                  handleChange={(e: any) => {
                    handleSearchDevice(e);
                  }}
                  convertObject={(obj: any) => ({
                    id: obj.deviceId,
                    value: obj.deviceId,
                    label: `${obj.macAddress}`,
                    obj
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screens.quiz.labels.campaign")} *
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translate("screens.quiz.labels.campaign")}
                  value={campaignSelected.name}
                  disabled
                  style={inputStyle}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" xs="12">
                <Form.Label>
                  {translate("screenApp_quiz_messageAvailable")}
                </Form.Label>
                <Form.Control
                  placeholder={`${translate(
                    "screenApp_quiz_messageAvailable"
                  )} (0-999)`}
                  type="text"
                  value={valueRemainingShots}
                  maxLength={3}
                  onChange={e => {
                    const inputValue = e.target.value;

                    if (/^\d*$/.test(inputValue) && Number(inputValue) <= 999) {
                      setValueRemainingShots(Number(inputValue));
                    }
                  }}
                  style={inputStyle}
                />
                <Form.Text className="text-muted">
                  {translate("maxNumber")}
                </Form.Text>
              </Form.Group>
            </Row>
            <Divider color="transparent" />
            {collections.length ? (
              <Box sx={{ flexGrow: 1, marginTop: 2 }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Checkbox
                    color="success"
                    value="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <Typography>{translate("labels.selectAll")}</Typography>
                </Stack>
                <Divider />
                <Grid container spacing={2}>
                  {collections.map((item: any) => (
                    <Grid key={item.fragranceId} xs={3}>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Checkbox
                          color="success"
                          value={item.selected}
                          checked={item.selected}
                          onChange={() =>
                            handleCheckboxChange(item.fragranceId)
                          }
                        />
                        <Button
                          sx={{ cursor: "none", color: "#000" }}
                          variant="text"
                          color="primary"
                          onClick={() => handleCheckboxChange(item.fragranceId)}
                        >
                          {item.slot + " - " + item.name}
                        </Button>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : null}
          </CardContent>
          <CardActions
            // style={{
            //   backgroundColor: "#fff"
            // }}
            className="border-0"
          >
            <Stack
              sx={{ marginY: "5px" }}
              justifyContent="flex-start"
              alignItems="flex-end"
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
            >
              <Button
                variant="contained"
                color="info"
                disabled={checkCampos()}
                onClick={checkItemsInAPI}
              >
                {translate("screens.capsule.labels.validateCapsules")}
              </Button>
              <Button
                variant="outlined"
                startIcon={<QrCode2 />}
                color="inherit"
                disabled={allowSave}
                onClick={() => handleGenerateQRCodes(getValues())}
              >
                {translate("generate.qrcodes")}
              </Button>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      disabled={allowSave}
                      checked={remainingTestsChecked}
                      onChange={(e: any) =>
                        setRemainingTestsChecked(e.target.checked)
                      }
                    />
                  }
                  label={`${translate(
                    "screenApp.capsula.consumptionByTests"
                  )} : ${deviceTestsInCampaigns}`}
                />
              </FormGroup>
            </Stack>
          </CardActions>
          <FormActions
            module="capsules"
            disableSubmit={allowSave}
            onReset={handleReset}
          />
        </Form>
      </Card>

      <QRCodesGrid
        name={campaignSelected?.name}
        data={qrCodes}
        open={showQRCodes}
        onClose={() => setShowQRCodes(false)}
      />

      <Loading
        isLoading={loading || loadCampaign}
        msg={loadCampaign ? translate("screens_login_loadingcampaign") : ""}
        progress={progress || progressSubmit}
      />
    </>
  );
};

export default CapsuleForm;

import React from "react";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CrudActions from "../../../../../partials/datatable/CrudActions";
import Datatable from "../../../../../partials/datatable/Datatable";
import { CategoryApiService } from "../../../../../services/Ananse/CategoryApiService";
import { TranslationContext } from "_metronic/i18n/language";

class CategoriesListing extends React.Component {
  static contextType = TranslationContext;
  constructor(props) {
    super(props);

    this.api = new CategoryApiService();
    this.state = {
      categories: [],
      reload: false,
      isActive: "true",
      translate: null
    };

    this.setCategories = this.setCategories.bind(this);
    this.onConfirmChangeStatus = this.onConfirmChangeStatus.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.initializeTranslation();
  }

  async initializeTranslation() {
    const translate = await this.context;
    this.setState({ translate });
  }

  setCategories(categories) {
    this.setState({ categories });
  }

  async onConfirmChangeStatus(category) {
    if (category.deletedAt) {
      await this.api.activate(category.categoryId);
    } else {
      await this.api.delete(category.categoryId);
    }

    this.setState({ reload: !this.state.reload });
  }

  async onConfirmDelete(categoryId) {
    await this.api.delete(categoryId, true);
    this.setState({ reload: !this.state.reload });
  }

  render() {
    const translate = this.context;
    window.setPageTitle(`${translate("screens.category.list.title")} - ${translate("labels_admin")}`);

    const headRows = [
      { label: translate("screens.category.list.actions") },
      {
        column: "Name",
        label: translate("screens.category.list.name")
      },
      {
        column: "CreatedAt",
        label: translate("screens.category.list.created")
      }
    ];

    const formatRow = r => {
      const actions = [];
      if (!r.deletedAt) {
        actions.push("edit");
      }

      if (r.deletedAt || !r.fragrances || !r.fragrances.length) {
        actions.push("toggle");
      }

      if (r.deletedAt && (!r.fragrances || !r.fragrances.length)) {
        actions.push("delete");
      }

      return (
        <TableRow hover tabIndex={-1} key={r.categoryId}>
          <TableCell>
            <CrudActions
              actions={actions}
              module="categories"
              onConfirmChangeStatus={this.onConfirmChangeStatus}
              onConfirmDelete={this.onConfirmDelete}
              id={r.categoryId}
              obj={r}
            />
          </TableCell>
          <TableCell scope="row">{r.name}</TableCell>
          <TableCell scope="row">
            {moment(r.createdAt).format("DD/MM/YYYY HH:mm")}
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Datatable
        title={translate("screens.category.list.type")}
        endpoint="/categories"
        headRows={headRows}
        formatRow={formatRow}
        rows={this.state.categories}
        setRows={this.setCategories}
        reload={this.state.reload}
        placeholderSearch={translate("screens.category.placeholders.search")}
        isActive={this.state.isActive}
        buttons={[
          {
            label: translate("datatable.add"),
            onClick: null,
            icone: "add",
            path: "/admin/categories/create"
          }
        ]}
      />
    );
  }
}

export default CategoriesListing;

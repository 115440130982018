import { useTranslation } from "_metronic/i18n/language";
import { useThemeApp } from "_metronic/materialUIThemeProvider/ThemeProvider";
import React, { ChangeEvent } from "react";
import { Col, Form } from "react-bootstrap";

interface InputSelectProps {
  name?: string | undefined;
  label: string;
  value: string;
  handleChange: (event: ChangeEvent<any>) => void;
  selectData: { key: number; name: string }[];
  lg: string;
  xs: string;
  className?: any;
}

const InputSelect: React.FC<InputSelectProps> = ({
  name,
  value: type,
  handleChange,
  selectData,
  label,
  className
}) => {
  const translate = useTranslation();
  const { isDarkMode } = useThemeApp();
  const theme = isDarkMode ? "dark" : "light";

  return (
    <div className={className}>
      <div className="form-label">{label}</div>
      <select
        className={`form-control custom-select select-border-${theme}`}
        name={name}
        value={type}
        onChange={handleChange}
      >
        <option value="">{translate("screenApp.quiz.selectOption")}</option>
        {selectData
          .map(p => (
            <option key={p.key} value={p.key}>
              {p.name}
            </option>
          ))
          .sort()}
      </select>
    </div>
  );
};

export default InputSelect;

import { UserTypes } from "interfaces";

export function arrayToString(arr: number[]): string {
  return arr.join(';');
}

export function stringToArray(str: string): number[] {
  return str.split(';').map(Number);
}

export function mergeArrays(selected: number[], userIds: number[]): number[] {
  const mergedArray = [...selected, ...userIds];

  const uniqueArray = Array.from(new Set(mergedArray));

  return uniqueArray;
}


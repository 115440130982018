import React from "react";
import Button from "@mui/material/Button/";
import Dialog from "@mui/material/Dialog/";
import DialogActions from "@mui/material/DialogActions/";
import DialogContent from "@mui/material/DialogContent/";
import DialogContentText from "@mui/material/DialogContentText/";
import DialogTitle from "@mui/material/DialogTitle/";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  open: boolean;
  closeDialog: any;
  confirm?: any;
  title: any;
  message: any;
}
export default function ConfirmDialog({
  open,
  closeDialog,
  confirm,
  title,
  message
}: Props) {
  const translate = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title && title !== ""
            ? title
            : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && message !== ""
              ? message
              : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={closeDialog}>
            {confirm? translate("buttons.cancel"):translate("buttons.ok")}
          </Button>
          {confirm&&(

          <Button
            variant="contained"
            onClick={confirm}
            color="success"
            autoFocus
          >
            {translate("buttons.confirm")}
          </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { CRUDApiService } from "./CRUDApiService";

export class DevicesApiService extends CRUDApiService {
  constructor() {
    super('devices');
  }

  removeUser(id) {
    if (!id) {
      throw new Error('id should be provided');
    }

    return this.makeHttpRequest({
      method: 'PUT',
      url: `/remove-user/${id}`
    });
  }
}

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper
} from "@mui/material";

import React, { ReactNode } from "react";
import { Form, Row } from "react-bootstrap";
import { formatFragrances } from "../utils";
import { Delete, Edit } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";

interface Props {
  answers?: any[];
  children?: ReactNode;
  clearAnswer?: any;
  collection?: any;
  editAnswer?: any;
  formType?: "1" | "3";
  requiredAnswers?: any;
}
const TableAnswers: React.FC<Props> = ({
  answers,
  children,
  clearAnswer,
  collection,
  editAnswer,
  formType,
  requiredAnswers
}) => {
  const translate = useTranslation();
  return (
    <Row>
      {requiredAnswers ? (
        <Form.Label style={{ color: "#FF0000" }}>
          {translate("screens.quiz.errors.answersRequired")}
        </Form.Label>
      ) : null}
      <Paper style={{ marginTop: 10 }}>
        <TableContainer>
          <Table size="small" aria-label="customized table">
            <TableHead>
              <TableRow className="text-uppercase">
                <TableCell className="font-weight-bold">
                  {translate("screens.quiz.list.actions")}
                </TableCell>
                <TableCell className="font-weight-bold">
                  {translate("screens.quiz.labels.answer")}
                </TableCell>
                {formType === "3" ? (
                  <TableCell className="font-weight-bold">
                    {translate("screens.quiz.labels.fragranceResponse")}
                    {/* {
            translate.screens.quiz?.labels
              ?.description
          } */}
                  </TableCell>
                ) : null}
                {/*
          <TableCell className="font-weight-bold" width="20%">
            {
              translate.screens.quiz?.labels
                ?.fragrances
            }
          </TableCell>
        */}
                <TableCell className="font-weight-bold" width="25%">
                  {translate("screens.quiz.labels.next")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answers?.length ? (
                answers?.map((a, i) => {
                  console.log(a);
                  return (
                    <TableRow key={"answer-" + i} sx={{ cursor: "text" }}>
                      <TableCell>
                        <IconButton
                          size="small"
                          title={translate("screens.quiz.labels.editAnswer")}
                          onClick={() => editAnswer(a, i)}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          title={translate("screens.quiz.labels.deleteAnswer")}
                          onClick={() => clearAnswer(a)}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{a.desc}</TableCell>
                      {formType === "3" && (
                        <TableCell>
                          {formatFragrances(a.description, collection)}
                        </TableCell>
                      )}
                      {/* <TableCell>{formatFragrances(a.fragrances)}</TableCell> */}
                      <TableCell>{a.nextQuizQuestion?.label}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className="text-center">
                  <TableCell colSpan={5}>
                    {translate("screens.quiz.labels.noQuestions")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {children}
    </Row>
  );
};

export default TableAnswers;
